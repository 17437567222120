<script>
export default {
    name: 'SuppliersIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { SUPPLIERS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import SupplierModel from '@/models/Supplier';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Alert from '@/components/Alert';
import DeletionModal from '@/components/DeletionModal';
import ListDisplay from '@/components/ListDisplay';
import SimpleEntityFormModal from '@/components/forms/SimpleEntityFormModal';
import SupplierCard from './partials/SupplierCard';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(SUPPLIERS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to suppliers.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Suppliers state
------------------------------------------------------------------------*/

const suppliersState = reactive({
    data: null,
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadSuppliers = () => {
    sendAbortableRequest(SupplierModel.all(orgId))
        .then((models) => (suppliersState.data = models))
        .finally(() => (suppliersState.isDataLoading = false));
};

const updateSuppliersState = (models) => {
    suppliersState.data = models;
    suppliersState.hasBeenJustUpdated = true;

    setTimeout(() => (suppliersState.hasBeenJustUpdated = false), 250);
};

const supplierPermissions = computed(() => ({
    create: $acl.can(SUPPLIERS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(SUPPLIERS_RESTRICTIONS.UPDATE, currentAccount),
    delete: $acl.can(SUPPLIERS_RESTRICTIONS.DELETE, currentAccount),
}));

/*------------------------------------------------------------------------
                             Supplier form state
------------------------------------------------------------------------*/

const supplierFormState = reactive({
    formData: null,
    validationErrors: null,
    isSubmitting: false,
    isModalOpened: false,
});

const openSupplierFormModal = (formData) => {
    supplierFormState.formData = formData;
    supplierFormState.isModalOpened = true;
};

const closeSupplierFormModal = () => {
    supplierFormState.formData = null;
    supplierFormState.validationErrors = null;
    supplierFormState.isSubmitting = false;
    supplierFormState.isModalOpened = false;
};

const submitSupplierForm = (attributes) => {
    const { formData } = supplierFormState;

    const isEditMode = formData !== null;

    supplierFormState.isSubmitting = true;

    const request = isEditMode
        ? SupplierModel.update(orgId, formData.id, attributes)
        : SupplierModel.create(orgId, attributes);

    request
        .then((models) => {
            updateSuppliersState(models);

            closeSupplierFormModal();

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: `The supplier has been successfully ${isEditMode ? 'updated' : 'created'}`,
            });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                supplierFormState.validationErrors = data.errors;
            }
        })
        .finally(() => (supplierFormState.isSubmitting = false));
};

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const submitDeleteSupplier = () => {
    deletionCallback(() =>
        deletionModel.value.delete(orgId).then((models) => {
            updateSuppliersState(models);

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'The supplier has been successfully deleted',
            });
        })
    );
};

/*------------------------------------------------------------------------
                             Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(loadSuppliers);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Suppliers</h1>
        </template>

        <InPlaceLoader v-if="suppliersState.isDataLoading" />

        <Alert
            v-else-if="suppliersState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Suppliers could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <template v-if="!suppliersState.hasBeenJustUpdated">
                    <ListDisplay
                        v-if="suppliersState.data.length > 0"
                        has-borders
                        data-testid="suppliers_list"
                        :items="suppliersState.data"
                    >
                        <template #item="{ item: supplier }">
                            <SupplierCard :supplier="supplier">
                                <template
                                    v-if="supplierPermissions.update || supplierPermissions.delete"
                                    #actions="{ qaPrefix }"
                                >
                                    <button
                                        v-if="supplierPermissions.update"
                                        type="button"
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                        @click="openSupplierFormModal(supplier)"
                                    >
                                        <EditIcon />
                                    </button>

                                    <button
                                        v-if="supplierPermissions.delete"
                                        type="button"
                                        data-testid="delete_supplier_btn"
                                        @click.stop="openDeletionModal(supplier)"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </template>
                            </SupplierCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        class="pl-suppliers__message"
                        data-testid="suppliers_empty_list_message"
                    >
                        Supplier list is empty.
                    </p>
                </template>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    v-if="supplierPermissions.create"
                    class="pl-suppliers__action"
                    style="transition-delay: 50ms"
                >
                    <button
                        type="button"
                        class="btn btn-primary"
                        data-testid="create_supplier_btn"
                        :disabled="supplierFormState.isModalOpened"
                        @click="openSupplierFormModal(null)"
                    >
                        Create supplier
                    </button>
                </div>
            </transition>

            <SimpleEntityFormModal
                v-if="supplierFormState.isModalOpened"
                is-edit-mode-allowed
                entity-name="supplier"
                :form-data="supplierFormState.formData"
                :validation-errors="supplierFormState.validationErrors"
                :is-submitting="supplierFormState.isSubmitting"
                @submit="submitSupplierForm"
                @close="closeSupplierFormModal"
            />

            <DeletionModal
                v-if="isDeletionModalOpened"
                qa-prefix="delete_supplier_modal"
                :is-pending="isDeletionPending"
                @close="closeDeletionModal"
                @submit-delete="submitDeleteSupplier"
            >
                Are you sure you want to delete "{{ deletionModel.name }}" from the company?
            </DeletionModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-suppliers {
    &__message {
        text-align: center;
        padding: custom-space(3) 0;
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: auto;
        padding-top: custom-space(1);
        width: 50%;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        & > button {
            width: 100%;
        }
    }
}
</style>
