export const mapLeafs = (data, callback) => {
    if (Array.isArray(data)) {
        return data.map(callback);
    }

    for (let node in data) {
        data[node] = mapLeafs(data[node], callback);
    }

    return data;
};

export const filterLeafs = (data, callback) => {
    const filteredData = {};

    if (Array.isArray(data)) {
        return data.filter(callback);
    }

    for (let node in data) {
        const filteredLeafs = filterLeafs(data[node], callback);

        if (filteredLeafs.length > 0) {
            filteredData[node] = filteredLeafs;
        }
    }

    return filteredData;
};

export const findLeafs = (tree, callback, result = [], excludeDuplicates = false) => {
    for (let node in tree) {
        if (Array.isArray(tree[node])) {
            for (const item of tree[node]) {
                if (callback(item)) {
                    if (!excludeDuplicates || !result.some(({ id }) => id === item.id)) {
                        result.push(item);
                    }
                }
            }
        } else {
            findLeafs(tree[node], callback, result);
        }
    }

    return result;
};
