<script>
export default {
    name: 'TimelineCardUI',
};
</script>

<script setup>
import { useSlots } from 'vue';

defineProps({
    isCompleted: {
        type: Boolean,
        default: false,
    },
    isOverproduction: {
        type: Boolean,
        default: false,
    },
    isOverdue: {
        type: Boolean,
        default: false,
    },
    isDismissAllowed: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();

/*-----------------------------------------------------------------------------------
                                    Class names
-----------------------------------------------------------------------------------*/

const rootClassName = 'pl-timeline-card';

const classNames = {
    name: `${rootClassName}__name`,
    actionBtn: `${rootClassName}__action-btn`,
};
</script>

<template>
    <article
        :class="{
            [rootClassName]: true,
            [`${rootClassName}--completed`]: isCompleted && !isOverproduction,
            [`${rootClassName}--overproduction`]: isOverproduction,
            [`${rootClassName}--overdue`]: isOverdue && !isCompleted && !isOverproduction,
            [`${rootClassName}--dismiss-allowed`]: isDismissAllowed,
        }"
        :data-qa-status="isCompleted ? 'completed' : 'uncompleted'"
    >
        <header :class="`${rootClassName}__header`">
            <slot
                name="header-content"
                :classNames="classNames"
            />
        </header>

        <div>
            <slot name="main-content" />
        </div>

        <footer
            v-if="slots['footer-content']"
            :class="`${rootClassName}__footer`"
        >
            <slot name="footer-content" />
        </footer>
    </article>
</template>

<style lang="scss">
.pl-timeline-card {
    position: relative;
    background-color: $white;
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    border: 1px solid $gray-200;

    &--completed,
    &--overproduction {
        .pl-timeline-card__name h2 {
            color: $white;
        }

        .pl-timeline-card__action-btn {
            color: $white;
            border-color: $white;
        }
    }

    &--completed {
        background-color: $primary;
        border-color: $primary;
    }

    &--overproduction {
        background-color: $orange;
        border-color: $orange;

        .pl-timeline-order-item {
            border: none;
        }
    }

    &--overdue {
        background-color: $yellow-light;
        border-color: $orange;
    }

    &--dismiss-allowed {
        position: relative;
        padding-top: custom-space(2);
    }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: custom-space(0.75);
    }

    &__name {
        display: flex;
        align-items: center;
        flex-grow: 1;

        svg {
            flex-shrink: 0;
        }

        h2 {
            color: $primary;
            font-size: $font-size-base * 1.25;
            margin: 0;
        }
    }

    &__action-btn {
        display: flex;
        align-items: center;
        padding: custom-space(0.25) custom-space(1);
        border: 2px solid $primary;
        border-radius: 1.5rem;
        gap: custom-space(0.6);
        flex-shrink: 0;
        text-transform: uppercase;
        font-weight: bold;
        font-size: $font-size-base * 0.75;
        color: $primary;
    }

    &__footer {
        display: flex;
        align-items: center;
        margin-top: custom-space(0.75);
    }

    &:not(&--dismiss-allowed):not(.pl-timeline-report-item-card):has(.pl-badge--absolute) {
        padding-top: custom-space(1.5);
    }

    &:not(&--overproduction) .pl-list-display > .pl-list-display__item:first-child > .pl-timeline-order-item {
        border-top: 1px solid $gray-400;
    }
}
</style>
