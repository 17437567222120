import { cutFloatNumber } from '@/utils/numberUtils';

export const getBatchUnitQtyStep = (unitsData, targetUnit) => {
    const {
        batch_yield_unit: batchYieldUnit,
        batch_yield_amount: batchYieldAmount,
        batch_size_amount: batchSizeAmount,
    } = unitsData;

    if (targetUnit === null || targetUnit.id === null) {
        return batchYieldAmount;
    }

    if (targetUnit.id === batchYieldUnit.id) {
        return 1;
    }

    return batchYieldAmount / batchSizeAmount;
};

export const getBatchYieldQtyAmount = (unitsData, currentUnit, qty) => {
    const currentUnitQtyStep = getBatchUnitQtyStep(unitsData, currentUnit);

    return Math.round(qty * currentUnitQtyStep);
};

export const getBatchUnitQtyAmount = (unitsData, currentUnit, targetUnit, qty, enableCut = false) => {
    const batchYieldQtyAmount = getBatchYieldQtyAmount(unitsData, currentUnit, qty);

    const unitQtyStep = getBatchUnitQtyStep(unitsData, targetUnit);

    const value = batchYieldQtyAmount / unitQtyStep;

    return enableCut ? cutFloatNumber(value) : value;
};

export const getBatchUnitQtyDisplay = (unitsData, currentUnit, targetUnit, qty, onHandQty = 0) => {
    const batchUnitQtyAmount = getBatchUnitQtyAmount(unitsData, currentUnit, targetUnit, qty, true);

    const unitValue = `${targetUnit?.value || 'batches'}`;

    if (+onHandQty > 0) {
        const targetOnHandQtyAmount = getBatchUnitQtyAmount(unitsData, currentUnit, targetUnit, onHandQty, true);

        return `${batchUnitQtyAmount} (*${targetOnHandQtyAmount}) ${unitValue}`;
    }

    return `${batchUnitQtyAmount} ${unitValue}`;
};

export const getBatchYieldUnitQtyDisplay = (unitsData, currentUnit, qty, includeBatchesDisplay = false) => {
    const batchYieldUnitQtyDisplay = getBatchUnitQtyDisplay(unitsData, currentUnit, unitsData.batch_yield_unit, qty);

    if (includeBatchesDisplay) {
        const batchesUnitQtyDisplay = getBatchUnitQtyDisplay(unitsData, currentUnit, null, qty);

        return `${batchesUnitQtyDisplay} / ${batchYieldUnitQtyDisplay}`;
    }

    return batchYieldUnitQtyDisplay;
};
