<script>
export default {
    name: 'AddDepartmentsIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 107,
    },
    height: {
        type: Number,
        default: 111,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 107 111"
        :width="width"
        :height="height"
    >
        <path
            d="M17.8884 96.5189C15.2849 96.5189 12.9228 95.2482 11.18 93.1946C11.1788 94.4253 11.1394 96.202 10.8525 97.498C11.0408 97.6288 11.1868 97.8437 11.2153 98.1109C11.5872 101.553 14.4558 104.149 17.8884 104.149C21.3204 104.149 24.1889 101.553 24.5615 98.1109C24.5904 97.8432 24.7333 97.6288 24.9244 97.4983C24.6374 96.202 24.598 94.4253 24.5968 93.1944C22.854 95.2482 20.4917 96.5189 17.8884 96.5189Z"
            fill="#FFB980"
        />
        <path
            d="M24.4512 93.351C22.723 95.3089 20.4223 96.5191 17.8884 96.5191C15.2849 96.5191 12.9228 95.2484 11.18 93.1947C11.1788 94.4254 11.1394 96.2021 10.8525 97.4981C11.0408 97.6289 11.1868 97.8438 11.2153 98.111C11.3797 99.6325 12.0331 100.987 13.0062 102.027C17.4939 101.308 22.5874 96.2679 24.4512 93.351Z"
            fill="#F8AB6B"
        />
        <path
            d="M31.4923 98.4136C31.4624 98.4041 31.4326 98.3966 31.402 98.3916L25.4523 97.3762C25.4159 97.37 25.338 97.3667 25.3009 97.3667C24.9246 97.3667 24.6073 97.6859 24.5614 98.1111C24.1888 101.553 21.3202 104.149 17.8883 104.149C14.4556 104.149 11.5872 101.553 11.2152 98.1111C11.1687 97.6736 10.8221 97.3444 10.4568 97.3672C10.4131 97.3643 10.3702 97.3685 10.3244 97.3764L4.37466 98.3917C4.34404 98.3967 4.31427 98.4041 4.28436 98.4137C1.47176 99.3094 0 102.457 0 105.103V108.388C0 109.786 1.09476 110.923 2.44052 110.923H33.336C34.6819 110.923 35.7765 109.786 35.7765 108.388V105.103C35.7765 102.457 34.3048 99.3094 31.4923 98.4136Z"
            fill="#CBBBA0"
        />
        <path
            d="M24.5964 75.3252H11.9255C9.87071 75.3252 8.19873 77.2269 8.19873 79.564V84.6504C8.19873 91.1948 12.5456 96.5189 17.8882 96.5189C23.2307 96.5189 27.5776 91.1948 27.5776 84.6504V78.7161C27.5777 76.8465 26.2406 75.3252 24.5964 75.3252Z"
            fill="#FDC88E"
        />
        <path
            d="M23.1057 66H13.4161C12.0142 66 10.8358 67.106 10.5185 68.5908C7.95265 68.9617 5.96265 71.4602 5.96265 74.4774V78.7162C5.96265 80.9009 6.77804 82.939 8.19872 84.3875V79.5638C8.19872 77.2267 9.8707 75.325 11.9255 75.325H24.5964C26.2407 75.325 27.5777 76.8462 27.5777 78.716V84.3866C28.9987 82.9384 29.8138 80.9003 29.8138 78.716V73.6296C29.8138 69.4229 26.8047 66 23.1057 66Z"
            fill="#414353"
        />
        <path
            d="M88.8881 96.5189C86.2847 96.5189 83.9226 95.2482 82.1798 93.1946C82.1785 94.4253 82.1391 96.202 81.8523 97.498C82.0405 97.6288 82.1866 97.8437 82.2151 98.1109C82.587 101.553 85.4556 104.149 88.8881 104.149C92.3201 104.149 95.1886 101.553 95.5612 98.1109C95.5902 97.8432 95.7331 97.6288 95.9241 97.4983C95.6371 96.202 95.5978 94.4253 95.5965 93.1944C93.8537 95.2482 91.4916 96.5189 88.8881 96.5189Z"
            fill="#FFB980"
        />
        <path
            d="M95.4509 93.351C93.7228 95.3089 91.4221 96.5191 88.8881 96.5191C86.2847 96.5191 83.9226 95.2484 82.1798 93.1947C82.1785 94.4254 82.1391 96.2021 81.8523 97.4981C82.0405 97.6289 82.1866 97.8438 82.2151 98.111C82.3794 99.6325 83.0328 100.987 84.006 102.027C88.4936 101.308 93.5872 96.2679 95.4509 93.351Z"
            fill="#F8AB6B"
        />
        <path
            d="M102.492 98.4136C102.462 98.4041 102.432 98.3966 102.402 98.3916L96.4522 97.3762C96.4158 97.37 96.3379 97.3667 96.3008 97.3667C95.9245 97.3667 95.6071 97.6859 95.5613 98.1111C95.1887 101.553 92.32 104.149 88.8882 104.149C85.4555 104.149 82.587 101.553 82.2151 98.1111C82.1685 97.6736 81.822 97.3444 81.4567 97.3672C81.413 97.3643 81.3701 97.3685 81.3242 97.3764L75.3745 98.3917C75.3439 98.3967 75.3141 98.4041 75.2842 98.4137C72.4718 99.3095 71 102.457 71 105.103V108.388C71 109.786 72.0948 110.923 73.4405 110.923H104.336C105.682 110.923 106.777 109.786 106.777 108.388V105.103C106.777 102.457 105.305 99.3094 102.492 98.4136Z"
            fill="#EDEDED"
        />
        <path
            d="M95.5964 75.3252H82.9255C80.8707 75.3252 79.1987 77.2269 79.1987 79.564V84.6504C79.1987 91.1948 83.5456 96.5189 88.8882 96.5189C94.2307 96.5189 98.5776 91.1948 98.5776 84.6504V78.7161C98.5779 76.8465 97.2407 75.3252 95.5964 75.3252Z"
            fill="#FDC88E"
        />
        <path
            d="M94.1058 66H84.4164C83.0145 66 81.836 67.106 81.5187 68.5908C78.9529 68.9617 76.9629 71.4602 76.9629 74.4774V78.7162C76.9629 80.9009 77.7783 82.939 79.199 84.3875V79.5638C79.199 77.2267 80.8709 75.325 82.9257 75.325H95.5966C97.241 75.325 98.578 76.8462 98.578 78.716V84.3866C99.9989 82.9384 100.814 80.9003 100.814 78.716V73.6296C100.814 69.4229 97.8049 66 94.1058 66Z"
            fill="#414353"
        />
        <path
            d="M53.4447 31.4679C50.7602 31.4679 48.3247 30.1577 46.5277 28.0401C46.5264 29.3092 46.4858 31.1409 46.1899 32.4774C46.384 32.6123 46.5346 32.8338 46.564 33.1094C46.9475 36.6587 49.9053 39.3349 53.4447 39.3349C56.9833 39.3349 59.9411 36.6584 60.3253 33.1094C60.3551 32.8335 60.5025 32.6123 60.6995 32.4778C60.4036 31.1412 60.363 29.3093 60.3616 28.0401C58.5647 30.1577 56.1291 31.4679 53.4447 31.4679Z"
            fill="#FDC88E"
        />
        <path
            d="M60.2115 28.2014C58.4296 30.2202 56.0575 31.4679 53.4447 31.4679C50.7602 31.4679 48.3247 30.1577 46.5277 28.0401C46.5264 29.3092 46.4858 31.1409 46.1899 32.4774C46.384 32.6123 46.5346 32.8338 46.564 33.1094C46.7336 34.6783 47.4073 36.0752 48.4107 37.1471C53.0379 36.406 58.2898 31.2091 60.2115 28.2014Z"
            fill="#FFB980"
        />
        <path
            d="M67.4714 33.4215C67.4407 33.4116 67.4099 33.404 67.3783 33.3989L61.2437 32.3519C61.2062 32.3454 61.1259 32.3421 61.0876 32.3421C60.6996 32.3421 60.3724 32.6712 60.3251 33.1094C59.9408 36.6583 56.983 39.3349 53.4444 39.3349C49.905 39.3349 46.9472 36.6589 46.5637 33.1094C46.5157 32.6583 46.1584 32.319 45.7817 32.3424C45.7366 32.3394 45.6923 32.3437 45.6451 32.3518L39.5104 33.3987C39.4789 33.4039 39.4482 33.4115 39.4174 33.4214C36.5175 34.3451 35 37.5905 35 40.3192V43.7055C35 45.1472 36.1288 46.3202 37.5164 46.3202H69.3725C70.7601 46.3202 71.8889 45.1473 71.8889 43.7055V40.3192C71.889 37.5905 70.3714 34.3451 67.4714 33.4215Z"
            fill="#CF382F"
        />
        <path
            d="M60.3611 9.6152H47.2962C45.1775 9.6152 43.4536 11.5759 43.4536 13.9858V19.2304C43.4536 25.9783 47.9356 31.4679 53.4444 31.4679C58.9531 31.4679 63.4351 25.9783 63.4351 19.2304V13.1116C63.4351 11.1838 62.0564 9.6152 60.3611 9.6152Z"
            fill="#FDC88E"
        />
        <path
            d="M58.8242 0H48.8334C47.3879 0 46.1729 1.14039 45.8456 2.67139C43.2 3.05381 41.1482 5.63003 41.1482 8.74109V13.1116C41.1482 15.3643 41.989 17.4659 43.4538 18.9594V13.9857C43.4538 11.5759 45.1776 9.6152 47.2964 9.6152H60.3612C62.0566 9.6152 63.4353 11.1837 63.4353 13.1116V18.9584C64.9004 17.4653 65.7409 15.3638 65.7409 13.1116V7.86698C65.7409 3.52933 62.6382 0 58.8242 0Z"
            fill="#1D1D1B"
        />
        <path
            d="M54.7188 84.7188H53.7188V85.7188V91C53.7188 91.0518 53.6977 91.1065 53.6521 91.1521C53.6065 91.1977 53.5518 91.2188 53.5 91.2188C53.4482 91.2188 53.3935 91.1977 53.3479 91.1521C53.3023 91.1065 53.2812 91.0518 53.2812 91V85.7188V84.7188H52.2812H47C46.9482 84.7188 46.8935 84.6977 46.8479 84.6521C46.8023 84.6065 46.7812 84.5518 46.7812 84.5C46.7812 84.4482 46.8023 84.3935 46.8479 84.3479C46.8935 84.3023 46.9482 84.2812 47 84.2812H52.2812H53.2812V83.2812V78C53.2812 77.9482 53.3023 77.8935 53.3479 77.8479C53.3935 77.8023 53.4482 77.7812 53.5 77.7812C53.5518 77.7812 53.6065 77.8023 53.6521 77.8479C53.6977 77.8935 53.7188 77.9482 53.7188 78V83.2812V84.2812H54.7188H60C60.0518 84.2812 60.1065 84.3023 60.1521 84.3479C60.1977 84.3935 60.2188 84.4482 60.2188 84.5C60.2188 84.5518 60.1977 84.6065 60.1521 84.6521C60.1065 84.6977 60.0518 84.7188 60 84.7188H54.7188ZM53.5 67.25C43.994 67.25 36.25 74.994 36.25 84.5C36.25 94.006 43.994 101.75 53.5 101.75C63.006 101.75 70.75 94.006 70.75 84.5C70.75 74.994 63.006 67.25 53.5 67.25Z"
            fill="url(#paint0_linear_970_2546)"
            stroke="white"
            stroke-width="2"
        />
        <rect
            x="16"
            y="55"
            width="73"
            height="3"
            fill="#414353"
        />
        <rect
            x="16"
            y="55"
            width="73"
            height="3"
            fill="black"
            fill-opacity="0.2"
        />
        <rect
            x="52"
            y="58"
            width="9"
            height="3"
            transform="rotate(-90 52 58)"
            fill="#414353"
        />
        <rect
            x="52"
            y="58"
            width="9"
            height="3"
            transform="rotate(-90 52 58)"
            fill="black"
            fill-opacity="0.2"
        />
        <rect
            x="16"
            y="64"
            width="9"
            height="3"
            transform="rotate(-90 16 64)"
            fill="#414353"
        />
        <rect
            x="16"
            y="64"
            width="9"
            height="3"
            transform="rotate(-90 16 64)"
            fill="black"
            fill-opacity="0.2"
        />
        <rect
            x="88"
            y="64"
            width="9"
            height="3"
            transform="rotate(-90 88 64)"
            fill="#414353"
        />
        <rect
            x="88"
            y="64"
            width="9"
            height="3"
            transform="rotate(-90 88 64)"
            fill="black"
            fill-opacity="0.2"
        />
        <defs>
            <linearGradient
                id="paint0_linear_970_2546"
                x1="69.75"
                y1="84.5"
                x2="37.25"
                y2="84.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF4439" />
                <stop
                    offset="1"
                    stop-color="#CF382F"
                />
            </linearGradient>
        </defs>
    </svg>
</template>
