import { markRaw } from 'vue';

import { DISTRIBUTION_MULTIPLE_OPTION, DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import MultipleUsersIcon from '@/components/icons/MultipleUsersIcon';
import BoldCheckIcon from '@/components/icons/BoldCheckIcon';
import NotebookIcon from '@/components/icons/NotebookIcon';

export const getOpenItemsOptionData = () => ({
    value: DISTRIBUTION_TYPES.OPEN_ITEMS,
    text: 'To open items',
    selectedText: 'Open items',
    icon: markRaw(NotebookIcon),
});

export const constructAssigneeOptions = (
    distributionType,
    distribution,
    availableAssignees,
    includeMultipleDistributionOption = true,
    includeOpenItemsOption = true
) => {
    if (!availableAssignees) {
        return null;
    }

    if (distributionType === DISTRIBUTION_TYPES.MIXED) {
        return [{ value: DISTRIBUTION_TYPES.MIXED, text: 'Mixed assignment' }];
    }

    const getBaseOptions = (selectedAssignees = []) =>
        availableAssignees.map(({ id, name }) => {
            const isAssigneeSelected = !!selectedAssignees.find(({ assignee_id: assigneeId }) => assigneeId === id);

            return {
                value: id,
                text: name,
                icon: isAssigneeSelected ? markRaw(BoldCheckIcon) : null,
            };
        });

    const getMultipleDistributionOption = (selectedAssignees = []) => {
        if (availableAssignees.length <= 1 || !includeMultipleDistributionOption) {
            return [];
        }

        let option;

        if (selectedAssignees.length === 0) {
            option = {
                text: 'Multiple employees...',
                value: DISTRIBUTION_MULTIPLE_OPTION,
                icon: markRaw(MultipleUsersIcon),
            };
        } else {
            option = {
                text: 'Change selection',
                selectedText: `${selectedAssignees.length} employees`,
                value: DISTRIBUTION_MULTIPLE_OPTION,
                icon: markRaw(MultipleUsersIcon),
            };
        }

        return [option];
    };

    const getOpenItemsOption = () => {
        if (!includeOpenItemsOption) {
            return [];
        }

        return [getOpenItemsOptionData()];
    };

    switch (distributionType) {
        case DISTRIBUTION_TYPES.SHARE:
        case DISTRIBUTION_TYPES.SPLIT:
        case DISTRIBUTION_TYPES.SPLIT_EQUAL:
            return [
                ...getBaseOptions(distribution),
                ...getMultipleDistributionOption(distribution),
                ...getOpenItemsOption(),
            ];

        default:
            return [...getBaseOptions(), ...getMultipleDistributionOption(), ...getOpenItemsOption()];
    }
};

export const constructAssigneeSelectedOption = (distributionType, distribution) => {
    switch (distributionType) {
        case DISTRIBUTION_TYPES.DIRECT:
            return distribution[0].assignee_id;

        case DISTRIBUTION_TYPES.SPLIT:
        case DISTRIBUTION_TYPES.SHARE:
        case DISTRIBUTION_TYPES.SPLIT_EQUAL:
            return DISTRIBUTION_MULTIPLE_OPTION;

        case DISTRIBUTION_TYPES.OPEN_ITEMS:
            return DISTRIBUTION_TYPES.OPEN_ITEMS;

        case DISTRIBUTION_TYPES.MIXED:
            return DISTRIBUTION_TYPES.MIXED;

        default:
            return null;
    }
};

export const getSplitDistributionPayload = (item, distributionData) => {
    const { ordered_BY_qty: orderedBYQty } = item;

    let remainingQty = orderedBYQty;

    const distribution = distributionData
        .sort((a, b) => +a - +b)
        .map(({ id, percentage }) => {
            const step = Math.max(Math.floor((orderedBYQty * percentage) / 100), 1);

            remainingQty -= step;

            return { assignee_id: +id, qty: step };
        });

    let assigneeIndex = 0;

    while (remainingQty > 0) {
        distribution[assigneeIndex].qty += 1;
        remainingQty -= 1;

        if (assigneeIndex === distribution.length - 1) {
            assigneeIndex = 0;
        } else {
            assigneeIndex++;
        }
    }

    return distribution;
};
