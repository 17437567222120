/*------------------------------------------------------------------------
                      Quick fill order item controls
------------------------------------------------------------------------*/

export const QF_ORDER_ITEM_CONTROLS = {
    DONE: {
        TITLE: 'Done',
        TYPE: 'all',
    },
    OUT_OF_STOCK: {
        TITLE: 'Out of stock',
        TYPE: 'out_of_stock',
    },
    DID_NOT_RECEIVE: {
        TITLE: 'Did not receive',
        TYPE: 'out_of_stock',
    },
    PARTIAL: {
        TITLE: 'Partial',
        TYPE: 'custom',
    },
};

/*------------------------------------------------------------------------
                                 Reassign
------------------------------------------------------------------------*/

export const ORDER_ITEM_REASSIGN_TYPES = {
    ALL: 'all_items',
    SELECTED: 'selected_items',
};

export const ORDER_ITEM_REASSIGN_TYPES_OPTIONS = [
    { value: ORDER_ITEM_REASSIGN_TYPES.ALL, text: 'All Items' },
    { value: ORDER_ITEM_REASSIGN_TYPES.SELECTED, text: 'Selected Items' },
];
