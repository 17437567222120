/**
 * Base model class.
 *
 * @author Loris Leiva
 * @see https://gist.github.com/lorisleiva/93e66ba226ec53cc13c9e54d7f334f2c
 */

export default class Model {
    constructor(attributes = {}) {
        this.fill(attributes);
    }

    static make(attributes = {}) {
        return Array.isArray(attributes) && (attributes.length === 0 || typeof attributes[0] === 'object')
            ? attributes.map((nested) => new this(nested))
            : new this(attributes);
    }

    static withAbort(request, onFulfilled) {
        const wrapperRequest = request.then((response) => Promise.resolve(onFulfilled(response)));

        wrapperRequest.abort = request.abort;

        return wrapperRequest;
    }

    fill(attributes = {}) {
        this.setAttributes(attributes);
        this.wrapRelationships();
        return this;
    }

    setAttributes(attributes) {
        Object.assign(this, attributes);
    }

    getAttributes() {
        return { ...this };
    }

    clone() {
        return this.constructor.make({ ...this.getAttributes() });
    }

    wrapRelationships() {
        let attributes = this.getAttributes() || {};
        let relationships = this.getRelationships() || {};

        Object.keys(relationships).forEach((key) => {
            if (Object.prototype.hasOwnProperty.call(attributes, key) && attributes[key]) {
                attributes[key] = relationships[key].make(attributes[key]);
            }
        });

        this.setAttributes(attributes);
    }

    getRelationships() {
        return {};
    }
}
