<script>
export default {
    name: 'PARTemplateShowView',
};
</script>

<script setup>
import { watch, reactive, computed, inject, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { isOrderEditing } from '@/utils/orderUtils';
import { PAR_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import PARTemplateModel from '@/models/PARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import DeletionModal from '@/components/DeletionModal';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PAR_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to PAR templates.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const templateState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadTemplate = () => {
    sendAbortableRequest(
        PARTemplateModel.find(orgId, props.templateId, {
            group_items_by: store.state.PLOrderViewSettings.groupItemsBy,
        })
    )
        .then((modelData) => (templateState.data = modelData))
        .finally(() => (templateState.isDataLoading = false));
};

const redirectToPendingTemplate = () => {
    const template = currentPARTemplate.value;

    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending template. Please finalize or cancel it, before editing a new one.',
    });

    if (isOrderEditing(template)) {
        return router.replace({
            name: SETTINGS_ROUTE_NAMES.PAR.EDIT.INDEX,
            params: { templateId: template.id },
        });
    }

    return router.replace({ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.INDEX });
};

const initEditTemplate = () => {
    if (currentPARTemplate.value !== null) {
        return redirectToPendingTemplate();
    }

    templateState.isActionProcessing = true;

    templateState.data
        .createDraft()
        .then((templateModel) => {
            store.commit('setCurrentPARTemplate', templateModel);

            router.push({
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.INDEX,
                params: { templateId: props.templateId },
            });
        })
        .finally(() => (templateState.isActionProcessing = false));
};

const templatePermissions = computed(() => ({
    update: $acl.can(PAR_RESTRICTIONS.UPDATE, currentAccount, templateState.data),
    delete: $acl.can(PAR_RESTRICTIONS.DELETE, currentAccount, templateState.data),
}));

watch(() => store.state.PLOrderViewSettings.groupItemsBy, loadTemplate, { immediate: true });

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const submitDeleteTemplate = () => {
    deletionCallback(() =>
        deletionModel.value.delete().then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Template has been successfully deleted',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });
        })
    );
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.PAR.INDEX">
        <template #title>
            <h1>PAR Details</h1>
        </template>

        <template
            v-if="templateState.data !== null"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                :key="`group-items-by-option-${value}`"
                                :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List
                            v-if="templatePermissions.update || templatePermissions.delete"
                            title="Actions"
                        >
                            <GroupedSettings.ListItem
                                v-if="templatePermissions.update"
                                @click="initEditTemplate"
                            >
                                {{ templateState.data.draft === null ? 'Edit template' : 'Continue editing' }}
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem
                                v-if="templatePermissions.delete"
                                @click="openDeletionModal(templateState.data)"
                            >
                                Delete template
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <OverlayLoader
            v-if="templateState.isActionProcessing"
            enable-sticky
        />

        <InPlaceLoader v-if="templateState.isDataLoading" />

        <Alert
            v-else-if="templateState.data === null"
            type="danger"
        >
            Template could not be loaded for some reason. Please try again later.
        </Alert>

        <OrderSummaryCard
            v-else
            :order="templateState.data"
        />

        <DeletionModal
            v-if="isDeletionModalOpened"
            qa-prefix="delete_template_modal"
            :is-pending="isDeletionPending"
            @close="closeDeletionModal"
            @submit-delete="submitDeleteTemplate"
        >
            Are you sure you want to delete the template "{{ deletionModel.name }}"?
        </DeletionModal>
    </TopBarLayout>
</template>
