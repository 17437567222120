<script>
export default {
    name: 'Switcher',
};
</script>

<script setup>
const props = defineProps({
    modelValue: {
        type: [String, Number],
        required: true,
    },
    options: {
        type: Array,
        required: true,
    },
});

const emit = defineEmits(['update:modelValue']);

/*------------------------------------------------------------------------
                              Switcher state
------------------------------------------------------------------------*/

const isOptionActive = (option) => props.modelValue === option.value;

const toggleOption = () => {
    const [firstOption, secondOption] = props.options;

    emit('update:modelValue', isOptionActive(firstOption) ? secondOption.value : firstOption.value);
};
</script>

<template>
    <div class="pl-switcher">
        <div
            v-for="option in options"
            :key="`switcher-option: ${option.value}`"
            class="pl-switcher__option"
            :class="{
                'pl-switcher__option--active': isOptionActive(option),
            }"
            @click="emit('update:modelValue', option.value)"
        >
            {{ option.text }}
        </div>

        <button
            type="button"
            @click="toggleOption"
        />
    </div>
</template>

<style lang="scss" scoped>
.pl-switcher {
    $circle-size: 20px;
    $space: 2px;

    display: flex;
    align-items: center;
    justify-content: center;
    gap: custom-space(0.6);

    & > button {
        position: relative;
        width: calc(($circle-size + $space) * 2.25);
        height: calc($circle-size + ($space * 2));
        border-radius: custom-space(1);
        border: 1px solid $gray-400;

        &::after {
            content: '';
            width: calc($circle-size - ($space / 2));
            height: calc($circle-size - ($space / 2));
            position: absolute;
            top: 50%;
            right: calc($space / 2);
            background-color: $primary;
            border-radius: 50%;
            transform: translateY(-50%);
            @include pl-transition(right);
        }
    }

    &__option {
        font-size: $font-size-base * 0.875;
        color: $gray-600;
        cursor: pointer;

        &:last-of-type {
            order: 2;
        }

        &--active {
            color: $primary;
            font-weight: 400;

            &:first-child ~ button::after {
                right: calc(100% - $circle-size);
            }
        }
    }
}
</style>
