<script>
export default {
    name: 'PeopleCreateView',
};
</script>

<script setup>
import { reactive, onBeforeMount, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { PEOPLE_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import EmployeeForm from '@/components/forms/EmployeeForm';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PEOPLE_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to invite employee.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Employee form state
------------------------------------------------------------------------*/

const employeeFormState = reactive({
    validationErrors: null,
    isSubmitting: false,
});

const submitSendInvitation = (attributes) => {
    employeeFormState.isSubmitting = true;

    AccountModel.create(orgId, attributes)
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Employee has been successfully invited',
            });

            router.push({
                name: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX,
                query: { initialTab: route.query.initialTab },
            });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                employeeFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (employeeFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.PEOPLE.INDEX">
        <template #title>
            <h1>Invite new employee</h1>
        </template>

        <EmployeeForm
            :organization-id="orgId"
            :validation-errors="employeeFormState.validationErrors"
            :is-submitting="employeeFormState.isSubmitting"
            :hidden-fields="['role']"
            @submit="submitSendInvitation"
        />
    </TopBarLayout>
</template>
