export const HTTP_RESPONSES_CODE = {
    INTERNAL_SERVER_ERROR: 500,

    BAD_REQUEST: 400,
    UNAUTHORIZED: 401,
    FORBIDDEN: 403,
    NOT_FOUND: 404,
    CONFLICT: 409,
    UNPROCESSABLE_ENTITY: 422,
};
