<script>
export default {
    name: 'Modal',
};
</script>

<script setup>
import { onBeforeMount, onMounted, onUnmounted, onBeforeUnmount, useSlots } from 'vue';

const props = defineProps({
    disableClickOutsideMechanism: {
        type: Boolean,
        default: false,
    },
    isCloseDisabled: {
        type: Boolean,
        default: false,
    },
    isContentCentered: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['close']);

const slots = useSlots();

/*------------------------------------------------------------------------
                          Click outside mechanism
------------------------------------------------------------------------*/

const onClickOutside = () => {
    if (!props.isCloseDisabled && !props.disableClickOutsideMechanism) {
        emit('close');
    }
};

/*------------------------------------------------------------------------
                               Close on Esc
------------------------------------------------------------------------*/

const closeModalOnEsc = (event) => {
    if (!props.isCloseDisabled && event.key === 'Escape') {
        emit('close');
    }
};

onMounted(() => document.addEventListener('keydown', closeModalOnEsc));
onUnmounted(() => document.removeEventListener('keydown', closeModalOnEsc));

/*------------------------------------------------------------------------
                            Disable body scroll
------------------------------------------------------------------------*/

onBeforeMount(() => document.body.classList.add('pl-body--no-scroll'));
onBeforeUnmount(() => document.body.classList.remove('pl-body--no-scroll'));
</script>

<template>
    <transition
        appear
        name="fade"
        type="transition"
    >
        <div class="pl-modal">
            <div class="pl-modal__wrapper">
                <transition
                    appear
                    name="down"
                    type="transition"
                >
                    <section v-click-outside="onClickOutside">
                        <h2 v-if="slots['title']">
                            <slot name="title" />
                        </h2>

                        <div
                            v-if="slots['content']"
                            class="pl-modal__content-wrapper"
                        >
                            <div
                                class="pl-modal__content"
                                :class="{
                                    'pl-modal__content--centered': isContentCentered,
                                }"
                            >
                                <slot name="content" />
                            </div>
                        </div>

                        <div
                            v-if="slots['actions']"
                            class="pl-modal__actions"
                        >
                            <slot name="actions" />
                        </div>
                    </section>
                </transition>
            </div>
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.pl-modal {
    position: fixed;
    inset: 0;
    display: flex;
    background-color: rgba($black, 0.3);
    padding: $grid-gutter-width * 0.5;
    z-index: 100;
    overflow: auto;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin: auto 0;

        & > section {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: custom-space(1.25);
            background-color: $white;
            max-width: custom-space(25);
            width: 100%;
            padding: custom-space(1) custom-space(1.25);
            border-radius: $border-radius;

            @include media-breakpoint-down(md) {
                padding: $grid-gutter-width * 0.5;
            }

            & > h2 {
                position: relative;
                background-color: white;
                color: black;
                font-weight: $font-weight-bolder;
                font-size: $font-size-base * 1.25;
                line-height: 1.45;
                text-transform: none;
                text-align: center;
                word-break: break-word;
                margin-bottom: 0;
            }
        }
    }

    &__content-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: $white;
        word-break: break-word;
    }

    &__content {
        max-width: 100%;
        width: 100%;
        text-align: left;

        &--centered {
            width: auto;
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: custom-space(0.5);
        margin-top: custom-space(0.5);

        & > :slotted(button) {
            flex: 1 1 50%;
        }
    }

    :slotted(.pl-overlay-spinner) {
        border-radius: $border-radius;
    }
}
</style>
