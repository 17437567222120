<template>
    <div
        ref="$root"
        class="pl-range-slider"
    >
        <div
            class="pl-range-slider-progress"
            :style="`width: ${progress}%`"
        >
            <button
                type="button"
                class="pl-range-slider-progress-control"
                :class="{
                    'pl-range-slider-progress-control--active': selectionModeOn,
                }"
                @mousedown="selectionModeEnabler"
                @touchstart.passive="selectionModeEnabler"
            />
        </div>
    </div>

    <footer
        v-if="showMax || showMin"
        class="pl-range-slider-footer"
    >
        <div class="pl-range-slider-footer-min">
            {{ showMinTemplate.replace('${value}', min) }}
        </div>
        <div class="pl-range-slider-footer-max">
            {{ showMaxTemplate.replace('${value}', max) }}
        </div>
    </footer>
</template>

<script>
export default {
    name: 'RangeSliderInput',
};
</script>

<script setup>
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue';

const props = defineProps({
    modelValue: {
        type: [Number, String],
        required: true,
    },
    max: {
        type: [Number, String],
        default: 100,
    },
    min: {
        type: [Number, String],
        default: 0,
    },
    capped: {
        type: Number,
        default: null,
    },
    showMin: {
        type: Boolean,
        default: false,
    },
    showMax: {
        type: Boolean,
        default: false,
    },
    showMinTemplate: {
        type: String,
        default: '${value}',
    },
    showMaxTemplate: {
        type: String,
        default: '${value}',
    },
    step: {
        type: Number,
        default: 0.1,
    },
    precision: {
        type: Number,
        default: 1,
    },
});

const emit = defineEmits(['update:modelValue', 'slider-stopped']);

const progress = ref((props.modelValue / props.max) * 100);
const selectionModeOn = ref(false);
const $root = ref(null);

watch(
    () => props.modelValue,
    (value) => {
        progress.value = Math.min((value / props.max) * 100, 100);
    },
    { immediate: true }
);

const selectionModeEnabler = () => {
    selectionModeOn.value = true;
};
const selectionModeDisabler = () => {
    if (selectionModeOn.value) {
        selectionModeOn.value = false;

        emit('slider-stopped');
    }
};

const stepsRange = computed(() => {
    const stepsRange = [];
    for (let i = props.min; i <= props.max; i = +(i + props.step).toFixed(props.precision)) {
        stepsRange.push(i);
    }
    return stepsRange;
});

const getApproximateStep = (absoluteValue) => {
    for (let i = 0; i < stepsRange.value.length; i++) {
        if (stepsRange.value[i] >= absoluteValue && absoluteValue < stepsRange.value[i + 1]) {
            return stepsRange.value[i];
        }
    }
    return stepsRange.value[stepsRange.value.length - 1];
};

const mouseMoveHandler = ($event) => {
    $event.preventDefault();

    if (selectionModeOn.value) {
        let clientX;

        if ($event.type === 'touchmove') {
            const touches = $event.touches[0];
            clientX = touches.pageX;
        } else {
            clientX = $event.clientX;
        }

        const { x: rootXOffset, width: rootWidth } = $root.value.getBoundingClientRect();

        const mouseXRootOffset = clientX - rootXOffset;
        let percentageValue = (mouseXRootOffset / rootWidth) * 100;
        if (percentageValue < props.min) {
            percentageValue = props.min;
        }

        let absoluteValue = +(props.max * (percentageValue / 100)).toFixed(props.precision);

        if (props.capped !== null && absoluteValue > props.capped) {
            absoluteValue = props.capped;
        }

        if (absoluteValue >= props.min && absoluteValue <= props.max) {
            const approximateStep = getApproximateStep(absoluteValue);
            progress.value = (approximateStep / props.max) * 100;

            emit('update:modelValue', approximateStep);
        }
    }
};

onMounted(() => {
    document.addEventListener('mouseup', selectionModeDisabler);
    document.addEventListener('touchend', selectionModeDisabler);
    $root.value.addEventListener('mousemove', mouseMoveHandler, { passive: false });
    $root.value.addEventListener('touchmove', mouseMoveHandler, { passive: false });
});
onBeforeUnmount(() => {
    document.removeEventListener('mouseup', selectionModeDisabler);
    document.removeEventListener('touchend', selectionModeDisabler);
    $root.value.removeEventListener('mousemove', mouseMoveHandler, { passive: false });
    $root.value.removeEventListener('touchmove', mouseMoveHandler, { passive: false });
});
</script>

<style scoped lang="scss">
$range-slider-height: custom-space(0.375);
$range-slider-control-size: custom-space(1.875);

.pl-range-slider {
    height: $range-slider-height;
    background-color: $gray-400;
    border-radius: calc($range-slider-height / 2);
    box-shadow: inset 0px 2px 2px rgba($black, 0.1);
    margin-top: calc($range-slider-control-size / 2);
    margin-bottom: calc($range-slider-control-size / 2);

    &-progress {
        height: 100%;
        background: linear-gradient(270deg, $red-light 0%, $primary 100%);
        border-radius: inherit;
        position: relative;

        &-control {
            cursor: pointer;
            position: absolute;
            top: calc($range-slider-control-size / -2);
            right: calc($range-slider-control-size / -2);
            width: $range-slider-control-size;
            height: $range-slider-control-size;
            border-radius: 50%;
            background: linear-gradient(180deg, $white 0%, $gray-400 100%);
            box-shadow: 0 4px 4px rgba($black, 0.25);

            &--active {
                background: linear-gradient(180deg, $gray-400 0%, $white 100%);
            }
        }
    }

    &-footer {
        display: flex;
        color: $gray-800;
        font-weight: $font-weight-light;
        margin-top: custom-space(0.5);
        font-size: $font-size-base * 0.875;

        &-min,
        &-max {
            flex-grow: 1;
        }

        &-min {
            padding-right: space(1);
        }

        &-max {
            text-align: right;
            padding-left: space(1);
        }
    }
}
</style>
