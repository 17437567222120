<script>
export default {
    name: 'OrderItemSummary',
};
</script>

<script setup>
import { computed } from 'vue';

import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import { getQFOrderItemOrderedQtyDisplay } from '@/utils/orderItemUtils';
import Progress from '@/components/Progress';
import ItemSummary from '@/components/model-specific/item/ItemSummary';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

/*-----------------------------------------------------------------------------------
                                    Item state
-----------------------------------------------------------------------------------*/

const itemState = computed(() => {
    const { item } = props;
    const { units_data: unitsData } = item;

    return {
        fulfilledQty: getBatchUnitQtyAmount(
            unitsData,
            unitsData.batch_yield_unit,
            unitsData.quick_fill_ordering_unit,
            item.fulfilled_BY_qty
        ),

        confirmedQty: getBatchUnitQtyAmount(
            unitsData,
            unitsData.batch_yield_unit,
            unitsData.quick_fill_ordering_unit,
            item.confirmed_BY_qty
        ),

        orderedQtyDisplay: getQFOrderItemOrderedQtyDisplay(item, true),
    };
});
</script>

<template>
    <div class="pl-order-summary-item">
        <div class="pl-order-summary-item-name-line mb-2">
            <div class="pl-order-summary-item-name-line-name">
                {{ item.name }}
            </div>

            <div class="pl-order-summary-item-name-line-qty">
                {{ itemState.orderedQtyDisplay }}
            </div>
        </div>

        <ItemSummary
            :labor-time="item.display_labor_time"
            :food-cost="item.display_food_cost"
            :labor-cost="item.display_labor_cost"
            :total-cost="item.display_total_cost"
        />

        <Progress
            :min="0"
            :max="+item.ordered_BY_qty"
            :value="+item.fulfilled_BY_qty"
        />

        <div class="pl-order-summary-item-progress-caption">
            <p>
                <span>Fulfilled:</span>

                <b>{{ itemState.fulfilledQty }}</b>
            </p>

            <p>
                <span>Confirmed:</span>

                <b>{{ itemState.confirmedQty }}</b>
            </p>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-order-summary-item {
    margin: custom-space(0.5) 0;
    padding: 12px 15px;
    border: 1px solid $gray-400;
    border-radius: 15px;

    &-name-line {
        display: flex;
        align-items: flex-end;

        &-name {
            line-height: 1.35;
            font-weight: $font-weight-normal;
            flex-grow: 1;
        }

        &-qty {
            text-align: right;
            font-size: $font-size-base * 0.75;
            line-height: 1.7;
            font-weight: $font-weight-normal;
            flex-shrink: 0;
            padding-left: space(2);
        }
    }

    &-progress {
        margin-top: custom-space(0.75);

        &-caption {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 75px;
            font-size: 0.9rem;
            margin-top: custom-space(0.25);

            p {
                display: flex;
                align-items: center;
                gap: 4px;
                margin: 0;
            }
        }
    }
}
</style>
