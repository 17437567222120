import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import SuppliersIndexView from '@/views/dashboard/settings/suppliers/index';

export default [
    {
        path: 'settings/suppliers',
        name: SETTINGS_ROUTE_NAMES.SUPPLIERS,
        component: SuppliersIndexView,
    },
];
