<script>
export default {
    name: 'ItemSummary',
};
</script>

<script setup>
import { ITEM_TYPES } from '@/enums/itemEnums';
import ClockIcon from '@/components/icons/ClockIcon';
import OpenGloveIcon from '@/components/icons/OpenGloveIcon';
import PanIcon from '@/components/icons/PanIcon';

defineProps({
    laborTime: {
        type: String,
        default: null,
    },
    foodCost: {
        type: String,
        default: null,
    },
    laborCost: {
        type: String,
        default: null,
    },
    totalCost: {
        type: String,
        default: null,
    },
    isOpenItem: {
        type: Boolean,
        default: false,
    },
    itemType: {
        type: String,
        default: ITEM_TYPES.ITEM,
    },
});
</script>

<template>
    <ul class="pl-item-summary">
        <li>
            <small>Hours</small>

            <ClockIcon
                :width="15"
                :height="17"
            />

            <span>{{ laborTime ?? '??h' }}</span>
        </li>

        <li
            :class="{
                'pl-item-summary__info--hidden': itemType === ITEM_TYPES.PREP_ACTION,
            }"
        >
            <small>Food cost</small>

            <PanIcon />

            <span>${{ foodCost ?? '??' }}</span>
        </li>

        <li>
            <small>{{ isOpenItem ? 'Est. labor cost' : 'Labor cost' }}</small>

            <OpenGloveIcon />

            <span>${{ laborCost ?? '??' }}</span>
        </li>

        <li>
            <small>Total</small>

            <b>${{ totalCost ?? '??' }}</b>
        </li>
    </ul>
</template>

<style lang="scss" scoped>
.pl-item-summary {
    display: flex;
    gap: custom-space(0.5);
    background-color: $gray-50;
    padding: custom-space(0.625) custom-space(0.875);
    margin-bottom: custom-space(1);
    border-radius: $border-radius;
    list-style: none;

    @include media-breakpoint-down(xl) {
        justify-content: space-between;
    }

    @include media-breakpoint-down(lg) {
        $space: calc($grid-gutter-width / 2);

        flex-direction: column;
        gap: 0;
        padding: custom-space(0.125) $space;
        margin-left: -$space;
        margin-right: -$space;
        border-radius: 0;
    }

    & > li {
        flex: 1 1 25%;
        font-size: $font-size-base * 0.875;
        white-space: nowrap;

        @include media-breakpoint-down(xl) {
            flex: 0 1 auto;
        }

        @include media-breakpoint-down(lg) {
            display: flex;
            align-items: center;
            gap: custom-space(0.6);
            padding: custom-space(0.25) 0;

            & > b {
                order: 2;
                margin-left: auto;
            }

            &:not(:last-child) {
                border-bottom: 1px solid $gray-400;
            }
        }

        & > small {
            display: block;
            color: $gray-800;
            margin-bottom: custom-space(0.325);

            @include media-breakpoint-down(lg) {
                order: 1;
                margin-bottom: 0;
            }
        }

        & > span {
            position: relative;
            top: 2px;
            margin-left: custom-space(0.275);

            @include media-breakpoint-down(lg) {
                order: 2;
                top: 0;
                margin-left: auto;
            }
        }

        & > b {
            font-size: $font-size-base;
            font-weight: $font-weight-normal;
        }

        &:last-child {
            text-align: right;
        }

        &.pl-item-summary__info--hidden {
            opacity: 0;

            @include media-breakpoint-down(lg) {
                display: none;
            }
        }
    }
}
</style>
