<script>
export default {
    name: 'Accordion',
};
</script>

<script setup>
import { ref } from 'vue';

import useSlideToggle from '@/composition/useSlideToggle';

defineProps({
    title: {
        type: String,
        required: true,
    },
    contentModifierPrefix: {
        type: String,
        default: '',
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    dataTestId: {
        type: String,
        required: true,
    },
});

const { enter, beforeEnter, leave } = useSlideToggle();

/*------------------------------------------------------------------------
                              Accordion state
------------------------------------------------------------------------*/

const rootRef = ref(null);
const isAccordionOpened = ref(false);

const toggleAccordionContent = () => (isAccordionOpened.value = !isAccordionOpened.value);

defineExpose({
    scrollIntoView: () => {
        if (rootRef.value) {
            isAccordionOpened.value = true;

            rootRef.value.scrollIntoView({ behavior: 'smooth' });
        }
    },
});
</script>

<template>
    <div
        ref="rootRef"
        class="pl-accordion"
        :class="{
            'pl-accordion--opened': isAccordionOpened,
            'pl-accordion--has-error': hasError,
        }"
    >
        <div
            class="pl-accordion__top-section"
            :data-testid="`${dataTestId}_accordion`"
            @click="toggleAccordionContent"
        >
            <h6>{{ title }}</h6>

            <ArrowDownIcon
                :width="13"
                :height="8"
            />
        </div>

        <transition
            :css="false"
            @enter="enter"
            @leave="leave"
            @before-enter="beforeEnter"
        >
            <div
                v-if="isAccordionOpened"
                class="pl-accordion__content"
                :class="{
                    [`pl-accordion__content--${contentModifierPrefix}`]: contentModifierPrefix.length > 0,
                }"
            >
                <slot />
            </div>
        </transition>
    </div>
</template>

<style lang="scss">
.pl-accordion {
    background-color: $white;
    padding: custom-space(0.4) custom-space(0.75);
    border: 1px solid $gray-200;
    border-radius: $border-radius;
    @include pl-transition(border-color);

    &--has-error {
        border-color: $red-light;

        .pl-accordion__top-section h6 {
            color: $danger;
        }
    }

    &__top-section {
        position: relative;
        display: flex;
        align-items: center;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            left: custom-space(-0.75);
            right: custom-space(-0.75);
            bottom: custom-space(-0.25);
            height: 1px;
            background-color: transparent;
            @include pl-transition(background-color);
        }

        h6 {
            flex-grow: 1;
            font-weight: $font-weight-normal;
            margin-bottom: 0;
            padding: custom-space(0.25) 0;
            @include pl-transition(color);
        }

        svg {
            flex-shrink: 0;
            @include pl-transition(transform);
        }
    }

    &__content {
        margin: custom-space(0.75) 0 custom-space(0.2) 0;
    }

    &--opened &__top-section {
        &::after {
            background-color: $gray-200;
        }

        svg {
            transform: rotate(-180deg);
        }
    }
}
</style>
