<script>
export default {
    name: 'PeopleIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { PEOPLE_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import DeletionModal from '@/components/DeletionModal';
import Alert from '@/components/Alert';
import Tooltip from '@/components/Tooltip';
import Tabs from '@/components/Tabs';
import ListDisplay from '@/components/ListDisplay';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';

const $acl = inject('$acl');

const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                               People state
------------------------------------------------------------------------*/

const peopleState = reactive({
    data: null,
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadPeople = () => {
    sendAbortableRequest(AccountModel.all(orgId, { 'on_demand[]': 'has_unfinished_items' }))
        .then((models) => (peopleState.data = models))
        .finally(() => (peopleState.isDataLoading = false));
};

const updatePeopleState = (models) => {
    peopleState.data = models;
    peopleState.hasBeenJustUpdated = true;

    setTimeout(() => (peopleState.hasBeenJustUpdated = false), 250);
};

const employeesLists = computed(() => {
    const { data } = peopleState;

    if (data === null) {
        return null;
    }

    return data.reduce(
        (acc, employee) => {
            if (employee.joined_at !== null) {
                acc.joined.push(employee);
            } else {
                acc.invited.push(employee);
            }

            return acc;
        },
        { joined: [], invited: [] }
    );
});

const accountPermissions = computed(() => ({
    create: $acl.can(PEOPLE_RESTRICTIONS.CREATE, currentAccount),
    update: (employee) => $acl.can(PEOPLE_RESTRICTIONS.UPDATE, currentAccount, employee),
    delete: (employee) => $acl.can(PEOPLE_RESTRICTIONS.DELETE, currentAccount, employee),
}));

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const tabOptions = computed(() => {
    if (employeesLists.value === null) {
        return [];
    }

    return Object.entries(employeesLists.value).map(([key, values]) => ({
        value: key,
        text: `${key} employees (${values.length})`,
    }));
});

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const submitDeleteAccount = () => {
    deletionCallback(() =>
        deletionModel.value.delete({ 'on_demand[]': 'has_unfinished_items' }).then((models) => {
            updatePeopleState(models);

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message:
                    deletionModel.value.joined_at !== null
                        ? 'The employee has been successfully deleted'
                        : 'The invitation has been successfully removed',
            });
        })
    );
};

/*------------------------------------------------------------------------
                             Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(loadPeople);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>People</h1>
        </template>

        <InPlaceLoader v-if="peopleState.isDataLoading" />

        <Alert
            v-else-if="peopleState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            People list could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <Tabs
                :model-value="route.query.initialTab || null"
                :tab-options="tabOptions"
                :has-been-content-just-updated="peopleState.hasBeenJustUpdated"
            >
                <template #content="{ activeTab }">
                    <ListDisplay
                        v-if="employeesLists[activeTab].length > 0"
                        has-borders
                        :data-testid="`${activeTab}_employees_list`"
                        :items="employeesLists[activeTab]"
                    >
                        <template #item="{ item: employee }">
                            <EmployeeCard :employee="employee">
                                <template
                                    v-if="accountPermissions.update(employee) || accountPermissions.delete(employee)"
                                    #actions="{ qaPrefix }"
                                >
                                    <router-link
                                        v-if="accountPermissions.update(employee)"
                                        :to="{
                                            name: SETTINGS_ROUTE_NAMES.PEOPLE.EDIT,
                                            params: { accountId: employee.id },
                                            query: { initialTab: activeTab },
                                        }"
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                    >
                                        <EditIcon />
                                    </router-link>

                                    <template v-if="accountPermissions.delete(employee)">
                                        <Tooltip
                                            v-if="employee.has_unfinished_items"
                                            enable-transparency
                                            text="You can't delete this employee. This employee currently has unfinished orders."
                                        >
                                            <DeleteIcon />
                                        </Tooltip>

                                        <button
                                            v-else
                                            type="button"
                                            data-testid="delete_employee_btn"
                                            @click.stop="openDeletionModal(employee)"
                                        >
                                            <DeleteIcon />
                                        </button>
                                    </template>
                                </template>
                            </EmployeeCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        :data-testid="`${activeTab}_empty_list_message`"
                    >
                        The list is empty.
                    </p>

                    <transition
                        appear
                        name="scale"
                        type="transition"
                    >
                        <div
                            v-if="accountPermissions.create && activeTab === tabOptions[1].value"
                            class="pl-employees__action"
                            style="transition-delay: 50ms"
                        >
                            <router-link
                                class="btn btn-primary"
                                data-testid="invite_new_member_btn"
                                :to="{
                                    name: SETTINGS_ROUTE_NAMES.PEOPLE.CREATE,
                                    query: { initialTab: tabOptions[1].value },
                                }"
                            >
                                Invite new employee
                            </router-link>
                        </div>
                    </transition>
                </template>
            </Tabs>

            <DeletionModal
                v-if="isDeletionModalOpened"
                qa-prefix="delete_account_modal"
                :is-pending="isDeletionPending"
                :submit-btn-text="deletionModel.joined_at !== null ? 'delete' : 'remove'"
                @close="closeDeletionModal"
                @submit-delete="submitDeleteAccount"
            >
                {{
                    deletionModel.joined_at !== null
                        ? `Are you sure you want to delete "${deletionModel.name}" from the company?`
                        : `Are you sure you want to remove the invitation of employee "${deletionModel.name}"?`
                }}
            </DeletionModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-tabs__content) > p {
    text-align: center;
}

.pl-employees__action {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    padding-top: custom-space(1);

    & > a {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}
</style>
