<script>
export default {
    name: 'SelectableItemQtyCard',
};
</script>

<script setup>
import { ref } from 'vue';

import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import ItemCard from '@/components/model-specific/item/ItemCard';
import QtyInput from '@/components/form-controls/QtyInput';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    isQtyInputLoading: {
        type: Boolean,
        default: false,
    },
    isItemBeingSaved: {
        type: Boolean,
        default: false,
    },
    hasItemBeenJustSaved: {
        type: Boolean,
        default: false,
    },
    excludeQtyType: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['handle-change-qty']);

/*------------------------------------------------------------------------
                                 Qty state
------------------------------------------------------------------------*/

const isQtyTypeOnHand = ref(!props.excludeQtyType && props.item.par_default_qty !== 0);

const qtyTypeOptions = [
    { value: true, text: 'On hand' },
    { value: false, text: 'To order' },
];

const onQtyUpdated = (...args) => emit('handle-change-qty', isQtyTypeOnHand.value, ...args);
</script>

<template>
    <div
        class="pl-selectable-item-qty-card"
        :class="{
            'pl-selectable-item-qty-card--added': item.is_overlaid,
        }"
    >
        <Badge
            v-if="item.prep_tasks_count > 0"
            is-absolute
            size="sm"
            :type="BADGE_TYPES.ORDER_CARD"
        >
            Prep Task ({{ item.prep_tasks_count }})
        </Badge>

        <ItemCard
            include-category-name
            include-only-batch-settings
            :item="item"
        >
            <template #actions>
                <div
                    v-if="!excludeQtyType && item.par_default_qty !== 0"
                    class="pl-switch-btn"
                >
                    <button
                        v-for="{ value, text } in qtyTypeOptions"
                        :key="`qty type option: ${text}`"
                        type="button"
                        class="pl-switch-btn__option"
                        :class="{
                            'pl-switch-btn__option--active': isQtyTypeOnHand === value,
                        }"
                        @click="isQtyTypeOnHand = value"
                    >
                        {{ text }}
                    </button>
                </div>

                <QtyInput
                    custom-on-success-handling
                    ask-for-saving-changes-on-blur
                    is-increment-mode
                    :min="0"
                    :max="9999"
                    :model-value="isQtyTypeOnHand ? item.par_on_hand_qty : item.qty"
                    :loading="isQtyInputLoading"
                    :disabled="isQtyInputLoading"
                    @update:modelValue="onQtyUpdated"
                />

                <small>({{ item.qty_label }})</small>
            </template>

            <template #footer>
                <Alert
                    v-if="!item.has_available_assignees"
                    :type="ALERT_TYPES.WARNING"
                >
                    There are no available assignees.
                </Alert>

                <template v-else>
                    <transition
                        name="scale"
                        type="transition"
                        mode="out-in"
                    >
                        <span
                            v-if="isItemBeingSaved"
                            :key="`saving-caption-${item.prototype_id}`"
                        >
                            Saving...
                        </span>

                        <span
                            v-else-if="hasItemBeenJustSaved"
                            :key="`saved-caption-${item.prototype_id}`"
                        >
                            Saved
                        </span>
                    </transition>

                    <div
                        v-if="!excludeQtyType && item.par_default_qty !== 0"
                        class="pl-selectable-item-qty-card__statuses"
                    >
                        <small>
                            Required:

                            <b>{{ item.par_default_qty }}</b>
                        </small>

                        <small>
                            On hand:

                            <b>{{ item.par_on_hand_qty ?? 0 }}</b>
                        </small>

                        <small>
                            To order:

                            <b>{{ item.qty }}</b>
                        </small>
                    </div>

                    <Alert
                        v-if="item.has_prep_tasks_out_of_time"
                        :type="ALERT_TYPES.WARNING"
                    >
                        Pay attention that this item contains some prep tasks with a time delay.
                    </Alert>
                </template>
            </template>
        </ItemCard>
    </div>
</template>

<style lang="scss" scoped>
.pl-selectable-item-qty-card {
    position: relative;
    background-color: $white;
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    border: 1px solid $gray-200;
    @include pl-transition((background-color, border-color));

    &__statuses {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid $gray-200;
        padding-top: custom-space(0.5);
    }

    &--added {
        background-color: $yellow-light;
    }

    .pl-alert {
        margin-top: custom-space(0.5);
    }

    .pl-badge.pl-badge--absolute {
        left: 50%;
        transform: translateX(-50%);
    }
}

:deep(.pl-item-card__actions) {
    flex-basis: custom-space(8);
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin: custom-space(0.375) 0;
}

:deep(.pl-item-card__footer) {
    margin-top: custom-space(0.25);

    span {
        font-weight: 400;
        font-size: $font-size-base * 0.85;
    }
}

:deep(.pl-qty-input) {
    margin-bottom: custom-space(0.25);
}

.pl-switch-btn {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: custom-space(0.75);
    border: 1px solid $primary;
    border-radius: custom-space(0.25);

    &__option {
        flex: 1 1 50%;
        padding: custom-space(0.25);
        text-align: center;
        font-size: $font-size-base * 0.7;
        line-height: 1;

        &--active {
            color: $white;
            background-color: $primary;
        }
    }
}
</style>
