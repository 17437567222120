<script>
export default {
    name: 'OrganizationForm',
};
</script>

<script setup>
import { reactive, computed, watch } from 'vue';
import { required, maxLength, numeric, minValue, minLength } from '@vuelidate/validators';

import FormManager from '@/utils/form/FormManager';
import { NOTIFICATION_CHANNELS_OPTIONS } from '@/enums/organizationEnums';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import NumericInput from '@/components/form-controls/NumericInput';
import TextInput from '@/components/form-controls/TextInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    defaultHourlyRate: {
        type: [Number, String],
        default: null,
    },
    notificationChannels: {
        type: Array,
        default: () => [],
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            name: '',
            default_hourly_rate: null,
            notification_channels: [],
        },
        {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            default_hourly_rate: {
                required,
                numeric,
                minValue: minValue(1),
            },
        }
    )
);

const submitForm = () => {
    if (form.validate()) {
        const { default_hourly_rate: defaultHourlyRate, ...restPayload } = form.getPayload();

        emit('submit', {
            ...restPayload,
            default_hourly_rate: defaultHourlyRate * 100,
        });
    }
};

const isLoading = computed(() => props.isDataLoading || props.isSubmitting);

const isSaveBtnDisabled = computed(() => !form.isFilled() || form.errors.any() || isLoading.value);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            form.name = props.name;
            form.default_hourly_rate = props.defaultHourlyRate;
            form.notification_channels = props.notificationChannels;
        }
    },
    { immediate: true }
);
</script>

<template>
    <FormUI
        data-test-id="organization_form"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerLg">
                <TextInput
                    v-model="form.name"
                    include-asterisk
                    label="Company name"
                    name="name"
                    autocomplete="name"
                    :has-error="form.errors.has('name')"
                    :data-test-id="`${qaPrefix}_name_input`"
                    @input="form.errors.clear('name')"
                    @blur="form.validate('name')"
                />

                <ValidationErrors
                    v-if="form.errors.has('name')"
                    :data-testid="`${qaPrefix}_name_errors`"
                    :errors="form.errors.get('name')"
                />
            </div>

            <div :class="classNames.spacerLg">
                <NumericInput
                    v-model="form.default_hourly_rate"
                    only-positive
                    always-use-dot
                    include-asterisk
                    label="Default hourly rate"
                    name="default_hourly_rate"
                    :data-test-id="`${qaPrefix}_default_hourly_rate_input`"
                    :max-precision="2"
                    :max="9999"
                    :has-error="form.errors.has('default_hourly_rate')"
                    @input="form.errors.clear('default_hourly_rate')"
                    @blur="form.validate('default_hourly_rate')"
                />

                <ValidationErrors
                    v-if="form.errors.has('default_hourly_rate')"
                    :data-testid="`${qaPrefix}_default_hourly_rate_errors`"
                    :errors="form.errors.get('default_hourly_rate')"
                />

                <small
                    v-else-if="!isDataLoading"
                    :class="classNames.ribbon"
                >
                    Note that default hourly rate will be used only when there is no hourly rate specified
                </small>
            </div>

            <CheckboxInput
                v-model="form.notification_channels"
                label="Send notifications via:"
                name="notification_channels"
                :options="NOTIFICATION_CHANNELS_OPTIONS"
                :data-testid="`${qaPrefix}_notification_channels_input`"
                :has-error="form.errors.has('notification_channels')"
                @blur="form.validate('notification_channels')"
                @update:modelValue="form.validate('notification_channels')"
            />

            <ValidationErrors
                v-if="form.errors.has('notification_channels')"
                :data-testid="`${qaPrefix}_notification_channels_errors`"
                :errors="form.errors.get('notification_channels')"
            />
        </template>

        <template #actions="{ qaPrefix }">
            <button
                type="submit"
                class="btn btn-primary"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="isSaveBtnDisabled"
            >
                Save
            </button>
        </template>
    </FormUI>
</template>
