import { ref } from 'vue';

export default function useUniqueHashTable() {
    const _hashTable = ref({});

    return {
        put(key, value) {
            _hashTable.value[key] = value;
        },
        get(key) {
            return _hashTable.value[key];
        },
        has(key) {
            return key in _hashTable.value;
        },
        any() {
            return Object.entries(_hashTable.value).length > 0;
        },
        delete(key) {
            delete _hashTable.value[key];
        },
        deleteAll() {
            _hashTable.value = {};
        },
    };
}
