/**
 * Please note this directive currently supports only US numbers spacing.
 * TODO: support other countries
 */

function formatInputValue(event) {
    const value = event.target.value;
    if (!value) return;
    event.target.value = putSpaces(value);
}

function putSpaces(value) {
    let digits = value.split('');

    digits = digits.filter((i) => i !== ' ');

    digits.splice(3, 0, ' ');
    digits.splice(7, 0, ' ');

    return digits.join('').trim();
}

export default {
    beforeMount(el) {
        if (el.tagName === 'INPUT') {
            el.addEventListener('input', formatInputValue);
        } else {
            el.textContent = putSpaces(el.textContent).replace(/^0/, '');
        }
    },

    unmounted(el) {
        if (el.tagName === 'INPUT') {
            el.removeEventListener('input', formatInputValue);
        }
    },

    updated(el) {
        if (el.tagName === 'INPUT') {
            el.value = putSpaces(el.value);
        } else {
            el.textContent = putSpaces(el.textContent).replace(/^0/, '');
        }
    },
};
