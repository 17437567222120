<script>
export default {
    name: 'OrderItemControlsCard',
};
</script>

<script setup>
import { computed, reactive, watch } from 'vue';

import { getBatchUnitQtyDisplay } from '@/utils/batchUnitsUtils';
import { QF_ORDER_ITEM_CONTROLS } from '@/enums/orderItemEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import Alert from '@/components/Alert';
import Progress from '@/components/Progress';
import GroupedSettings from '@/components/constructions/grouped-settings';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    categoryName: {
        type: String,
        required: true,
    },
    isFulfillControls: {
        type: Boolean,
        required: true,
    },
    isLoading: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['handle-report-order-item']);

/*-----------------------------------------------------------------------------------
                                    Order item actions
-----------------------------------------------------------------------------------*/

const actionOptions = computed(() => {
    const { item, isFulfillControls } = props;

    const getDoneAction = (qty) => ({
        title: QF_ORDER_ITEM_CONTROLS.DONE.TITLE,
        type: QF_ORDER_ITEM_CONTROLS.DONE.TYPE,
        qty: +qty,
    });

    const outOfStockAction = {
        title: QF_ORDER_ITEM_CONTROLS.OUT_OF_STOCK.TITLE,
        type: QF_ORDER_ITEM_CONTROLS.OUT_OF_STOCK.TYPE,
        qty: 0,
    };

    const didNotReceiveAction = {
        title: QF_ORDER_ITEM_CONTROLS.DID_NOT_RECEIVE.TITLE,
        type: QF_ORDER_ITEM_CONTROLS.DID_NOT_RECEIVE.TYPE,
        qty: 0,
    };

    const partialAction = {
        title: QF_ORDER_ITEM_CONTROLS.PARTIAL.TITLE,
        type: QF_ORDER_ITEM_CONTROLS.PARTIAL.TYPE,
    };

    if (isFulfillControls) {
        return [getDoneAction(item.ordered_BY_qty), outOfStockAction, partialAction];
    }

    return [getDoneAction(item.fulfilled_BY_qty), partialAction, didNotReceiveAction];
});

const getInitialActionTitle = () => {
    const { item, isFulfillControls } = props;

    const { fulfilled_type: fulfilledType } = item;

    if (isFulfillControls) {
        return actionOptions.value.find(({ type }) => fulfilledType === type)?.title || 'Fulfill';
    }

    const { fulfilled_BY_qty: fulfilledQty, confirmed_BY_qty: confirmedQty } = item;

    if (confirmedQty === '' && fulfilledType !== QF_ORDER_ITEM_CONTROLS.OUT_OF_STOCK.TYPE) {
        return 'Confirm';
    }

    if (+confirmedQty === 0) {
        return QF_ORDER_ITEM_CONTROLS.DID_NOT_RECEIVE.TITLE;
    }

    if (+confirmedQty === +fulfilledQty) {
        return QF_ORDER_ITEM_CONTROLS.DONE.TITLE;
    }

    return QF_ORDER_ITEM_CONTROLS.PARTIAL.TITLE;
};

const actionsState = reactive({
    title: getInitialActionTitle(),
    isOpened: false,
});

const isActionSelected = computed(
    () => actionOptions.value.some(({ title }) => actionsState.title === title) || props.isLoading
);

const filteredActionOptions = computed(() =>
    actionOptions.value.filter(({ title }) => {
        if (title === QF_ORDER_ITEM_CONTROLS.DONE.TITLE && !isActionSelected.value) {
            return false;
        }

        return title !== actionsState.title;
    })
);

const currentActionTitle = computed(() => {
    if (props.isLoading) {
        return props.isFulfillControls ? 'Fulfilling..' : 'Confirming..';
    }

    return actionsState.title;
});

const handleToggleShowActions = () => (actionsState.isOpened = !actionsState.isOpened);

const handleCloseActions = () => (actionsState.isOpened = false);

const handleChangeActionTitle = (title) => {
    const isActionPartial = title === QF_ORDER_ITEM_CONTROLS.PARTIAL.TITLE;

    const isUpdateNeeded = actionsState.title !== title || isActionPartial;

    if (isUpdateNeeded) {
        actionsState.title = isActionPartial ? getInitialActionTitle() : title;

        const { type, qty } = actionOptions.value.find((action) => action.title === title);

        emit('handle-report-order-item', props.item, type, qty);
    }

    handleCloseActions();
};

const handleClickActionTitle = () => {
    const isActionPartial = actionsState.title === QF_ORDER_ITEM_CONTROLS.PARTIAL.TITLE;

    if (!isActionSelected.value || isActionPartial) {
        const actionTitle = isActionPartial ? actionsState.title : QF_ORDER_ITEM_CONTROLS.DONE.TITLE;

        handleChangeActionTitle(actionTitle);
    }
};

watch(
    () => props.isLoading,
    (isLoading) => {
        if (!isLoading) {
            actionsState.title = getInitialActionTitle();
        }
    }
);

/*-----------------------------------------------------------------------------------
                                    Progress
-----------------------------------------------------------------------------------*/

const isProgressVisible = computed(() => {
    const { isFulfillControls, item } = props;

    return (
        isFulfillControls ||
        item.fulfilled_type !== QF_ORDER_ITEM_CONTROLS.OUT_OF_STOCK.TYPE ||
        +item.confirmed_BY_qty > 0
    );
});

const progressData = computed(() => {
    const { item, isFulfillControls } = props;
    const {
        units_data: unitsData,
        ordered_BY_qty: orderedBYQty,
        fulfilled_BY_qty: fulfilledBYQty,
        confirmed_BY_qty: confirmedBYQty,
    } = item;

    const max = isFulfillControls ? +orderedBYQty : +fulfilledBYQty || +orderedBYQty;
    const value = isFulfillControls ? +fulfilledBYQty : +confirmedBYQty;

    const maxDisplay = getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.quick_fill_ordering_unit,
        max
    );

    const valueDisplay = getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.quick_fill_ordering_unit,
        value
    );

    return {
        max,
        value,
        display: `${valueDisplay} / ${maxDisplay}`,
    };
});

const orderedQtyDisplay = computed(() => {
    const { units_data: unitsData, ordered_BY_qty: orderedBYQty } = props.item;

    return getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.quick_fill_ordering_unit,
        orderedBYQty
    );
});

const canSeeControls = computed(() => {
    const { item } = props;

    if (props.isFulfillControls) {
        return item.can_be_fulfilled_by_current_account;
    }

    return item.can_be_confirmed_by_current_account;
});

const itemStateMessage = computed(() => {
    if (canSeeControls.value) {
        return null;
    }

    const { name } = props.item.source;

    if (props.isFulfillControls) {
        if (+props.item.confirmed_BY_qty > 0) {
            return 'The item has been already confirmed!';
        }

        return `The item will be produced in "${name}"`;
    }

    return `The item hasn't been produced yet in "${name}"`;
});
</script>

<template>
    <div class="pl-order-item-card">
        <OverlayLoader v-if="isLoading" />

        <div class="pl-order-item-card__header pl-order-item-card-header">
            <div class="pl-order-item-card-header__category">
                {{ categoryName }}
            </div>

            <div
                v-if="canSeeControls"
                class="pl-order-item-card-header__actions"
                :class="{
                    'pl-order-item-card-header__actions--opened': actionsState.isOpened,
                    'pl-order-item-card-header__actions--selected': isActionSelected,
                }"
            >
                <template v-if="isActionSelected && !isLoading">
                    <CheckIcon
                        :width="14"
                        :height="14"
                    />
                </template>

                <span
                    type="button"
                    @click="handleClickActionTitle"
                >
                    {{ currentActionTitle }}
                </span>

                <button
                    type="button"
                    class="pl-order-item-card-header__actions-invoker"
                    @click="handleToggleShowActions"
                >
                    <CaretDownIcon />
                </button>

                <transition
                    appear
                    name="down"
                    type="transition"
                >
                    <GroupedSettings.Root v-if="actionsState.isOpened">
                        <GroupedSettings.List>
                            <GroupedSettings.ListItem
                                v-for="{ title, type } in filteredActionOptions"
                                :key="`group-items-by-option-${type}`"
                                @click="handleChangeActionTitle(title)"
                            >
                                {{ title }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </transition>
            </div>
        </div>

        <div class="pl-order-item-card__details">
            <div class="pl-order-item-card__name">
                {{ item.name }}
            </div>

            <div
                v-if="!isFulfillControls"
                class="pl-order-item-card__ordered-qty"
            >
                {{ orderedQtyDisplay }}
            </div>
        </div>

        <template v-if="canSeeControls || isFulfillControls">
            <div
                v-if="isProgressVisible"
                class="pl-order-item-card__progress pl-order-item-card-progress"
            >
                <Progress
                    :min="0"
                    :max="progressData.max"
                    :value="progressData.value"
                />

                <div
                    v-if="progressData.max !== 0"
                    class="pl-order-item-card-progress__qty"
                >
                    {{ progressData.display }}
                </div>
            </div>

            <Badge
                v-else
                size="sm"
            >
                Out of stock
            </Badge>
        </template>

        <Alert
            v-if="!canSeeControls"
            :type="ALERT_TYPES.WARNING"
        >
            {{ itemStateMessage }}
        </Alert>
    </div>
</template>

<style lang="scss" scoped>
.pl-order-item-card {
    position: relative;
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border: 1px solid $gray-400;
    border-radius: 15px;

    :deep(.pl-overlay-spinner) {
        border-radius: inherit;
        z-index: 19;
    }

    &__details {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 5px;
        margin-bottom: custom-space(0.5);
    }

    &__name {
        font-size: 1.2rem;
        font-weight: 400;
        margin-right: 25px;
    }

    &__ordered-qty {
        font-weight: 400;
        font-size: 0.9rem;
        line-height: 1;
    }

    &-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: custom-space(0.5);

        &__category {
            color: $primary;
            font-size: 0.95rem;
            font-weight: $font-weight-normal;
        }

        &__actions {
            position: relative;
            border: 2px solid $black;
            border-radius: 35px;

            span {
                font-weight: 400;
                text-transform: uppercase;
                font-size: 0.85rem;
                padding: 5px 15px 5px 17px;
            }

            .pl-grouped-settings {
                position: absolute;
                right: 0;
                top: 40px;
                z-index: 20;
            }

            &-invoker {
                border-left: 1px solid $black;
                padding: 0 17px 0 15px;

                svg {
                    width: 9px;
                    margin-bottom: 2px;

                    :deep(path) {
                        fill: $black;
                    }
                }
            }

            &--opened .pl-order-item-card-header__actions-invoker svg {
                transform: rotate(180deg);
            }

            &--selected {
                border-color: $primary;
                background-color: $primary;
                padding: 5px 0 5px 17px;

                svg {
                    margin-right: 10px;
                    margin-bottom: 2px;
                }

                span {
                    padding: 0;
                    color: $white;
                }

                .pl-order-item-card-header__actions-invoker {
                    border-left-color: $white;
                    padding-right: 10px;
                    margin-left: 15px;

                    svg :deep(path) {
                        fill: $white;
                    }
                }
            }
        }
    }

    &__progress + .pl-alert {
        margin-top: custom-space(0.25);
    }

    &-progress__qty {
        color: $primary;
        font-weight: 400;
        margin-top: custom-space(0.25);
    }
}
</style>
