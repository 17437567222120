<script>
export default {
    name: 'OverlayLoader',
};
</script>

<script setup>
defineProps({
    enableSticky: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <div
        class="pl-overlay-spinner"
        :class="{
            'pl-overlay-spinner--sticky': enableSticky,
        }"
    >
        <img
            src="~@/assets/images/loader.gif"
            :width="28"
            :height="28"
            alt="Loading..."
        />
    </div>
</template>

<style lang="scss" scoped>
.pl-overlay-spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($white, 0.6);
    z-index: 99;

    &:not(&--sticky) {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &--sticky img {
        position: sticky;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
</style>
