import ApiClient from '@/utils/ApiClient';
import Model from './Model';
import Organization from './Organization';

export default class UserAccount extends Model {
    static all(query = {}) {
        return this.withAbort(ApiClient.get('user/accounts', { params: query }), ({ data }) => this.make(data));
    }

    async join() {
        const { data } = await ApiClient.put(`organizations/${this.organization.id}/accounts/${this.id}/join`);

        return UserAccount.make(data);
    }

    static async deleteAccounts() {
        const { data } = await ApiClient.delete(`user/delete-accounts`);

        return UserAccount.make(data);
    }

    getRelationships() {
        return {
            organization: Organization,
        };
    }
}
