import Velocity from 'velocity-animate';

const transitionDuration = 400;

export default function () {
    return {
        beforeEnter(el) {
            el.style.opacity = 0;
            el.style.display = 'none';
        },
        enter(el, done) {
            el.style.display = '';

            Velocity(el, 'slideDown', {
                duration: transitionDuration,
                easing: 'ease',
            });
            Velocity(
                el,
                { opacity: 1 },
                {
                    duration: transitionDuration,
                    easing: 'ease',
                    queue: false,
                    complete: done,
                }
            );
        },
        leave(el, done) {
            Velocity(el, 'slideUp', {
                duration: transitionDuration,
                easing: 'ease',
            });
            Velocity(
                el,
                { opacity: 0 },
                {
                    duration: transitionDuration,
                    easing: 'ease',
                    queue: false,
                    complete: done,
                }
            );
        },
    };
}