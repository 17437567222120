<template>
    <button
        class="btn unstyled pl-loading-button"
        :disabled="loading"
    >
        <template v-if="loading">
            <InPlaceLoader enable-auto-width />
            &nbsp;
        </template>
        <slot v-else />
    </button>
</template>

<script>
export default {
    name: 'LoadingButton',
};
</script>

<script setup>
defineProps({
    loading: {
        type: Boolean,
        default: false,
    },
});
</script>

<style scoped lang="scss">
.pl-loading-button {
    position: relative;

    &:disabled,
    &[disabled] {
        color: var(--bs-btn-disabled-color) !important;
        background-color: var(--bs-btn-disabled-bg) !important;
        border-color: var(--bs-btn-disabled-border-color) !important;
        opacity: var(--bs-btn-disabled-opacity) !important;
    }

    :deep(.pl-in-place-loader) {
        background-color: transparent;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        height: 100%;
        background-size: $spacer $spacer;
    }
}
</style>
