<script>
export default {
    name: 'FlashNotifications',
};
</script>

<script setup>
import { ref } from 'vue';

import EventBus from '@/utils/EventBus';
import useCodeGenerator from '@/composition/useCodeGenerator';
import Alert from '@/components/Alert';

/*------------------------------------------------------------------------
                            Notifications state
------------------------------------------------------------------------*/

const { generateUniqueCode, clearUsedCode } = useCodeGenerator();

const currentNotifications = ref(new Map());

const registerNotification = (notification) => {
    const id = generateUniqueCode();

    currentNotifications.value.set(id, {
        id,
        ...notification,
    });

    setTimeout(() => {
        currentNotifications.value.delete(id);

        clearUsedCode(id);
    }, 3000);
};

EventBus.$on('pl.flash-notification', registerNotification);
</script>

<template>
    <div
        v-if="currentNotifications.size > 0"
        class="pl-flash-notifications"
        data-testid="flash_notifications"
    >
        <transition-group
            appear
            name="scale"
            type="transition"
        >
            <Alert
                v-for="notification in currentNotifications.values()"
                :key="`notification: ${notification.id}`"
                :type="notification.type"
            >
                {{ notification.message }}
            </Alert>
        </transition-group>
    </div>
</template>

<style lang="scss" scoped>
.pl-flash-notifications {
    position: fixed;
    inset: custom-space(1);
    bottom: auto;
    z-index: 101;

    @include media-breakpoint-up(lg) {
        left: auto;
    }

    .pl-alert {
        width: custom-space(17);
        margin: 0 auto custom-space(0.75) auto;
    }
}
</style>
