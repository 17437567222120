<script>
export default {
    name: 'HeaderNavigation',
};
</script>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { isCook } from '@/utils/accountUtils';
import { isRouteLinkActive } from '@/utils/navigationUtils';
import { formatToQA } from '@/utils/qaUtils';
import { MAIN_NAVIGATION_CONFIG } from '@/enums/navigationEnums';

const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                             Navigation state
------------------------------------------------------------------------*/

const filteredNavigationLinks = computed(() =>
    MAIN_NAVIGATION_CONFIG.filter(({ guards }) => (guards || []).every((guard) => guard(store)))
);
</script>

<template>
    <nav
        class="pl-header-nav"
        data-testid="main_navigation"
        :class="{
            'pl-header-nav--cook': isCook(currentAccount),
        }"
    >
        <ul>
            <li
                v-for="link in filteredNavigationLinks"
                :key="`navigation link: ${link.name}`"
            >
                <router-link
                    :to="{ name: link.route }"
                    custom
                >
                    <template #default="{ href, navigate, route: linkRoute }">
                        <a
                            class="pl-header-nav__link"
                            :class="{
                                'pl-header-nav__link--active': isRouteLinkActive(route, linkRoute.path),
                            }"
                            :href="href"
                            :data-testid="`main_navigation_link_${formatToQA(link.name)}`"
                            @click="navigate"
                        >
                            {{ link.name }}
                        </a>
                    </template>
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<style lang="scss" scoped>
.pl-header-nav {
    width: 100%;

    & > ul {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(2.5);
        margin: 0 0 1px 0;
        padding: 0;
        list-style: none;

        & > li {
            display: flex;
        }
    }

    &__link {
        position: relative;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
        color: $black;
        @include pl-transition(color);

        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: transparent;
            border-radius: $border-radius;
        }

        &--active,
        &:hover,
        &:active {
            color: $primary;
        }

        &--active::after {
            background-color: $primary;
        }
    }

    &--cook > ul {
        justify-content: flex-start;
    }
}
</style>
