<script>
export default {
    name: 'PARTemplateEditStep3View',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mapGetters } from '@/utils/vuex';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import PARTemplateModel from '@/models/PARTemplate';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsAssignee from '@/components/model-specific/prep-lists/selectable-items-assignee';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

/*------------------------------------------------------------------------
                      Selectable items assignee state
------------------------------------------------------------------------*/

const selectableItemsAssigneeState = reactive({
    areActionsDisabled: true,
    isSubmitting: false,
});

const setAreActionsDisabled = (areActionsDisabled) =>
    (selectableItemsAssigneeState.areActionsDisabled = areActionsDisabled);

const initSubmitAssignees = () => (selectableItemsAssigneeState.isSubmitting = true);

const submitAssignees = (selectableAssignees) => {
    PARTemplateModel.setAssignees(orgId, currentPARTemplate.value.id, {
        items: Object.values(selectableAssignees),
    })
        .then(() =>
            router.push({
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.REVIEW_AND_CONFIRM,
                params: { templateId: props.templateId },
            })
        )
        .finally(() => (selectableItemsAssigneeState.isSubmitting = false));
};

const isNextStepDisabled = computed(() => {
    const { areActionsDisabled, isSubmitting } = selectableItemsAssigneeState;

    return isSubmitting || areActionsDisabled;
});
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        exclude-content-container
        :is-step-submitting="selectableItemsAssigneeState.isSubmitting"
    >
        <template #step-content>
            <SelectableItemsAssignee
                is-template
                :organization-id="orgId"
                :order-id="currentPARTemplate.id"
                :enable-submitting="selectableItemsAssigneeState.isSubmitting"
                @set-are-actions-disabled="setAreActionsDisabled"
                @submit-assignees="submitAssignees"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="btn btn-secondary"
                :to="{
                    name: SETTINGS_ROUTE_NAMES.PAR.EDIT.INDEX,
                    params: { templateId },
                }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <button
                type="button"
                class="btn btn-primary"
                :disabled="isNextStepDisabled"
                @click="initSubmitAssignees"
            >
                Review And Confirm
            </button>
        </template>
    </OrderStepTemplate>
</template>
