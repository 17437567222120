import ApiClient from '@/utils/ApiClient';

export default {
    namespaced: true,
    state: () => ({
        meta: null,
    }),
    actions: {
        loadMeta({ getters, commit, state }, orgId) {
            if (!getters.isMetaLoaded) {
                return ApiClient.get(`organizations/${orgId}/item-batch-meta`).then(({ data }) => {
                    commit('setMeta', data);
                    return data;
                });
            }

            return Promise.resolve(state.meta);
        },
    },
    mutations: {
        setMeta: (state, meta) => (state.meta = meta),
    },
    getters: {
        isMetaLoaded: (state) => state.meta !== null,
    },
};
