<script>
export default {
    name: 'PARTemplateEditView',
};
</script>

<script setup>
import { inject, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { getBackRoute } from '@/utils/navigationUtils';
import { isOrderCreating } from '@/utils/orderUtils';
import { PAR_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

const notifyAboutPendingTemplate = () => {
    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending template. Please finalize or cancel it, before editing a new one.',
    });
};

onBeforeMount(() => {
    const template = currentPARTemplate.value;

    if (template === null || !$acl.can(PAR_RESTRICTIONS.UPDATE, currentAccount, template)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit PAR templates.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    } else if (isOrderCreating(template)) {
        notifyAboutPendingTemplate();

        router.replace({ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.INDEX });
    } else if (template.parent_id !== +props.templateId) {
        notifyAboutPendingTemplate();

        router.replace({ params: { templateId: template.parent_id } });
    }
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="getBackRoute(route, SETTINGS_ROUTE_NAMES.PAR.EDIT, SETTINGS_ROUTE_NAMES.PAR.INDEX)"
    >
        <template #title>
            <h1>Edit PAR</h1>
        </template>

        <template
            v-if="route.name !== SETTINGS_ROUTE_NAMES.PAR.EDIT.INDEX"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <template v-if="!!route.meta.parentProps?.enableOrderGroupItems">
                                <GroupedSettings.ListItem
                                    v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                    :key="`group-items-by-option-${value}`"
                                    :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                    @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                                >
                                    {{ text }}
                                </GroupedSettings.ListItem>
                            </template>

                            <GroupedSettings.ListItem
                                v-else
                                is-selected
                            >
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <router-view v-if="currentPARTemplate !== null" />
    </TopBarLayout>
</template>
