import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class Timeline extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/timeline`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }
}
