<script>
export default {
    name: 'TimelineOrderCard',
};
</script>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import { hasAccessToConfirmOrder, isOrderCompleted, isOrderDateGone } from '@/utils/orderUtils';
import { isCook } from '@/utils/accountUtils';
import { QFO_ROUTE_NAMES, PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import ListDisplay from '@/components/ListDisplay';
import TimelineOrderItem from '@/components/model-specific/orders/TimelineOrderItem';
import TimelineCardUI from './TimelineCardUI';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
    isQuickFill: {
        type: Boolean,
        default: false,
    },
    itemsDataKey: {
        type: String,
        default: '',
        validator: (propValue) => ['', 'ordered', 'other'].includes(propValue),
    },
});

const emit = defineEmits(['dismiss-order']);

const store = useStore();

const { currentAccount } = store.state.auth;

/*-----------------------------------------------------------------------------------
                                    Order state
-----------------------------------------------------------------------------------*/

const orderState = computed(() => {
    const { order, isQuickFill } = props;

    const isCompleted = isOrderCompleted(order);
    const hasAccessToConfirm = hasAccessToConfirmOrder(order, currentAccount);

    const getOrderRouteName = () => {
        if (isQuickFill) {
            return QFO_ROUTE_NAMES.SHOW;
        }

        if (order.is_overproduction) {
            return PL_ROUTE_NAMES.OVERPRODUCTION;
        }

        return PL_ROUTE_NAMES.SHOW;
    };

    const getActionTitle = () => {
        if (isCompleted && !order.is_overproduction) {
            return 'Completed';
        }

        if (isQuickFill && !isOrderDateGone(order)) {
            return hasAccessToConfirm ? 'Confirm' : 'Fulfill';
        }

        return 'View Details';
    };

    return {
        isCompleted,
        hasAccessToConfirm,
        routeName: getOrderRouteName(),
        actionTitle: getActionTitle(),
        dueDate: order.is_overdue ? `Due to ${dayjs(order.due_date).format('MMM D, YYYY')}` : null,
    };
});

/*-----------------------------------------------------------------------------------
                                        QA
-----------------------------------------------------------------------------------*/

const qaPrefix = `${props.isQuickFill ? 'qf' : 'pl'}_order`;
</script>

<template>
    <TimelineCardUI
        :is-completed="orderState.isCompleted"
        :is-overproduction="order.is_overproduction"
        :is-overdue="order.is_overdue"
        :is-dismiss-allowed="order.can_be_closed"
        :data-testid="qaPrefix"
    >
        <template #header-content="{ classNames }">
            <Badge
                v-if="order.is_overdue"
                is-absolute
                size="sm"
                :type="BADGE_TYPES.ORDER_CARD"
            >
                {{ orderState.dueDate }}
            </Badge>

            <button
                v-if="order.can_be_closed && !isCook(currentAccount)"
                type="button"
                class="pl-timeline-order-card__cross-icon"
                data-testid="dismiss_order_btn"
                @click="emit('dismiss-order')"
            >
                <CrossIcon
                    :width="16"
                    :height="16"
                />
            </button>

            <div :class="classNames.name">
                <OrderIcon />

                <h2 :data-testid="`${qaPrefix}__name`">
                    {{ order.name }}
                </h2>
            </div>

            <router-link
                v-if="!isCook(currentAccount) || isQuickFill"
                :class="classNames.actionBtn"
                :to="{
                    name: orderState.routeName,
                    params: { orderId: order.id },
                }"
                :data-testid="`${qaPrefix}_open_details`"
            >
                <CheckIcon
                    v-if="orderState.isCompleted && !order.is_overproduction"
                    :width="16"
                    :height="16"
                />

                <span>{{ orderState.actionTitle }}</span>
            </router-link>
        </template>

        <template #main-content>
            <ListDisplay
                :items="itemsDataKey ? order.items[itemsDataKey] : order.items"
                :data-testid="`${qaPrefix}_${itemsDataKey ? `${itemsDataKey}_` : ''}items_list`"
            >
                <template #item="{ item: orderItem }">
                    <TimelineOrderItem
                        :order-item="orderItem"
                        :is-quick-fill="isQuickFill"
                        :has-access-to-confirm="orderState.hasAccessToConfirm"
                        :is-overproduction="itemsDataKey === 'other'"
                    />
                </template>
            </ListDisplay>
        </template>

        <template
            v-if="order.is_par || order.destination"
            #footer-content
        >
            <Badge
                v-if="!!order.is_par"
                size="sm"
                :type="BADGE_TYPES.ORDER_CARD"
            >
                PAR
            </Badge>

            <Badge
                v-if="!!order.destination"
                size="sm"
                :type="BADGE_TYPES.ORDER_CARD"
            >
                To: {{ order.destination.name }}
            </Badge>
        </template>
    </TimelineCardUI>
</template>

<style lang="scss" scoped>
.pl-timeline-order-card__cross-icon {
    position: absolute;
    top: 0;
    right: custom-space(0.5);
}

.pl-timeline-card {
    &--completed,
    &--overproduction {
        :deep(.pl-timeline-order-item) {
            color: $white;
        }

        :deep(.pl-timeline-order-item__progress) {
            background-color: transparent;
            border: 1px solid $white;
        }
    }

    &__name {
        gap: custom-space(0.5);
        padding-right: custom-space(1);
    }

    :deep(.pl-timeline-card__footer) {
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: custom-space(0.25);

        .pl-badge--order-card:not(:first-child) {
            margin-left: 0;
        }
    }
}

:deep(.pl-timeline-order-item__progress-wrapper) {
    max-width: 80px;
}
</style>
