<script>
export default {
    name: 'EditPLOrderIndexView',
};
</script>

<script setup>
import { inject, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { getBackRoute } from '@/utils/navigationUtils';
import { isOrderCreating } from '@/utils/orderUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

const notifyAboutPendingOrder = () => {
    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending order. Please finalize or cancel it, before editing a new one.',
    });
};

onBeforeMount(() => {
    const order = currentPLOrder.value;

    if (order === null || !$acl.can(PL_RESTRICTIONS.UPDATE, currentAccount, order)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit the order.',
        });

        return router.replace({
            name: PL_ROUTE_NAMES.SHOW,
            params: { orderId: props.orderId },
        });
    }

    if (isOrderCreating(order)) {
        notifyAboutPendingOrder();

        router.replace({ name: PL_ROUTE_NAMES.CREATE.INDEX });
    } else if (order.parent_id !== +props.orderId) {
        notifyAboutPendingOrder();

        router.replace({ params: { orderId: order.parent_id } });
    }
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="getBackRoute(route, PL_ROUTE_NAMES.EDIT, PL_ROUTE_NAMES.INDEX)"
    >
        <template #title>
            <h1>
                <span>
                    Edit Order

                    <Badge
                        v-if="currentPLOrder?.is_par"
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        PAR
                    </Badge>
                </span>
            </h1>
        </template>

        <template
            v-if="route.name !== PL_ROUTE_NAMES.EDIT.INDEX"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <template v-if="!!route.meta.parentProps?.enableOrderGroupItems">
                                <GroupedSettings.ListItem
                                    v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                    :key="`group-items-by-option-${value}`"
                                    :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                    @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                                >
                                    {{ text }}
                                </GroupedSettings.ListItem>
                            </template>

                            <GroupedSettings.ListItem
                                v-else
                                is-selected
                            >
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <router-view v-if="currentPLOrder !== null" />
    </TopBarLayout>
</template>
