<script>
export default {
    name: 'QFOIndexView',
};
</script>

<script setup>
import OrdersIndexViewTemplate from '@/components/model-specific/orders/OrdersIndexViewTemplate';
import TimelineView from './partials/TimelineView';
</script>

<template>
    <OrdersIndexViewTemplate>
        <template #viewAsManager>
            <TimelineView />
        </template>
    </OrdersIndexViewTemplate>
</template>
