<script>
export default {
    name: 'EditPLOrderStep1View',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PrepDateForm from '@/components/forms/PrepDateForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const cancelOrderDraft = () => {
    currentPLOrder.value.cancel().then(() => {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.SUCCESS,
            message: 'Order editing process has been successfully cancelled',
        });

        router.push({ name: PL_ROUTE_NAMES.INDEX });

        store.commit('setCurrentPLOrder', null);
    });
};

/*------------------------------------------------------------------------
                         Order settings form state
------------------------------------------------------------------------*/

const orderSettingsFormState = reactive({
    validationErrors: null,
    isSubmittingAllowed: false,
    hasChangesMade: false,
    isSubmitting: false,
});

const setHasChangesMade = (hasChangesMade) => (orderSettingsFormState.hasChangesMade = hasChangesMade);
const setIsSubmittingAllowed = (isEnabled) => (orderSettingsFormState.isSubmittingAllowed = isEnabled);

const navigateToNextStep = () => {
    router.push({
        name: PL_ROUTE_NAMES.EDIT.ADD_ITEMS,
        params: { orderId: props.orderId },
    });
};

const submitForm = (payload) => {
    if (!orderSettingsFormState.hasChangesMade) {
        navigateToNextStep();
    } else {
        orderSettingsFormState.isSubmitting = true;

        currentPLOrder.value
            .update(payload)
            .then((model) => {
                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Order settings have been successfully updated',
                });

                store.commit('setCurrentPLOrder', model);

                navigateToNextStep();
            })
            .catch((error) => {
                const { status, data } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                    orderSettingsFormState.validationErrors = data?.errors || data?.data?.errors;
                }
            })
            .finally(() => (orderSettingsFormState.isSubmitting = false));
    }
};

const isSubmittingDisabled = computed(() => {
    const { isSubmittingAllowed, isSubmitting } = orderSettingsFormState;

    return !isSubmittingAllowed || isSubmitting;
});

const submitBtnText = computed(() => (orderSettingsFormState.hasChangesMade ? 'Apply Changes' : 'Select Items'));
</script>

<template>
    <OrderStepTemplate
        is-edit-mode
        :is-step-submitting="orderSettingsFormState.isSubmitting"
        @cancel-process="cancelOrderDraft"
    >
        <template #step-content>
            <PrepDateForm
                id="pl-edit-order-step-1-form"
                :prep-date="currentPLOrder.prep_date"
                :validation-errors="orderSettingsFormState.validationErrors"
                @set-is-submitting-allowed="setIsSubmittingAllowed"
                @set-has-changes-made="setHasChangesMade"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <button
                type="submit"
                form="pl-edit-order-step-1-form"
                class="btn btn-primary"
                :disabled="isSubmittingDisabled"
            >
                {{ submitBtnText }}
            </button>
        </template>
    </OrderStepTemplate>
</template>
