<script>
export default {
    name: 'FilterIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 24,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 1000 1000"
        :width="width"
        :height="height"
    >
        <path
            d="M92.78 13.42c-21.87 4.6-45.58 16.8-58.7 29.92-15.65 15.42-22.79 32-23.94 54.1-1.61 32 10.82 60.31 40.28 91.16 8.52 9.21 79.88 76.42 158.37 149.4l142.73 132.81 1.15 245.39c1.38 274.16 0 252.98 17.5 266.56 11.97 8.98 34.76 10.36 48.57 2.53 13.81-7.6 221.67-206.02 225.59-215.23 2.3-5.98 2.99-36.6 2.99-153.08l.23-145.71L799.7 329.7c83.56-77.8 156.3-147.32 161.59-154.23 22.33-28.77 29.93-49.72 28.54-78.04-1.84-41.67-33.61-73.66-83.56-84.25-23.47-4.83-790.7-4.83-813.49.24zm792.78 95.76c.92.92-.69 4.6-3.68 8.29-3 3.68-75.96 73.66-162.06 155.38-101.97 96.67-158.14 151.46-160.67 156.75-3.68 7.83-3.92 20.72-3.92 150.78v142.26l-23.48 23.02c-13.12 12.66-37.75 36.14-54.79 52.48l-31.08 29.23-1.15-196.35c-.92-164.82-1.61-197.74-4.37-203.03-1.84-3.68-71.82-71.36-155.38-150.78C124.53 124.82 113.26 114 113.26 111.01c0-1.15 3.45-2.53 7.6-2.99 12.89-1.61 763.09-.46 764.7 1.16z"
            fill="#fff"
        />
    </svg>
</template>
