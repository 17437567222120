import ApiClient from '@/utils/ApiClient';
import Model from './Model';
import CategoryModel from './Category';

export default class Department extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/departments`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, departmentId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/departments/${departmentId}`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/departments`, attributes);

        return this.make(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/departments/${this.id}`,
            attributes
        );

        return this.fill(data);
    }

    async delete(query) {
        const { data } = await ApiClient.delete(
            `organizations/${this.organization_id}/departments/${this.id}`,
            {},
            { params: query }
        );

        return Department.make(data);
    }

    getRelationships() {
        return {
            categories: CategoryModel,
        };
    }
}
