<script>
export default {
    name: 'ProductionReportView',
};
</script>

<script setup>
import {
    REPORTS,
    COMMON_REPORT_DEFAULT_BREAK_DOWN_BY,
    PRODUCTION_REPORT_FILTER_SETTINGS_CONFIG,
} from '@/enums/reportsEnums';
import useReportData from '@/composition/useReportData';
import Badge from '@/components/Badge';
import FilterSettingsTemplate from '@/components/templates/FilterSettingsTemplate';
import ReportViewTemplate from '../partials/ReportViewTemplate';
import ReportDatePicker from '../partials/ReportDatePicker';
import ReportSummaryCard from '../partials/ReportSummaryCard';

/*------------------------------------------------------------------------
                                Report data
------------------------------------------------------------------------*/

const initialFiltersConfig = {
    group_by: null,
    break_down_by: COMMON_REPORT_DEFAULT_BREAK_DOWN_BY,
    include_all_locations: false,
};

const { filters, dateSettings, reportState, updateFilters, updateDateSettings, downloadReport } = useReportData(
    REPORTS.PRODUCTION.NAME,
    initialFiltersConfig
);
</script>

<template>
    <ReportViewTemplate
        :report-title="REPORTS.PRODUCTION.TITLE"
        :is-report-loading="reportState.isDataLoading"
        :summaries="reportState.data"
        @download="downloadReport"
    >
        <template #report-actions>
            <FilterSettingsTemplate
                :initial-filters="filters"
                :filters-config="PRODUCTION_REPORT_FILTER_SETTINGS_CONFIG"
                @update-filters="updateFilters"
            />
        </template>

        <template #top-section>
            <Badge v-if="filters.include_all_locations">
                All locations
            </Badge>

            <ReportDatePicker
                :initial-start-date="dateSettings.start_date"
                :initial-end-date="dateSettings.end_date"
                @update-date="updateDateSettings"
            />
        </template>

        <template #summary-card="{ item }">
            <ReportSummaryCard
                include-prep-tasks
                :item="item"
            />
        </template>
    </ReportViewTemplate>
</template>
