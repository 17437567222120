<script>
export default {
    name: 'Alert',
};
</script>

<script setup>
import { ALERT_TYPES } from '@/enums/componentsEnums';

defineProps({
    type: {
        type: String,
        required: true,
        validator: (value) => Object.values(ALERT_TYPES).includes(value),
    },
});
</script>

<template>
    <div
        role="alert"
        :class="`pl-alert pl-alert--${type}`"
    >
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.pl-alert {
    height: fit-content;
    background-color: $white;
    padding: custom-space(1);
    border-radius: $border-radius;
    box-shadow: 0 custom-space(0.5) custom-space(1) rgba($black, 0.15);
    text-align: center;
    font-size: $font-size-base * 0.875;
    font-weight: $font-weight-normal;
    line-height: 1.5;

    @include media-breakpoint-down(xl) {
        padding: custom-space(0.75);
    }

    &--warning {
        color: $warning;
    }

    &--success {
        color: $success;
    }

    &--fail {
        color: $fail;
    }
}
</style>
