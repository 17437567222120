<script>
export default {
    name: 'NotFoundView',
};
</script>

<script setup>
import { ALERT_TYPES } from '@/enums/componentsEnums';
import Alert from '@/components/Alert';
</script>

<template>
    <TopBarLayout>
        <template #title>
            <h1>Page Not Found</h1>
        </template>

        <Alert :type="ALERT_TYPES.FAIL">
            Sorry, the page you were looking for could not be found.
        </Alert>
    </TopBarLayout>
</template>
