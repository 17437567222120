<template>
    <div class="pl-search-form">
        <transition
            appear
            name="scale"
            type="transition"
        >
            <form
                v-if="isSearchFormOpened"
                novalidate
                class="pl-search-form-root"
                @submit.prevent="search"
            >
                <input
                    ref="inputRef"
                    :value="modelValue"
                    type="search"
                    class="form-control"
                    data-testid="search_form_input"
                    name="search"
                    @input="onInput"
                />

                <button
                    type="button"
                    class="pl-search-form-root-close"
                    data-testid="search_form_close_btn"
                    @click="closeSearchForm"
                >
                    <CrossIcon />
                </button>
            </form>
        </transition>

        <button
            type="button"
            data-testid="search_form_open_btn"
            @click="toggleSearchForm"
        >
            <SearchIcon
                :width="21"
                :height="21"
            />
        </button>
    </div>
</template>

<script>
export default {
    name: 'SearchForm',
};
</script>

<script setup>
import { ref, nextTick, onMounted, onBeforeUnmount } from 'vue';

import SearchIcon from '@/components/icons/SearchIcon';

const emit = defineEmits(['update:modelValue']);
defineProps({
    modelValue: {
        type: String,
        default: null,
    },
});

const inputRef = ref(null);
const isSearchFormOpened = ref(false);
const timeOutId = ref(null);

const showSearchForm = () => {
    isSearchFormOpened.value = true;
    nextTick(() => inputRef.value.focus());
};

const closeSearchForm = () => {
    inputRef.value.value = '';
    search();

    isSearchFormOpened.value = false;
};

const toggleSearchForm = () => {
    if (isSearchFormOpened.value) {
        closeSearchForm();
    } else {
        showSearchForm();
    }
};

const search = () => {
    if (timeOutId.value) clearTimeout(timeOutId.value);
    emit('update:modelValue', inputRef.value.value);
};
const onInput = () => {
    if (timeOutId.value) clearTimeout(timeOutId.value);
    timeOutId.value = setTimeout(() => search(), 500);
};

const closeByEscape = (event) => {
    if (event.key === 'Escape') {
        closeSearchForm();
    }
};

onMounted(() => {
    window.addEventListener('keydown', closeByEscape);
});
onBeforeUnmount(() => {
    window.removeEventListener('keydown', closeByEscape);
});
</script>

<style lang="scss">
/**
    To use this component put it inside an HTML element with the "relative" position.
*/
.pl-search-form {
    &-root {
        position: absolute;
        top: 50%;
        left: calc($grid-gutter-width / 2);
        right: custom-space(3);
        width: auto;
        z-index: 24;
        transform: translateY(-50%);

        .form-control {
            border-width: 0;
            padding-top: space(2);
            padding-bottom: space(2);
            padding-right: $form-select-indicator-padding * 1.5;
        }

        &-close {
            display: flex;
            position: absolute;
            top: 50%;
            right: space(3);
            transform: translate3d(0, -50%, 0);
        }
    }

    & > button {
        display: flex;
    }

    &-fading-in,
    &-leave-to {
        opacity: 0;
        transform: scale(0.95);
        @include pl-transition(all, true);
    }

    &-enter-to {
        opacity: 1;
        @include pl-transition(all, true);
    }
}
</style>
