import Echo from 'laravel-echo';
window.io = require('socket.io-client');

export const echo = new Echo({
    broadcaster: 'socket.io',
    host: process.env.VUE_APP_SOCKET_URL,
});

export default {
    install(app) {
        app.provide('echo', echo);
    },
};
