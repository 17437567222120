import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class BatchUnit extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/units`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static async create(orgId, attributes, query = {}) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/units`,
            { value: attributes.name },
            { params: query }
        );

        return this.make(data);
    }

    static async update(orgId, unitId, attributes, query = {}) {
        const { data } = await ApiClient.put(
            `organizations/${orgId}/units/${unitId}`,
            { value: attributes.name },
            { params: query }
        );

        return this.make(data);
    }

    async delete(orgId, query = {}) {
        const { data } = await ApiClient.delete(`organizations/${orgId}/units/${this.id}`, {}, { params: query });

        return BatchUnit.make(data);
    }
}
