import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import ConfigurationOfItemIndexView from '@/views/dashboard/settings/configuration-of-item/index';

export default [
    {
        path: 'settings/configuration-of-item',
        name: SETTINGS_ROUTE_NAMES.CONFIGURATION_OF_ITEM,
        component: ConfigurationOfItemIndexView,
    },
];
