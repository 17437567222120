/**
 * Usage:
 * 1) allow digits only: <input v-digits-only />
 * 1) allow digits and spaces: <input v-digits-only.withSpaces />
 */

const filterInputValue = (event, regex) => {
    const el = event.target;

    const sourceValue = el.value;
    const newValue = sourceValue.replace(regex, '');

    if (sourceValue !== newValue) {
        el.value = newValue;

        el.dispatchEvent(new Event('input', { bubbles: true }));
    } else {
        event.preventDefault();
    }
};

const digitsOnly = (e) => {
    filterInputValue(e, /\D/g);
};

const digitsAndSpaces = (e) => {
    filterInputValue(e, /[^\d ]/g);
};

export default {
    beforeMount(el, binding) {
        if (binding.modifiers.withSpaces) {
            el.addEventListener('input', digitsAndSpaces);
        } else {
            el.addEventListener('input', digitsOnly);
        }
    },

    unmounted(el, binding) {
        if (binding.modifiers.withSpaces) {
            el.removeEventListener('input', digitsAndSpaces);
        } else {
            el.removeEventListener('input', digitsOnly);
        }
    },
};
