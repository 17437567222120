<template>
    <div
        class="pl-radio"
        :class="{ 'pl-radio--buttons': buttons }"
    >
        <div
            v-for="(option, index) in options"
            :key="`radio-option-${index}`"
            :class="{ 'form-check': !buttons }"
        >
            <input
                v-show="!buttons"
                :id="`radio-option-${option.value}`"
                type="radio"
                class="form-check-input"
                :class="{ 'is-invalid': hasError }"
                :name="name"
                :value="option.value"
                :checked="option.value == modelValue"
                :tabindex="tabindex"
                :data-testid="`radio_option_${name}_${option.text}`"
                @input="emit('update:modelValue', option.value)"
                @blur="emit('blur')"
            />

            <label
                :class="{
                    'form-check-label': !buttons,
                    'pl-radio-btn': buttons,
                }"
                :for="`radio-option-${option.value}`"
            >
                {{ option.text }}
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Radio',
};
</script>

<script setup>
defineProps({
    options: {
        type: Array,
        default: () => [],
    },
    modelValue: {
        type: [String, Number, null],
        required: true,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    name: {
        type: String,
        required: true,
    },
    tabindex: {
        type: String,
        default: null,
    },
    buttons: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue', 'blur']);
</script>

<style lang="scss">
.pl-radio {
    position: relative;
    z-index: 1;

    .form-check:not(:last-child) {
        margin-bottom: $spacer * 1.3;
    }

    &--buttons {
        display: flex;
        margin-top: space(1) * -1;
        margin-right: space(1) * -1;
        margin-left: space(1) * -1;

        .form-check-input:checked + .pl-radio-btn {
            color: $white;
            background-color: $primary;
            border-color: $primary;
        }

        .pl-radio-btn {
            user-select: none;
            margin-top: space(1);
            margin-right: space(1);
            margin-left: space(1);
        }
    }

    &-btn {
        border: 1px solid $primary;
        color: $primary;
        border-radius: $border-radius;
        padding: space(1) space(2);
        font-weight: $font-weight-normal;
    }
}
</style>
