<script>
export default {
    name: 'SelectableItems',
};
</script>

<script setup>
import { ref, watch, onBeforeMount } from 'vue';

import EventBus from '@/utils/EventBus';
import ErrorsBag from '@/utils/form/ErrorsBag';
import { getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { ALERT_TYPES, QTY_INPUT_EVENTS } from '@/enums/componentsEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { SELECTABLE_ITEM_CONTROLS } from '@/enums/selectableItemsEnums';
import { ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import useSelectableItemsQty from '@/composition/useSelectableItemsQty';
import QFSelectableItemModel from '@/models/QFSelectableItem';
import ListDisplay from '@/components/ListDisplay';
import Alert from '@/components/Alert';
import Tabs from '@/components/Tabs';
import SelectableItemWrapper from './SelectableItemWrapper';

const props = defineProps({
    organizationId: {
        type: Number,
        required: true,
    },
    overlaidByOrderId: {
        type: Number,
        required: true,
    },
});

const emit = defineEmits(['set-are-actions-disabled', 'set-is-any-item-added']);

/*------------------------------------------------------------------------
                             Selectable items
------------------------------------------------------------------------*/

const {
    areItemsAbsent,
    selectedItems,
    getCurrentItemsByTab,
    loadSelectableItems,

    departmentOptions,

    isItemBeingSaved,
    hasItemBeenJustSaved,
    areItemControlsBeingLoaded,

    incrementItem,
    decrementItem,
} = useSelectableItemsQty(QFSelectableItemModel);

const commonQueryParams = {
    group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY,
    overlaid_by_order_id: props.overlaidByOrderId,
};

/*-----------------------------------------------------------------------------------
                                Selected items counting
-----------------------------------------------------------------------------------*/

watch(selectedItems, () => emit('set-is-any-item-added', selectedItems.value.length > 0));

/*-----------------------------------------------------------------------------------
                                        Item qty
-----------------------------------------------------------------------------------*/

const onQtyChanged = (value, type, qtyInputUndo, isTriggeredByUndo, onSuccess, item) => {
    if (isTriggeredByUndo) {
        return;
    }

    item.qty = +value;

    const { units_data: unitsData } = item;

    let request;

    switch (true) {
        case type === QTY_INPUT_EVENTS.INCREMENT:
            request = incrementItem(
                props.organizationId,
                props.overlaidByOrderId,
                item.prototype_id,
                false,
                {},
                qtyInputUndo,
                commonQueryParams
            );

            break;

        case type === QTY_INPUT_EVENTS.DECREMENT:
            request = decrementItem(
                props.organizationId,
                props.overlaidByOrderId,
                item.prototype_id,
                false,
                qtyInputUndo,
                commonQueryParams
            );

            break;

        default:
            request = incrementItem(
                props.organizationId,
                props.overlaidByOrderId,
                item.prototype_id,
                false,
                { qty: getBatchYieldQtyAmount(unitsData, unitsData.quick_fill_ordering_unit, item.qty) },
                qtyInputUndo,
                commonQueryParams,
                [SELECTABLE_ITEM_CONTROLS.QTY]
            );

            break;
    }

    emit('set-are-actions-disabled', true);

    request
        .then(() => onSuccess?.())
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                if ('prep_date' in data.errors) {
                    EventBus.$emit('pl.global-modal.open', {
                        title: 'Error occurred',
                        message: new ErrorsBag().flatten(data.errors).join('\r\n'),
                    });
                }
            }

            return Promise.reject(error);
        })
        .finally(() => emit('set-are-actions-disabled', false));
};

/*-----------------------------------------------------------------------------------
                            Selectable item additional props
-----------------------------------------------------------------------------------*/

const getSelectableItemSettings = (item) => {
    const isQtyInputLoading = areItemControlsBeingLoaded(item, [SELECTABLE_ITEM_CONTROLS.QTY]);

    const isCurrentItemBeingSaved = isItemBeingSaved(item.prototype_id);
    const hasCurrentItemBeenJustSaved = hasItemBeenJustSaved(item.prototype_id);

    return {
        isQtyInputLoading,
        isItemBeingSaved: isCurrentItemBeingSaved,
        hasItemBeenJustSaved: hasCurrentItemBeenJustSaved,
    };
};

/*-----------------------------------------------------------------------------------
                                Load necessary data
-----------------------------------------------------------------------------------*/

const isDataLoading = ref(true);

onBeforeMount(() => {
    isDataLoading.value = true;

    loadSelectableItems(props.organizationId, commonQueryParams).finally(() => (isDataLoading.value = false));
});
</script>

<template>
    <InPlaceLoader v-if="isDataLoading" />

    <div
        v-else-if="areItemsAbsent"
        class="container"
    >
        <Alert :type="ALERT_TYPES.WARNING">
            There are no items to select.
        </Alert>
    </div>

    <Tabs
        v-else
        enable-slider-mode
        include-content-container
        :tab-options="departmentOptions"
    >
        <template #content="{ activeTab }">
            <ListDisplay :items="getCurrentItemsByTab(activeTab)">
                <template #item="{ item }">
                    <SelectableItemWrapper
                        :key="`selectable-item-${item.prototype_id}`"
                        :item="item"
                        :settings="getSelectableItemSettings(item)"
                        @on-qty-changed="(...args) => onQtyChanged(...args, item)"
                    />
                </template>
            </ListDisplay>
        </template>
    </Tabs>
</template>

<style lang="scss" scoped>
:deep(.pl-item-card__actions) {
    flex-basis: custom-space(8);
}
</style>
