<script>
export default {
    name: 'DropDown',
};
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue';

import ThreeDotsIcon from '@/components/icons/ThreeDotsIcon';

defineProps({
    posX: {
        type: String,
        default: 'right',
        validator: (value) => ['left', 'right'].includes(value),
    },
    posY: {
        type: String,
        default: 'bottom',
        validator: (value) => ['top', 'bottom'].includes(value),
    },
    dataTestId: {
        type: String,
        default: '',
    }
});

/*------------------------------------------------------------------------
                              DropDown state
------------------------------------------------------------------------*/

const isDropDownOpened = ref(false);

const toggleDropDown = () => (isDropDownOpened.value = !isDropDownOpened.value);

/*------------------------------------------------------------------------
                          Click outside mechanism
------------------------------------------------------------------------*/

const onClickOutside = () => {
    if (isDropDownOpened.value) {
        isDropDownOpened.value = false;
    }
};

/*------------------------------------------------------------------------
                               Close on Esc
------------------------------------------------------------------------*/

const closeDropDownOnEsc = (event) => {
    if (isDropDownOpened.value && event.key === 'Escape') {
        isDropDownOpened.value = false;
    }
};

onMounted(() => window.addEventListener('keydown', closeDropDownOnEsc));
onBeforeUnmount(() => window.removeEventListener('keydown', closeDropDownOnEsc));
</script>

<template>
    <div
        v-click-outside="onClickOutside"
        class="pl-dropdown"
        :class="`pl-dropdown--x-${posX} pl-dropdown--y-${posY}`"
    >
        <button
            type="button"
            :data-testid="dataTestId"
            @click="toggleDropDown"
        >
            <slot
                name="invoker"
                :isOpened="isDropDownOpened"
            >
                <ThreeDotsIcon />
            </slot>
        </button>

        <transition
            name="down"
            type="transition"
        >
            <div
                v-if="isDropDownOpened"
                class="pl-dropdown__content"
                @click="toggleDropDown"
            >
                <slot name="content" />
            </div>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.pl-dropdown {
    $space: custom-space(0.325);

    position: relative;
    z-index: 23;

    & > button {
        padding: $click-space;
        margin: -$click-space;
    }

    &__content {
        position: absolute;
        cursor: initial;
    }

    &--x-left &__content {
        left: 0;
    }

    &--x-right &__content {
        right: 0;
    }

    &--y-top &__content {
        bottom: calc(100% + $space);
    }

    &--y-bottom &__content {
        top: calc(100% + $space);
    }
}
</style>
