<script>
export default {
    name: 'TimelineSecondaryManager',
};
</script>

<script setup>
import { useStore } from 'vuex';

import { isAdmin } from '@/utils/accountUtils';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';
import TimelineViewTemplate from '../../partials/timeline-view-template';
import TimelineOrderItemCard from '../../partials/TimelineOrderItemCard';

const store = useStore();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                             Timeline settings
------------------------------------------------------------------------*/

const factory = isAdmin(currentAccount) ? 'admin_by_item' : 'manager_by_item';

const initialFiltersConfig = {
    department_id: null,
    category_id: null,
    status: null,
    only_my: isAdmin(currentAccount) ? '0' : '1',
};
</script>

<template>
    <TimelineViewTemplate
        include-tabs
        regular-orders-list-key="ordered"
        other-orders-list-key="other"
        :view-mode="TIMELINE_VIEW_MODES.SECONDARY"
        :factory="factory"
        :initial-filters-config="initialFiltersConfig"
    >
        <template #order-list-item="{ item: orderItem }">
            <TimelineOrderItemCard :order-item="orderItem" />
        </template>
    </TimelineViewTemplate>
</template>
