import dayjs from 'dayjs';

import { ORDER_STATUSES } from '@/enums/orderEnums';

/*------------------------------------------------------------------------
                                  General
------------------------------------------------------------------------*/

export const isOrderDateGone = (order) => dayjs(order.prep_date).isBefore(dayjs().startOf('day'));

export const isOrderCreator = (account, order) => account?.id === order.creator?.id || account?.id === order.creator_id;

export const hasOrderDraft = (order) => order.draft !== null;

/*------------------------------------------------------------------------
                              Order statuses
------------------------------------------------------------------------*/

const hasStatus = (order, status) => order.status === status;

export const isOrderCreating = (order) => hasStatus(order, ORDER_STATUSES.CREATING);
export const isOrderInProgress = (order) => hasStatus(order, ORDER_STATUSES.IN_PROGRESS);
export const isOrderEditing = (order) => hasStatus(order, ORDER_STATUSES.EDITING);
export const isOrderCompleted = (order) => hasStatus(order, ORDER_STATUSES.COMPLETED) || order.is_completed;

/*------------------------------------------------------------------------
                               Order access
------------------------------------------------------------------------*/

export const hasAccessToConfirmOrder = (order, account) =>
    !isOrderDateGone(order) && account.location.id === order.destination?.id;
