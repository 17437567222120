/*------------------------------------------------------------------------
                                  General
------------------------------------------------------------------------*/

export const ORDER_TYPES = {
    PREP_LIST: 'prep_list',
    QUICK_FILL: 'quick_fill',
    PAR: 'par',
};

export const ORDER_STATUSES = {
    CREATING: 'creating',
    IN_PROGRESS: 'in_progress',
    EDITING: 'editing',
    COMPLETED: 'completed',
};

/*------------------------------------------------------------------------
                                 Group by
------------------------------------------------------------------------*/

export const ORDER_GROUP_ITEMS_BY = {
    ASSIGNEE: 'assignee',
    STATION: 'station',
    CATEGORY: 'category',
    DISTRIBUTION: 'distribution',
    ITEM: 'item',
    LOCATION: 'location',
};

export const ORDER_DEFAULT_GROUP_ITEMS_BY = ORDER_GROUP_ITEMS_BY.ASSIGNEE;

export const ORDER_GROUP_ITEMS_BY_OPTIONS = {
    [ORDER_GROUP_ITEMS_BY.ASSIGNEE]: 'Assignee',
    [ORDER_GROUP_ITEMS_BY.STATION]: 'Station',
    [ORDER_GROUP_ITEMS_BY.CATEGORY]: 'Category',
    [ORDER_GROUP_ITEMS_BY.DISTRIBUTION]: 'Distribution',
};

export const OVERPRODUCTION_ORDER_GROUP_ITEMS_BY_OPTIONS = {
    [ORDER_GROUP_ITEMS_BY.ASSIGNEE]: 'Assignee',
    [ORDER_GROUP_ITEMS_BY.ITEM]: 'Item',
    [ORDER_GROUP_ITEMS_BY.LOCATION]: 'Location',
    [ORDER_GROUP_ITEMS_BY.DISTRIBUTION]: 'Distribution',
};