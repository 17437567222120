<script>
export default {
    name: 'UnfinishedOrderRibbon',
};
</script>

<script setup>
defineProps({
    navigateTo: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <transition
        appear
        name="down"
        type="transition"
    >
        <router-link
            class="pl-unfinished-order-ribbon"
            :to="navigateTo"
        >
            <p>
                <OrderIcon
                    :width="19"
                    :height="23"
                />

                <span>
                    <slot>You haven't finished your order. Back to order</slot>
                </span>
            </p>

            <ArrowDownIcon
                :width="13"
                :height="9"
            />
        </router-link>
    </transition>
</template>

<style lang="scss" scoped>
.pl-unfinished-order-ribbon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: custom-space(1.25);
    background-color: $orange-300;
    padding: custom-space(0.4) custom-space(0.8);
    line-height: $small-line-height;
    cursor: pointer;

    @include media-breakpoint-down(xl) {
        padding: custom-space(0.4) 0;
        box-shadow: 70px 0 0 $orange-300, -70px 0 0 $orange-300;
    }

    p {
        display: flex;
        align-items: center;
        margin-bottom: 0;
        gap: custom-space(0.75);

        span {
            font-size: $font-size-base * 0.8;
            font-weight: 400;
            color: $orange-700;
        }
    }

    & > svg {
        transform: rotate(-90deg);

        :deep(path) {
            fill: $orange-700;
        }
    }

    svg {
        flex-shrink: 0;
    }
}
</style>
