<script>
export default {
    name: 'PanIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 18,
    },
    height: {
        type: Number,
        default: 16,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 16"
        :width="width"
        :height="height"
    >
        <path
            fill="#DADADA"
            d="M16.81 10.157v.36a.54.54 0 0 1-.54.54H.81a.54.54 0 0 1-.54-.54v-.36a.54.54 0 0 1 .54-.539h15.46a.54.54 0 0 1 .54.54ZM6.763 5.19l3.582-.313-.094-1.074a.72.72 0 0 0-.78-.654l-2.148.188a.72.72 0 0 0-.654.779l.094 1.074Z"
        />
        <path
            fill="#BDBDBE"
            d="m14.572 9.33-.38 3.81a2.876 2.876 0 0 1-2.863 2.59H5.75a2.876 2.876 0 0 1-2.862-2.59l-.381-3.81a.72.72 0 0 1 .715-.79h10.636a.72.72 0 0 1 .716.79Z"
        />
        <path
            fill="#DADADA"
            d="m14.572 9.33-.38 3.81a2.876 2.876 0 0 1-2.863 2.59H9.06a2.876 2.876 0 0 1-2.862-2.59l-.38-3.81a.72.72 0 0 1 .715-.79h7.324a.72.72 0 0 1 .716.79Zm.477-4.323a.899.899 0 0 0-.974-.817L2.972 5.16a.899.899 0 0 0 .156 1.791l11.104-.971a.899.899 0 0 0 .817-.974Z"
        />
        <path
            fill="#BDBDBE"
            d="M15.05 5.007a.899.899 0 0 1-.818.974l-11.104.971a.899.899 0 0 1-.846-1.362c.172.283.494.46.846.429l11.104-.972a.897.897 0 0 0 .69-.428c.07.114.115.245.127.388Z"
        />
        <path
            fill="#FFA233"
            d="M12.135 8.18a.36.36 0 0 1 .36.36v2.876a.72.72 0 0 1-.746.718.73.73 0 0 1-.693-.736v-.439a.715.715 0 0 1-.43.094.73.73 0 0 1-.648-.732V8.539a.36.36 0 0 1 .36-.36H12.134Z"
        />
        <path
            fill="#FFC248"
            d="M11.775 10.337a.72.72 0 0 1-.719-.72V8.18h1.079a.36.36 0 0 1 .36.36v1.078a.72.72 0 0 1-.72.719Z"
        />
        <path
            fill="#64393F"
            d="M11.506 2.787V.27a.27.27 0 1 1 .539 0v2.517a.27.27 0 1 1-.54 0Zm1.348.27a.27.27 0 0 0 .27-.27V.27a.27.27 0 1 0-.54 0v2.517c0 .148.121.27.27.27Zm4.225 7.1v.36a.81.81 0 0 1-.81.809h-.898a.27.27 0 1 1 0-.54h.899a.27.27 0 0 0 .27-.27v-.359a.27.27 0 0 0-.27-.27h-1.482l-.328 3.28A3.136 3.136 0 0 1 11.33 16H5.75a3.136 3.136 0 0 1-3.131-2.833l-.328-3.28H.809a.27.27 0 0 0-.27.27v.36c0 .149.121.27.27.27h.899a.27.27 0 1 1 0 .539H.809a.81.81 0 0 1-.809-.81v-.359a.81.81 0 0 1 .809-.809h1.428a.99.99 0 0 1 .985-1.078h5.677a.27.27 0 1 1 0 .539H3.222a.444.444 0 0 0-.334.148.444.444 0 0 0-.114.346l.381 3.81a2.599 2.599 0 0 0 2.594 2.348h5.58c1.345 0 2.46-1.01 2.594-2.348l.352-3.52v-.005l.03-.285a.444.444 0 0 0-.115-.346.444.444 0 0 0-.333-.148h-1.093v2.607a.98.98 0 0 1-.302.711.983.983 0 0 1-.723.277.994.994 0 0 1-.953-1.006v-.076a.994.994 0 0 1-1.079-1.001V8.539a.63.63 0 0 1 .63-.629h1.798a.63.63 0 0 1 .568.36h1.154a.99.99 0 0 1 .985 1.078h1.428a.81.81 0 0 1 .809.81ZM12.225 8.54a.09.09 0 0 0-.09-.09h-1.798a.09.09 0 0 0-.09.09v1.782c0 .238.178.441.405.463a.448.448 0 0 0 .269-.058.27.27 0 0 1 .405.233v.44c0 .248.194.457.433.466a.447.447 0 0 0 .466-.45V8.54ZM1.886 6.16a1.161 1.161 0 0 1 .269-.853c.2-.24.482-.386.793-.413l3.493-.306-.04-.448a.99.99 0 0 1 .9-1.07l2.148-.189a.99.99 0 0 1 1.072.899l.039.448 3.492-.306a1.17 1.17 0 0 1 1.266 1.062 1.17 1.17 0 0 1-1.062 1.266l-11.104.972a1.17 1.17 0 0 1-1.266-1.062Zm.537-.047a.625.625 0 0 0 .682.572l11.104-.972a.63.63 0 0 0-.11-1.254l-5.536.485a.27.27 0 0 1-.047-.537l1.506-.132-.039-.448a.45.45 0 0 0-.487-.409l-2.149.188a.447.447 0 0 0-.408.487l.039.448.46-.04a.27.27 0 0 1 .046.537l-.726.064h-.004l-3.759.329a.625.625 0 0 0-.572.682Z"
        />
    </svg>
</template>
