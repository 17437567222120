<script>
export default {
    name: 'FilterSettingsTemplate',
};
</script>

<script setup>
import { computed, reactive } from 'vue';

import SelectInput from '@/components/form-controls/SelectInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import FilterSettingsUI from '@/components/UI/FilterSettingsUI';

const props = defineProps({
    initialFilters: {
        type: Object,
        required: true,
    },
    filtersConfig: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['update-filters']);

/*------------------------------------------------------------------------
                              Settings state
------------------------------------------------------------------------*/

const settingsState = reactive({
    isModalOpened: false,
    filters: {},
});

const openSettingsModal = () => {
    settingsState.isModalOpened = true;
    settingsState.filters = { ...props.initialFilters };
};

const closeSettingsModal = () => {
    settingsState.isModalOpened = false;
    settingsState.filters = {};
};

/*------------------------------------------------------------------------
                               Filters state
------------------------------------------------------------------------*/

const updateFilter = (key, value) => {
    const { filters } = settingsState;

    const isToggleable = props.filtersConfig[key].defaultValue === null;

    if (isToggleable && filters[key] === value) {
        filters[key] = null;
    } else {
        filters[key] = value;
    }
};

const applyChanges = () => {
    emit('update-filters', settingsState.filters);

    closeSettingsModal();
};

const isAnyFilterActive = computed(() => {
    const filtersKey = Object.keys(props.initialFilters);

    return filtersKey.some((key) => props.initialFilters[key] !== props.filtersConfig[key].defaultValue);
});

const isApplyBtnDisabled = computed(() => {
    const filtersKey = Object.keys(props.initialFilters);

    return !filtersKey.some((key) => settingsState.filters[key] !== props.initialFilters[key]);
});
</script>

<template>
    <FilterSettingsUI
        :is-modal-opened="settingsState.isModalOpened"
        :is-any-setting-active="isAnyFilterActive"
        :is-apply-btn-disabled="isApplyBtnDisabled"
        @open="openSettingsModal"
        @close="closeSettingsModal"
        @apply-changes="applyChanges"
    >
        <template #settings="{ classNames, qaPrefix }">
            <div
                v-for="(filter, key) in filtersConfig"
                :key="`filter setting: ${key}`"
                :class="filter.component === 'select' ? classNames.selectBlock : classNames.block"
            >
                <SelectInput
                    v-if="filter.component === 'select'"
                    v-model="settingsState.filters[key]"
                    searchable
                    size="sm"
                    :label="filter.title"
                    :options="filter.options"
                    :data-test-id="`${qaPrefix}_${key}_select`"
                />

                <CheckboxInput
                    v-else-if="filter.component === 'checkbox'"
                    v-model="settingsState.filters[key]"
                    enable-boolean-mode
                    :name="`${qaPrefix}_${key}_checkbox`"
                    :qa-prefix="`${qaPrefix}_${key}_checkbox`"
                    :options="filter.options"
                />

                <template v-else>
                    <div :class="classNames.title">
                        {{ filter.title }}
                    </div>

                    <div :class="classNames.options">
                        <button
                            v-for="{ value, text } in filter.options"
                            :key="`filter setting: ${key}; value: ${value}`"
                            type="button"
                            :class="{
                                [classNames.option]: true,
                                [classNames.optionActive]: settingsState.filters[key] === value,
                            }"
                            @click="updateFilter(key, value)"
                        >
                            {{ text }}
                        </button>
                    </div>
                </template>
            </div>
        </template>
    </FilterSettingsUI>
</template>
