/*------------------------------------------------------------------------
                                 Controls
------------------------------------------------------------------------*/

export const SELECTABLE_ITEM_CONTROLS = {
    QTY: 'qty',
};

/*------------------------------------------------------------------------
                               Distribution
------------------------------------------------------------------------*/

export const DISTRIBUTION_MULTIPLE_OPTION = 'multiple-distribution';

export const DISTRIBUTION_TYPES = {
    OPEN_ITEMS: 'open_item',
    DIRECT: 'direct',
    SPLIT_EQUAL: 'split_equal',
    SPLIT: 'split',
    SHARE: 'share',
    MIXED: 'mixed',
};

export const DISTRIBUTION_TITLES = {
    SPLIT_EQUAL: 'Split (equal)',
    SPLIT: 'Split (%)',
    SHARE: 'Share',
};

export const DISTRIBUTION_TITLE_BY_TYPE = {
    [DISTRIBUTION_TYPES.SPLIT_EQUAL]: DISTRIBUTION_TITLES.SPLIT_EQUAL,
    [DISTRIBUTION_TYPES.SPLIT]: DISTRIBUTION_TITLES.SPLIT,
    [DISTRIBUTION_TYPES.SHARE]: DISTRIBUTION_TITLES.SHARE,
};
