`
<template>
    <AuthLayout is-content-centered>
        <FullScreenLoader v-if="preLoadingState.loading" />
        <Alert
            v-else-if="preLoadingState.error"
            :type="ALERT_TYPES.FAIL"
        >
            Something went wrong during loading the page. Please try again later.
        </Alert>
        <template v-else>
            <p class="text-center text-uppercase fs-6 fw-bolder mb-0">
                Welcome to
            </p>

            <div class="pl-login-bg">
                <img
                    src="/images/logo.svg"
                    alt="Logo"
                    width="280"
                    class="pl-login-bg-logo"
                />

                <img
                    src="/images/splash_bg.svg"
                    alt=""
                    width="280"
                    class="pl-login-bg-bg"
                />
            </div>

            <section
                class="pl-login-form-section text-center position-relative"
                :class="{ 'with-confirmation-code': state.codeSent }"
            >
                <OverlayLoader v-if="isFormSubmitting" />

                <h5 class="pl-login-form-section-title">
                    Please Log in or Sign up
                </h5>

                <form
                    novalidate
                    @submit.prevent="requestCode"
                >
                    <label class="form-label mb-3">Phone number</label>

                    <PhoneInput
                        v-model="phoneForm.phone"
                        include-asterisk
                        tabindex="1"
                        :has-error="phoneForm.errors.has('phone')"
                        data-testid="login_phone_field"
                        @blur="phoneForm.validate('phone')"
                        @update:modelValue="onPhoneChanged"
                        @country-changed="onPhoneCountryChanged"
                        @send="requestCode"
                    />

                    <ValidationErrors
                        v-if="phoneForm.errors.has('phone') || confirmationForm.errors.has('phone')"
                        :errors="phoneForm.errors.get('phone') || confirmationForm.errors.get('phone')"
                        data-testid="login_phone_error"
                    />
                </form>

                <transition
                    appear
                    :css="false"
                    @before-enter="beforeEnter"
                    @enter="enter"
                    @leave="leave"
                >
                    <form
                        v-if="state.codeSent"
                        class="text-start pt-5"
                        novalidate
                        @submit.prevent="confirmLogin"
                    >
                        <div class="mb-5">
                            <label class="form-label text-center">Enter the code</label>

                            <ConfirmationCodeInput
                                v-model="confirmationForm.confirmation_code"
                                data-testid="auth_code_field"
                                :focused="state.codeSent"
                                :has-error="confirmationForm.errors.has('confirmation_code')"
                                @update:modelValue="confirmationForm.errors.clear('confirmation_code')"
                            />

                            <ValidationErrors
                                v-if="confirmationForm.errors.has('confirmation_code')"
                                :errors="confirmationForm.errors.get('confirmation_code')"
                                data-testid="auth_code_error"
                            />
                        </div>

                        <div class="d-grid">
                            <button
                                type="submit"
                                class="btn btn-primary"
                                tabindex="3"
                                data-testid="login_btn"
                                :disabled="
                                    !confirmationForm.isFilled() ||
                                        confirmationForm.errors.any() ||
                                        phoneForm.errors.any() ||
                                        isFormSubmitting
                                "
                            >
                                Enter
                            </button>
                        </div>
                    </form>
                </transition>
            </section>
        </template>
    </AuthLayout>
</template>

<script>
export default {
    name: 'LoginView',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { ref, reactive, onBeforeMount } from 'vue';
import { required, maxLength, minLength } from '@vuelidate/validators';

import Alert from '@/components/Alert';
import PhoneInput from '@/components/form-controls/PhoneInput';
import ConfirmationCodeInput from '@/components/form-controls/ConfirmationCodeInput';

import FormManager from '@/utils/form/FormManager';
import { phone } from '@/utils/form/validators';

import { ALERT_TYPES } from '@/enums/componentsEnums';

import useSlideToggle from '@/composition/useSlideToggle';

const isFormSubmitting = ref(false);

const state = reactive({
    codeSent: false,
});

const store = useStore();
const route = useRoute();

const phoneForm = reactive(
    new FormManager({
        phone: {
            phone: null,
            phone_country_id: null,
        },
    })
);
const requestCode = () => {
    if (phoneForm.validate()) {
        isFormSubmitting.value = true;

        phoneForm
            .submit('post', 'auth/request-phone-confirmation-code')
            .then(() => {
                state.codeSent = true;

                setTimeout(() => {
                    window.scrollTo({
                        left: 0,
                        top: document.body.scrollHeight || document.documentElement.scrollHeight,
                        behavior: 'smooth',
                    });
                }, 300);
            })
            .finally(() => (isFormSubmitting.value = false));
    }
};

const confirmationForm = reactive(
    new FormManager(
        {
            confirmation_code: '',
        },
        {
            confirmation_code: {
                required,
                maxLength: maxLength(4),
                minLength: minLength(4),
            },
        }
    )
);

const confirmLogin = () => {
    if (confirmationForm.validate()) {
        isFormSubmitting.value = true;

        confirmationForm
            .format((data) => {
                return {
                    ...phoneForm.getPayload(),
                    ...data,
                };
            })
            .submit('post', 'auth/login')
            .then(() => {
                window.location.href = route.query.come_from || '/auth/options';
            })
            .catch(() => (isFormSubmitting.value = false));
    }
};

const onPhoneChanged = () => {
    state.codeSent = false;
    phoneForm.errors.clearAll();
    confirmationForm.confirmation_code = '';
    confirmationForm.errors.clearAll();
};
const onPhoneCountryChanged = () => {
    state.codeSent = false;
    if (phoneForm.phone.phone) {
        phoneForm.validate('phone');
    } else {
        phoneForm.errors.clearAll();
    }
    confirmationForm.confirmation_code = '';
    confirmationForm.errors.clearAll();
};
const { beforeEnter, enter, leave } = useSlideToggle();

/*------------------------------------------------------------------------------------
                    Load necessary data from server
-----------------------------------------------------------------------------------*/

const preLoadingState = reactive({
    loading: true,
    error: false,
});

onBeforeMount(() => {
    store
        .dispatch('countries/loadCountries')
        .then(() => {
            phoneForm.setValidationRules({
                phone: {
                    phone: phone(store.state.countries.countries),
                },
            });
        })
        .catch(() => (preLoadingState.error = true))
        .finally(() => (preLoadingState.loading = false));
});
</script>

<style lang="scss" scoped>
.pl-login-bg {
    text-align: center;

    &-logo {
        position: relative;
        z-index: 2;
    }

    &-bg {
        position: relative;
        z-index: 1;
        top: rem(-50px);
    }
}

.pl-login-form-section {
    position: relative;
    z-index: 21;
    top: -80px;

    &.with-confirmation-code {
        top: 0;
    }

    &-title {
        text-transform: initial;
        font-weight: 400;
    }
}

.pl-auth-layout {
    position: relative;

    &::after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 40%;
        background-color: $white;
        box-shadow: 0 -50px 50px 10px $white;
        z-index: 10;
    }
}
</style>
