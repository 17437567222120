<script>
import { mapState } from 'vuex';

export default {
    name: 'Dashboard',
    beforeRouteEnter(to, from, next) {
        next(($vm) => {
            if ($vm.currentAccount === null || $vm.currentAccount === undefined) {
                $vm.$router.replace({ name: 'auth.options' });
            }
        });
    },
    computed: mapState('auth', ['currentAccount']),
};
</script>

<template>
    <router-view />
</template>
