<script>
export default {
    name: 'DepartmentCreateView',
};
</script>

<script setup>
import { inject, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { DEPARTMENTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import DepartmentModel from '@/models/Department';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import DepartmentForm from '@/components/forms/DepartmentForm';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(DEPARTMENTS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create departments.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                           Department form state
------------------------------------------------------------------------*/

const departmentFormState = reactive({
    accounts: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadAccounts = () => {
    sendAbortableRequest(AccountModel.all(orgId, { exclude_owner: 1 }))
        .then((models) => (departmentFormState.accounts = models))
        .finally(() => (departmentFormState.isDataLoading = false));
};

const submitCreateDepartment = ({ members, ...attributes }) => {
    departmentFormState.isSubmitting = true;

    DepartmentModel.create(orgId, {
        ...attributes,
        members: members.map(({ id }) => id),
    })
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Department has been successfully created',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                departmentFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (departmentFormState.isSubmitting = false));
};

onBeforeMount(loadAccounts);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX">
        <template #title>
            <h1>Create Department</h1>
        </template>

        <Alert
            v-if="departmentFormState.accounts === null && !departmentFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Data could not be loaded for some reason. Please try again later.
        </Alert>

        <DepartmentForm
            v-else
            :accounts="departmentFormState.accounts"
            :validation-errors="departmentFormState.validationErrors"
            :is-data-loading="departmentFormState.isDataLoading"
            :is-submitting="departmentFormState.isSubmitting"
            @submit="submitCreateDepartment"
        />
    </TopBarLayout>
</template>
