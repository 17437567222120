<template>
    <div class="d-flex flex-column flex-grow-1">
        <OverlayLoader v-if="isFormSubmitting" />

        <transition
            appear
            name="down"
            type="transition"
        >
            <p
                v-if="!state.departments.length"
                class="mb-5 text-center"
            >
                The list is empty. Please press the plus icon below to add a department.
            </p>
        </transition>

        <ListDisplay
            v-if="state.departments.length"
            has-borders
            :items="state.departments"
            class="pl-departments-list"
            data-testid="departments_list"
        >
            <template #item="{ item: department, index }">
                <DepartmentCard
                    :department="department"
                    :members-count="department.members.length"
                    :class="{
                        'text-danger': hasApiValidationErrors(index),
                    }"
                >
                    <template #actions>
                        <button
                            type="button"
                            data-testid="auth_wizard_edit_department_btn"
                            @click="editDepartment(department, index)"
                        >
                            <EditIcon />
                        </button>

                        <button
                            type="button"
                            data-testid="auth_wizard_remove_department_btn"
                            @click.stop="openDeletionModal(department)"
                        >
                            <DeleteIcon />
                        </button>
                    </template>
                </DepartmentCard>

                <ValidationErrors
                    v-if="hasApiValidationErrors(index)"
                    :errors="getApiValidationErrors(index)"
                />
            </template>
        </ListDisplay>

        <transition
            appear
            name="down"
            type="transition"
        >
            <div
                class="text-center"
                :style="`transition-delay: ${state.departments.length * 0.5 * 100}ms`"
            >
                <router-link
                    :to="{ name: 'registration.step-3.add-department' }"
                    data-testid="auth_wizard_add_department_btn"
                >
                    <template v-if="!state.departments.length">
                        <div class="pl-add-departments-icon">
                            <AddDepartmentsIcon />
                        </div>

                        <b>Add department</b>
                    </template>

                    <div v-else>
                        <PlusIcon />
                    </div>
                </router-link>
            </div>
        </transition>

        <transition
            appear
            name="down"
            type="transition"
        >
            <div
                class="d-grid mt-auto"
                :style="`transition-delay: ${(state.departments.length + 1) * 0.5 * 100}ms`"
            >
                <button
                    type="button"
                    class="btn btn-primary mt-5"
                    data-testid="auth_wizard_complete_step_3"
                    :disabled="isFormSubmitting"
                    @click="sendOnboardingDataPack"
                >
                    Continue
                </button>
            </div>
        </transition>

        <DeletionModal
            v-if="isDeletionModalOpened"
            qa-prefix="delete_department_modal"
            :is-pending="isDeletionPending"
            @close="closeDeletionModal"
            @submit-delete="deleteDepartment"
        >
            Are you sure you want to delete the department "{{ deletionModel.name }}" from the company?
        </DeletionModal>
    </div>
</template>

<script>
export default {
    name: 'RegistrationStep3DepartmentsList',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, reactive } from 'vue';

import useDeletionModal from '@/composition/useDeletionModal';

import ListDisplay from '@/components/ListDisplay';
import DeletionModal from '@/components/DeletionModal';
import DepartmentCard from '@/components/model-specific/department/DepartmentCard';
import AddDepartmentsIcon from '@/components/icons/AddDepartmentsIcon';

import { LS } from '@/instances/localStorage';
import ApiClient from '@/utils/ApiClient';
import EventBus from '@/utils/EventBus';
import ErrorsBag from '@/utils/form/ErrorsBag';

import AccountModel from '@/models/Account';

import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';

const router = useRouter();
const store = useStore();

const state = reactive({
    departments: LS.get('onboarding_departments', []),
});

const isFormSubmitting = ref(false);

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const deleteDepartment = () => {
    deletionCallback(() => {
        state.departments.splice(state.departments.indexOf(deletionModel.value), 1);

        LS.set('onboarding_departments', state.departments);

        return Promise.resolve();
    });
};

/*------------------------------------------------------------------------------------
                    Edit department
-----------------------------------------------------------------------------------*/

const editDepartment = (department, index) => {
    router.push({
        name: 'registration.step-3.edit-department',
        params: { index },
    });
};

/*---------------------------------------------------------------------
                        API validation errors
--------------------------------------------------------------------*/

const hasApiValidationErrors = (index) => {
    return store.getters['SVE/getErrorsBag']('registration')?.has(`departments.${index}.name`);
};
const getApiValidationErrors = (index) => {
    return store.getters['SVE/getErrorsBag']('registration')?.get(`departments.${index}.name`);
};

/*---------------------------------------------------------------------
                    Send registration data package
--------------------------------------------------------------------*/

const invalidResponseRedirectionMap = {
    'registration.step-1': [
        /**
         * If one of the keys from the response error bag
         * matches with one of the specified patterns,
         * the user will be redirected to the first step.
         */
        /^owner(?:[.].*)?$/,
        /^organization(?:[.].*)?$/,
    ],
    'registration.step-2': [
        /**
         * If one of the keys from the response error bag
         * matches with one of the specified patterns,
         * the user will be redirected to the second step.
         */
        /^employees(?:[.].*)?$/,
    ],
};

/**
 * Checks if there is a key in the errors bag that
 * matches with one of the defined patterns. If so,
 * returns the correspondent route the user must be
 * redirected to.
 *
 * @param {Object} errorsBag
 *
 * @returns {(String|null)}
 */
const getInvalidResponseRedirectionRoute = (errorsBag) => {
    for (let route in invalidResponseRedirectionMap) {
        for (let keyPattern of invalidResponseRedirectionMap[route]) {
            for (let key of Object.keys(errorsBag.get())) {
                if (keyPattern.test(key)) {
                    return route;
                }
            }
        }
    }
    return null;
};

const sendOnboardingDataPack = () => {
    isFormSubmitting.value = true;

    store.commit('SVE/clearErrorsBag', 'registration');

    ApiClient.post('auth/wizard', {
        owner: LS.get('onboarding_owner', []),
        organization: LS.get('onboarding_organization', []),
        employees: LS.get('onboarding_employees', []),
        departments: LS.get('onboarding_departments', []),
    })
        .then(({ data }) => {
            LS.remove('onboarding_owner');
            LS.remove('onboarding_organization');
            LS.remove('onboarding_employees');
            LS.remove('onboarding_departments');

            store.commit('auth/setCurrentAccount', AccountModel.make(data.owner));

            router.push({ name: PL_ROUTE_NAMES.INDEX });

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Congratulations! Organization successfully created.',
            });
        })
        .catch((error) => {
            const code = error.response?.status;
            const errorsBag = new ErrorsBag();

            errorsBag.record(error.response.data.errors);

            if (code === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                store.commit('SVE/setErrorsBag', {
                    name: 'registration',
                    bag: errorsBag,
                });

                const routeToRedirect = getInvalidResponseRedirectionRoute(errorsBag);

                if (routeToRedirect !== null) {
                    return router.replace({ name: routeToRedirect });
                }
            }

            return Promise.reject(error);
        })
        .finally(() => (isFormSubmitting.value = false));
};
</script>

<style lang="scss" scoped>
.pl-department-actions {
    > * {
        margin-left: custom-space(0.7);
    }
}

.pl-add-departments-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 154px;
    margin: 0 auto custom-space(0.5) auto;
    height: 154px;
    background: $white;
    box-shadow: 0px 0px 25px 10px #f2f2f2;
    border-radius: 50%;

    svg {
        @include margin($bottom: spacer(15px));
    }
}

.pl-departments-list {
    margin-bottom: custom-space(1);

    :deep(.pl-validation-errors) {
        font-size: $font-size-base * 0.875;
        line-height: 1.4;
    }
}
</style>
