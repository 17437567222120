<script>
export default {
    name: 'ItemsIndexView',
};
</script>

<script setup>
import { watch, inject, computed, reactive, onBeforeMount } from 'vue';
import { useRouter, useRoute, RouterLink } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { ITEMS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ITEMS_GROUP_BY, ITEMS_DEFAULT_GROUP_BY, ITEMS_FILTER_SETTINGS_CONFIG } from '@/enums/itemEnums';
import ItemModel from '@/models/Item';
import useAbortableRequest from '@/composition/useAbortableRequest';
import ListDisplay from '@/components/ListDisplay';
import TreeDisplay from '@/components/TreeDisplay';
import Fab from '@/components/Fab';
import FilterSettingsTemplate from '@/components/templates/FilterSettingsTemplate';
import SearchForm from '@/components/forms/SearchForm';
import ItemCard from '@/components/model-specific/item/ItemCard';
import AddItemsIcon from '@/components/icons/AddItemsIcon';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ITEMS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to items.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                                  Filters
------------------------------------------------------------------------*/

const filters = reactive({
    name: route.query.name ?? '',
    group_by: route.query.group_by ?? ITEMS_DEFAULT_GROUP_BY,
    order_type: route.query.order_type || null,
});

const getCurrentFilters = () => ({
    name: filters.name,
    group_by: filters.group_by,
    type: filters.order_type,
});

const updateFilters = (updatedFilters) => {
    filters.group_by = updatedFilters.group_by;
    filters.order_type = updatedFilters.order_type;
};

/*------------------------------------------------------------------------
                                Items state
------------------------------------------------------------------------*/

const itemsState = reactive({
    data: null,
    isDataLoading: true,
});

const itemPermissions = computed(() => ({
    create: $acl.can(ITEMS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(ITEMS_RESTRICTIONS.CREATE, currentAccount),
}));

const loadItems = () => {
    itemsState.isDataLoading = true;

    sendAbortableRequest(ItemModel.all(orgId, getCurrentFilters()))
        .then((models) => (itemsState.data = models))
        .finally(() => (itemsState.isDataLoading = false));
};

const initEditItem = ({ id }) => {
    if (itemPermissions.value.update) {
        router.push({
            name: SETTINGS_ROUTE_NAMES.ITEMS.EDIT,
            params: { itemId: id },
            query: route.query,
        });
    }
};

const getTitleTestId = (nestingLevel) => {
    const { group_by: groupBy } = filters;

    let configurationName = groupBy;

    if (groupBy === ITEMS_GROUP_BY.DEPARTMENT_N_CATEGORY) {
        configurationName = nestingLevel === 0 ? ITEMS_GROUP_BY.DEPARTMENT : ITEMS_GROUP_BY.CATEGORY;
    }

    return `items_list_title_${configurationName}}`;
};

const areItemsPresent = computed(() => {
    const { data } = itemsState;

    if (Array.isArray(data)) {
        return data.length > 0;
    }

    return Object.entries(data).length > 0;
});

const emptyListMessage = computed(() => {
    if (filters.name.length > 0) {
        return 'There is no items according to your request.';
    }

    if (itemPermissions.value.create) {
        return 'The list is empty. Please press the plus icon below to create item.';
    }

    return 'No items here! Please wait until an administrator creates some items to work with.';
});

watch(
    filters,
    () => {
        router.replace({ query: filters });

        loadItems();
    },
    { immediate: true }
);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Items</h1>
        </template>

        <template
            v-if="!itemsState.isDataLoading"
            #actions
        >
            <SearchForm
                v-model="filters.name"
                data-testid="items_search_form"
            />

            <FilterSettingsTemplate
                :initial-filters="{ group_by: filters.group_by, order_type: filters.order_type }"
                :filters-config="ITEMS_FILTER_SETTINGS_CONFIG"
                @update-filters="updateFilters"
            />
        </template>

        <InPlaceLoader v-if="itemsState.isDataLoading" />

        <template v-else-if="areItemsPresent">
            <transition
                appear
                type="transition"
                name="down"
            >
                <TreeDisplay
                    :data="itemsState.data"
                    class="pl-items-tree"
                >
                    <template #node="{ key, nestingLevel }">
                        <div
                            class="pl-items-tree__title"
                            :data-testid="getTitleTestId(nestingLevel)"
                        >
                            {{ key }}
                        </div>
                    </template>

                    <template #leaf="{ leaf }">
                        <ListDisplay
                            has-borders
                            data-testid="items_list"
                            :items="leaf"
                            @tap="initEditItem"
                        >
                            <template #item="{ item }">
                                <ItemCard
                                    include-prep-tasks-count
                                    :item="item"
                                    :class="{
                                        clickable: itemPermissions.update,
                                    }"
                                />
                            </template>
                        </ListDisplay>
                    </template>
                </TreeDisplay>
            </transition>

            <Fab
                v-if="itemPermissions.create"
                data-testid="create_item_fab"
            >
                <router-link :to="{ name: SETTINGS_ROUTE_NAMES.ITEMS.CREATE, query: route.query }">
                    <PlusIcon />
                </router-link>
            </Fab>
        </template>

        <template v-else>
            <transition
                appear
                type="transition"
                name="down"
            >
                <div class="pl-items__empty-note">
                    <span data-testid="items_list_empty_note">{{ emptyListMessage }}</span>

                    <router-link
                        v-if="itemPermissions.create && filters.name.length === 0"
                        :to="{ name: SETTINGS_ROUTE_NAMES.ITEMS.CREATE, query: route.query }"
                        data-testid="create_item_fab"
                    >
                        <AddItemsIcon />
                    </router-link>
                </div>
            </transition>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-items__empty-note {
    display: flex;
    flex-direction: column;
    padding-top: custom-space(1.5);
    text-align: center;
}

.pl-items-tree {
    @media screen and (max-width: 800px) {
        padding-bottom: custom-space(4.5);
    }

    & > :deep(.pl-tree-display__node:not(:last-child)) {
        padding-bottom: custom-space(1.5);
    }

    & > :deep(.pl-tree-display__node > .pl-items-tree__title) {
        font-size: $font-size-base * 1.35;
        font-weight: $font-weight-bolder;
        padding-bottom: custom-space(0.5);
    }

    & > :deep(.pl-tree-display__node > .pl-tree-display > .pl-tree-display__node):not(:last-child) {
        margin-bottom: custom-space(1);
    }

    & > :deep(.pl-tree-display__node > .pl-tree-display > .pl-tree-display__node > .pl-items-tree__title) {
        font-weight: $font-weight-bolder;
        padding-bottom: custom-space(0.25);
        padding-left: custom-space(0.75);
    }
}
</style>
