<script>
export default {
    name: 'InPlaceLoader',
};
</script>

<script setup>
defineProps({
    enableAutoWidth: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <transition
        appear
        name="scale"
        type="transition"
    >
        <div
            class="pl-in-place-loader"
            :class="{
                'pl-in-place-loader--auto-width': enableAutoWidth,
            }"
        />
    </transition>
</template>

<style lang="scss" scoped>
.pl-in-place-loader {
    height: custom-space(7.5);
    min-width: custom-space(10);
    background-image: url('~@/assets/images/loader.gif');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: custom-space(1.5);

    &--auto-width {
        min-width: auto;
    }
}
</style>
