<script>
export default {
    name: 'ItemCreateView',
};
</script>

<script setup>
import { inject, reactive, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { ITEMS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import ItemModel from '@/models/Item';
import ItemForm from '@/components/forms/item-form';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ITEMS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create items.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Item form state
------------------------------------------------------------------------*/

const itemFormState = reactive({
    isSubmitting: false,
    validationErrors: null,
});

const submitCreateItem = (attributes) => {
    itemFormState.isSubmitting = true;

    ItemModel.create(orgId, attributes)
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Item has been successfully created',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX, query: route.query });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                itemFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (itemFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout
        include-back-route-query
        :back-route="SETTINGS_ROUTE_NAMES.ITEMS.INDEX"
    >
        <template #title>
            <h1>Create Item</h1>
        </template>

        <ItemForm
            :validation-errors="itemFormState.validationErrors"
            :is-submitting="itemFormState.isSubmitting"
            @submit="submitCreateItem"
        />
    </TopBarLayout>
</template>
