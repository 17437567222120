import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class QFOrder extends Model {
    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/quick-fill-orders`, attributes);

        return this.make(data);
    }

    static find(orgId, orderId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/quick-fill-orders/${orderId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    async update(attributes) {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/quick-fill-orders/${this.id}`,
            attributes
        );

        return this.fill(data);
    }

    async delete() {
        return ApiClient.delete(`organizations/${this.organization_id}/quick-fill-orders/${this.id}`);
    }

    async cancel() {
        return ApiClient.delete(`organizations/${this.organization_id}/quick-fill-orders/${this.id}/cancel`);
    }

    async confirm() {
        return ApiClient.put(`organizations/${this.organization_id}/quick-fill-orders/${this.id}/confirm`);
    }

    static async markAsConfirmed(orgId, orderId) {
        const { data } = await ApiClient.post(`organizations/${orgId}/quick-fill-orders/${orderId}/mark-as-confirmed`);

        return this.make(data);
    }

    createDraft() {
        return ApiClient.post(`organizations/${this.organization_id}/quick-fill-orders/${this.id}/draft`);
    }

    cancelDraft() {
        return ApiClient.delete(
            `organizations/${this.organization_id}/quick-fill-orders/${this.parent_id}/draft/cancel`
        );
    }

    completeDraft() {
        return ApiClient.post(`organizations/${this.organization_id}/quick-fill-orders/${this.id}/draft/complete`);
    }

    getRelationships() {
        return {
            draft: QFOrder,
        };
    }
}
