<script>
export default {
    name: 'DepartmentEditView',
};
</script>

<script setup>
import { reactive, onBeforeMount, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { DEPARTMENTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import DepartmentModel from '@/models/Department';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import DepartmentForm from '@/components/forms/DepartmentForm';

const props = defineProps({
    departmentId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(DEPARTMENTS_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit departments.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                           Department form state
------------------------------------------------------------------------*/

const departmentFormState = reactive({
    data: null,
    accounts: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const submitUpdateDepartment = ({ members, ...attributes }) => {
    departmentFormState.isSubmitting = true;

    departmentFormState.data
        .update({
            ...attributes,
            members: members.map(({ id }) => id),
        })
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Department has been successfully updated',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                departmentFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (departmentFormState.isSubmitting = false));
};

const departmentFormProps = computed(() => {
    const { data } = departmentFormState;

    if (data === null) {
        return {};
    }

    return {
        name: data.name,
        members: data.members,
    };
});

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(() => {
    Promise.all([
        sendAbortableRequest(DepartmentModel.find(orgId, props.departmentId, { 'on_demand[]': 'members' })),
        sendAbortableRequest(AccountModel.all(orgId, { exclude_owner: 1 })),
    ])
        .then(([departmentModelData, accountModels]) => {
            departmentFormState.data = departmentModelData;
            departmentFormState.accounts = accountModels;
        })
        .finally(() => (departmentFormState.isDataLoading = false));
});
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.DEPARTMENTS.INDEX">
        <template #title>
            <h1>Edit department</h1>
        </template>

        <Alert
            v-if="departmentFormState.data === null && !departmentFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            The department could not be loaded for some reason. Please try again later.
        </Alert>

        <DepartmentForm
            v-else
            :name="departmentFormProps.name"
            :members="departmentFormProps.members"
            :accounts="departmentFormState.accounts"
            :validation-errors="departmentFormState.validationErrors"
            :is-data-loading="departmentFormState.isDataLoading"
            :is-submitting="departmentFormState.isSubmitting"
            @submit="submitUpdateDepartment"
        />
    </TopBarLayout>
</template>
