<script>
export default {
    name: 'PeopleEditView',
};
</script>

<script setup>
import { reactive, computed, onBeforeMount, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { PEOPLE_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import EmployeeForm from '@/components/forms/EmployeeForm';

const props = defineProps({
    accountId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PEOPLE_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit employee.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Employee form state
------------------------------------------------------------------------*/

const employeeFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadEmployeeData = () => {
    sendAbortableRequest(AccountModel.find(orgId, props.accountId))
        .then((accountModel) => (employeeFormState.data = accountModel))
        .finally(() => (employeeFormState.isDataLoading = false));
};

const submitUpdateEmployee = (attributes) => {
    employeeFormState.isSubmitting = true;

    employeeFormState.data
        .update(attributes)
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Employee has been successfully updated',
            });

            router.push({
                name: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX,
                query: { initialTab: route.query.initialTab },
            });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                employeeFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (employeeFormState.isSubmitting = false));
};

const employeeFormProps = computed(() => {
    const { data } = employeeFormState;

    const defaultHiddenFields = ['phone', 'role'];

    if (data === null) {
        return { hiddenFields: defaultHiddenFields };
    }

    return {
        name: data.name,
        hourlyRate: data.hourly_rate,
        phone: data.phone,
        email: data.email,
        availableLocations: data.available_locations,
        hiddenFields: data.is_owner
            ? ['hourly_rate', 'available_locations', ...defaultHiddenFields]
            : defaultHiddenFields,
    };
});

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(loadEmployeeData);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.PEOPLE.INDEX">
        <template #title>
            <h1>Edit account</h1>
        </template>

        <Alert
            v-if="employeeFormState.data === null && !employeeFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Account could not be loaded for some reason. Please try again later.
        </Alert>

        <EmployeeForm
            v-else
            submit-btn-text="Save"
            :organization-id="orgId"
            :name="employeeFormProps.name"
            :hourly-rate="employeeFormProps.hourlyRate"
            :phone="employeeFormProps.phone"
            :email="employeeFormProps.email"
            :available-locations="employeeFormProps.availableLocations"
            :hidden-fields="employeeFormProps.hiddenFields"
            :validation-errors="employeeFormState.validationErrors"
            :is-data-loading="employeeFormState.isDataLoading"
            :is-submitting="employeeFormState.isSubmitting"
            @submit="submitUpdateEmployee"
        />
    </TopBarLayout>
</template>
