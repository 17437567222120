import ErrorsBag from './ErrorsBag';
import Validator from './Validator';
import ApiClient from '@/utils/ApiClient';
import { isObject } from '@/utils/objectUtils';

export default class {
    constructor(payload, validationRules = [], validationFieldNames = null, validationMessages = null) {
        this.errors = new ErrorsBag();
        this.originalPayload = payload;

        this.isInProcess = false;

        this.validationRules = validationRules;
        this.validationFieldNames = validationFieldNames;
        this.validationMessages = validationMessages;

        this.payloadFormatCallback = null;

        this.fillProps(payload);
    }

    submit(method, endpoint) {
        let payload = null;

        if (['post', 'put', 'patch', 'delete'].includes(method.toLowerCase())) {
            payload = this.payloadFormatCallback
                ? this.payloadFormatCallback.call(this, this.getPayload())
                : this.getPayload();
            payload = this._encode(payload);
        }

        this.isInProcess = true;

        return ApiClient[method](endpoint, payload)
            .then((response) => Promise.resolve(response))
            .catch((error) => {
                if (error.response.status === 422) {
                    this.errors.record(error.response.data?.errors || error.response.data?.data?.errors);
                }

                return Promise.reject(error);
            })
            .finally(() => (this.isInProcess = false));
    }

    fillProps(payload) {
        for (let field in payload) {
            this[field] = payload[field];
        }
    }

    validate(field = null) {
        const isValid = field ? this.validateField(field) : this.validateForm();

        return isValid;
    }

    validateField(field) {
        const validator = new Validator({
            payload: this.getPayload(),
            rules: this.validationRules,
            fieldNames: this.validationFieldNames,
            customMessages: this.validationMessages,
        });

        this.errors.clear(field);

        if (!validator.validate(field)) {
            this.errors.mergeFor(field, validator.getErrors(field));
            return false;
        }

        return true;
    }

    validateForm() {
        const validator = new Validator({
            payload: this.getPayload(),
            rules: this.validationRules,
            fieldNames: this.validationFieldNames,
            customMessages: this.validationMessages,
        });
        this.errors.clearAll();

        if (!validator.validate()) {
            this.errors.record(validator.getErrors());
            return false;
        }

        return true;
    }

    getPayload() {
        const payload = {};

        for (const key of Object.keys(this.originalPayload)) {
            payload[key] = this[key];
        }

        return payload;
    }

    format(callback) {
        this.payloadFormatCallback = callback;

        return this;
    }

    enctype(enctype) {
        this._enctype = enctype;

        return this;
    }

    _encode(payload) {
        if (this._enctype === 'multipart/form-data') {
            const formData = new FormData();

            for (let field in payload) {
                this.appendToFormData(formData, field, payload[field]);
            }

            return formData;
        }

        return payload;
    }

    /**
     * Append the specified property to the specified FormData instance.
     *
     * @param {FormData} formData
     * @param {String}   property
     * @param            value
     */
    appendToFormData(formData, property, value) {
        if (value instanceof Array) {
            value.forEach((item, index) => {
                if (item instanceof Object) {
                    for (let itemPropName in item) {
                        if (Object.prototype.hasOwnProperty.call(item, itemPropName)) {
                            this.appendToFormData(
                                formData,
                                `${property}[${index}][${itemPropName}]`,
                                item[itemPropName]
                            );
                        }
                    }
                } else {
                    this.appendToFormData(formData, `${property}[${index}]`, item);
                }
            });
        } else if (value instanceof Object && !(value instanceof File)) {
            for (let key in value) {
                if (Object.prototype.hasOwnProperty.call(value, key)) {
                    this.appendToFormData(formData, `${property}[${key}]`, value[property]);
                }
            }
        } else {
            formData.append(property, value);
        }
    }

    isFilled(payload = this.getPayload(), rules = this.validationRules) {
        for (let field in payload) {
            const fieldValue = payload[field];
            const fieldRules = rules[field];

            if (isObject(fieldValue) && !this.isFilled(fieldValue, fieldRules)) {
                return false;
            }

            if (
                fieldRules === undefined ||
                (fieldRules.required === undefined &&
                    (fieldRules.requiredIf === undefined || !fieldRules.requiredIf.$params.prop()))
            ) {
                continue;
            }

            if (
                fieldValue === undefined ||
                fieldValue === null ||
                fieldValue === '' ||
                (Array.isArray(fieldValue) && fieldValue.length === 0)
            ) {
                return false;
            }
        }

        return true;
    }

    setValidationRules(rules = null) {
        this.validationRules = rules;
    }

    setPayload(payload) {
        this.originalPayload = payload;
    }
}
