<script>
export default {
    name: 'ItemCard',
};
</script>

<script setup>
import { useSlots } from 'vue';

import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import ItemCardUI from './ItemCardUI';

defineProps({
    item: {
        type: Object,
        required: true,
    },
    includePrepTasksCount: {
        type: Boolean,
        default: false,
    },
    includeCategoryName: {
        type: Boolean,
        default: false,
    },
    includeOnlyBatchSettings: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();

/*-----------------------------------------------------------------------------------
                                        QA
-----------------------------------------------------------------------------------*/

const qaPrefix = 'item';
</script>

<template>
    <ItemCardUI :data-testid="qaPrefix">
        <template #default="{ classNames }">
            <div
                :class="{
                    [classNames.wrapper]: true,
                    [`${classNames.wrapper}--with-prep-tasks`]: includePrepTasksCount && item.prep_tasks.length > 0,
                }"
            >
                <Badge
                    v-if="includePrepTasksCount && item.prep_tasks.length > 0"
                    is-absolute
                    size="sm"
                    :type="BADGE_TYPES.ITEM_CARD"
                    :data-testid="`${qaPrefix}_prep_tasks_count`"
                >
                    Prep Task ({{ item.prep_tasks.length }})
                </Badge>

                <div :class="classNames.content">
                    <div
                        v-if="includeCategoryName"
                        :class="classNames.categoryName"
                    >
                        {{ item.category.name || item.category }}
                    </div>

                    <div :class="classNames.contentWrapper">
                        <b
                            :class="classNames.name"
                            :data-testid="`${qaPrefix}_name`"
                        >
                            {{ item.name }}
                        </b>

                        <slot
                            name="info"
                            :item="item"
                        >
                            <ul :class="classNames.infoList">
                                <li v-if="item.batch_size_amount !== null">
                                    <span :data-testid="`${qaPrefix}_batch_size_amount`">
                                        {{ item.batch_size_amount }}
                                    </span>

                                    <span :data-testid="`${qaPrefix}_batch_size_unit`">
                                        {{ item.batch_size_unit.text }}
                                    </span>
                                </li>

                                <li v-if="item.batch_yield_amount !== null">
                                    <span :data-testid="`${qaPrefix}_batch_yield_amount`">
                                        {{ item.batch_yield_amount }}
                                    </span>

                                    <span :data-testid="`${qaPrefix}_batch_yield_unit`">
                                        {{ item.batch_yield_unit.text }}
                                    </span>
                                </li>

                                <li v-if="item.batch_labor_time !== null">
                                    <span :data-testid="`${qaPrefix}_labor_time`">{{ item.batch_labor_time }}m</span>
                                </li>

                                <li v-if="item.batch_weight_amount !== null">
                                    <span :data-testid="`${qaPrefix}_batch_weight_amount`">
                                        {{ item.batch_weight_amount }}
                                    </span>

                                    <span :data-testid="`${qaPrefix}_batch_weight_unit`">
                                        {{ item.batch_weight_unit.text }}
                                    </span>
                                </li>
                            </ul>

                            <ul
                                v-if="!includeOnlyBatchSettings"
                                :class="classNames.infoList"
                            >
                                <li>
                                    <span :data-testid="`${qaPrefix}_department_name`">
                                        {{ item.department.name }}
                                    </span>
                                </li>

                                <li>
                                    <span :data-testid="`${qaPrefix}_category_name`">
                                        {{ item.category.name }}
                                    </span>
                                </li>

                                <li v-if="item.shelf_life">
                                    <span :data-testid="`${qaPrefix}_shelf_life`">
                                        Shelf life: {{ item.shelf_life }}d
                                    </span>
                                </li>
                            </ul>

                            <ul
                                v-if="!includeOnlyBatchSettings && item.stations.length > 0"
                                :class="`${classNames.infoList} ${classNames.stationsList}`"
                            >
                                <li>
                                    <span
                                        v-for="station in item.stations"
                                        :key="`station: ${station?.id ?? station}`"
                                        :data-testid="`${qaPrefix}_station`"
                                    >
                                        {{ station?.name ?? station }}
                                    </span>
                                </li>
                            </ul>
                        </slot>
                    </div>
                </div>

                <div
                    v-if="slots.actions"
                    :class="classNames.actions"
                >
                    <slot
                        name="actions"
                        :item="item"
                    />
                </div>
            </div>

            <div
                v-if="slots.footer"
                :class="classNames.footer"
            >
                <slot name="footer" />
            </div>
        </template>
    </ItemCardUI>
</template>

<style lang="scss" scoped>
.pl-item-card__wrapper--with-prep-tasks {
    padding-top: custom-space(0.25);
}
</style>
