<script>
export default {
    name: 'ItemEditView',
};
</script>

<script setup>
import { onBeforeMount, computed, reactive, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { ITEMS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { AVAILABLE_ORDER_TYPES } from '@/enums/itemEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import ItemModel from '@/models/Item';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Alert from '@/components/Alert';
import DeletionModal from '@/components/DeletionModal';
import ItemForm from '@/components/forms/item-form';

const props = defineProps({
    itemId: {
        type: [String, Number],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ITEMS_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit items.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                              Item form state
------------------------------------------------------------------------*/

const itemFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadItemData = () => {
    sendAbortableRequest(ItemModel.find(orgId, props.itemId, { 'on_demand[]': 'location_specific_settings' }))
        .then((modelData) => (itemFormState.data = modelData))
        .finally(() => (itemFormState.isDataLoading = false));
};

const submitUpdateItem = (attributes) => {
    itemFormState.isSubmitting = true;

    itemFormState.data
        .update(attributes)
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Item settings has been successfully updated',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX, query: route.query });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                itemFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (itemFormState.isSubmitting = false));
};

const getItemAvailableOrderType = () => {
    const { is_quick_fill: isQuickFill, is_prep_list: isPrepList } = itemFormState.data;

    if (isQuickFill && isPrepList) {
        return AVAILABLE_ORDER_TYPES.BOTH;
    }

    if (isQuickFill) {
        return AVAILABLE_ORDER_TYPES.QUICK_FILL;
    }

    return AVAILABLE_ORDER_TYPES.PREP_LIST;
};

const itemFormProps = computed(() => {
    const { data } = itemFormState;

    if (!data) {
        return {};
    }

    return {
        availableOrderType: getItemAvailableOrderType(),
        name: data.name,
        departmentId: data.department.id,
        category: data.category.id,
        stations: data.stations.map(({ id }) => id),
        shelfLife: data.shelf_life,
        locationSpecificSettings: data.location_specific_settings,
        batchSizeAmount: data.batch_size_amount,
        batchSizeUnitId: data.batch_size_unit?.id,
        batchYieldAmount: data.batch_yield_amount,
        batchYieldUnitId: data.batch_yield_unit?.id,
        batchWeightAmount: data.batch_weight_amount?.toString(),
        batchWeightUnit: data.batch_weight_unit.value,
        batchCost: data.batch_cost,
        batchLaborTime: data.batch_labor_time,
        laborTimeSettings: data.labor_time_settings,
        quickFillOrderingUnitId: data.quick_fill_ordering_unit?.id,
        prepListOrderingUnitId: data.prep_list_ordering_unit?.id,
        prepTasks: data.prep_tasks,
        defaultReportingUnitId: data.default_reporting_unit?.id,
        isChangeableReportingUnit: data.is_changeable_reporting_unit,
    };
});

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const submitDeleteItem = () => {
    deletionCallback(() =>
        deletionModel.value.delete().then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Item has been successfully deleted',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX, query: route.query });
        })
    );
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

const isLoading = computed(() => itemFormState.isDataLoading || itemFormState.isSubmitting);

onBeforeMount(loadItemData);
</script>

<template>
    <TopBarLayout
        include-back-route-query
        :back-route="SETTINGS_ROUTE_NAMES.ITEMS.INDEX"
    >
        <template #title>
            <h1>Edit Item</h1>
        </template>

        <Alert
            v-if="itemFormState.data === null && !itemFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Item could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <ItemForm
                is-edit-mode
                :available-order-type="itemFormProps.availableOrderType"
                :name="itemFormProps.name"
                :department-id="itemFormProps.departmentId"
                :category="itemFormProps.category"
                :stations="itemFormProps.stations"
                :shelf-life="itemFormProps.shelfLife"
                :location-specific-settings="itemFormProps.locationSpecificSettings"
                :batch-size-amount="itemFormProps.batchSizeAmount"
                :batch-size-unit-id="itemFormProps.batchSizeUnitId"
                :batch-yield-amount="itemFormProps.batchYieldAmount"
                :batch-yield-unit-id="itemFormProps.batchYieldUnitId"
                :batch-weight-amount="itemFormProps.batchWeightAmount"
                :batch-weight-unit="itemFormProps.batchWeightUnit"
                :batch-cost="itemFormProps.batchCost"
                :batch-labor-time="itemFormProps.batchLaborTime"
                :labor-time-settings="itemFormProps.laborTimeSettings"
                :quick-fill-ordering-unit-id="itemFormProps.quickFillOrderingUnitId"
                :prep-list-ordering-unit-id="itemFormProps.prepListOrderingUnitId"
                :prep-tasks="itemFormProps.prepTasks"
                :default-reporting-unit-id="itemFormProps.defaultReportingUnitId"
                :is-changeable-reporting-unit="itemFormProps.isChangeableReportingUnit"
                :validation-errors="itemFormState.validationErrors"
                :is-data-loading="itemFormState.isDataLoading"
                :is-submitting="itemFormState.isSubmitting"
                @submit="submitUpdateItem"
            >
                <template #delete-item-btn>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        data-testid="item_delete_btn"
                        :disabled="isLoading"
                        @click="openDeletionModal(itemFormState.data)"
                    >
                        Delete Item
                    </button>
                </template>
            </ItemForm>

            <DeletionModal
                v-if="isDeletionModalOpened"
                qa-prefix="delete_item_modal"
                :is-pending="isDeletionPending"
                @close="closeDeletionModal"
                @submit-delete="submitDeleteItem"
            >
                Are you sure you want to delete the item "{{ deletionModel.name }}" from the company?
            </DeletionModal>
        </template>
    </TopBarLayout>
</template>
