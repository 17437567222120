<script>
export default {
    name: 'TimelineMainManager',
};
</script>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { isAdmin } from '@/utils/accountUtils';
import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';
import PLOrderModel from '@/models/PLOrder';
import useDeletionModal from '@/composition/useDeletionModal';
import DeletionModal from '@/components/DeletionModal';
import TimelineOrderCard from '@/components/model-specific/orders/TimelineOrderCard';
import TimelineViewTemplate from '../../partials/timeline-view-template';

const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                             Timeline settings
------------------------------------------------------------------------*/

const factory = isAdmin(currentAccount) ? 'admin_by_order' : 'manager_by_order';

const initialFiltersConfig = {
    department_id: null,
    category_id: null,
    status: null,
    only_my: isAdmin(currentAccount) ? '0' : '1',
};

const freshTimeline = ref({});

/*------------------------------------------------------------------------
                               Dismiss order
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const submitDismissOrder = () => {
    deletionCallback(() =>
        PLOrderModel.markAsClosed(orgId, deletionModel.value.id, {
            ...route.query,
            factory,
            page_backward: 1,
            include_start_date: 1,
            page_forward: 1,
        }).then((models) => {
            freshTimeline.value = models;

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully dismissed',
            });
        })
    );
};
</script>

<template>
    <TimelineViewTemplate
        regular-orders-list-key="regular"
        other-orders-list-key="overproduction"
        :view-mode="TIMELINE_VIEW_MODES.MAIN"
        :factory="factory"
        :initial-filters-config="initialFiltersConfig"
        :fresh-timeline="freshTimeline"
    >
        <template #order-list-item="{ item: order, isRegularList }">
            <TimelineOrderCard
                :order="order"
                :items-data-key="`${isRegularList ? 'ordered' : 'other'}`"
                @dismiss-order="openDeletionModal(order)"
            />
        </template>

        <template
            v-if="isDeletionModalOpened"
            #modal
        >
            <DeletionModal
                qa-prefix="dismiss_order_modal"
                submit-btn-text="Yes"
                cancel-btn-text="No"
                :is-pending="isDeletionPending"
                @close="closeDeletionModal"
                @submit-delete="submitDismissOrder"
            >
                <template #title>
                    Action required
                </template>

                {{ `Are you sure you want to dismiss ${deletionModel.name}?` }}
            </DeletionModal>
        </template>
    </TimelineViewTemplate>
</template>
