<template>
    <EmployeeForm
        :hidden-fields="['available_locations']"
        :added-employees="state.employees"
        @submit="cacheData"
    />
</template>

<script>
export default {
    name: 'RegistrationStep2AddEmployee',
};
</script>

<script setup>
import { reactive } from 'vue';
import { useRouter } from 'vue-router';

import { LS } from '@/instances/localStorage';
import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import EmployeeForm from '@/components/forms/EmployeeForm';

const router = useRouter();

const state = reactive({
    employees: LS.get('onboarding_employees', []),
});

const cacheData = (payload) => {
    state.employees.push(payload);

    LS.set('onboarding_employees', state.employees);

    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.SUCCESS,
        message: 'Employee added',
    });
    router.back();
};
</script>
