<script>
export default {
    name: 'ArrowDownIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 18,
    },
    height: {
        type: Number,
        default: 11,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 11"
        :width="width"
        :height="height"
    >
        <path
            d="M1.503.989 9 8.297 16.497.989a.717.717 0 0 1 1.002 0 .682.682 0 0 1 0 .975L9.524 9.74a.75.75 0 0 1-1.047 0L.5 1.965a.683.683 0 0 1 0-.978.717.717 0 0 1 1.002 0V.99Z"
            fill="#5E6273"
        />
    </svg>
</template>
