/*------------------------------------------------------------------------
                         src/components/Alert.vue
------------------------------------------------------------------------*/

export const ALERT_TYPES = {
    WARNING: 'warning',
    SUCCESS: 'success',
    FAIL: 'fail',
};

/*------------------------------------------------------------------------
                         src/components/Badge.vue
------------------------------------------------------------------------*/

export const BADGE_TYPES = {
    ROUNDED: 'rounded',
    ITEM_CARD: 'item-card',
    ORDER_CARD: 'order-card',
    PAR: 'par',
};

/*------------------------------------------------------------------------
                        src/components/Tooltip.vue
------------------------------------------------------------------------*/

export const TOOLTIP_OFFSET_PADDING = 16 * 0.75; // dev note: 0.75rem

/*------------------------------------------------------------------------
                 src/components/form-controls/QtyInput.vue
------------------------------------------------------------------------*/

export const QTY_INPUT_EVENTS = {
    INCREMENT: 'increment',
    DECREMENT: 'decrement',
    UPDATE: 'update',
};

/*------------------------------------------------------------------------
              src/components/form-controls/TagListSelect.vue
------------------------------------------------------------------------*/

export const TAG_LIST_SELECT_EVENTS = {
    ADD: 'add',
    REMOVE: 'remove',
};
