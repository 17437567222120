<script>
export default {
    name: 'PlusIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 57,
    },
    height: {
        type: Number,
        default: 57,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 57 57"
        :width="width"
        :height="height"
    >
        <path
            d="M57 28.5a28.5 28.5 0 1 1-57 0 28.5 28.5 0 0 1 57 0zM30.281 16.031a1.781 1.781 0 1 0-3.562 0V26.72H16.03a1.781 1.781 0 1 0 0 3.562H26.72V40.97a1.781 1.781 0 1 0 3.562 0V30.28H40.97a1.781 1.781 0 1 0 0-3.562H30.28V16.03z"
            fill="#CF382F"
        />
    </svg>
</template>