<script>
export default {
    name: 'CreatePLOrderIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { getBackRoute } from '@/utils/navigationUtils';
import { isOrderEditing } from '@/utils/orderUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

const notifyAboutPendingOrder = () => {
    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending order. Please finalize or cancel it, before creating a new one.',
    });
};

onBeforeMount(() => {
    if (!$acl.can(PL_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create orders.',
        });

        return router.replace({ name: PL_ROUTE_NAMES.INDEX });
    }

    const order = currentPLOrder.value;

    if (order === null) {
        return;
    }

    if (isOrderEditing(order)) {
        notifyAboutPendingOrder();

        router.replace({
            name: PL_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        });
    } else if (route.query.action === 'new-order') {
        notifyAboutPendingOrder();
    }
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="getBackRoute(route, PL_ROUTE_NAMES.CREATE, PL_ROUTE_NAMES.INDEX)"
    >
        <template #title>
            <h1>
                <span>
                    Create Order

                    <Badge
                        v-if="currentPLOrder?.is_par"
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        PAR
                    </Badge>
                </span>
            </h1>
        </template>

        <template
            v-if="route.name !== PL_ROUTE_NAMES.CREATE.INDEX"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <template v-if="!!route.meta.parentProps?.enableOrderGroupItems">
                                <GroupedSettings.ListItem
                                    v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                    :key="`group-items-by-option-${value}`"
                                    :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                    @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                                >
                                    {{ text }}
                                </GroupedSettings.ListItem>
                            </template>

                            <GroupedSettings.ListItem
                                v-else
                                is-selected
                            >
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <router-view />
    </TopBarLayout>
</template>
