<script>
export default {
    name: 'PrepDateForm',
};
</script>

<script setup>
import { reactive, watch } from 'vue';
import dayjs from 'dayjs';
import { required } from '@vuelidate/validators';

import FormManager from '@/utils/form/FormManager';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    prepDate: {
        type: String,
        default: null,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits(['set-is-submitting-allowed', 'set-has-changes-made', 'submit']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const getInitialPrepDate = () => {
    const { prepDate } = props;

    const today = dayjs().startOf('day').format('YYYY-MM-DD');

    if (prepDate === null) {
        return today;
    }

    const isPrepDateValid = dayjs(prepDate).isSameOrAfter(today);

    return isPrepDateValid ? prepDate : null;
};

const form = reactive(
    new FormManager(
        {
            prep_date: getInitialPrepDate(),
        },
        {
            prep_date: {
                required,
            },
        },
        {
            prep_date: 'Prep Date',
        }
    )
);

const submitForm = () => {
    if (form.validate()) {
        emit('submit', form.getPayload());
    }
};

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.errors.record(errors);
        }
    },
    { immediate: true }
);

watch(
    () => form.prep_date,
    (prepDate) => {
        emit('set-is-submitting-allowed',  prepDate !== null);
        emit('set-has-changes-made', props.prepDate !== prepDate);
    },
    { immediate: true }
);
</script>

<template>
    <FormUI
        class="pl-prep-date-from"
        data-test-id="prep-date-form"
        @submit="submitForm"
    >
        <template #content>
            <div
                class="pl-prep-date-from__datepicker"
                :class="{
                    'pl-prep-date-from__datepicker--selected': form.prep_date !== null,
                }"
            >
                <h5>Prep Date</h5>

                <DatePickerHOC
                    v-model="form.prep_date"
                    inline
                    :min-date="new Date()"
                    @update:modelValue="form.errors.clear('prep_date')"
                />
            </div>

            <ValidationErrors
                v-if="form.errors.has('prep_date')"
                :errors="form.errors.get('prep_date')"
            />
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-prep-date-from {
    @include media-breakpoint-up(md) {
        max-width: 360px;
    }

    &__datepicker {
        background-color: $white;
        padding: custom-space(0.5);
        border: 1px solid $form-select-border-color;
        border-radius: $border-radius;

        & > h5 {
            text-align: center;
            margin-bottom: custom-space(0.5);
        }

        :deep(.dp__menu) {
            flex-grow: 1;
        }

        :deep(.dp__calendar_header),
        :deep(.dp__calendar) {
            width: 100%;
        }

        &--selected {
            border-color: $input-focus-border-color;
        }
    }
}
</style>
