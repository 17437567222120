<script>
export default {
    name: 'EyeIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 24,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 500 500"
        :width="width"
        :height="height"
    >
        <path
            d="M494.8 241.4 444.2 192c-50.1-48.9-116.9-75.8-188.2-75.8S117.9 143.1 67.8 192l-50.6 49.4c-11.3 12.3-4.3 25.4 0 29.2L67.8 320c50.1 48.9 116.9 75.8 188.2 75.8s138.1-26.9 188.2-75.8l50.6-49.4c4-3.8 11.7-16.4 0-29.2zM256 325.8c-38.5 0-69.8-31.3-69.8-69.8s31.3-69.8 69.8-69.8 69.8 31.3 69.8 69.8-31.3 69.8-69.8 69.8zM60.7 256l35.7-34.8c27-26.4 59.8-45.2 95.7-55.4-28.2 20.1-46.6 53-46.6 90.1 0 37.1 18.4 70.1 46.6 90.1-35.9-10.2-68.7-29-95.7-55.3L60.7 256zm355 34.8c-27 26.3-59.8 45.1-95.7 55.3 28.2-20.1 46.6-53 46.6-90.1 0-37.2-18.4-70.1-46.6-90.1 35.9 10.2 68.7 29 95.7 55.4l35.6 34.8-35.6 34.7z"
            fill="#969aab"
        />
    </svg>
</template>
