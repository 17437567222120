<script>
export default {
    name: 'CompanyEditView',
};
</script>

<script setup>
import { computed, onBeforeMount, inject, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { cutFloatNumber } from '@/utils/numberUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import OrganizationModel from '@/models/Organization';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import OrganizationForm from '@/components/forms/OrganizationForm';
import { ORGANIZATION_RESTRICTIONS } from '@/enums/restrictionsEnums';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(ORGANIZATION_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit organization.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                          Organization form state
------------------------------------------------------------------------*/

const organizationFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadOrganizationData = () => {
    sendAbortableRequest(OrganizationModel.find(orgId))
        .then((modelData) => (organizationFormState.data = modelData))
        .finally(() => (organizationFormState.isDataLoading = false));
};

const submitUpdateOrganization = (attributes) => {
    organizationFormState.isSubmitting = true;

    currentAccount.organization
        .update(attributes)
        .then((model) => {
            store.commit('auth/setOrganizationName', model.name);

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Organization has been successfully updated',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.INDEX });
        })
        .finally(() => (organizationFormState.isSubmitting = false));
};

const organizationFormProps = computed(() => {
    const { data } = organizationFormState;

    if (data === null) {
        return {};
    }

    return {
        name: data.name,
        defaultHourlyRate: cutFloatNumber(+data.default_hourly_rate / 100),
        notificationChannels: data.notification_channels,
    };
});

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

const isLoading = computed(() => organizationFormState.isDataLoading || organizationFormState.isSubmitting);

onBeforeMount(loadOrganizationData);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Company settings</h1>
        </template>

        <Alert
            v-if="organizationFormState.data === null && !isLoading"
            :type="ALERT_TYPES.FAIL"
        >
            Organization could not be loaded for some reason. Please try again later.
        </Alert>

        <OrganizationForm
            v-else
            :name="organizationFormProps.name"
            :default-hourly-rate="organizationFormProps.defaultHourlyRate"
            :notification-channels="organizationFormProps.notificationChannels"
            :is-data-loading="organizationFormState.isDataLoading"
            :is-submitting="organizationFormState.isSubmitting"
            @submit="submitUpdateOrganization"
        />
    </TopBarLayout>
</template>
