<script>
export default {
    name: 'TreeDisplay',
};
</script>

<script setup>
import { useSlots } from 'vue';

defineProps({
    data: {
        type: Object,
        required: true,
    },
    nodeSourceColumn: {
        type: String,
        default: null,
    },
    nestingLevel: {
        type: Number,
        default: 0,
    },
});

const slots = useSlots();
</script>

<template>
    <ul class="pl-tree-display">
        <li
            v-if="Array.isArray(nodeSourceColumn ? data[nodeSourceColumn] : data)"
            class="pl-tree-display__leaf"
        >
            <slot
                name="leaf"
                :leaf="data"
            />
        </li>

        <template v-else>
            <li
                v-for="(node, name) in nodeSourceColumn ? data[nodeSourceColumn] : data"
                :key="`tree-display-node-${name}`"
                class="pl-tree-display__node"
            >
                <slot
                    :key="name"
                    name="node"
                    :nesting-level="nestingLevel"
                    :node="node"
                />

                <TreeDisplay
                    :data="node"
                    :node-source-column="nodeSourceColumn"
                    :nesting-level="nestingLevel + 1"
                >
                    <!-- Pass on all scoped slots -->
                    <template
                        v-for="slot in Object.keys(slots)"
                        #[slot]="scope"
                    >
                        <slot
                            :name="slot"
                            v-bind="scope"
                        />
                    </template>
                </TreeDisplay>
            </li>
        </template>
    </ul>
</template>

<style lang="scss" scoped>
.pl-tree-display {
    padding: 0;
    margin: 0;
    list-style: none;
    word-break: break-word;
}
</style>
