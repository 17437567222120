<script>
export default {
    name: 'EmployeeForm',
};
</script>

<script setup>
import { reactive, onBeforeMount, computed, watch, ref } from 'vue';
import { useStore } from 'vuex';
import { required, email, minLength, maxLength, numeric } from '@vuelidate/validators';

import FormManager from '@/utils/form/FormManager';
import { phone } from '@/utils/form/validators';
import { ACCOUNT_ROLES, ROLE_OPTIONS } from '@/enums/accountEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import Radio from '@/components/form-controls/Radio';
import TextInput from '@/components/form-controls/TextInput';
import PhoneInput from '@/components/form-controls/PhoneInput';
import SelectInput from '@/components/form-controls/SelectInput';
import NumericInput from '@/components/form-controls/NumericInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    organizationId: {
        type: Number,
        default: null,
    },
    name: {
        type: String,
        default: null,
    },
    hourlyRate: {
        type: [Number, String],
        default: null,
    },
    phone: {
        type: Object,
        default: () => ({
            phone: null,
            phone_country_id: null,
        }),
    },
    email: {
        type: String,
        default: null,
    },
    role: {
        type: String,
        default: ACCOUNT_ROLES.COOK,
    },
    availableLocations: {
        type: Array,
        default: () => [],
    },
    hiddenFields: {
        type: Array,
        default: () => [],
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    addedEmployees: {
        type: Array,
        default: () => [],
    },
    submitBtnText: {
        type: String,
        default: 'Add to invitation list',
    },
});

const emit = defineEmits(['submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    locations: [],
    isLoading: true,
    hasError: false,
});

const isFieldVisible = (name) => !props.hiddenFields.includes(name);

const loadGeneralState = () => {
    Promise.all([
        isFieldVisible('available_locations')
            ? sendAbortableRequest(LocationModel.all(props.organizationId))
            : Promise.resolve(),
        isFieldVisible('phone') ? store.dispatch('countries/loadCountries') : Promise.resolve(),
    ])
        .then(([locationModels]) => (generalState.locations = locationModels || []))
        .catch(() => (generalState.hasError = true))
        .finally(() => (generalState.isLoading = false));
};

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const getInitialFormValues = () => {
    const initialValues = {
        name: '',
        hourly_rate: '',
        phone: { phone: null, phone_country_id: null },
        email: '',
        role: null,
        available_locations: [],
    };

    return Object.entries(initialValues).reduce((acc, [key, value]) => {
        if (isFieldVisible(key)) {
            acc[key] = value;
        }

        return acc;
    }, {});
};

const form = reactive(new FormManager(getInitialFormValues()));

const setFormPropsValues = () => {
    const propsValues = {
        name: props.name,
        hourly_rate: props.hourlyRate,
        phone: {
            phone: props.phone.phone || form.phone.phone,
            phone_country_id: props.phone.phone_country_id || form.phone.phone_country_id,
        },
        email: props.email,
        role: props.role,
        available_locations: props.availableLocations,
    };

    const formValues = Object.entries(propsValues).reduce((acc, [key, value]) => {
        if (isFieldVisible(key)) {
            acc[key] = value;
        }

        return acc;
    }, {});

    form.setPayload(formValues);
    form.fillProps(formValues);
};

const setFormValidationRules = () => {
    const validationRules = {
        name: { required, minLength: minLength(2), maxLength: maxLength(255) },
        hourly_rate: { required, numeric },
        email: { email, maxLength: maxLength(255) },
        role: { required },
        phone: { phone: phone(store.state.countries.countries) },
        available_locations: { required },
    };

    const rules = Object.keys(validationRules).reduce((acc, key) => {
        if (isFieldVisible(key)) {
            acc[key] = validationRules[key];
        }

        return acc;
    }, {});

    form.setValidationRules(rules);
};

const isIdenticalPhone = (phoneToCompare) => {
    const { phone } = form;

    return phone.phone === phoneToCompare.phone && phone.phone_country_id === phoneToCompare.phone_country_id;
};

const validatePhoneNumber = () => {
    if (isFieldVisible('phone')) {
        const isPhoneAlreadyAdded = props.addedEmployees.some(
            (employee) => isIdenticalPhone(employee.phone) && !isIdenticalPhone(props.phone)
        );

        if (isPhoneAlreadyAdded) {
            form.errors.push('phone', 'The employee is already invited.');

            return false;
        }

        const isOwnerPhone = isIdenticalPhone(store.state.auth.user);

        if (isOwnerPhone) {
            form.errors.push('phone', 'You can`t invite yourself.');

            return false;
        }
    }

    return true;
};

const submitForm = () => {
    if (form.validate() && validatePhoneNumber()) {
        emit('submit', form.getPayload());
    }
};

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.errors.record(errors);
        }
    }
);

/*------------------------------------------------------------------------
                                   Phone
------------------------------------------------------------------------*/

const contactsApiSupported = 'contacts' in navigator && 'select' in navigator.contacts;

const isPhoneFilled = () => {
    if (!isFieldVisible('phone')) {
        return true;
    }

    return form.phone.phone.length > 0;
};

const getProcessedPhoneData = (phone) => {
    const { countries } = store.state.countries;

    const phoneNumber = phone.replace('+', '').replace(new RegExp(/^00/), '');

    for (let country of countries) {
        const { phone_code: code } = country;

        if (new RegExp(`^${code}`).test(phoneNumber)) {
            return {
                phone: phoneNumber.replace(code, ''),
                phoneCountryId: country.id,
            };
        }
    }

    return {
        phone: phoneNumber,
        phoneCountryId: form.phone.phone_country_id,
    };
};

const fillContactData = (contact) => {
    if (isFieldVisible('name')) {
        form.name = contact.name.shift();

        form.validate('name');
    }

    if (isFieldVisible('email')) {
        form.email = contact.email.length ? contact.email.shift() : '';

        form.validate('email');
    }

    if (isFieldVisible('phone')) {
        const { phone, phoneCountryId } = getProcessedPhoneData(contact.tel.shift());

        form.phone.phone = phone;
        form.phone.phone_country_id = phoneCountryId;

        form.validate('phone');
    }
};

const openContactsBook = async () => {
    const contacts = await navigator.contacts.select(['name', 'tel', 'email'], {
        multiple: false,
    });

    if (contacts.length !== 0) {
        const contact = contacts.shift();

        fillContactData(contact);
    }
};

const onPhoneCountryChanged = () => {
    if (form.phone.phone) {
        form.validate('phone');
    } else {
        form.errors.clear('phone');
    }
};

/*------------------------------------------------------------------------
                            Available locations
------------------------------------------------------------------------*/

const activeLocations = ref({});

const getLocationRoleValue = (location) => form.available_locations.find(({ id }) => location.id === id)?.role ?? null;

const handleActiveLocationChanged = (location, index) => {
    const isActive = !!activeLocations.value[index][0];

    if (isActive) {
        form.available_locations.push({
            id: location.id,
            role: ACCOUNT_ROLES.COOK,
        });
    } else {
        form.available_locations = form.available_locations.filter(({ id }) => location.id !== id);
    }

    form.errors.clear(`available_locations.${index}.role`);
};

const handleLocationRoleChanged = (role, currentLocation) => {
    form.available_locations = form.available_locations.map((location) => {
        if (location.id === currentLocation.id) {
            return {
                ...location,
                role,
            };
        }

        return location;
    });
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

const isLoading = computed(() => generalState.isLoading || props.isDataLoading || props.isSubmitting);

watch(
    () => isLoading.value,
    (isLoading) => {
        if (!isLoading && !props.isSubmitting) {
            setFormPropsValues();
            setFormValidationRules();

            activeLocations.value = generalState.locations.reduce((acc, { id }, index) => {
                acc[index] = [];

                if (form.available_locations.some((location) => location.id === id)) {
                    acc[index].push('active');
                }

                return acc;
            }, {});
        }
    },
    { immediate: true }
);

onBeforeMount(loadGeneralState);
</script>

<template>
    <FormUI
        data-test-id="employee_form"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <Alert
                v-if="generalState.hasError"
                :type="ALERT_TYPES.FAIL"
            >
                Something went wrong during loading the page. Please try again later.
            </Alert>

            <template v-else>
                <div
                    v-if="contactsApiSupported"
                    class="mb-4 d-grid"
                >
                    <button
                        type="button"
                        class="btn btn-primary btn-sm"
                        :data-testid="`${qaPrefix}_select_from_contacts_btn`"
                        @click="openContactsBook"
                    >
                        Select from contacts
                    </button>
                </div>

                <div
                    v-if="isFieldVisible('name')"
                    :class="classNames.spacerMd"
                >
                    <TextInput
                        v-model="form.name"
                        include-asterisk
                        name="name"
                        autocomplete="off"
                        label="Name of Employee"
                        :disabled="isLoading"
                        :data-test-id="`${qaPrefix}_name_input`"
                        :has-error="form.errors.has('name')"
                        @input="form.errors.clear('name')"
                        @blur="form.validate('name')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('name')"
                        :data-testid="`${qaPrefix}_name_error`"
                        :errors="form.errors.get('name')"
                    />
                </div>

                <div
                    v-if="isFieldVisible('hourly_rate')"
                    :class="classNames.spacerMd"
                >
                    <NumericInput
                        v-model="form.hourly_rate"
                        include-asterisk
                        only-positive
                        always-use-dot
                        name="hourly_rate"
                        label="Hourly Rate"
                        :max-precision="2"
                        :max="9999"
                        :disabled="isLoading"
                        :data-test-id="`${qaPrefix}_hourly_rate_input`"
                        :has-error="form.errors.has('hourly_rate')"
                        @input="form.errors.clear('hourly_rate')"
                        @blur="form.validate('hourly_rate')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('hourly_rate')"
                        :data-testid="`${qaPrefix}_hourly_rate_error`"
                        :errors="form.errors.get('hourly_rate')"
                    />
                </div>

                <div
                    v-if="isFieldVisible('phone')"
                    :class="classNames.spacerMd"
                >
                    <PhoneInput
                        v-model="form.phone"
                        include-asterisk
                        label="Phone Number"
                        :send-button="false"
                        :disabled="isLoading"
                        :data-testid="`${qaPrefix}_phone_input`"
                        :has-error="form.errors.has('phone')"
                        @blur="form.validate('phone')"
                        @country-changed="onPhoneCountryChanged"
                        @update:modelValue="form.errors.clear('phone')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('phone')"
                        :data-testid="`${qaPrefix}_phone_error`"
                        :errors="form.errors.get('phone')"
                    />
                </div>

                <div
                    v-if="isFieldVisible('email')"
                    :class="classNames.spacerLg"
                >
                    <TextInput
                        v-model="form.email"
                        name="email"
                        type="email"
                        label="Email"
                        :disabled="isLoading"
                        :data-test-id="`${qaPrefix}_email_input`"
                        :has-error="form.errors.has('email')"
                        @input="form.errors.clear('email')"
                        @blur="form.validate('email')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('email')"
                        :data-testid="`${qaPrefix}_email_error`"
                        :errors="form.errors.get('email')"
                    />
                </div>

                <template v-if="isFieldVisible('available_locations') && Object.keys(activeLocations).length > 0">
                    <h5 :class="classNames.spacerSm">
                        {{ `Locations (${form.available_locations.length})` }}
                    </h5>

                    <div
                        v-for="(location, index) in generalState.locations"
                        :key="`available locations: ${index}`"
                        class="pl-employee-location"
                        :class="classNames.spacerSm"
                    >
                        <CheckboxInput
                            v-model="activeLocations[index]"
                            :name="`available_locations.${index}.active`"
                            :qa-prefix="qaPrefix"
                            :options="[{ value: 'active' }]"
                            @update:modelValue="handleActiveLocationChanged(location, index)"
                        />

                        <div class="pl-employee-location__wrapper">
                            <SelectInput
                                :model-value="getLocationRoleValue(location)"
                                :data-test-id="`${qaPrefix}_available_locations_role_select`"
                                :label="location.name"
                                :options="ROLE_OPTIONS"
                                :has-error="form.errors.has(`available_locations.${index}.role`)"
                                :disabled="!activeLocations[index][0]"
                                @update:modelValue="(value) => handleLocationRoleChanged(value, location)"
                            />

                            <ValidationErrors
                                v-if="form.errors.has(`available_locations.${index}.role`)"
                                :data-testid="`${qaPrefix}_role_${index}_errors`"
                                :errors="form.errors.get(`available_locations.${index}.role`)"
                            />
                        </div>
                    </div>
                </template>

                <template v-if="isFieldVisible('role')">
                    <h5 :class="classNames.spacerMd">
                        Role
                    </h5>

                    <Radio
                        v-model="form.role"
                        label="Role"
                        name="role"
                        :options="ROLE_OPTIONS"
                        :data-testid="`${qaPrefix}_role_input`"
                        :has-error="form.errors.has('role')"
                        @blur="form.validate('role')"
                        @update:modelValue="form.errors.clear('role')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('role')"
                        :data-testid="`${qaPrefix}_role_error`"
                        :errors="form.errors.get('role')"
                    />
                </template>
            </template>
        </template>

        <template #actions="{ qaPrefix }">
            <button
                type="submit"
                class="btn btn-primary"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="form.errors.any() || !form.isFilled() || isLoading || !isPhoneFilled()"
            >
                {{ submitBtnText }}
            </button>
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-employee-location {
    display: flex;
    align-items: flex-start;

    &__wrapper {
        flex: 1;
    }

    :deep(.pl-checkbox) {
        margin-top: custom-space(1);

        .form-check {
            margin: 0;
        }

        .form-check-input[type='checkbox'] {
            border-color: $black;
            border-radius: custom-space(0.25);
        }
    }
}

h5.pl-form__spacer--sm {
    border-bottom: 1px solid $gray-200;
}
</style>
