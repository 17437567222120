<script>
export default {
    name: 'TimelineMainCook',
};
</script>

<script setup>
import { computed, reactive, ref, watch } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import EventBus from '@/utils/EventBus';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import {
    TIMELINE_VIEW_MODES,
    TIMELINE_ARBITRARY_REPORT_TYPES,
    TIMELINE_ARBITRARY_REPORT_TYPES_OPTIONS,
} from '@/enums/timelineEnums';
import OrderItemModel from '@/models/OrderItem';
import SelectInput from '@/components/form-controls/SelectInput';
import TimelineViewTemplate from '../../../partials/timeline-view-template';
import TimelineCookItemCard from './partials/TimelineCookItemCard';
import ReportItemFormModal from './partials/ReportItemFormModal';

const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                             Timeline settings
------------------------------------------------------------------------*/

const factory = 'cook_by_item';

const initialFiltersConfig = {
    viewmode: null,
};

const freshTimeline = ref({});
const selectedOrdersList = ref([]);

const setSelectedOrdersList = (ordersList) => (selectedOrdersList.value = ordersList);

/*------------------------------------------------------------------------
                            Report item form state
------------------------------------------------------------------------*/

const reportItemFormState = reactive({
    item: null,
    validationErrors: null,
    isModalOpened: false,
});

const loadingItemState = reactive({
    ids: new Map(),
    count: 0,
});

const openReportItemFormModal = (item, initCompleteItem = false) => {
    reportItemFormState.item = item;

    if (initCompleteItem) {
        reportItem(item.ordered_BY_qty - item.produced_BY_qty);
    } else {
        reportItemFormState.isModalOpened = true;
    }
};

const closeReportItemFormModal = () => {
    reportItemFormState.isModalOpened = false;
    reportItemFormState.item = null;
};

const reportItem = (qty) => {
    const { item } = reportItemFormState;

    const payload = {
        qty,
        assignee_id: item.assignee_id,
        assignee_type: item.assignee_type,
        date: route.query.start_date,
    };

    if (item.status === 'open_item') {
        payload.is_open_item = true;
    }

    if (!reportItemFormState.isModalOpened) {
        loadingItemState.ids.set(item.id, true);
    }

    loadingItemState.count++;

    OrderItemModel.report(orgId, item.prototype_id, payload, {
        factory,
        page_backward: 1,
        include_start_date: 1,
        page_forward: 1,
        timeline_start_date: route.query.start_date,
    })
        .then((freshTimelineModel) => {
            loadingItemState.count--;

            if (loadingItemState.count === 0) {
                closeReportItemFormModal();

                freshTimeline.value = freshTimelineModel;

                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Batches have been successfully reported',
                });
            }
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                reportItemFormState.validationErrors = data?.errors || data?.data?.errors;

                openReportItemFormModal(item);
            }
        })
        .finally(() => {
            if (loadingItemState.count === 0) {
                loadingItemState.ids.clear();
            }
        });
};

const isReportingDisabled = computed(
    () => !dayjs(route.query.start_date).startOf('day').isSame(dayjs().startOf('day'))
);

/*------------------------------------------------------------------------
                          Arbitrary report state
------------------------------------------------------------------------*/

const arbitraryReportState = reactive({
    type: TIMELINE_ARBITRARY_REPORT_TYPES.ALL,
    id: null,
});

const arbitraryTypeOptions = computed(() => {
    const { items_to_order: itemsToOrder } = selectedOrdersList.value || {};

    if (Object.keys(itemsToOrder || {}).length === 0 || isReportingDisabled.value) {
        return [];
    }

    const entries = Object.entries(TIMELINE_ARBITRARY_REPORT_TYPES_OPTIONS);

    return entries.reduce((acc, [type, text]) => {
        if (Object.keys(itemsToOrder[type] || {}).length > 0 || type === TIMELINE_ARBITRARY_REPORT_TYPES.ALL) {
            acc.push({ value: type, text });
        }

        return acc;
    }, []);
});

const convertArbitraryListToOptions = (arbitraryList = null) => {
    const isEmptyArray = Array.isArray(arbitraryList) && arbitraryList.length === 0;

    if (arbitraryList === null || isEmptyArray) {
        return [];
    }

    if (Array.isArray(arbitraryList)) {
        const actionsList = arbitraryList.map(({ id, name }) => ({
            value: id,
            text: name,
        }));

        return [{ text: 'Actions', groupLevel: 1 }, ...actionsList];
    }

    const result = [];

    for (const department in arbitraryList) {
        result.push({
            text: department,
            groupLevel: 1,
        });

        const data = arbitraryList[department] || [];

        for (const item of data) {
            result.push({
                text: item.name,
                value: item.id,
            });
        }
    }

    return result;
};

const arbitraryItemsOptions = computed(() => {
    const { items_to_order: itemsToOrder } = selectedOrdersList.value || {};

    if (!itemsToOrder || isReportingDisabled.value) {
        return [];
    }

    const { type } = arbitraryReportState;

    if (type === TIMELINE_ARBITRARY_REPORT_TYPES.ALL) {
        return Object.values(TIMELINE_ARBITRARY_REPORT_TYPES).flatMap((type) =>
            convertArbitraryListToOptions(itemsToOrder[type])
        );
    }

    return convertArbitraryListToOptions(itemsToOrder[type]);
});

const arbitrarySelectLabel = computed(() => {
    if (arbitraryReportState.type === TIMELINE_ARBITRARY_REPORT_TYPES.ALL) {
        return 'Select to report';
    }

    const entity = TIMELINE_ARBITRARY_REPORT_TYPES_OPTIONS[arbitraryReportState.type];

    return `Select ${entity} to report`;
});

const openReportArbitraryItem = () => {
    const { text, value } = arbitraryItemsOptions.value.find((option) => arbitraryReportState.id === option.value);

    const payload = {
        assignee_type: 'account',
        name: text,
        prototype_id: value,
        assignee_id: currentAccount.id,
    };

    openReportItemFormModal(payload);

    arbitraryReportState.id = null;
};

watch(
    () => arbitraryReportState.type,
    () => (arbitraryReportState.id = null)
);

watch(
    () => arbitraryTypeOptions.value,
    (arbitraryTypes) => {
        if (!arbitraryTypes.some((type) => arbitraryReportState.type === type)) {
            arbitraryReportState.type = arbitraryTypes[0]?.value || null;
        }
    }
);
</script>

<template>
    <TimelineViewTemplate
        include-tabs
        regular-orders-list-key="ordered"
        other-orders-list-key="other"
        :view-mode="TIMELINE_VIEW_MODES.MAIN"
        :factory="factory"
        :initial-filters-config="initialFiltersConfig"
        :fresh-timeline="freshTimeline"
        @set-selected-orders-list="setSelectedOrdersList"
    >
        <template #order-list-item="{ item: orderItem }">
            <TimelineCookItemCard
                :order-item="orderItem"
                :is-reporting-disabled="isReportingDisabled || loadingItemState.count > 0"
                :is-loading="loadingItemState.ids.has(orderItem.id)"
                @init-report-item="openReportItemFormModal"
            />
        </template>

        <template
            v-if="arbitraryTypeOptions.length > 0"
            #arbitrary-section
        >
            <div class="pl-arbitrary-section">
                <small>Report any item or prep task not mentioned above</small>

                <div class="pl-arbitrary-section__wrapper">
                    <div class="pl-arbitrary-section__types">
                        <button
                            v-for="{ value, text } in arbitraryTypeOptions"
                            :key="value"
                            type="button"
                            class="pl-arbitrary-section__type-option"
                            :class="{
                                'pl-arbitrary-section__type-option--active': arbitraryReportState.type === value,
                            }"
                            @click="arbitraryReportState.type = value"
                        >
                            {{ text }}
                        </button>
                    </div>

                    <SelectInput
                        :key="arbitraryReportState.type"
                        v-model="arbitraryReportState.id"
                        enable-group-mode
                        searchable
                        data-test-id="arbitrary_report_select"
                        :options="arbitraryItemsOptions"
                        :label="arbitrarySelectLabel"
                    />

                    <button
                        type="button"
                        class="btn btn-secondary btn-sm"
                        :disabled="arbitraryReportState.id === null"
                        @click="openReportArbitraryItem"
                    >
                        Report
                    </button>
                </div>
            </div>
        </template>

        <template
            v-if="reportItemFormState.isModalOpened"
            #modal
        >
            <ReportItemFormModal
                :organization-id="orgId"
                :item="reportItemFormState.item"
                :validation-errors="reportItemFormState.validationErrors"
                @close-modal="closeReportItemFormModal"
                @submit="reportItem"
            />
        </template>
    </TimelineViewTemplate>
</template>

<style lang="scss" scoped>
.pl-arbitrary-section {
    display: flex;
    flex-direction: column;
    max-width: 400px;
    margin: 0 auto;

    :deep(.pl-select) {
        max-width: 100%;
        margin: custom-space(0.3) 0;
    }

    & > small {
        color: $gray-800;
        text-align: center;
        margin-bottom: custom-space(1);
    }

    &__wrapper {
        display: flex;
        flex-direction: column;

        & > button {
            align-self: flex-end;
        }
    }

    &__types {
        display: flex;
        align-items: center;
        gap: custom-space(0.3);
    }

    &__type-option {
        background-color: $gray-400;
        padding: custom-space(0.2) custom-space(1);
        text-transform: capitalize;
        font-size: $font-size-base * 0.875;
        font-weight: 400;
        color: $black;
        border-radius: 1.5rem;

        &--active {
            background-color: $primary;
            color: $white;
        }
    }
}

:deep(.pl-timeline-view__orders-list--space) {
    padding-bottom: custom-space(1);
}

:deep(.pl-overlay-spinner) {
    border-radius: $border-radius;
}
</style>
