import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';

import QFOIndexView from '@/views/dashboard/quick-fill-orders/index';

import CreateQFOrderIndexView from '@/views/dashboard/quick-fill-orders/create';
import CreateQFOrderStepOneView from '@/views/dashboard/quick-fill-orders/create/step-1';
import CreateQFOrderStepTwoView from '@/views/dashboard/quick-fill-orders/create/step-2';
import CreateQFOrderStepThreeView from '@/views/dashboard/quick-fill-orders/create/step-3';

import EditQFOrderIndexView from '@/views/dashboard/quick-fill-orders/edit';
import EditQFOrderStepOneView from '@/views/dashboard/quick-fill-orders/edit/step-1';
import EditQFOrderStepTwoView from '@/views/dashboard/quick-fill-orders/edit/step-2';
import EditQFOrderStepThreeView from '@/views/dashboard/quick-fill-orders/edit/step-3';

import ShowQFOrderView from '@/views/dashboard/quick-fill-orders/show';

export default [
    {
        path: 'quick-fill-orders',
        name: QFO_ROUTE_NAMES.INDEX,
        component: QFOIndexView,
    },

    {
        path: 'quick-fill-orders/create',
        component: CreateQFOrderIndexView,
        children: [
            {
                path: '',
                name: QFO_ROUTE_NAMES.CREATE.INDEX,
                component: CreateQFOrderStepOneView,
            },
            {
                path: 'add-items',
                name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS,
                component: CreateQFOrderStepTwoView,
            },
            {
                path: 'review-and-confirm',
                name: QFO_ROUTE_NAMES.CREATE.REVIEW_AND_CONFIRM,
                component: CreateQFOrderStepThreeView,
            },
        ],
    },

    {
        path: 'quick-fill-orders/:orderId/edit',
        component: EditQFOrderIndexView,
        props: true,
        children: [
            {
                path: '',
                name: QFO_ROUTE_NAMES.EDIT.INDEX,
                component: EditQFOrderStepOneView,
                props: true,
            },
            {
                path: 'add-items',
                name: QFO_ROUTE_NAMES.EDIT.ADD_ITEMS,
                component: EditQFOrderStepTwoView,
                props: true,
            },
            {
                path: 'review-and-confirm',
                name: QFO_ROUTE_NAMES.EDIT.REVIEW_AND_CONFIRM,
                component: EditQFOrderStepThreeView,
                props: true,
            },
        ],
    },

    {
        path: 'quick-fill-orders/:orderId',
        name: QFO_ROUTE_NAMES.SHOW,
        component: ShowQFOrderView,
        props: true,
    },
];
