<script>
export default {
    name: 'ChangeLocationModal',
};
</script>

<script setup>
import { computed, onBeforeMount, reactive, ref } from 'vue';
import { useStore } from 'vuex';
import { required, integer } from '@vuelidate/validators';

import FormManager from '@/utils/form/FormManager';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Modal from '@/components/Modal';
import Alert from '@/components/Alert';
import SelectInput from '@/components/form-controls/SelectInput';
import FormUI from '@/components/UI/FormUI';

const emit = defineEmits(['close']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            location_id: null,
        },
        {
            location_id: {
                required,
                integer,
            },
        },
        {
            location_id: 'Location',
        }
    )
);

const isFormSubmitting = ref(false);

const submitForm = () => {
    if (form.validate()) {
        isFormSubmitting.value = true;

        AccountModel.changeLocation(orgId, currentAccount.id, form.getPayload())
            .then(() => (window.location.href = '/'))
            .catch((error) => {
                const { status, data } = error.response || {};

                isFormSubmitting.value = false;

                if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                    form.errors.record(data?.errors || data?.data?.errors || {});
                }
            });
    }
};

/*------------------------------------------------------------------------
                                 Locations
------------------------------------------------------------------------*/

const locationsState = reactive({
    data: [],
    isDataLoading: true,
});

const loadLocations = () => {
    sendAbortableRequest(LocationModel.all(orgId))
        .then((models) => (locationsState.data = models || []))
        .finally(() => (locationsState.isDataLoading = false));
};

const locationOptions = computed(() => {
    const { location, available_locations: availableLocations } = store.state.auth.currentAccount;

    const availableLocationIds = new Set(availableLocations.map(({ id }) => id));

    return locationsState.data.reduce((acc, { id, name }) => {
        if (availableLocationIds.has(id) && location.id !== id) {
            acc.push({
                value: id,
                text: name,
            });
        }

        return acc;
    }, []);
});

const hasNoAvailableLocations = computed(() => !locationsState.isDataLoading && locationOptions.value.length === 0);

onBeforeMount(loadLocations);
</script>

<template>
    <Modal
        :is-close-disabled="isFormSubmitting"
        @close="emit('close')"
    >
        <template #title>
            Change location
        </template>

        <template #content>
            <FormUI
                id="pl-change-location-form"
                data-test-id="change_location_form"
                :is-loading="isFormSubmitting"
                @submit="submitForm"
            >
                <template #content="{ classNames, qaPrefix }">
                    <div>
                        <SelectInput
                            v-model="form.location_id"
                            searchable
                            include-asterisk
                            label="Location"
                            :data-test-id="`${qaPrefix}_location_select`"
                            :options="locationOptions"
                            :has-error="form.errors.has('location_id')"
                            :loading="locationsState.isDataLoading"
                            :disabled="locationOptions.length === 0"
                            :class="{
                                [classNames.spacerSm]: hasNoAvailableLocations,
                            }"
                            @blur="form.validate('location_id')"
                            @update:modelValue="form.errors.clear('location_id')"
                        />

                        <Alert
                            v-if="hasNoAvailableLocations"
                            :type="ALERT_TYPES.FAIL"
                        >
                            There are no available locations.
                        </Alert>

                        <ValidationErrors
                            v-if="form.errors.has('location_id')"
                            :data-testid="`${qaPrefix}_department_errors`"
                            :errors="form.errors.get('location_id')"
                        />
                    </div>
                </template>
            </FormUI>
        </template>

        <template #actions>
            <button
                type="button"
                class="btn btn-secondary"
                @click="emit('close')"
            >
                Cancel
            </button>

            <button
                type="submit"
                class="btn btn-primary"
                form="pl-change-location-form"
                data-test-id="change_location_form_submit_btn"
                :disabled="form.errors.any() || !form.isFilled() || isFormSubmitting"
            >
                Apply
            </button>
        </template>
    </Modal>
</template>
