<script>
export default {
    name: 'TopBarLayout',
};
</script>

<script setup>
import { useSlots } from 'vue';
import { useRoute } from 'vue-router';

import LayoutHeader from './partials/layout-header';

defineProps({
    backRoute: {
        type: String,
        default: '',
    },
    includeBackRouteQuery: {
        type: Boolean,
        default: false,
    },
    excludeContentContainer: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();
const route = useRoute();
</script>

<template>
    <section class="pl-layout">
        <LayoutHeader>
            <template #ribbon>
                <slot name="ribbon" />
            </template>
        </LayoutHeader>

        <div class="pl-layout__wrapper">
            <div class="container">
                <router-link
                    v-if="backRoute"
                    :to="{ name: backRoute, query: includeBackRouteQuery ? route.query : {} }"
                    class="pl-layout__arrow-back"
                    data-testid="page_arrow_back"
                >
                    <ArrowDownIcon :height="10" />
                </router-link>

                <div class="pl-layout__title">
                    <slot name="title" />
                </div>

                <transition
                    appear
                    name="fade"
                    type="transition"
                >
                    <div
                        v-if="slots['actions']"
                        class="pl-layout__actions"
                    >
                        <slot name="actions" />
                    </div>
                </transition>
            </div>
        </div>

        <div
            class="pl-layout__content"
            :class="{ container: !excludeContentContainer }"
        >
            <slot />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.pl-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    &__wrapper {
        padding: custom-space(0.5) 0;
        color: $white;
        background-color: $primary;

        & > .container {
            position: relative;
            display: flex;
            align-items: center;
            gap: custom-space(1);
        }
    }

    &__title {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;
        width: 100%;

        & > :slotted(h1) {
            font-size: calc(1.25rem + 0.75vw);
            color: inherit;
            margin-bottom: 0;

            > span {
                position: relative;
            }
        }
    }

    &__arrow-back {
        padding: $click-space;
        margin: -$click-space;
        margin-left: -($click-space + custom-space(0.2));

        svg {
            transform: rotate(90deg);

            :deep(path) {
                fill: $white;
            }
        }
    }

    &__actions {
        flex: 0 0 auto;
        display: flex;
        align-items: center;
        gap: custom-space(1.125);

        & > :slotted(*) {
            cursor: pointer;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        padding-top: custom-space(1.5);
        padding-bottom: custom-space(1.5);
    }
}
</style>
