<script>
export default {
    name: 'AuthLayout',
};
</script>

<script setup>
import { useSlots } from 'vue';

defineProps({
    isContentCentered: {
        type: Boolean,
        default: false,
    },
});

const slots = useSlots();
</script>

<template>
    <section
        class="pl-auth-layout"
        :class="{
            'pl-auth-layout--centered': isContentCentered,
        }"
    >
        <header v-if="slots['header']">
            <slot name="header" />
        </header>

        <div class="container">
            <slot />
        </div>
    </section>
</template>

<style lang="scss" scoped>
.pl-auth-layout {
    display: flex;
    flex-direction: column;
    height: 100%;

    & > header {
        padding: custom-space(1);
        padding-bottom: 0;
    }

    & > .container {
        max-width: 380px;
        padding-top: custom-space(3);
        padding-bottom: custom-space(1.5);
    }

    &--centered > .container {
        margin-top: auto;
        margin-bottom: auto;
    }

    &:not(&--centered) > .container {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
</style>
