<script>
export default {
    name: 'SettingsIndexView',
};
</script>

<script setup>
import { useStore } from 'vuex';

import { SETTINGS_NAVIGATION_CONFIG } from '@/enums/navigationEnums';
import VerticalNavigation from '@/components/VerticalNavigation';

const store = useStore();

/*------------------------------------------------------------------------
                                  Log out
------------------------------------------------------------------------*/

const logout = () => store.dispatch('auth/logout').then(() => (window.location.href = '/'));
</script>

<template>
    <TopBarLayout>
        <template #title>
            <h1>Menu</h1>
        </template>

        <FullScreenLoader v-if="store.state.auth.isLogOutPending" />

        <VerticalNavigation
            data-test-id="settings_vertical_navigation"
            :navigation-config="SETTINGS_NAVIGATION_CONFIG"
        />

        <transition
            appear
            name="down"
            type="transition"
        >
            <div
                class="pl-settings__action"
                style="transition-delay: 100ms"
            >
                <button
                    type="button"
                    class="btn btn-primary"
                    data-testid="settings_logout_btn"
                    @click="logout"
                >
                    Logout
                </button>
            </div>
        </transition>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-settings__action {
    display: flex;
    align-items: center;
    justify-content: center;
    align-self: center;
    margin-top: auto;
    padding-top: custom-space(1);
    width: 50%;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }

    & > button {
        width: 100%;
    }
}
</style>
