<script>
export default {
    name: 'App',
};
</script>

<script setup>
import { ref, onErrorCaptured, onMounted } from 'vue';
import { useStore } from 'vuex';

import OccurredErrorModal from './partials/OccurredErrorModal';
import FlashNotifications from './partials/FlashNotifications';
import GlobalModal from './partials/GlobalModal';

const store = useStore();

/*------------------------------------------------------------------------
                                 App state
------------------------------------------------------------------------*/

const isAppStateLoading = ref(true);

onMounted(() => {
    store.dispatch('loadAppState').finally(() => (isAppStateLoading.value = false));
});

/*------------------------------------------------------------------------
                           Occurred error state
------------------------------------------------------------------------*/

const occurredErrorMessage = ref(null);

onErrorCaptured((error) => {
    occurredErrorMessage.value = error?.message || 'Something went wrong...';

    return false;
});
</script>

<template>
    <FullScreenLoader v-if="isAppStateLoading" />

    <div
        v-else
        class="pl-app"
    >
        <OccurredErrorModal
            v-if="occurredErrorMessage !== null"
            :error-message="occurredErrorMessage"
        />

        <template v-else>
            <router-view />

            <FlashNotifications />

            <GlobalModal />
        </template>
    </div>
</template>

<style lang="scss">
@import '~@/assets/sass/main.scss';
</style>
