<script>
export default {
    name: 'CreateQFOrderIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { isOrderEditing } from '@/utils/orderUtils';
import { getBackRoute } from '@/utils/navigationUtils';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { QFO_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

const notifyAboutPendingOrder = () => {
    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending order. Please finalize or cancel it, before creating a new one.',
    });
};

onBeforeMount(() => {
    if (!$acl.can(QFO_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create orders.',
        });

        return router.replace({ name: QFO_ROUTE_NAMES.INDEX });
    }

    const order = currentQFOrder.value;

    if (order === null) {
        return;
    }

    if (isOrderEditing(order)) {
        notifyAboutPendingOrder();

        router.replace({
            name: QFO_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        });
    } else if (route.query.action === 'new-order') {
        notifyAboutPendingOrder();
    }
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="getBackRoute(route, QFO_ROUTE_NAMES.CREATE, QFO_ROUTE_NAMES.INDEX)"
    >
        <template #title>
            <h1>Create Order</h1>
        </template>

        <template #actions>
            <DropDown
                v-if="route.name !== QFO_ROUTE_NAMES.CREATE.INDEX"
                data-test-id="qf_order_group_by"
            >
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem is-selected>
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <router-view />
    </TopBarLayout>
</template>
