<script>
export default {
    name: 'LocationCard',
};
</script>

<script setup>
import { useSlots } from 'vue';

import Badge from '@/components/Badge';

defineProps({
    location: {
        type: Object,
        required: true,
    },
});

const slots = useSlots();

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'location';
</script>

<template>
    <div
        class="pl-location-card"
        :data-testid="qaPrefix"
    >
        <div class="pl-location-card__content">
            <h6 :data-testid="`${qaPrefix}_name`">
                {{ location.name }}
            </h6>

            <Badge size="sm">
                <span :data-testid="`${qaPrefix}_employees_count`">{{ location.accounts_count }}</span>

                employees
            </Badge>
        </div>

        <div
            v-if="slots.actions"
            class="pl-location-card__actions"
        >
            <slot
                name="actions"
                :qaPrefix="qaPrefix"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-location-card {
    display: flex;
    align-items: center;

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        & > h6 {
            font-weight: $font-weight-normal;
            word-break: break-word;
            margin: 0;
        }

        .pl-badge {
            gap: custom-space(0.2);
        }
    }

    &__actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: custom-space(0.75);
    }
}
</style>
