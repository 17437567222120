<template>
    <div>
        <div
            class="pl-qty-input"
            :class="dynamicRootClasses"
        >
            <template v-if="updateInputAllowed && updateInputShown">
                <form
                    novalidate
                    class="pl-qty-input-update-form"
                    @submit.prevent="update"
                >
                    <NumericInput
                        ref="$updateInput"
                        v-model="updateValue"
                        only-positive
                        :always-use-dot="precision > 1"
                        :max-precision="precision > 1 ? precision : null"
                        :max="max"
                        :min="min"
                        :disabled="disabled"
                        :loading="loading"
                        :data-test-id="qtyDataTestId"
                        class="pl-qty-input-update-form-control"
                        @blur="updateInputOnBlurHandler"
                    />
                </form>

                <Modal
                    v-if="askForSavingChangesOnBlur && onBlurConfirmationModalShown"
                    disable-click-outside-mechanism
                    is-content-centered
                    @close="onChangesDiscarded"
                >
                    <template #title>
                        Action required
                    </template>

                    <template #content>
                        Save changes?
                    </template>

                    <template #actions>
                        <button
                            type="button"
                            class="btn btn-secondary"
                            @click="onChangesDiscarded"
                        >
                            No
                        </button>

                        <button
                            type="button"
                            class="btn btn-primary"
                            @click="onChangesSaved"
                        >
                            Yes
                        </button>
                    </template>
                </Modal>
            </template>

            <template v-else>
                <button
                    type="button"
                    :disabled="minLimitReached || disabled || decrementBtnDisabled"
                    class="btn p-0 border-0 btn-unstyled pl-qty-input-btn"
                    data-testid="qty_input_decrease_btn"
                    @click="decrease"
                    @mousedown="startIncrement('decrease')"
                    @touchstart.passive="startIncrement('decrease', $event)"
                    @touchend="stopIncrement"
                >
                    <MinusIcon
                        :width="iconSize"
                        :height="iconSize"
                    />
                </button>

                <div
                    v-if="isInputAlwaysAllowed"
                    class="pl-qty-input-update-form always-allowed"
                >
                    <NumericInput
                        ref="$updateInput"
                        v-model="updateValue"
                        only-positive
                        :max="max"
                        :min="min"
                        :always-use-dot="precision > 1"
                        :max-precision="precision > 1 ? precision : null"
                        :disabled="disabled"
                        :loading="loading"
                        :data-test-id="qtyDataTestId"
                        class="pl-qty-input-update-form-control"
                        @update:modelValue="update"
                        @blur="$emit('blur')"
                    />
                </div>

                <span
                    v-else
                    class="pl-qty-input-value"
                    @click="showUpdateInput"
                >
                    <template v-if="!loading">{{ cutFloatNumber(modelValue, precision) }}</template>
                </span>

                <button
                    type="button"
                    :disabled="maxLimitReached || disabled || incrementBtnDisabled"
                    class="btn p-0 border-0 btn-unstyled pl-qty-input-btn"
                    data-testid="qty_input_increase_btn"
                    @click="increase"
                    @mousedown="startIncrement('increase')"
                    @touchstart.passive="startIncrement('increase', $event)"
                    @touchend="stopIncrement"
                >
                    <PlusIcon
                        :width="iconSize"
                        :height="iconSize"
                    />
                </button>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'QtyInput',
};
</script>

<script setup>
import { ref, watch, computed, defineAsyncComponent, reactive, onMounted, onUnmounted } from 'vue';

import { sizePropValidator } from '@/utils/propsUtils';
import { cutFloatNumber } from '@/utils/numberUtils';
import { onceRefInitiated } from '@/utils/componentsUtils';
import { QTY_INPUT_EVENTS } from '@/enums/componentsEnums';
import MinusIcon from '@/components/icons/MinusIcon';

const props = defineProps({
    modelValue: {
        type: [Number, String],
        required: false,
        default: 0,
    },
    step: {
        type: [Number, String],
        default: 1,
    },
    min: {
        type: [Number, String],
        default: null,
    },
    max: {
        type: [Number, String],
        default: null,
    },
    precision: {
        type: Number,
        default: 1,
    },
    stretched: {
        type: Boolean,
        default: false,
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    incrementBtnDisabled: {
        type: Boolean,
        default: false,
    },
    decrementBtnDisabled: {
        type: Boolean,
        default: false,
    },
    size: {
        type: String,
        default: 'md',
        validator: sizePropValidator,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    updateInputAllowed: {
        type: Boolean,
        default: true,
    },
    isInputAlwaysAllowed: {
        type: Boolean,
        default: false,
    },
    isIncrementMode: {
        type: Boolean,
        default: false,
    },
    customOnSuccessHandling: {
        type: Boolean,
        default: false,
    },
    askForSavingChangesOnBlur: {
        type: Boolean,
        default: false,
    },
    qtyDataTestId: {
        type: String,
        default: '',
    },
});

const emit = defineEmits(['update:modelValue', 'update-input-shown', 'update-input-hidden', 'blur']);

const getIncreasedValue = () => +props.modelValue + +props.step;

const getDecreasedValue = () => +props.modelValue - +props.step;

const increase = () => {
    if (maxLimitReached.value) {
        clearInterval(incrementModeState.interval);

        return;
    }

    // Do not skip any arguments, pass null instead.
    emit(
        'update:modelValue',
        getIncreasedValue(),
        QTY_INPUT_EVENTS.INCREMENT,
        undoIncrease(),
        false, // is triggered by undo callback
        null // on success callback
    );

    setTimeout(() => {
        emit('blur');
    });
};

const decrease = () => {
    if (minLimitReached.value) {
        clearInterval(incrementModeState.interval);

        return;
    }

    // Do not skip any arguments, pass null instead.
    emit(
        'update:modelValue',
        getDecreasedValue(),
        QTY_INPUT_EVENTS.DECREMENT,
        undoDecrease(),
        false, // is triggered by undo callback
        null // on success callback
    );

    setTimeout(() => {
        emit('blur');
    });
};

const incrementModeState = reactive({
    interval: {},
    type: null,
});

const startIncrement = (type, event = null) => {
    if (props.isIncrementMode && (event === null || event.touches?.length === 1)) {
        clearInterval(incrementModeState.interval);

        const incrementFn = type === 'increase' ? increase : decrease;

        incrementModeState.type = type;
        incrementModeState.interval = setInterval(incrementFn, 150); // 7 increments per second
    }
};

const stopIncrement = () => {
    if (incrementModeState.type !== null) {
        clearInterval(incrementModeState.interval);
    }
};

const undoIncrease = () => {
    const value = props.modelValue;

    return () => {
        // Do not skip any arguments, pass null instead.
        emit(
            'update:modelValue',
            value,
            QTY_INPUT_EVENTS.DECREMENT,
            null, // undo callback
            true, // is triggered by undo callback
            null // on success callback
        );
    };
};

const undoDecrease = () => {
    const value = props.modelValue;

    return () => {
        // Do not skip any arguments, pass null instead.
        emit(
            'update:modelValue',
            value,
            QTY_INPUT_EVENTS.INCREMENT,
            null, // undo callback
            true, // is triggered by undo callback
            null // on success callback
        );
    };
};

const maxLimitReached = computed(
    () => props.max !== null && cutFloatNumber(+props.modelValue, props.precision) >= +props.max
);
const minLimitReached = computed(
    () => props.min !== null && cutFloatNumber(+props.modelValue, props.precision) <= props.min
);

const dynamicRootClasses = computed(() => ({
    'pl-qty-input--stretched': props.stretched,
    'pl-qty-input--loading': props.loading,
    'pl-qty-input--invalid': props.hasError,
    'pl-qty-input--disabled': props.disabled,
    'pl-qty-input--clickable': !props.disabled && props.updateInputAllowed,
    [`pl-qty-input--${props.size}`]: true,
}));

const iconSize = computed(() => {
    switch (props.size) {
        case 'sm':
            return 25;
        case 'lg':
            return 50;
    }
    return 35;
});

/*---------------------------------------------------------------------
                            Update input
--------------------------------------------------------------------*/

const NumericInput = defineAsyncComponent(() => import('@/components/form-controls/NumericInput.vue'));

const updateInputShown = ref(false);
const $updateInput = ref(null);
const updateValue = ref(props.modelValue);
const isBeingUpdated = ref(false);

watch(
    () => [props.modelValue, updateInputShown.value],
    ([newValue]) => (updateValue.value = newValue),
    { immediate: true }
);

const showUpdateInput = () => {
    if (!props.updateInputAllowed || props.disabled) {
        return;
    }
    updateInputShown.value = true;

    emit('update-input-shown');

    onceRefInitiated($updateInput, () => {
        $updateInput.value.$refs.$input.focus();
    });
};

const hideUpdateInput = () => {
    updateInputShown.value = false;
    isBeingUpdated.value = false;
    emit('update-input-hidden');
};

const undoUpdate = () => {
    const value = props.modelValue;

    return () => {
        // Do not skip any arguments, pass null instead.
        emit(
            'update:modelValue',
            value,
            QTY_INPUT_EVENTS.UPDATE,
            null, // undo callback
            true, // is triggered by undo callback
            null // on success callback
        );
        updateValue.value = value;
        isBeingUpdated.value = false;
    };
};

const update = () => {
    if (updateValue.value == props.modelValue || updateValue.value === '') {
        hideUpdateInput();
        return;
    }

    isBeingUpdated.value = true;

    emit(
        'update:modelValue',
        updateValue.value,
        QTY_INPUT_EVENTS.UPDATE,
        undoUpdate(),
        false, // is triggered by undo callback
        props.customOnSuccessHandling ? hideUpdateInput : null
    );

    if (!props.customOnSuccessHandling) {
        hideUpdateInput();
    }
};

const updateInputOnBlurHandler = () => {
    if (isBeingUpdated.value) {
        return;
    }

    if (updateValue.value == props.modelValue || !updateValue.value) {
        hideUpdateInput();
        return;
    }

    if (props.askForSavingChangesOnBlur) {
        onBlurConfirmationModalShown.value = true;
        return;
    }

    const undoCallback = undoUpdate();

    undoCallback();
    hideUpdateInput();
};

/*---------------------------------------------------------------------
                        On blur confirmation modal
--------------------------------------------------------------------*/

const Modal = defineAsyncComponent(() => import('@/components/Modal.vue'));

const onBlurConfirmationModalShown = ref(false);

const hideOnBlurConfirmationModal = () => {
    onBlurConfirmationModalShown.value = false;
};

const onChangesSaved = () => {
    hideOnBlurConfirmationModal();
    update();
};
const onChangesDiscarded = () => {
    const undoCallback = undoUpdate();

    undoCallback();
    hideOnBlurConfirmationModal();
    hideUpdateInput();
};

watch(
    () => props.loading,
    (isLoading) => {
        if (!isLoading && !props.hasError) {
            hideUpdateInput();
        }
    }
);

onMounted(() => {
    window.addEventListener('mouseup', stopIncrement);
});

onUnmounted(() => {
    window.removeEventListener('mouseup', stopIncrement);
});
</script>

<style lang="scss">
.pl-qty-input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;

    &-value {
        font-size: $font-size-base * 1.125;
        line-height: 1;
        font-weight: $font-weight-normal;
        text-align: center;
        padding: space(2) space(3);
        word-break: initial;
        overflow-wrap: break-word;
        max-width: 160px;
    }

    &-update-form {
        max-width: custom-space(7);
        margin-left: auto;
        margin-right: auto;

        &.always-allowed {
            max-width: custom-space(8);
            margin: 0 5px;
        }

        &-control > .form-control {
            text-align: center;
            padding-top: custom-space(0.6);
            padding-bottom: custom-space(0.6);
        }
    }

    &--lg .pl-qty-input-value {
        font-size: $font-size-base * 3.3;
    }

    &--loading {
        .pl-qty-input-value {
            width: custom-space(1.1);
            height: custom-space(1.1);
            background-image: url('~@/assets/images/loader.gif');
            background-size: custom-space(1.1) custom-space(1.1);
            background-position: center center;
            background-repeat: no-repeat;
        }
    }

    &--disabled {
        .pl-qty-input-value {
            opacity: 0.65;
        }
    }

    &--invalid {
        color: $danger;
    }

    &--clickable .pl-qty-input-value {
        cursor: pointer;
    }

    &-btn {
        &:disabled,
        &[disabled] {
            opacity: 0.65 !important;
        }

        &:active {
            > svg {
                transform: translate3d(0, 1px, 0);
            }

            opacity: 0.8;
        }
    }
}
</style>
