import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import ItemsIndexView from '@/views/dashboard/settings/items/index';
import ItemCreateView from '@/views/dashboard/settings/items/create';
import ItemEditView from '@/views/dashboard/settings/items/edit';

export default [
    {
        path: 'settings/items',
        name: SETTINGS_ROUTE_NAMES.ITEMS.INDEX,
        component: ItemsIndexView,
    },
    {
        path: 'settings/items/create',
        name: SETTINGS_ROUTE_NAMES.ITEMS.CREATE,
        component: ItemCreateView,
    },
    {
        path: 'settings/items/:itemId/edit',
        name: SETTINGS_ROUTE_NAMES.ITEMS.EDIT,
        component: ItemEditView,
        props: true,
    },
];
