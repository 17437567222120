/**
 * SVE - Stateful Validation Errors
 *
 * Use this module in case there is a necessity to keep
 * a validation errors bag, moving between several views.
 */
export default {
    namespaced: true,
    state: () => ({
        errorBags: {},
    }),
    mutations: {
        setErrorsBag(state, { name, bag }) {
            state.errorBags[name] = bag;
        },
        clearErrorsBag(state, bagName) {
            delete state.errorBags[bagName];
        },
        clearAllBags(state) {
            state.errorsBag = {};
        },
    },
    getters: {
        getErrorsBag: (state) => (bagName) => state.errorBags[bagName] ?? null,
    },
};
