<script>
export default {
    name: 'CreateOrderFromTemplateView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { isOrderEditing } from '@/utils/orderUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import PLOrderModel from '@/models/PLOrder';
import Badge from '@/components/Badge';
import CreateOrderFromTemplateForm from '@/components/forms/CreateOrderFromTemplateForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PL_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create orders.',
        });

        return router.replace({ name: PL_ROUTE_NAMES.INDEX });
    }

    const order = currentPLOrder.value;

    if (order === null) {
        return;
    }

    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending order. Please finalize or cancel it, before creating a new one.',
    });

    if (isOrderEditing(order)) {
        router.replace({
            name: PL_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        });
    } else {
        router.replace({ name: PL_ROUTE_NAMES.CREATE.INDEX });
    }
});

/*------------------------------------------------------------------------
                         Order settings form state
------------------------------------------------------------------------*/

const orderSettingsFormState = reactive({
    validationErrors: null,
    isSubmittingAllowed: false,
    isSubmitting: false,
});

const redirectToTimeline = () => {
    router.push({ name: PL_ROUTE_NAMES.INDEX });
};

const setIsSubmittingAllowed = (isSubmittingAllowed) =>
    (orderSettingsFormState.isSubmittingAllowed = isSubmittingAllowed);

const submitForm = (payload) => {
    orderSettingsFormState.isSubmitting = true;

    PLOrderModel.createFromTemplate(orgId, payload)
        .then((model) => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'New pending order has been successfully created',
            });

            store.commit('setCurrentPLOrder', model);

            router.push({ name: PL_ROUTE_NAMES.CREATE.ADD_ITEMS });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                orderSettingsFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (orderSettingsFormState.isSubmitting = false));
};

const isSubmittingDisabled = computed(() => {
    const { isSubmittingAllowed, isSubmitting } = orderSettingsFormState;

    return !isSubmittingAllowed || isSubmitting;
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="PL_ROUTE_NAMES.INDEX"
    >
        <template #title>
            <h1>
                <span>
                    Create Order

                    <Badge
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        PAR
                    </Badge>
                </span>
            </h1>
        </template>

        <OrderStepTemplate
            :is-step-submitting="orderSettingsFormState.isSubmitting"
            @before-init-cancel-process="redirectToTimeline"
        >
            <template #step-content>
                <CreateOrderFromTemplateForm
                    id="pl-create-order-from-template-form"
                    :validation-errors="orderSettingsFormState.validationErrors"
                    @set-is-submitting-allowed="setIsSubmittingAllowed"
                    @submit="submitForm"
                />
            </template>

            <template #next-step-action>
                <button
                    type="submit"
                    form="pl-create-order-from-template-form"
                    class="btn btn-primary"
                    :disabled="isSubmittingDisabled"
                >
                    Next
                </button>
            </template>
        </OrderStepTemplate>
    </TopBarLayout>
</template>
