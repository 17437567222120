import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class Location extends Model {
    static all(orgId, query) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/locations`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, locationId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/locations/${locationId}`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/locations`, attributes);

        return this.make(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/locations/${this.id}`,
            attributes
        );

        return this.fill(data);
    }

    async delete() {
        const { data } = await ApiClient.delete(`organizations/${this.organization_id}/locations/${this.id}`);

        return Location.make(data);
    }
}
