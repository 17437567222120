<script>
export default {
    name: 'BackArrowIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 30,
    },
    height: {
        type: Number,
        default: 30,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 30 30"
        :width="width"
        :height="height"
    >
        <path
            d="M11.9625 7.4125L4.375 15L11.9625 22.5875"
            stroke="#969AAB"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M25.625 15H4.58752"
            stroke="#969AAB"
            stroke-width="1.5"
            stroke-miterlimit="10"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>