import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import NoPrepActionsIndexView from '@/views/dashboard/settings/no-prep-actions';

export default [
    {
        path: 'settings/no-prep-actions',
        name: SETTINGS_ROUTE_NAMES.NO_PREP_ACTIONS,
        component: NoPrepActionsIndexView,
    },
];
