<script>
export default {
    name: 'PLOrdersIndexSecondaryView',
};
</script>

<script setup>
import OrdersIndexViewTemplate from '@/components/model-specific/orders/OrdersIndexViewTemplate';
import TimelineSecondaryCook from './partials/TimelineSecondaryCook';
import TimelineSecondaryManager from './partials/TimelineSecondaryManager';
</script>

<template>
    <OrdersIndexViewTemplate>
        <template #viewAsCook>
            <TimelineSecondaryCook />
        </template>

        <template #viewAsManager>
            <TimelineSecondaryManager />
        </template>
    </OrdersIndexViewTemplate>
</template>
