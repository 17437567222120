<script>
export default {
    name: 'Tooltip',
};
</script>

<script setup>
import { ref, reactive, nextTick } from 'vue';

import { TOOLTIP_OFFSET_PADDING } from '@/enums/componentsEnums';

defineProps({
    text: {
        type: String,
        required: true,
    },
    enableTransparency: {
        type: Boolean,
        default: false,
    },
    enableWideContent: {
        type: Boolean,
        default: false,
    },
});

/*------------------------------------------------------------------------
                               Tooltip state
------------------------------------------------------------------------*/

const contentRef = ref(null);
const invokerRef = ref(null);

const tooltipState = reactive({
    isVisible: false,
    positions: {},
});

const getTooltipLeftPosition = (contentRect, invokerRect) => {
    const { width } = contentRect;

    const left = invokerRect.left + invokerRect.width / 2 - width / 2;

    if (left <= TOOLTIP_OFFSET_PADDING) {
        return TOOLTIP_OFFSET_PADDING;
    }

    if (left + width > window.innerWidth - TOOLTIP_OFFSET_PADDING) {
        return window.innerWidth - width - TOOLTIP_OFFSET_PADDING * 1.5;
    }

    return left;
};

const getTooltipTopPosition = (contentRect, invokerRect) => {
    const top = invokerRect.top - contentRect.height - TOOLTIP_OFFSET_PADDING * 1.5;

    if (top < TOOLTIP_OFFSET_PADDING) {
        return invokerRect.top + invokerRect.height + TOOLTIP_OFFSET_PADDING * 1.5;
    }

    return top;
};

const setTooltipPositions = () => {
    const contentRect = contentRef.value.getBoundingClientRect();
    const invokerRect = invokerRef.value.getBoundingClientRect();

    tooltipState.positions = {
        top: `${getTooltipTopPosition(contentRect, invokerRect)}px`,
        left: `${getTooltipLeftPosition(contentRect, invokerRect)}px`,
    };
};

const showTooltip = () => {
    tooltipState.isVisible = true;

    nextTick(setTooltipPositions);
};

const hideTooltip = () => (tooltipState.isVisible = false);
</script>

<template>
    <span
        class="pl-tooltip"
        @mouseenter="showTooltip"
        @mouseleave="hideTooltip"
    >
        <teleport to="body">
            <transition
                appear
                name="scale"
                type="transition"
            >
                <span
                    v-if="tooltipState.isVisible"
                    ref="contentRef"
                    class="pl-tooltip__content"
                    :class="{
                        'pl-tooltip__content--wide': enableWideContent,
                    }"
                    :style="tooltipState.positions"
                >
                    {{ text }}
                </span>
            </transition>
        </teleport>

        <span
            ref="invokerRef"
            class="pl-tooltip__invoker"
            :class="{
                'pl-tooltip__invoker--semitransparent': enableTransparency,
            }"
        >
            <slot />
        </span>
    </span>
</template>

<style lang="scss" scoped>
.pl-tooltip {
    &__invoker--semitransparent {
        opacity: 0.5;
    }

    &__content {
        position: fixed;
        top: 0;
        left: 0;
        display: inline-block;
        width: custom-space(10);
        max-width: 100%;
        padding: custom-space(0.375);
        border-radius: $border-radius;
        background-color: $black;
        color: $white;
        text-align: center;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
        line-height: 1.5;
        z-index: 101;

        &--wide {
            width: custom-space(18);
            padding: custom-space(0.5);
            word-break: break-all;
        }
    }
}
</style>
