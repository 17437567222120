<template>
    <AuthLayout is-content-centered>
        <template #header>
            <div class="mb-2">
                <a
                    href="#"
                    class="fs-6 text-uppercase pl-link"
                    data-testid="logout_btn"
                    @click.prevent="logout"
                >
                    <BackArrowIcon />
                </a>
            </div>
        </template>

        <FullScreenLoader v-if="store.state.auth.isLogOutPending" />

        <OverlayLoader v-if="isFormSubmitting" />

        <div class="text-center">
            <transition
                appear
                name="down"
                type="transition"
            >
                <h1 class="mb-5">
                    How would you like to proceed?
                </h1>
            </transition>

            <!--------------------- Invited accounts list --------------------->
            <transition
                appear
                name="down"
                type="transition"
            >
                <section
                    v-if="store.getters.invitedAccounts.length > 0"
                    class="mb-5"
                    style="transition-delay: 50ms"
                >
                    <h6>Accept invitation to</h6>

                    <ListDisplay
                        has-borders
                        :items="store.getters.invitedAccounts"
                    >
                        <template #item="{ item: account }">
                            <div class="d-flex align-items-center">
                                <div class="text-start flex-grow-1 pe-3 fw-bold">
                                    {{ account.organization.name }}
                                </div>
                                <div class="flex-shrink-0">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        data-testid="accept_invite_btn"
                                        @click="acceptInvitation(account)"
                                    >
                                        Accept
                                    </button>
                                </div>
                            </div>
                        </template>
                    </ListDisplay>
                </section>
            </transition>
            <!--------------------- /Invited accounts list --------------------->

            <!--------------------- Joined accounts list --------------------->
            <transition
                appear
                name="down"
                type="transition"
            >
                <section
                    v-if="store.getters.joinedAccounts.length > 0"
                    class="mb-5"
                    style="transition-delay: 50ms"
                >
                    <h6>
                        <template v-if="store.getters.invitedAccounts.length > 0">
                            or continue to
                        </template>
                        <template v-else>
                            Choose the company
                        </template>
                    </h6>
                    <ListDisplay
                        has-borders
                        :items="store.getters.joinedAccounts"
                    >
                        <template #item="{ item: account }">
                            <div class="d-flex align-items-center">
                                <div class="text-start flex-grow-1 pe-3 fw-bold">
                                    {{ account.organization.name }}
                                </div>
                                <div class="flex-shrink-0">
                                    <button
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        data-testid="enter_invite_btn"
                                        @click="enterCompany(account)"
                                    >
                                        Enter
                                    </button>
                                </div>
                            </div>
                        </template>
                    </ListDisplay>
                </section>
            </transition>
            <!--------------------- /Joined accounts list --------------------->

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    class="d-grid"
                    style="transition-delay: 50ms"
                >
                    <h6>Or</h6>

                    <router-link
                        :to="{ name: 'registration.step-1' }"
                        class="btn btn-primary"
                        data-testid="auth_option_owner_btn"
                    >
                        Create a new company
                    </router-link>
                </div>
            </transition>
        </div>
    </AuthLayout>
</template>

<script>
export default {
    name: 'AuthOptions',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { ref, onBeforeMount } from 'vue';

import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import ListDisplay from '@/components/ListDisplay';

const store = useStore();
const router = useRouter();
const { currentAccount } = store.state.auth;

const accounts = store.state.accounts;

const isFormSubmitting = ref(false);

onBeforeMount(() => {
    if (currentAccount !== null) {
        router.replace({ name: PL_ROUTE_NAMES.INDEX });
    } else if (accounts.length === 0) {
        router.replace({ name: 'registration.step-1' });
    }
});

/*---------------------------------------------------------------------
                            Actions
--------------------------------------------------------------------*/

const enterCompany = (account) => {
    store.commit('auth/setCurrentAccount', account);
    router.push({ name: PL_ROUTE_NAMES.INDEX });
};

const acceptInvitation = (account) => {
    isFormSubmitting.value = true;

    account
        .join()
        .then((model) => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: "Congratulations! You've successfully accepted the invitation.",
            });
            store.commit('auth/setCurrentAccount', model);
            router.push({ name: PL_ROUTE_NAMES.INDEX });
        })
        .finally(() => (isFormSubmitting.value = false));
};

const logout = () => store.dispatch('auth/logout').then(() => (window.location.href = '/'));
</script>
