import { onBeforeMount, reactive } from 'vue';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ITEM_CONFIGURATION_ENTITIES_NAME } from '@/enums/itemEnums';
import BatchUnitModel from '@/models/BatchUnit';
import CategoryModel from '@/models/Category';
import DepartmentModel from '@/models/Department';
import StationModel from '@/models/Station';
import useAbortableRequest from '@/composition/useAbortableRequest';

const useItemConfiguration = (entitiesQueryParams = {}, isItemConfigurationRoute = false) => {
    const store = useStore();

    const { sendAbortableRequest } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                             Item configuration state
    ------------------------------------------------------------------------*/

    const itemConfigurationState = reactive({
        departments: [],
        batchUnits: [],
        stations: [],
        isDataLoading: false,
    });

    const loadItemConfigurationState = () => {
        itemConfigurationState.isDataLoading = true;

        Promise.all([
            sendAbortableRequest(DepartmentModel.all(orgId, { 'on_demand[]': 'categories' })),
            sendAbortableRequest(BatchUnitModel.all(orgId, { 'on_demand[]': 'can_be_deleted' })),
            sendAbortableRequest(StationModel.all(orgId)),
        ])
            .then(([departmentModels, batchUnitModels, stationModels]) => {
                itemConfigurationState.departments = departmentModels;
                itemConfigurationState.batchUnits = batchUnitModels;
                itemConfigurationState.stations = stationModels;
            })
            .finally(() => (itemConfigurationState.isDataLoading = false));
    };

    /*------------------------------------------------------------------------
                         Active entity configuration state
    ------------------------------------------------------------------------*/

    const activeEntityConfigurationState = reactive({
        dataKey: null,
        name: null,
    });

    const setActiveEntityConfiguration = (entityName) => {
        const entitiesDataKeysByName = {
            [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: 'departments',
            [ITEM_CONFIGURATION_ENTITIES_NAME.UNIT]: 'batchUnits',
            [ITEM_CONFIGURATION_ENTITIES_NAME.STATION]: 'stations',
        };

        activeEntityConfigurationState.dataKey = entitiesDataKeysByName[entityName];
        activeEntityConfigurationState.name = entityName;
    };

    const resetActiveEntityConfiguration = () => {
        activeEntityConfigurationState.dataKey = null;
        activeEntityConfigurationState.name = null;
    };

    const updateItemEntityState = (models) => {
        const { dataKey, name } = activeEntityConfigurationState;

        itemConfigurationState[dataKey] = models;

        if (name === ITEM_CONFIGURATION_ENTITIES_NAME.UNIT && store.getters['itemBatchMeta/isMetaLoaded']) {
            store.commit('itemBatchMeta/setMeta', {
                ...store.state.itemBatchMeta.meta,
                batch_size_units: models,
                batch_yield_units: models,
            });
        }

        resetActiveEntityConfiguration();
    };

    /*------------------------------------------------------------------------
                              Item entity form state
    ------------------------------------------------------------------------*/

    const itemEntityFormState = reactive({
        entityData: null,
        validationErrors: null,
        isSubmitting: false,
        isModalOpened: false,
    });

    const openItemEntityForm = (entityName, entityData = null) => {
        setActiveEntityConfiguration(entityName);

        itemEntityFormState.entityData = entityData;
        itemEntityFormState.isModalOpened = true;
    };

    const closeItemEntityForm = () => {
        itemEntityFormState.entityData = null;
        itemEntityFormState.validationErrors = null;
        itemEntityFormState.isSubmitting = false;
        itemEntityFormState.isModalOpened = false;
    };

    const submitItemEntityForm = (attributes, resolveCallback) => {
        const entityModelsByName = {
            [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: CategoryModel,
            [ITEM_CONFIGURATION_ENTITIES_NAME.UNIT]: BatchUnitModel,
            [ITEM_CONFIGURATION_ENTITIES_NAME.STATION]: StationModel,
        };

        const { name } = activeEntityConfigurationState;
        const { entityData } = itemEntityFormState;

        const isEditMode = entityData !== null && isItemConfigurationRoute;
        const currentEntityModel = entityModelsByName[name];

        itemEntityFormState.isSubmitting = true;

        const queryParams = isItemConfigurationRoute
            ? { 'on_demand[]': 'can_be_deleted' }
            : entitiesQueryParams[name] || {};

        const request = isEditMode
            ? currentEntityModel.update(orgId, entityData.id, attributes, queryParams)
            : currentEntityModel.create(orgId, attributes, queryParams);

        request
            .then((models) => {
                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: `The ${name} has been successfully ${isEditMode ? 'updated' : 'created'}`,
                });

                resolveCallback?.(models);

                updateItemEntityState(models);
                closeItemEntityForm();
            })
            .catch((error) => {
                const { status, data } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                    itemEntityFormState.validationErrors = { name: data.errors.name || data.errors.value };
                }
            })
            .finally(() => (itemEntityFormState.isSubmitting = false));
    };

    /*------------------------------------------------------------------------
                                Load necessary data
    ------------------------------------------------------------------------*/

    onBeforeMount(() => {
        if (isItemConfigurationRoute) {
            loadItemConfigurationState();
        }
    });

    return {
        itemConfigurationState,

        activeEntityConfigurationState,
        setActiveEntityConfiguration,
        updateItemEntityState,

        itemEntityFormState,
        openItemEntityForm,
        closeItemEntityForm,
        submitItemEntityForm,
    };
};

export default useItemConfiguration;
