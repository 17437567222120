<script>
export default {
    name: 'AddEmployeesIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 224,
    },
    height: {
        type: Number,
        default: 224,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 224 224"
        :width="width"
        :height="height"
    >
        <g filter="url(#filter0_d_960_2084)">
            <circle
                cx="112"
                cy="112"
                r="77"
                fill="white"
            />
        </g>
        <path
            d="M88.2764 111.746C84.8888 111.746 81.8152 110.093 79.5475 107.421C79.5458 109.022 79.4946 111.334 79.1213 113.02C79.3663 113.19 79.5563 113.47 79.5934 113.818C80.0773 118.297 83.81 121.674 88.2764 121.674C92.7422 121.674 96.4746 118.296 96.9595 113.818C96.9971 113.469 97.1831 113.19 97.4317 113.021C97.0583 111.334 97.007 109.022 97.0054 107.42C94.7376 110.093 91.6639 111.746 88.2764 111.746Z"
            fill="#FFB980"
        />
        <path
            d="M96.8159 107.624C94.5673 110.172 91.5736 111.746 88.2764 111.746C84.8888 111.746 81.8152 110.093 79.5475 107.421C79.5458 109.022 79.4946 111.334 79.1213 113.02C79.3663 113.191 79.5563 113.47 79.5934 113.818C79.8072 115.798 80.6575 117.561 81.9237 118.914C87.7631 117.978 94.3908 111.42 96.8159 107.624Z"
            fill="#F8AB6B"
        />
        <path
            d="M105.978 114.212C105.939 114.199 105.9 114.189 105.86 114.183L98.1187 112.862C98.0713 112.854 97.9699 112.849 97.9217 112.849C97.432 112.849 97.0191 113.265 96.9594 113.818C96.4746 118.297 92.7419 121.674 88.2764 121.674C83.8097 121.674 80.0773 118.297 79.5933 113.818C79.5327 113.249 79.0818 112.82 78.6065 112.85C78.5496 112.846 78.4938 112.852 78.4341 112.862L70.6923 114.183C70.6525 114.19 70.6137 114.199 70.5748 114.212C66.9151 115.377 65 119.473 65 122.916V127.19C65 129.009 66.4245 130.489 68.1756 130.489H108.377C110.128 130.489 111.553 129.009 111.553 127.19V122.916C111.553 119.473 109.638 115.377 105.978 114.212Z"
            fill="#CBBBA0"
        />
        <path
            d="M97.0049 84.1689H80.5175C77.8438 84.1689 75.6682 86.6434 75.6682 89.6844V96.3029C75.6682 104.819 81.3244 111.746 88.2761 111.746C95.2279 111.746 100.884 104.819 100.884 96.3029V88.5812C100.884 86.1485 99.1443 84.1689 97.0049 84.1689Z"
            fill="#FDC88E"
        />
        <path
            d="M95.0654 72.0349H82.4573C80.6331 72.0349 79.0998 73.4741 78.6868 75.4061C75.3482 75.8887 72.7588 79.1397 72.7588 83.0657V88.5812C72.7588 91.4241 73.8198 94.0761 75.6684 95.9609V89.6842C75.6684 86.6432 77.844 84.1687 80.5176 84.1687H97.0051C99.1447 84.1687 100.884 86.1481 100.884 88.581V95.9596C102.733 94.0752 103.794 91.4233 103.794 88.581V81.9625C103.794 76.4888 99.8785 72.0349 95.0654 72.0349Z"
            fill="#414353"
        />
        <path
            d="M135.724 111.746C132.336 111.746 129.262 110.093 126.995 107.421C126.993 109.022 126.942 111.334 126.568 113.02C126.813 113.19 127.003 113.47 127.041 113.818C127.524 118.297 131.257 121.674 135.724 121.674C140.189 121.674 143.922 118.296 144.407 113.818C144.444 113.469 144.63 113.19 144.879 113.021C144.505 111.334 144.454 109.022 144.453 107.42C142.185 110.093 139.111 111.746 135.724 111.746Z"
            fill="#FFB980"
        />
        <path
            d="M144.263 107.624C142.014 110.172 139.021 111.746 135.724 111.746C132.336 111.746 129.262 110.093 126.995 107.421C126.993 109.022 126.942 111.334 126.568 113.02C126.813 113.191 127.003 113.47 127.041 113.818C127.254 115.798 128.105 117.561 129.371 118.914C135.21 117.978 141.838 111.42 144.263 107.624Z"
            fill="#F8AB6B"
        />
        <path
            d="M153.425 114.212C153.386 114.199 153.347 114.189 153.308 114.183L145.566 112.862C145.518 112.854 145.417 112.849 145.369 112.849C144.879 112.849 144.466 113.265 144.407 113.818C143.922 118.297 140.189 121.674 135.724 121.674C131.257 121.674 127.524 118.297 127.041 113.818C126.98 113.249 126.529 112.82 126.054 112.85C125.997 112.846 125.941 112.852 125.881 112.862L118.14 114.183C118.1 114.19 118.061 114.199 118.022 114.212C114.362 115.377 112.447 119.473 112.447 122.916V127.19C112.447 129.009 113.872 130.489 115.623 130.489H155.824C157.576 130.489 159 129.009 159 127.19V122.916C159 119.473 157.085 115.377 153.425 114.212Z"
            fill="#EDEDED"
        />
        <path
            d="M144.452 84.1689H127.965C125.291 84.1689 123.116 86.6434 123.116 89.6844V96.3029C123.116 104.819 128.772 111.746 135.724 111.746C142.675 111.746 148.331 104.819 148.331 96.3029V88.5812C148.332 86.1485 146.592 84.1689 144.452 84.1689Z"
            fill="#FDC88E"
        />
        <path
            d="M142.513 72.0349H129.905C128.081 72.0349 126.547 73.4741 126.134 75.4061C122.796 75.8887 120.206 79.1397 120.206 83.0657V88.5812C120.206 91.4241 121.267 94.0761 123.116 95.9609V89.6842C123.116 86.6432 125.291 84.1687 127.965 84.1687H144.452C146.592 84.1687 148.332 86.1481 148.332 88.581V95.9596C150.181 94.0752 151.241 91.4233 151.241 88.581V81.9625C151.241 76.4888 147.326 72.0349 142.513 72.0349Z"
            fill="#414353"
        />
        <path
            d="M112 117.14C106.87 117.14 102.215 114.636 98.7808 110.589C98.7782 113.014 98.7007 116.515 98.1353 119.069C98.5061 119.327 98.794 119.75 98.8502 120.277C99.5831 127.06 105.236 132.175 112 132.175C118.763 132.175 124.416 127.059 125.15 120.277C125.207 119.749 125.489 119.327 125.865 119.07C125.3 116.515 125.222 113.014 125.219 110.589C121.785 114.636 117.13 117.14 112 117.14Z"
            fill="#FDC88E"
        />
        <path
            d="M124.932 110.897C121.527 114.755 116.993 117.14 112 117.14C106.87 117.14 102.215 114.636 98.7808 110.589C98.7782 113.014 98.7007 116.515 98.1353 119.069C98.5061 119.327 98.794 119.75 98.8502 120.277C99.1742 123.275 100.462 125.945 102.379 127.993C111.223 126.577 121.26 116.645 124.932 110.897Z"
            fill="#FFB980"
        />
        <path
            d="M138.807 120.873C138.749 120.854 138.69 120.84 138.63 120.83L126.905 118.829C126.834 118.817 126.68 118.81 126.607 118.81C125.866 118.81 125.24 119.439 125.15 120.277C124.415 127.059 118.763 132.175 112 132.175C105.235 132.175 99.5828 127.06 98.8499 120.277C98.7581 119.414 98.0753 118.766 97.3553 118.811C97.2692 118.805 97.1845 118.813 97.0942 118.829L85.3701 120.83C85.3099 120.839 85.2511 120.854 85.1922 120.873C79.6502 122.638 76.75 128.841 76.75 134.056V140.527C76.75 143.283 78.9072 145.524 81.5592 145.524H142.441C145.093 145.524 147.25 143.283 147.25 140.527V134.056C147.25 128.841 144.35 122.638 138.807 120.873Z"
            fill="#CF382F"
        />
        <path
            d="M125.219 75.376H100.25C96.2008 75.376 92.9062 79.1232 92.9062 83.7287V93.752C92.9062 106.648 101.472 117.14 112 117.14C122.528 117.14 131.094 106.648 131.094 93.752V82.0582C131.094 78.3738 128.459 75.376 125.219 75.376Z"
            fill="#FDC88E"
        />
        <path
            d="M122.281 57H103.188C100.425 57 98.1029 59.1794 97.4774 62.1054C92.4214 62.8363 88.5 67.7598 88.5 73.7055V82.0582C88.5 86.3634 90.1068 90.3798 92.9062 93.2341V83.7287C92.9062 79.1232 96.2008 75.376 100.25 75.376H125.219C128.459 75.376 131.094 78.3736 131.094 82.0582V93.2322C133.894 90.3785 135.5 86.3623 135.5 82.0582V72.0349C135.5 63.745 129.57 57 122.281 57Z"
            fill="#1D1D1B"
        />
        <path
            d="M113.719 153.719H112.719V154.719V160C112.719 160.052 112.698 160.107 112.652 160.152C112.607 160.198 112.552 160.219 112.5 160.219C112.448 160.219 112.393 160.198 112.348 160.152C112.302 160.107 112.281 160.052 112.281 160V154.719V153.719H111.281H106C105.948 153.719 105.893 153.698 105.848 153.652C105.802 153.607 105.781 153.552 105.781 153.5C105.781 153.448 105.802 153.393 105.848 153.348C105.893 153.302 105.948 153.281 106 153.281H111.281H112.281V152.281V147C112.281 146.948 112.302 146.893 112.348 146.848C112.393 146.802 112.448 146.781 112.5 146.781C112.552 146.781 112.607 146.802 112.652 146.848C112.698 146.893 112.719 146.948 112.719 147V152.281V153.281H113.719H119C119.052 153.281 119.107 153.302 119.152 153.348C119.198 153.393 119.219 153.448 119.219 153.5C119.219 153.552 119.198 153.607 119.152 153.652C119.107 153.698 119.052 153.719 119 153.719H113.719ZM112.5 136.25C102.994 136.25 95.25 143.994 95.25 153.5C95.25 163.006 102.994 170.75 112.5 170.75C122.006 170.75 129.75 163.006 129.75 153.5C129.75 143.994 122.006 136.25 112.5 136.25Z"
            fill="url(#paint0_linear_960_2084)"
            stroke="white"
            stroke-width="2"
        />
        <defs>
            <filter
                id="filter0_d_960_2084"
                x="0"
                y="0"
                width="224"
                height="224"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="10"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_960_2084"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite
                    in2="hardAlpha"
                    operator="out"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.95 0 0 0 0 0.95 0 0 0 0 0.95 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_960_2084"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_960_2084"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_960_2084"
                x1="128.75"
                y1="153.5"
                x2="96.25"
                y2="153.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#FF4439" />
                <stop
                    offset="1"
                    stop-color="#CF382F"
                />
            </linearGradient>
        </defs>
    </svg>
</template>
