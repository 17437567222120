<script>
export default {
    name: 'OrderItemsWrapper',
};
</script>

<script setup>
import { computed } from 'vue';

import { isOrderCompleted } from '@/utils/orderUtils';
import Tabs from '@/components/Tabs';
import OrderDetails from '@/components/model-specific/orders/OrderDetails';
import OrderItemsSummary from './OrderItemsSummary';
import OrderItemControls from './OrderItemsControls';

const props = defineProps({
    order: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['handle-refresh-order']);

/*-----------------------------------------------------------------------------------
                                        Tabs
-----------------------------------------------------------------------------------*/

const departmentsOptions = computed(() =>
    Object.keys(props.order.items.data.data).map((department) => ({ value: department, text: department }))
);
</script>

<template>
    <OrderDetails
        include-content-container
        :order="order"
    />

    <Tabs
        enable-slider-mode
        include-content-container
        :tab-options="departmentsOptions"
    >
        <template #content="{ activeTab }">
            <OrderItemsSummary
                v-if="isOrderCompleted(order)"
                :order="order"
                :items="order.items.data.data[activeTab]"
            />

            <OrderItemControls
                v-else
                :order="order"
                :items="order.items.data.data[activeTab]"
                @handle-refresh-order="(args) => emit('handle-refresh-order', args)"
            />
        </template>
    </Tabs>
</template>

<style lang="scss" scoped>
.pl-tabs {
    margin-top: 0;
}

.pl-order-details {
    margin-top: custom-space(-0.5);
}
</style>
