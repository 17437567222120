<script>
export default {
    name: 'ClockIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 18,
    },
    height: {
        type: Number,
        default: 22,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 22"
        :width="width"
        :height="height"
    >
        <path
            d="M9.345 1.61v1.174c-.239-.02-.478-.04-.716-.04-.24 0-.478 0-.716.04V1.61h1.432ZM11.093 1.61h-4.93a.306.306 0 0 1-.298-.298A1.31 1.31 0 0 1 7.177 0h2.922a1.31 1.31 0 0 1 1.313 1.312c-.02.16-.16.298-.319.298Z"
            fill="#64393F"
        />
        <path
            d="M8.628 20a8.628 8.628 0 1 0 0-17.257 8.628 8.628 0 0 0 0 17.257Z"
            fill="#64393F"
        />
        <path
            d="M8.628 18.529a7.157 7.157 0 1 0 0-14.314 7.157 7.157 0 0 0 0 14.314Z"
            fill="#F9F3F3"
        />
        <path
            d="M8.628 5.13a6.253 6.253 0 0 0-6.242 6.242 6.253 6.253 0 0 0 6.242 6.242 6.253 6.253 0 0 0 6.243-6.242 6.253 6.253 0 0 0-6.243-6.243Zm.16 12.146v-.696c0-.099-.08-.159-.16-.159s-.159.08-.159.16v.695c-3.121-.08-5.666-2.624-5.745-5.745h.696c.099 0 .159-.08.159-.16 0-.099-.08-.158-.16-.158h-.695c.08-3.122 2.624-5.666 5.745-5.746v.696c0 .1.08.159.16.159.079 0 .158-.08.158-.159v-.696c3.122.08 5.666 2.624 5.746 5.746h-.696c-.1 0-.159.08-.159.159 0 .1.08.159.159.159h.696a5.895 5.895 0 0 1-5.746 5.745Z"
            fill="#64393F"
        />
        <path
            d="M9.324 8.29h-.378l-.159 2.247c-.06-.02-.12-.02-.179-.02-.06 0-.119 0-.179.02L8.27 8.29h-.377l.695-1.133.736 1.133ZM9.782 13.38l.219-.218-.935-1.075c.1-.06.199-.159.258-.278l1.074.935.22-.219.258 1.134-1.094-.279Z"
            fill="#F1543F"
        />
        <path
            d="M8.629 12.208a.835.835 0 1 0 0-1.671.835.835 0 0 0 0 1.67Z"
            fill="#FF7058"
        />
        <path
            d="m24.248 6.76-2.744.896.168.63 2.142-.7V16h.728V6.76h-.294Zm8.542 4.97c0-1.568-.938-2.632-2.464-2.632-.938 0-1.68.392-2.142 1.064V5.5h-.7V16h.7v-4.214c0-1.176.84-2.03 2.016-2.03 1.162 0 1.89.812 1.89 2.03V16h.7v-4.27Z"
            fill="#000"
            fill-opacity=".2"
        />
    </svg>
</template>
