<script>
export default {
    name: 'OrdersIndexViewTemplate',
};
</script>

<script setup>
import { computed, useSlots } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { isCook, isManager } from '@/utils/accountUtils';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';

const route = useRoute();
const store = useStore();
const slots = useSlots();

const { currentAccount } = store.state.auth;

const isViewAsCook = computed(() => {
    const isManagerAsCook = isManager(currentAccount) && route.query.viewmode === TIMELINE_VIEW_MODES.AS_COOK;

    return isCook(currentAccount) || isManagerAsCook;
});
</script>

<template>
    <slot
        v-if="isViewAsCook && slots.viewAsCook"
        name="viewAsCook"
    />

    <slot
        v-else
        name="viewAsManager"
    />
</template>

<style lang="scss" scoped>
:slotted(.pl-layout) .pl-timeline-dates-carousel {
    margin-top: custom-space(-1.5);
    padding-top: custom-space(0.6);
    padding-bottom: custom-space(0.5);
    background-color: $white;
    border-bottom: 1px solid $gray-100;

    .carousel__prev,
    .carousel__next {
        background-color: transparent;

        > .carousel__icon {
            fill: $black;
        }
    }

    .carousel__prev--in-active,
    .carousel__next--in-active {
        display: none;
    }
}
</style>
