<script>
export default {
    name: 'DepartmentsIndexView',
};
</script>

<script setup>
import { onBeforeMount, inject, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { DEPARTMENTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DepartmentModel from '@/models/Department';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Alert from '@/components/Alert';
import ListDisplay from '@/components/ListDisplay';
import DeletionModal from '@/components/DeletionModal';
import DepartmentCard from '@/components/model-specific/department/DepartmentCard';

const $acl = inject('$acl');

const router = useRouter()
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(DEPARTMENTS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to departments.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                             Departments state
------------------------------------------------------------------------*/

const departmentsState = reactive({
    data: null,
    isDataLoading: true,
    hasBeenJustUpdated: false,
});

const loadDepartments = () => {
    sendAbortableRequest(DepartmentModel.all(orgId, { 'on_demand[]': 'members_count' }))
        .then((models) => (departmentsState.data = models))
        .finally(() => (departmentsState.isDataLoading = false));
};

const updateDepartmentsState = (models) => {
    departmentsState.data = models;
    departmentsState.hasBeenJustUpdated = true;

    setTimeout(() => (departmentsState.hasBeenJustUpdated = false), 250);
};

const departmentPermissions = computed(() => ({
    create: $acl.can(DEPARTMENTS_RESTRICTIONS.CREATE, currentAccount),
    update: $acl.can(DEPARTMENTS_RESTRICTIONS.UPDATE, currentAccount),
    delete: $acl.can(DEPARTMENTS_RESTRICTIONS.DELETE, currentAccount),
}));

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const submitDeleteDepartment = () => {
    deletionCallback(() =>
        deletionModel.value
            .delete({ 'on_demand[]': 'members_count' })
            .then((models) => {
                updateDepartmentsState(models);

                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'The department has been successfully deleted',
                });
            })
            .catch((error) => {
                const { status } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.FORBIDDEN) {
                    EventBus.$emit('pl.flash-notification', {
                        type: ALERT_TYPES.FAIL,
                        message: 'Make sure there are not unfinished items that belong to the department.',
                    });
                }
            })
    );
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(loadDepartments);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Departments</h1>
        </template>

        <InPlaceLoader v-if="departmentsState.isDataLoading" />

        <Alert
            v-else-if="departmentsState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            Departments list could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <template v-if="!departmentsState.hasBeenJustUpdated">
                    <ListDisplay
                        v-if="departmentsState.data.length > 0"
                        has-borders
                        data-testid="departments_list"
                        :items="departmentsState.data"
                    >
                        <template #item="{ item: department }">
                            <DepartmentCard
                                :department="department"
                                :members-count="department.members_count"
                            >
                                <template
                                    v-if="departmentPermissions.update || departmentPermissions.delete"
                                    #actions="{ qaPrefix }"
                                >
                                    <router-link
                                        v-if="departmentPermissions.update"
                                        :to="{
                                            name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.EDIT,
                                            params: { departmentId: department.id },
                                        }"
                                        :data-testid="`${qaPrefix}_edit_btn`"
                                    >
                                        <EditIcon />
                                    </router-link>

                                    <button
                                        v-if="departmentPermissions.delete"
                                        type="button"
                                        data-testid="delete_department_btn"
                                        @click.stop="openDeletionModal(department)"
                                    >
                                        <DeleteIcon />
                                    </button>
                                </template>
                            </DepartmentCard>
                        </template>
                    </ListDisplay>

                    <p
                        v-else
                        class="pl-departments__message"
                        data-testid="departments_list_empty_note"
                    >
                        Departments list is empty.
                    </p>
                </template>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    v-if="departmentPermissions.create"
                    class="pl-departments__action"
                    style="transition-delay: 50ms"
                >
                    <router-link
                        :to="{ name: SETTINGS_ROUTE_NAMES.DEPARTMENTS.CREATE }"
                        class="btn btn-primary"
                        data-testid="create_department_btn"
                    >
                        Create department
                    </router-link>
                </div>
            </transition>

            <DeletionModal
                v-if="isDeletionModalOpened"
                qa-prefix="delete_department_modal"
                :is-pending="isDeletionPending"
                @close="closeDeletionModal"
                @submit-delete="submitDeleteDepartment"
            >
                <p>Are you sure you want to delete "{{ deletionModel.name }}" from the company?</p>
                <b>All the department items will be deleted as well.</b>
            </DeletionModal>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-departments {
    &__message {
        text-align: center;
        padding-top: custom-space(1);
    }

    &__action {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        margin-top: auto;
        padding-top: custom-space(1);
        width: 50%;

        @include media-breakpoint-down(xl) {
            width: 75%;
        }

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        & > a {
            width: 100%;
        }
    }
}
</style>
