<script>
export default {
    name: 'EditPLOrderStep3View',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mapGetters } from '@/utils/vuex';
import EventBus from '@/utils/EventBus';
import ErrorsBag from '@/utils/form/ErrorsBag';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import PLOrderModel from '@/models/PLOrder';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsAssignee from '@/components/model-specific/prep-lists/selectable-items-assignee';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                      Selectable items assignee state
------------------------------------------------------------------------*/

const selectableItemsAssigneeState = reactive({
    areActionsDisabled: true,
    isSubmitting: false,
});

const setAreActionsDisabled = (areActionsDisabled) =>
    (selectableItemsAssigneeState.areActionsDisabled = areActionsDisabled);

const initSubmitAssignees = () => (selectableItemsAssigneeState.isSubmitting = true);

const submitAssignees = (selectableAssignees) => {
    PLOrderModel.setAssignees(orgId, currentPLOrder.value.id, {
        items: Object.values(selectableAssignees),
    })
        .then(() => {
            router.push({
                name: PL_ROUTE_NAMES.EDIT.REVIEW_AND_CONFIRM,
                params: { orderId: props.orderId },
            });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                if ('prep_date' in data.errors) {
                    EventBus.$emit('pl.global-modal.open', {
                        title: 'Error occurred',
                        message: new ErrorsBag().flatten(data.errors).join('\r\n'),
                    });
                }
            }
        })
        .finally(() => (selectableItemsAssigneeState.isSubmitting = false));
};

const isNextStepDisabled = computed(() => {
    const { areActionsDisabled, isSubmitting } = selectableItemsAssigneeState;

    return isSubmitting || areActionsDisabled;
});
</script>

<template>
    <OrderStepTemplate
        is-edit-mode
        exclude-content-container
        :is-step-submitting="selectableItemsAssigneeState.isSubmitting"
    >
        <template #step-content>
            <SelectableItemsAssignee
                :organization-id="orgId"
                :order-id="currentPLOrder.id"
                :enable-submitting="selectableItemsAssigneeState.isSubmitting"
                @set-are-actions-disabled="setAreActionsDisabled"
                @submit-assignees="submitAssignees"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="btn btn-secondary"
                :to="{
                    name: PL_ROUTE_NAMES.EDIT.ADD_ITEMS,
                    params: { orderId },
                }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <button
                type="button"
                class="btn btn-primary"
                :disabled="isNextStepDisabled"
                @click="initSubmitAssignees"
            >
                Review And Confirm
            </button>
        </template>
    </OrderStepTemplate>
</template>
