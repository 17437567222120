<script>
export default {
    name: 'ItemForm',
};
</script>

<script setup>
import { reactive, ref, computed, watch, nextTick } from 'vue';
import { useStore } from 'vuex';
import {
    required,
    requiredIf,
    maxLength,
    integer,
    numeric,
    minValue,
    minLength,
} from '@vuelidate/validators';
import { Line } from 'vue-chartjs';

import { cutFloatNumber } from '@/utils/numberUtils';
import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';
import FormManager from '@/utils/form/FormManager';
import {
    getLocationAssigneeOptions,
    validateBatchSizeAmount,
    getBatchSettingsPayload,
    getBatchTimeSettingsPayload,
    calculateTotalAdvancedBatchLaborTime,
    getBatchTimeChartData,
    getBatchTimeChartOptions,
    getSelectedUnitsOptions,
    getInitCreateNewEntityOptionValue,
    getItemEntityOptions,
} from '@/utils/itemUtils';
import EventBus from '@/utils/EventBus';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import {
    AVAILABLE_ORDER_TYPES,
    DEFAULT_AVAILABLE_ORDER_TYPE,
    AVAILABLE_ORDER_TYPE_OPTIONS,
    ADVANCED_BATCH_TIME_CHART_TYPES,
    ADVANCED_BATCH_TIME_CHART_TYPES_OPTIONS,
    ADVANCED_BATCH_TIME_CHART_DISPLAYS,
    ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS,
    REPORTING_UNIT_CHECKBOX_OPTIONS,
    ITEM_CONFIGURATION_ENTITIES_NAME,
} from '@/enums/itemEnums';
import { ALERT_TYPES, TAG_LIST_SELECT_EVENTS } from '@/enums/componentsEnums';
import { ACCOUNT_ROLES } from '@/enums/accountEnums';
import StationModel from '@/models/Station';
import DepartmentModel from '@/models/Department';
import LocationModel from '@/models/Location';
import PrepTaskModel from '@/models/PrepTask';
import SupplierModel from '@/models/Supplier';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useItemConfiguration from '@/composition/useItemConfiguration';
import Alert from '@/components/Alert';
import Accordion from '@/components/Accordion';
import Switcher from '@/components/Switcher';
import Modal from '@/components/Modal';
import SimpleEntityFormModal from '@/components/forms/SimpleEntityFormModal';
import PrepTaskForm from '@/components/forms/PrepTaskForm';
import TextInput from '@/components/form-controls/TextInput';
import NumericInput from '@/components/form-controls/NumericInput';
import TagListSelect from '@/components/form-controls/TagListSelect';
import SelectInput from '@/components/form-controls/SelectInput';
import Radio from '@/components/form-controls/Radio';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import ItemFormUI from '@/components/model-specific/item/ItemFormUI';
import PlusAltIcon from '@/components/icons/PlusAltIcon';
import AddPrepTaskModal from './partials/AddPrepTaskModal';

const props = defineProps({
    availableOrderType: {
        type: String,
        default: DEFAULT_AVAILABLE_ORDER_TYPE,
    },
    name: {
        type: String,
        default: '',
    },
    departmentId: {
        type: Number,
        default: null,
    },
    category: {
        type: [String, Number],
        default: null,
    },
    stations: {
        type: Array,
        default: () => [],
    },
    shelfLife: {
        type: Number,
        default: null,
    },
    locationSpecificSettings: {
        type: Array,
        default: null,
    },
    batchSizeAmount: {
        type: Number,
        default: null,
    },
    batchSizeUnitId: {
        type: Number,
        default: null,
    },
    batchYieldAmount: {
        type: Number,
        default: null,
    },
    batchYieldUnitId: {
        type: Number,
        default: null,
    },
    batchWeightAmount: {
        type: String,
        default: null,
    },
    batchWeightUnit: {
        type: String,
        default: null,
    },
    batchCost: {
        type: String,
        default: null,
    },
    batchLaborTime: {
        type: Number,
        default: null,
    },
    laborTimeSettings: {
        type: Object,
        default: null,
    },
    quickFillOrderingUnitId: {
        type: Number,
        default: null,
    },
    prepListOrderingUnitId: {
        type: Number,
        default: null,
    },
    prepTasks: {
        type: Array,
        default: () => [],
    },
    defaultReportingUnitId: {
        type: Number,
        default: null,
    },
    isChangeableReportingUnit: {
        type: Boolean,
        default: false,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isEditMode: {
        type: Boolean,
        default: false,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            available_order_type: props.availableOrderType,
            name: props.name,
            department_id: props.departmentId,
            category: props.category,
            stations: props.stations,
            shelf_life: props.shelfLife,
            location_specific_settings: [],
            batch_size_amount: props.batchSizeAmount,
            batch_size_unit_id: props.batchSizeUnitId,
            batch_yield_amount: props.batchYieldAmount,
            batch_yield_unit_id: props.batchYieldUnitId,
            batch_weight_amount: props.batchWeightAmount,
            batch_weight_unit: props.batchWeightUnit,
            batch_cost: props.batchCost,
            batch_labor_time: props.batchLaborTime,
            batch_time_speedup: null,
            batch_time_initial: null,
            batch_time_final: null,
            quick_fill_ordering_unit_id: props.quickFillOrderingUnitId,
            prep_list_ordering_unit_id: props.prepListOrderingUnitId,
            prep_tasks: props.prepTasks,
            default_reporting_unit_id: props.defaultReportingUnitId,
            is_changeable_reporting_unit: [],
        },
        {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            department_id: {
                required,
                integer,
            },
            category: {
                required,
                integer,
            },
            shelf_life: {
                integer,
                minValue: minValue(1),
            },
            batch_size_amount: {
                integer,
                minValue: minValue(1),
            },
            batch_yield_amount: {
                integer,
                required,
                minValue: minValue(1),
            },
            batch_yield_unit_id: {
                required,
            },
            batch_weight_amount: {
                integer,
                required,
            },
            batch_weight_unit: {
                required,
            },
            batch_cost: {
                required,
                numeric,
            },
            batch_labor_time: {
                integer,
                minValue: minValue(1),
                requiredIf: requiredIf(() => !batchTimeSettings.isAdvancedModeActivated),
            },
            batch_time_speedup: {
                integer,
                requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
                minValue: minValue(2),
            },
            batch_time_initial: {
                integer,
                minValue: minValue(1),
                requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            },
            batch_time_final: {
                integer,
                requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            },
        },
        {
            name: 'Item name',
            department_id: 'Department',
            shelf_life: 'Shelf life',
            batch_size_unit_id: 'Batch size unit',
            batch_yield_unit_id: 'Batch yield unit',
            batch_weight_unit: 'Batch weight unit',
            batch_labor_time: 'Labor time',
            batch_time_initial: 'Batch initial time',
            batch_time_final: 'Batch final time',
        }
    )
);

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.errors.record(errors);
        }
    }
);

/*------------------------------------------------------------------------
                            Item configuration
------------------------------------------------------------------------*/

const entitiesQueryParams = {
    [ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY]: {
        only_joined_members: 1,
        'member_role_in[0]': ACCOUNT_ROLES.COOK,
        'member_role_in[1]': ACCOUNT_ROLES.MANAGER,
        'on_demand[0]': 'assignees',
        'on_demand[1]': 'categories',
    },
};

const {
    itemConfigurationState,
    activeEntityConfigurationState,
    itemEntityFormState,
    openItemEntityForm,
    closeItemEntityForm,
    submitItemEntityForm,
} = useItemConfiguration(entitiesQueryParams);

const setCurrentUnitId = (id = null) => {
    if (form.batch_yield_unit_id === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        form.batch_yield_unit_id = id;
    } else {
        form.batch_size_unit_id = id;
    }
};

const handleCloseItemEntityForm = () => {
    switch (activeEntityConfigurationState.name) {
        case ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY:
            form.category = null;

            break;

        case ITEM_CONFIGURATION_ENTITIES_NAME.UNIT:
            setCurrentUnitId();

            break;
    }

    closeItemEntityForm();
};

const handleSubmitItemEntityForm = (attributes) => {
    const resolveCallback = (models) => {
        const { name } = activeEntityConfigurationState;

        switch (name) {
            case ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY: {
                const { categories } = models.find(({ id }) => form.department_id === id);

                form.category = categories[categories.length - 1].id;

                break;
            }

            case ITEM_CONFIGURATION_ENTITIES_NAME.UNIT:
                setCurrentUnitId(models[models.length - 1].id);

                break;

            case ITEM_CONFIGURATION_ENTITIES_NAME.STATION:
                form.stations.unshift(models[models.length - 1].id);

                break;
        }
    };

    submitItemEntityForm(attributes, resolveCallback);
};

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    locations: [],
    prepTasks: [],
    suppliers: [],
    isDataLoading: false,
});

const loadGeneralState = () => {
    generalState.isDataLoading = true;

    Promise.all([
        sendAbortableRequest(
            DepartmentModel.all(orgId, {
                only_joined_members: 1,
                'member_role_in[0]': ACCOUNT_ROLES.COOK,
                'member_role_in[1]': ACCOUNT_ROLES.MANAGER,
                'on_demand[0]': 'assignees',
                'on_demand[1]': 'categories',
                ...(props.departmentId !== null ? { force_load: [props.departmentId] } : {}),
            })
        ),
        sendAbortableRequest(StationModel.all(orgId)),
        sendAbortableRequest(LocationModel.all(orgId)),
        sendAbortableRequest(PrepTaskModel.all(orgId)),
        sendAbortableRequest(SupplierModel.all(orgId)),
        store.dispatch('itemBatchMeta/loadMeta', orgId),
    ])
        .then(([departmentModels, stationModels, locationModels, prepTaskModels, supplierModels]) => {
            itemConfigurationState.departments = departmentModels;
            itemConfigurationState.stations = stationModels;

            generalState.locations = locationModels || [];
            generalState.prepTasks = prepTaskModels;
            generalState.suppliers = supplierModels;

            form.available_order_type = props.availableOrderType;
            form.name = props.name;
            form.department_id = props.departmentId;
            form.stations = props.stations;
            form.shelf_life = props.shelfLife;
            form.location_specific_settings =
                props.locationSpecificSettings ??
                generalState.locations.map(({ id, name }) => ({
                    location_name: name,
                    location_id: id,
                    assignee_id: null,
                    source_id: null,
                    source_type: null,
                }));
            form.batch_size_amount = props.batchSizeAmount;
            form.batch_size_unit_id = props.batchSizeUnitId;
            form.batch_yield_amount = props.batchYieldAmount;
            form.batch_yield_unit_id = props.batchYieldUnitId;
            form.batch_weight_amount = props.batchWeightAmount;
            form.batch_weight_unit = props.batchWeightUnit;
            form.batch_cost = props.batchCost;
            form.batch_labor_time = props.batchLaborTime;
            form.quick_fill_ordering_unit_id = props.quickFillOrderingUnitId;
            form.prep_list_ordering_unit_id = props.prepListOrderingUnitId;
            form.prep_tasks = props.prepTasks.map(({ id, qty, ...restData }) => ({
                id,
                BYQty: qty,
                times: qty / props.batchYieldAmount,
                ...restData,
            }));
            form.default_reporting_unit_id = props.defaultReportingUnitId;
            form.is_changeable_reporting_unit = props.isChangeableReportingUnit ? ['true'] : [];

            const { laborTimeSettings } = props;

            if (laborTimeSettings !== null) {
                form.batch_time_speedup = laborTimeSettings.period;
                form.batch_time_initial = laborTimeSettings.init_time;
                form.batch_time_final = laborTimeSettings.final_time;

                batchTimeSettings.chartType = laborTimeSettings.strategy;
                batchTimeSettings.isAdvancedModeActivated = props.prepTasks.length === 0;
            }

            nextTick(() => {
                form.category = props.category;

                activeSourceIndexes.value = form.location_specific_settings.reduce((acc, location, index) => {
                    acc[index] = [];

                    if (location.source_id !== null) {
                        acc[index].push('active');
                    }

                    return acc;
                }, {});
            });
        })
        .finally(() => (generalState.isDataLoading = false));
};

/*------------------------------------------------------------------------
                           Available order type
------------------------------------------------------------------------*/

const availableOrderTypeState = computed(() => {
    const isTypeAvailable = (type) => [type, AVAILABLE_ORDER_TYPES.BOTH].includes(form.available_order_type);

    return {
        isQuickFill: isTypeAvailable(AVAILABLE_ORDER_TYPES.QUICK_FILL),
        isPrepList: isTypeAvailable(AVAILABLE_ORDER_TYPES.PREP_LIST),
    };
});

watch(
    () => form.available_order_type,
    (availableOrderType) => {
        if (availableOrderType === AVAILABLE_ORDER_TYPES.QUICK_FILL) {
            form.prep_list_ordering_unit_id = null;
        } else if (availableOrderType === AVAILABLE_ORDER_TYPES.PREP_LIST) {
            form.quick_fill_ordering_unit_id = null;

            form.location_specific_settings.forEach((location, index) => {
                location.source_id = null;
                location.source_type = null;

                activeSourceIndexes.value[index] = [];
            });
        }
    }
);

/*------------------------------------------------------------------------
                                Department
------------------------------------------------------------------------*/

const departmentOptions = computed(() =>
    itemConfigurationState.departments.map(({ id, name }) => ({
        value: id,
        text: name,
    }))
);

const selectedDepartmentState = computed(() => {
    const department = itemConfigurationState.departments.find(({ id }) => form.department_id === id) || {};

    return {
        categories: department.categories || [],
        assignees: department.assignees || [],
    };
});

/*------------------------------------------------------------------------
                                 Category
------------------------------------------------------------------------*/

const categoryOptions = computed(() =>
    getItemEntityOptions(
        selectedDepartmentState.value.categories,
        ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY,
        true,
        false
    )
);

const handleCategoryUpdated = (value) => {
    if (value === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY)) {
        openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY, { department_id: form.department_id });
    }

    form.errors.clear('category');
};

/*------------------------------------------------------------------------
                                 Stations
------------------------------------------------------------------------*/

const stationOptions = computed(() =>
    getItemEntityOptions(itemConfigurationState.stations, ITEM_CONFIGURATION_ENTITIES_NAME.STATION, true, false)
);

const updateStations = ({ value, type }) => {
    switch (type) {
        case TAG_LIST_SELECT_EVENTS.ADD: {
            if (value === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.STATION)) {
                openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.STATION);
            } else {
                form.stations.unshift(value);
            }

            break;
        }

        case TAG_LIST_SELECT_EVENTS.REMOVE: {
            const index = form.stations.findIndex((stationValue) => stationValue === value);

            form.stations.splice(index, 1);

            break;
        }
    }
};

/*------------------------------------------------------------------------
                              Source settings
------------------------------------------------------------------------*/

const activeSourceIndexes = ref({});

const getSourceOptionValue = (index) => {
    const { source_id: id, source_type: type } = form.location_specific_settings[index];

    if (!id) {
        return null;
    }

    return `${id} ${type}`;
};

const getProductionSourceOptions = (targetId) => {
    const { locations, suppliers } = generalState;

    if (locations.length === 1 && suppliers.length === 0) {
        return [];
    }

    const options = [];

    const locationOptions = locations.reduce((acc, { id, name }) => {
        if (targetId !== id) {
            acc.push({
                value: `${id} location`,
                text: name,
            });
        }

        return acc;
    }, []);

    if (locationOptions.length > 0) {
        options.push({ text: 'Locations', groupLevel: 1 });
        options.push(...locationOptions);
    }

    if (suppliers.length > 0) {
        const supplierOptions = suppliers.map(({ id, name }) => ({
            value: `${id} supplier`,
            text: name,
        }));

        options.push({ text: 'Suppliers', groupLevel: 1 });
        options.push(...supplierOptions);
    }

    return options;
};

const handleActiveSourceChanged = (index) => {
    if (!activeSourceIndexes.value[index][0]) {
        form.location_specific_settings[index].source_id = null;
        form.location_specific_settings[index].source_type = null;
    }

    form.errors.clear(`location_specific_settings.${index}.source_id`);
};

const handleSourceSettingsChanged = (value, index) => {
    const [id, type] = value.split(' ');

    form.location_specific_settings[index].source_id = +id;
    form.location_specific_settings[index].source_type = type;

    form.errors.clear(`location_specific_settings.${index}.source_id`);
};

const validateSourceSettings = () => {
    let isValid = true;

    form.location_specific_settings.forEach((location, index) => {
        if (activeSourceIndexes.value[index][0] && location.source_id === null) {
            form.errors.push(`location_specific_settings.${index}.source_id`, 'Source is required.');

            isValid = false;
        }
    });

    return isValid;
};

const isSourceSettingsAccordionVisible = computed(() => {
    if (generalState.isDataLoading || props.isDataLoading) {
        return true;
    }

    return (
        form.available_order_type !== AVAILABLE_ORDER_TYPES.PREP_LIST &&
        (generalState.locations.length > 1 || generalState.suppliers.length > 0)
    );
});

/*------------------------------------------------------------------------
                             Default assignees
------------------------------------------------------------------------*/

const defaultAssigneesRibbonText = computed(() => {
    if (form.department_id === null) {
        return 'Please select a department';
    }

    if (selectedDepartmentState.value.assignees.length === 0) {
        return 'The selected department does not have available assignees.';
    }

    return null;
});

watch(
    () => form.department_id,
    () => {
        const { categories, assignees } = selectedDepartmentState.value;

        form.category = categories.find((category) => category.is_default)?.id ?? null;

        if (form.category !== null) {
            form.errors.clear('category');
        }

        const assigneeIds = new Set(assignees.map(({ id }) => id));

        form.location_specific_settings.forEach((location) => {
            if (!assigneeIds.has(location.assignee_id)) {
                location.assignee_id = null;
            }
        });
    }
);

/*------------------------------------------------------------------------
                          General batch settings
------------------------------------------------------------------------*/

const batchSettingsRef = ref(null);

const batchUnitsState = computed(() => {
    const {
        batch_size_units: batchSizeUnits,
        batch_yield_units: batchYieldUnits,
        batch_weight_units: batchWeightUnits,
    } = store.state.itemBatchMeta.meta || {};

    return {
        sizeOptions: getItemEntityOptions(batchSizeUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT),
        yieldOptions: getItemEntityOptions(batchYieldUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, true, false),
        weightOptions: getItemEntityOptions(batchWeightUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, false, false),
    };
});

const handleBatchUnitUpdated = (value, batchKey) => {
    if (value === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT);
    } else {
        form.validate('batch_size_amount');
    }

    form.errors.clear(batchKey);
};

watch(
    () => form.prep_tasks,
    () => {
        if (addedPrepTasks.value.length === 0) {
            form.batch_cost = props.batchCost;
            form.batch_labor_time = props.batchLaborTime;
        } else {
            const { batchCost, batchLaborTime } = addedPrepTasks.value.reduce(
                (totals, prepTask) => {
                    const {
                        batch_yield_amount: batchYieldAmount,
                        labor_time_settings: laborTimeSettings,
                        BYQty,
                    } = prepTask;

                    const batchesQty = BYQty / batchYieldAmount;

                    totals.batchCost += +prepTask.batch_cost * batchesQty;

                    if (laborTimeSettings === null) {
                        totals.batchLaborTime += +prepTask.batch_labor_time * batchesQty;
                    } else {
                        totals.batchLaborTime += calculateTotalAdvancedBatchLaborTime(
                            BYQty,
                            +laborTimeSettings.period * batchYieldAmount,
                            +laborTimeSettings.init_time,
                            +laborTimeSettings.final_time,
                            laborTimeSettings.strategy,
                            batchYieldAmount
                        );
                    }

                    return totals;
                },
                { batchCost: 0, batchLaborTime: 0 }
            );

            form.batch_cost = cutFloatNumber(batchCost);
            form.batch_labor_time = Math.ceil(batchLaborTime);

            batchTimeSettings.isAdvancedModeActivated = false;
        }

        form.errors.clear('batch_cost');
        form.errors.clear('batch_labor_time');
    },
    { deep: true }
);

/*------------------------------------------------------------------------
                       Advanced batch time settings
------------------------------------------------------------------------*/

const batchTimeSettings = reactive({
    isAdvancedModeActivated: false,
    chartType: ADVANCED_BATCH_TIME_CHART_TYPES.LINEAR,
    chartDisplay: ADVANCED_BATCH_TIME_CHART_DISPLAYS.PER_BATCH,
});

const toggleBatchTimeAdvancedMode = () => {
    batchTimeSettings.isAdvancedModeActivated = !batchTimeSettings.isAdvancedModeActivated;

    form.errors.clear('batch_labor_time');
    form.errors.clear('batch_time_speedup');
    form.errors.clear('batch_time_initial');
    form.errors.clear('batch_time_final');
};

const isBatchTimeChartInvalid = computed(
    () => !(form.batch_time_speedup && form.batch_time_initial && form.batch_time_final) && form.prep_tasks.length === 0
);

const batchTimeChartData = computed(() =>
    getBatchTimeChartData(
        form,
        batchTimeSettings.chartType,
        batchTimeSettings.chartDisplay === ADVANCED_BATCH_TIME_CHART_DISPLAYS.TOTAL,
        addedPrepTasks.value
    )
);

const batchTimeChartOptions = computed(() => getBatchTimeChartOptions(isBatchTimeChartInvalid.value));

/*------------------------------------------------------------------------
                                 Ordering
------------------------------------------------------------------------*/

const resetFieldIfInvalid = (field, options) => {
    const values = new Set(options.map(({ value }) => value));

    if (!values.has(form[field])) {
        form[field] = options[0].value;
    }
};

const orderingUnitOptions = computed(() => getSelectedUnitsOptions(form, batchUnitsState.value));

watch(orderingUnitOptions, (options) => {
    resetFieldIfInvalid('quick_fill_ordering_unit_id', options);
    resetFieldIfInvalid('prep_list_ordering_unit_id', options);
});

/*------------------------------------------------------------------------
                         Add prep task modal state
------------------------------------------------------------------------*/

const addPrepTaskModalState = reactive({
    isOpened: false,
    id: null,
    BYQty: 0,
});

const openAddPrepTaskModal = (prepTask) => {
    if (form.department_id !== null && form.batch_yield_amount !== null) {
        if (prepTask !== null) {
            addPrepTaskModalState.id = prepTask.id;
            addPrepTaskModalState.BYQty = prepTask.BYQty;
        }

        addPrepTaskModalState.isOpened = true;
    }
};

const closeAddPrepTaskModal = () => {
    addPrepTaskModalState.isOpened = false;
    addPrepTaskModalState.id = null;
    addPrepTaskModalState.BYQty = 0;
};

const addPrepTask = (payload) => {
    const prepTaskIndex = form.prep_tasks.findIndex((prepTask) => prepTask.id === addPrepTaskModalState.id);

    if (prepTaskIndex !== -1) {
        form.prep_tasks[prepTaskIndex] = payload;
    } else {
        form.prep_tasks.unshift(payload);
    }

    closeAddPrepTaskModal();
};

const removePrepTask = (id) => {
    const index = form.prep_tasks.findIndex((prepTask) => prepTask.id === id);

    form.prep_tasks.splice(index, 1);
};

const availablePrepTasks = computed(() => {
    if (form.department_id === null) {
        return [];
    }

    return generalState.prepTasks.filter(
        ({ id, departments }) =>
            !form.prep_tasks.some((prepTask) => prepTask.id === id && prepTask.id !== addPrepTaskModalState.id) &&
            departments.some((department) => department.id === form.department_id)
    );
});

const addedPrepTasks = computed(() =>
    form.prep_tasks.map(({ id, BYQty, times }) => {
        const prepTaskData = generalState.prepTasks.find((prepTask) => prepTask.id === id);

        return {
            ...prepTaskData,
            BYQty,
            times,
            qty: getBatchUnitQtyAmount(
                prepTaskData,
                prepTaskData.batch_yield_unit,
                prepTaskData.ordering_unit,
                BYQty,
                true
            ),
        };
    })
);

watch(
    () => form.department_id,
    (departmentId) => {
        if (form.prep_tasks.length > 0) {
            form.prep_tasks = addedPrepTasks.value.reduce((prepTasks, { id, BYQty, times, departments }) => {
                if (departments.some((department) => department.id === departmentId)) {
                    prepTasks.push({ id, BYQty, times });
                }

                return prepTasks;
            }, []);
        }
    }
);

watch(
    () => form.batch_yield_amount,
    (itemBYAmount) => {
        form.prep_tasks = addedPrepTasks.value.map(({ id, times }) => ({ id, times, BYQty: times * itemBYAmount }));
    }
);

/*------------------------------------------------------------------------
                       Create prep task modal state
------------------------------------------------------------------------*/

const createPrepTaskFormState = reactive({
    isModalOpened: false,
    isSubmitting: false,
    validationErrors: null,
    initialDepartments: [],
});

const openCreatePrepTaskModal = () => {
    createPrepTaskFormState.isModalOpened = true;
    createPrepTaskFormState.initialDepartments = [{ id: form.department_id }];
};

const closeCreatePrepTaskModal = () => {
    createPrepTaskFormState.isModalOpened = false;
    createPrepTaskFormState.validationErrors = null;
};

const submitCreatePrepTask = (attributes) => {
    createPrepTaskFormState.isSubmitting = true;

    PrepTaskModel.create(orgId, attributes)
        .then((prepTaskData) => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Prep task has been successfully created',
            });

            generalState.prepTasks = [...generalState.prepTasks, prepTaskData];
            addPrepTaskModalState.id = prepTaskData.id;

            closeCreatePrepTaskModal();
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                createPrepTaskFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (createPrepTaskFormState.isSubmitting = false));
};

/*------------------------------------------------------------------------
                                 Reporting
------------------------------------------------------------------------*/

const reportingUnitOptions = computed(() => {
    const defaultOption = {
        value: null,
        text: 'batches',
    };

    return getSelectedUnitsOptions(form, batchUnitsState.value, defaultOption);
});

watch(reportingUnitOptions, (options) => {
    resetFieldIfInvalid('default_reporting_unit_id', options);
});

/*------------------------------------------------------------------------
                                Submit form
------------------------------------------------------------------------*/

const handleBatchSizeError = (errorMessage) => {
    form.errors.push('batch_size_amount', errorMessage);
};

const submitForm = () => {
    if (!validateBatchSizeAmount(form, handleBatchSizeError)) {
        batchSettingsRef.value.scrollIntoView();
    } else if (form.validate() && validateSourceSettings()) {
        const {
            batch_labor_time: batchLaborTime,
            batch_time_speedup: batchTimeSpeedup,
            batch_time_initial: batchTimeInitial,
            batch_time_final: batchTimeFinal,
            prep_tasks: prepTasks,
            is_changeable_reporting_unit: isChangeableReportingUnit,
            ...restFormPayload
        } = form.getPayload();

        const payload = {
            ...restFormPayload,
            ...getBatchSettingsPayload(form),
            ...getBatchTimeSettingsPayload(
                batchLaborTime,
                batchTimeSpeedup,
                batchTimeInitial,
                batchTimeFinal,
                batchTimeSettings.chartType,
                batchTimeSettings.isAdvancedModeActivated
            ),
            is_quick_fill: availableOrderTypeState.value.isQuickFill,
            is_prep_list: availableOrderTypeState.value.isPrepList,
            prep_tasks: prepTasks.map(({ id, BYQty }) => ({ id, qty: BYQty })),
            is_changeable_reporting_unit: isChangeableReportingUnit.length !== 0,
        };

        emit('submit', payload);
    }
};

/*------------------------------------------------------------------------
                             Accordions state
------------------------------------------------------------------------*/

const prepTasksAccordionLabel = computed(() => {
    const prepTasksCount = form.prep_tasks.length;

    return prepTasksCount > 0 ? `Prep Tasks (${prepTasksCount})` : 'Prep Tasks';
});

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            loadGeneralState();
        }
    },
    { immediate: true }
);
</script>

<template>
    <transition
        appear
        name="down"
        type="transition"
    >
        <Alert
            v-if="itemConfigurationState.departments.length === 0 && !isLoading"
            :type="ALERT_TYPES.WARNING"
        >
            You can't create an Item because there are no available departments. Please note only departments that have
            at least one joined employee with the role "Cook" or "Manager" will be available.
        </Alert>

        <ItemFormUI
            v-else
            :is-loading="isLoading"
            @submit="submitForm"
        >
            <template #content="{ classNames, qaPrefix, accordionModifiers }">
                <Accordion
                    title="Order Types"
                    :data-test-id="`${qaPrefix}_order_types`"
                    :content-modifier-prefix="accordionModifiers.space"
                >
                    <label>This item is available for:</label>

                    <Radio
                        v-model="form.available_order_type"
                        name="available-order-type"
                        :data-testid="`${qaPrefix}_order_type_radio`"
                        :options="AVAILABLE_ORDER_TYPE_OPTIONS"
                    />
                </Accordion>

                <Accordion
                    title="General Information"
                    :data-test-id="`${qaPrefix}_general_information`"
                    :has-error="form.errors.hasOneOf(['name', 'department_id', 'category', 'stations', 'shelf_life'])"
                >
                    <div :class="classNames.spacerMd">
                        <TextInput
                            v-model="form.name"
                            include-asterisk
                            name="name"
                            label="Item name"
                            :data-test-id="`${qaPrefix}_name_input`"
                            :has-error="form.errors.has('name')"
                            @input="form.errors.clear('name')"
                            @blur="form.validate('name')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('name')"
                            :data-testid="`${qaPrefix}_name_errors`"
                            :errors="form.errors.get('name')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <SelectInput
                            v-model="form.department_id"
                            searchable
                            include-asterisk
                            label="Department"
                            :data-test-id="`${qaPrefix}_department_select`"
                            :options="departmentOptions"
                            :has-error="form.errors.has('department_id')"
                            :disabled="departmentOptions.length === 0"
                            @blur="form.validate('department_id')"
                            @update:modelValue="form.errors.clear('department_id')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('department_id')"
                            :data-testid="`${qaPrefix}_department_errors`"
                            :errors="form.errors.get('department_id')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <SelectInput
                            v-model="form.category"
                            searchable
                            include-asterisk
                            is-last-option-marked
                            label="Category"
                            :data-test-id="`${qaPrefix}_category_select`"
                            :options="categoryOptions"
                            :disabled="form.department_id === null"
                            :has-error="form.errors.has('category')"
                            @blur="form.validate('category')"
                            @update:modelValue="handleCategoryUpdated"
                        />

                        <small
                            v-if="form.department_id === null && !isLoading"
                            :class="classNames.ribbon"
                        >
                            Please select a department.
                        </small>

                        <ValidationErrors
                            v-if="form.errors.has('category')"
                            :data-testid="`${qaPrefix}_category_errors`"
                            :errors="form.errors.get('category')"
                        />
                    </div>

                    <TagListSelect
                        label="Stations"
                        is-last-option-marked
                        :data-form-control-test-id="`${qaPrefix}_station_select`"
                        :data-form-control-test-id-error="`${qaPrefix}_station_errors`"
                        :class="classNames.spacerMd"
                        :tags="form.stations"
                        :options="stationOptions"
                        :has-error="form.errors.has('stations')"
                        :errors="form.errors.get('stations')"
                        @update-tags-list="updateStations"
                    />

                    <NumericInput
                        v-model="form.shelf_life"
                        only-positive
                        name="shelf_life"
                        label="Shelf Life"
                        :max="65535"
                        :data-test-id="`${qaPrefix}_shelf_life_input`"
                        :has-error="form.errors.has('shelf_life')"
                        @input="form.errors.clear('shelf_life')"
                        @blur="form.validate('shelf_life')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('shelf_life')"
                        :data-testid="`${qaPrefix}_shelf_life_errors`"
                        :errors="form.errors.get('shelf_life')"
                    />
                </Accordion>

                <Accordion
                    v-if="isSourceSettingsAccordionVisible"
                    title="Source settings"
                    :data-test-id="`${qaPrefix}_source_settings`"
                >
                    <div
                        v-for="(setting, index) in form.location_specific_settings"
                        :key="`location-source-settings-field-${index}`"
                        class="pl-item-source-setting"
                        :class="{
                            [classNames.spacerSm]: index < form.location_specific_settings.length - 1,
                        }"
                    >
                        <CheckboxInput
                            v-model="activeSourceIndexes[index]"
                            :name="`source_settings.${index}.active`"
                            :qa-prefix="qaPrefix"
                            :options="[{ value: 'active' }]"
                            @update:modelValue="handleActiveSourceChanged(index)"
                        />

                        <div class="pl-item-source-setting__wrapper">
                            <SelectInput
                                searchable
                                enable-group-mode
                                :model-value="getSourceOptionValue(index)"
                                :data-test-id="`${qaPrefix}_source_select`"
                                :label="setting.location_name"
                                :options="getProductionSourceOptions(setting.location_id)"
                                :has-error="form.errors.has(`location_specific_settings.${index}.source_id`)"
                                :disabled="!activeSourceIndexes[index][0]"
                                @update:modelValue="(value) => handleSourceSettingsChanged(value, index)"
                            />

                            <ValidationErrors
                                v-if="form.errors.has(`location_specific_settings.${index}.source_id`)"
                                :data-testid="`${qaPrefix}_source_id_${index}_errors`"
                                :errors="form.errors.get(`location_specific_settings.${index}.source_id`)"
                            />
                        </div>
                    </div>
                </Accordion>

                <Accordion
                    title="Default Assignees"
                    :data-test-id="`${qaPrefix}_default_assignees`"
                >
                    <div
                        v-for="(setting, index) in form.location_specific_settings"
                        :key="`location-specific-default-employee-field-${index}`"
                        :class="{
                            [classNames.spacerSm]: index < form.location_specific_settings.length - 1,
                        }"
                    >
                        <SelectInput
                            v-model="form.location_specific_settings[index].assignee_id"
                            searchable
                            :data-test-id="`${qaPrefix}_default_assignee_select`"
                            :label="setting.location_name"
                            :options="
                                getLocationAssigneeOptions(selectedDepartmentState.assignees, setting.location_id)
                            "
                            :has-error="form.errors.has(`location_specific_settings.${index}.assignee_id`)"
                            :disabled="selectedDepartmentState.assignees.length === 0"
                            @update:modelValue="form.errors.clear(`location_specific_settings.${index}.assignee_id`)"
                        />

                        <small
                            v-if="defaultAssigneesRibbonText !== null"
                            :class="classNames.ribbon"
                        >
                            {{ defaultAssigneesRibbonText }}
                        </small>

                        <ValidationErrors
                            v-if="form.errors.has(`location_specific_settings.${index}.assignee_id`)"
                            :data-testid="`${qaPrefix}_assignee_${index}_errors`"
                            :errors="form.errors.get(`location_specific_settings.${index}.assignee_id`)"
                        />
                    </div>
                </Accordion>

                <Accordion
                    ref="batchSettingsRef"
                    title="Batch Settings"
                    :data-test-id="`${qaPrefix}_batch_settings`"
                    :has-error="
                        form.errors.hasOneOf([
                            'batch_size_amount',
                            'batch_size_unit_id',
                            'batch_yield_amount',
                            'batch_yield_unit_id',
                            'batch_weight_amount',
                            'batch_weight_unit',
                            'batch_cost',
                            'batch_labor_time',
                            'batch_time_speedup',
                            'batch_time_initial',
                            'batch_time_final',
                        ])
                    "
                >
                    <div :class="classNames.spacerMd">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="form.batch_size_amount"
                                only-positive
                                label="Batch Size"
                                name="batch_size_amount"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_size_amount_input`"
                                :has-error="form.errors.has('batch_size_amount')"
                                @input="form.errors.clear('batch_size_amount')"
                                @blur="form.validate('batch_size_amount')"
                            />

                            <SelectInput
                                v-model="form.batch_size_unit_id"
                                searchable
                                is-last-option-marked
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_size_unit_select`"
                                :options="batchUnitsState.sizeOptions"
                                :has-error="form.errors.has('batch_size_unit_id')"
                                :disabled="batchUnitsState.sizeOptions.length === 0"
                                @blur="form.validate('batch_size_unit_id')"
                                @update:modelValue="($value) => handleBatchUnitUpdated($value, 'batch_size_unit_id')"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('batch_size_amount')"
                            :data-testid="`${qaPrefix}_batch_size_amount_errors`"
                            :errors="form.errors.get('batch_size_amount')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_size_unit_id')"
                            :data-testid="`${qaPrefix}_batch_size_unit_errors`"
                            :errors="form.errors.get('batch_size_unit_id')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="form.batch_yield_amount"
                                only-positive
                                include-asterisk
                                name="batch_yield_amount"
                                label="Batch Yield"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_yield_amount_input`"
                                :has-error="form.errors.has('batch_yield_amount')"
                                :disabled="isEditMode"
                                @input="form.errors.clear('batch_yield_amount')"
                                @blur="
                                    form.validate('batch_size_amount');
                                    form.validate('batch_yield_amount');
                                "
                            />

                            <SelectInput
                                v-model="form.batch_yield_unit_id"
                                searchable
                                is-last-option-marked
                                include-asterisk
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_yield_unit_select`"
                                :options="batchUnitsState.yieldOptions"
                                :has-error="form.errors.has('batch_yield_unit_id')"
                                :disabled="batchUnitsState.yieldOptions.length === 0 || isEditMode"
                                @blur="form.validate('batch_yield_unit_id')"
                                @update:modelValue="($value) => handleBatchUnitUpdated($value, 'batch_yield_unit_id')"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('batch_yield_amount')"
                            :data-testid="`${qaPrefix}_batch_yield_amount_errors`"
                            :errors="form.errors.get('batch_yield_amount')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_yield_unit_id')"
                            :data-testid="`${qaPrefix}_batch_yield_unit_errors`"
                            :errors="form.errors.get('batch_yield_unit_id')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="form.batch_weight_amount"
                                only-positive
                                include-asterisk
                                label="Batch Weight"
                                name="batch_weight_amount"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_weight_amount_input`"
                                :has-error="form.errors.has('batch_weight_amount')"
                                @input="form.errors.clear('batch_weight_amount')"
                                @blur="form.validate('batch_weight_amount')"
                            />

                            <SelectInput
                                v-model="form.batch_weight_unit"
                                searchable
                                include-asterisk
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_weight_unit_select`"
                                :options="batchUnitsState.weightOptions"
                                :has-error="form.errors.has('batch_weight_unit')"
                                :disabled="batchUnitsState.weightOptions.length === 0"
                                @blur="form.validate('batch_weight_unit')"
                                @update:modelValue="($value) => handleBatchUnitUpdated($value, 'batch_weight_unit')"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('batch_weight_amount')"
                            :data-testid="`${qaPrefix}_batch_weight_amount_errors`"
                            :errors="form.errors.get('batch_weight_amount')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_weight_unit')"
                            :data-testid="`${qaPrefix}_batch_weight_unit_errors`"
                            :errors="form.errors.get('batch_weight_unit')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <NumericInput
                            v-model="form.batch_cost"
                            only-positive
                            always-use-dot
                            include-asterisk
                            label="Batch Cost"
                            name="batch_cost"
                            :max="9999"
                            :data-test-id="`${qaPrefix}_batch_cost_input`"
                            :max-precision="2"
                            :disabled="form.prep_tasks.length > 0"
                            :has-error="form.errors.has('batch_cost')"
                            @input="form.errors.clear('batch_cost')"
                            @blur="form.validate('batch_cost')"
                        />

                        <small
                            v-if="form.prep_tasks.length > 0"
                            :class="classNames.ribbon"
                        >
                            Batch cost is calculated according to prep tasks.
                        </small>

                        <ValidationErrors
                            v-if="form.errors.has('batch_cost')"
                            :data-testid="`${qaPrefix}_batch_cost_errors`"
                            :errors="form.errors.get('batch_cost')"
                        />
                    </div>

                    <div :class="classNames.batchTimeWrapper">
                        <template v-if="batchTimeSettings.isAdvancedModeActivated">
                            <TextInput
                                disabled
                                model-value="Advanced mode activated"
                                name="batch_labor_time"
                                label="Labor Time"
                            />

                            <button
                                type="button"
                                :data-test-id="`${qaPrefix}_batch_labor_time_advanced_btn_close`"
                                @click="toggleBatchTimeAdvancedMode"
                            >
                                <CrossIcon
                                    :height="18"
                                    :width="18"
                                />
                            </button>
                        </template>

                        <template v-else>
                            <NumericInput
                                v-model="form.batch_labor_time"
                                only-positive
                                include-asterisk
                                label="Labor Time (minutes)"
                                name="batch_labor_time"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_labor_time_input`"
                                :disabled="form.prep_tasks.length > 0"
                                :has-error="form.errors.has('batch_labor_time')"
                                @input="form.errors.clear('batch_labor_time')"
                                @blur="form.validate('batch_labor_time')"
                            />

                            <button
                                v-if="form.prep_tasks.length === 0"
                                type="button"
                                :data-test-id="`${qaPrefix}_batch_labor_time_advanced_btn_open`"
                                :disabled="+form.batch_labor_time !== 0"
                                @click="toggleBatchTimeAdvancedMode"
                            >
                                <SettingsIcon
                                    :height="30"
                                    :width="30"
                                />
                            </button>
                        </template>
                    </div>

                    <template v-if="batchTimeSettings.isAdvancedModeActivated">
                        <div :class="classNames.spacerSm">
                            <NumericInput
                                v-model="form.batch_time_speedup"
                                only-positive
                                include-asterisk
                                label="Speedup period (batches)"
                                name="batch_time_speedup"
                                :max="10"
                                :data-test-id="`${qaPrefix}_batch_time_speedup_input`"
                                :has-error="form.errors.has('batch_time_speedup')"
                                @input="form.errors.clear('batch_time_speedup')"
                                @blur="form.validate('batch_time_speedup')"
                            />

                            <ValidationErrors
                                v-if="form.errors.has('batch_time_speedup')"
                                :data-testid="`${qaPrefix}_batch_time_speedup_errors`"
                                :errors="form.errors.get('batch_time_speedup')"
                            />
                        </div>

                        <div :class="classNames.spacerSm">
                            <NumericInput
                                v-model="form.batch_time_initial"
                                only-positive
                                include-asterisk
                                label="Initial Time (minutes)"
                                name="batch_time_initial"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_time_initial_input`"
                                :has-error="form.errors.has('batch_time_initial')"
                                @input="form.errors.clear('batch_time_initial')"
                                @blur="form.validate('batch_time_initial')"
                            />

                            <ValidationErrors
                                v-if="form.errors.has('batch_time_initial')"
                                :data-testid="`${qaPrefix}_batch_time_initial_errors`"
                                :errors="form.errors.get('batch_time_initial')"
                            />
                        </div>

                        <div :class="classNames.spacerMd">
                            <NumericInput
                                v-model="form.batch_time_final"
                                only-positive
                                include-asterisk
                                label="Final Time (minutes)"
                                name="batch_time_final"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_time_final_input`"
                                :has-error="form.errors.has('batch_time_final')"
                                @input="form.errors.clear('batch_time_final')"
                                @blur="form.validate('batch_time_final')"
                            />

                            <ValidationErrors
                                v-if="form.errors.has('batch_time_final')"
                                :data-testid="`${qaPrefix}_batch_time_final_errors`"
                                :errors="form.errors.get('batch_time_final')"
                            />
                        </div>

                        <div :class="classNames.batchTimeChart">
                            <div :class="classNames.batchTimeChartTypeOptions">
                                <button
                                    v-for="{ value, text } in ADVANCED_BATCH_TIME_CHART_TYPES_OPTIONS"
                                    :key="value"
                                    type="button"
                                    :class="{
                                        [classNames.batchTimeChartTypeOption]: true,
                                        [classNames.batchTimeChartTypeOptionActive]:
                                            batchTimeSettings.chartType === value,
                                    }"
                                    :disabled="isBatchTimeChartInvalid"
                                    @click="batchTimeSettings.chartType = value"
                                >
                                    {{ text }}
                                </button>
                            </div>

                            <small
                                v-if="isBatchTimeChartInvalid"
                                :class="classNames.ribbon"
                            >
                                Please specify advanced settings to see the chart.
                            </small>
                        </div>
                    </template>

                    <template v-else>
                        <small
                            v-if="form.prep_tasks.length > 0"
                            :class="classNames.ribbon"
                        >
                            Labor time is calculated according to prep tasks.
                        </small>

                        <ValidationErrors
                            v-if="form.errors.has('batch_labor_time')"
                            :data-testid="`${qaPrefix}_batch_labor_time_errors`"
                            :errors="form.errors.get('batch_labor_time')"
                        />
                    </template>

                    <template v-if="batchTimeSettings.isAdvancedModeActivated || form.prep_tasks.length > 0">
                        <div :class="classNames.batchTimeChartContainer">
                            <Line
                                :data="batchTimeChartData"
                                :options="batchTimeChartOptions"
                            />
                        </div>

                        <Switcher
                            v-if="!isBatchTimeChartInvalid"
                            :model-value="batchTimeSettings.chartDisplay"
                            :options="ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS"
                            @update:modelValue="batchTimeSettings.chartDisplay = $event"
                        />
                    </template>
                </Accordion>

                <Accordion
                    title="Ordering"
                    :data-test-id="`${qaPrefix}_ordering`"
                >
                    <SelectInput
                        v-if="availableOrderTypeState.isQuickFill"
                        v-model="form.quick_fill_ordering_unit_id"
                        searchable
                        size="sm"
                        label="Quick fill ordering unit"
                        :data-test-id="`${qaPrefix}_quick_fill_ordering_unit_select`"
                        :class="classNames.spacerSm"
                        :options="orderingUnitOptions"
                    />

                    <SelectInput
                        v-if="availableOrderTypeState.isPrepList"
                        v-model="form.prep_list_ordering_unit_id"
                        searchable
                        size="sm"
                        label="Prep lists ordering unit"
                        :data-test-id="`${qaPrefix}_prep_list_ordering_unit_select`"
                        :options="orderingUnitOptions"
                    />
                </Accordion>

                <Accordion
                    :title="prepTasksAccordionLabel"
                    :data-test-id="`${qaPrefix}_prep_tasks`"
                >
                    <div
                        class="pl-item-prep-task"
                        :class="{
                            'pl-item-prep-task--disabled':
                                form.department_id === null || form.batch_yield_amount === null,
                        }"
                        :data-testid="`${qaPrefix}_add_prep_task_btn`"
                        @click="openAddPrepTaskModal(null)"
                    >
                        <PlusAltIcon
                            :width="28"
                            :height="28"
                        />

                        <span>Add prep task</span>
                    </div>

                    <small
                        v-if="form.department_id === null"
                        :class="classNames.ribbon"
                    >
                        Please select a department.
                    </small>

                    <small
                        v-if="form.batch_yield_amount === null && !isLoading"
                        :class="classNames.ribbon"
                    >
                        Please specify batch yield amount.
                    </small>

                    <div
                        v-for="prepTask in addedPrepTasks"
                        :key="prepTask.id"
                        class="pl-item-prep-task pl-item-prep-task--added"
                        :data-testid="`${qaPrefix}_added_prep_task`"
                        @click="openAddPrepTaskModal(prepTask)"
                    >
                        <span :data-testid="`${qaPrefix}_added_prep_task_info`">
                            {{ `${prepTask.name} - ${prepTask.qty} ${prepTask.ordering_unit?.text || 'batches'}` }}
                        </span>

                        <button
                            type="button"
                            :data-testid="`${qaPrefix}_added_prep_task_delete_btn`"
                            @click.stop="removePrepTask(prepTask.id)"
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                </Accordion>

                <Accordion
                    title="Reporting"
                    :data-test-id="`${qaPrefix}_reporting`"
                    :content-modifier-prefix="accordionModifiers.space"
                >
                    <label>By default, report using these units:</label>

                    <Radio
                        v-model="form.default_reporting_unit_id"
                        name="reporting-unit"
                        :data-testid="`${qaPrefix}_default_reporting_unit_radio`"
                        :options="reportingUnitOptions"
                    />

                    <CheckboxInput
                        v-model="form.is_changeable_reporting_unit"
                        name="reporting-unit-checkbox"
                        :qa-prefix="qaPrefix"
                        :class="classNames.reportingCheckbox"
                        :options="REPORTING_UNIT_CHECKBOX_OPTIONS"
                    />
                </Accordion>
            </template>

            <template #actions="{ qaPrefix }">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :data-testid="`${qaPrefix}_submit_btn`"
                    :disabled="
                        form.errors.any() ||
                            !form.isFilled() ||
                            selectedDepartmentState.assignees.length === 0 ||
                            isLoading
                    "
                >
                    Save item
                </button>

                <slot name="delete-item-btn" />
            </template>
        </ItemFormUI>
    </transition>

    <SimpleEntityFormModal
        v-if="itemEntityFormState.isModalOpened"
        :departments="itemConfigurationState.departments"
        :entity-name="activeEntityConfigurationState.name"
        :form-data="itemEntityFormState.entityData"
        :validation-errors="itemEntityFormState.validationErrors"
        :is-submitting="itemEntityFormState.isSubmitting"
        @submit="handleSubmitItemEntityForm"
        @close="handleCloseItemEntityForm"
    />

    <Modal
        v-if="createPrepTaskFormState.isModalOpened"
        disable-click-outside-mechanism
        :is-close-disabled="createPrepTaskFormState.isSubmitting"
        @close="closeCreatePrepTaskModal"
    >
        <template #title>
            Create new Prep Task
        </template>

        <template #content>
            <PrepTaskForm
                enable-lock-initial-departments
                :departments="createPrepTaskFormState.initialDepartments"
                :validation-errors="createPrepTaskFormState.validationErrors"
                :is-submitting="createPrepTaskFormState.isSubmitting"
                @submit="submitCreatePrepTask"
            >
                <template #delete-prep-task-btn>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="closeCreatePrepTaskModal"
                    >
                        Cancel
                    </button>
                </template>
            </PrepTaskForm>
        </template>
    </Modal>

    <AddPrepTaskModal
        v-else-if="addPrepTaskModalState.isOpened"
        :item-batch-yield-amount="form.batch_yield_amount"
        :prep-tasks="availablePrepTasks"
        :prep-task-id="addPrepTaskModalState.id"
        :prep-task-batch-yield-qty="addPrepTaskModalState.BYQty"
        @close="closeAddPrepTaskModal"
        @open-create-prep-task-modal="openCreatePrepTaskModal"
        @add-prep-task="addPrepTask"
    />
</template>

<style lang="scss" scoped>
.pl-item-source-setting {
    display: flex;
    align-items: flex-start;

    &__wrapper {
        flex: 1;
    }

    :deep(.pl-checkbox) {
        margin-top: custom-space(1);

        .form-check {
            margin: 0;
        }

        .form-check-input[type='checkbox'] {
            border-color: $black;
            border-radius: custom-space(0.25);
        }
    }
}

.pl-item-prep-task {
    display: flex;
    align-items: center;
    gap: custom-space(1);
    padding: custom-space(0.5) custom-space(0.8);
    border: 1px solid $gray-500;
    border-radius: custom-space(2);
    cursor: pointer;

    &--added {
        justify-content: space-between;
        gap: custom-space(1.25);
        margin-top: custom-space(0.25);
        cursor: auto;

        span,
        svg {
            cursor: pointer;
        }

        span {
            font-size: $font-size-base * 0.95;
            font-weight: 400;
        }

        svg {
            flex-shrink: 0;
        }
    }

    &--disabled {
        opacity: 0.5;
        user-select: none;
        pointer-events: none;
    }

    &:nth-of-type(2) {
        margin-top: custom-space(0.5);
    }
}
</style>
