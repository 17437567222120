import { ref, reactive, computed, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import TimelineModel from '@/models/Timeline';
import useAbortableRequest from '@/composition/useAbortableRequest';

const isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
const isSameOrBefore = require('dayjs/plugin/isSameOrBefore');
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const useTimeline = (factory, initialFiltersConfig = {}, isFactoryDynamic = false) => {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { sendAbortableRequest } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount?.organization.id;

    /*-----------------------------------------------------------------------------------
                                        Timeline state
    -----------------------------------------------------------------------------------*/

    const timelineState = reactive({
        data: null,
        isDataLoading: true,
    });

    const timelineDates = computed(() => {
        const { data } = timelineState;

        return data === null ? null : Object.keys(data);
    });

    /*-----------------------------------------------------------------------------------
                                        Pagination state
    -----------------------------------------------------------------------------------*/

    const paginationState = reactive({
        nextBackwardPageUrl: null,
        nextForwardPageUrl: null,
        isBackwardPageLoading: false,
        isForwardPageLoading: false,
    });

    const areLoadedBackwardPagesAvailable = computed(() => selectedDateIndex.value !== 0);
    const areNextBackwardPagesAvailable = computed(() => paginationState.nextBackwardPageUrl !== null);

    const areLoadedForwardPagesAvailable = computed(() => timelineDates.value.length - 1 !== selectedDateIndex.value);
    const areNextForwardPagesAvailable = computed(() => paginationState.nextForwardPageUrl !== null);

    /*-----------------------------------------------------------------------------------
                                    Selected date state
    -----------------------------------------------------------------------------------*/

    const selectedDateIndex = ref(null);

    const isDateSlideChanging = ref(false);

    const selectedOrdersList = computed(() => {
        const { data } = timelineState;

        if (data === null || selectedDateIndex.value == null) {
            return null;
        }

        const selectedDate = Object.entries(data)[selectedDateIndex.value][0];

        return data[selectedDate];
    });

    /*-----------------------------------------------------------------------------------
                                        Filters
    -----------------------------------------------------------------------------------*/

    const getInitialFilters = () =>
        Object.entries(initialFiltersConfig).reduce(
            (acc, [key, defaultValue]) => ({
                ...acc,
                [key]: route.query[key] || defaultValue,
            }),
            {}
        );

    const filters = ref(getInitialFilters());

    const updateFilters = (updatedFilters) => {
        filters.value = {
            ...filters.value,
            start_date: route.query.start_date,
            ...updatedFilters,
        };
    };

    onBeforeRouteUpdate((updatedRoute) => {
        const isSameViewMode = route.query.viewmode === updatedRoute.query.viewmode;

        if (isSameViewMode && updatedRoute.query.start_date === undefined) {
            router.replace({ name: route.name, query: { ...route.query, start_date: filters.value.start_date } });

            if (timelineState.data !== null) {
                const currentDateIndex = Object.keys(timelineState.data).findIndex(
                    (timelineDate) => filters.value.start_date === timelineDate
                );

                selectedDateIndex.value = currentDateIndex || 0;
            }
        }
    });

    /*-----------------------------------------------------------------------------------
                                        Load timeline
    -----------------------------------------------------------------------------------*/

    const setTimeline = (model, startDate = filters.value.start_date) => {
        if (model === null) {
            return;
        }

        timelineState.data = model.data;

        paginationState.nextBackwardPageUrl = model.backward_paginator.next_page_url;
        paginationState.nextForwardPageUrl = model.forward_paginator.next_page_url;

        selectedDateIndex.value = Object.entries(timelineState.data).findIndex(
            ([currentDate]) => startDate === currentDate
        );
    };

    const loadTimeline = (extraParams = {}, dynamicFactory = null) => {
        const activeFilters = Object.fromEntries(Object.entries(filters.value).filter(([, value]) => !!value));

        const factoryParam = isFactoryDynamic ? dynamicFactory : factory;

        router.push({ query: { ...route.query, ...activeFilters } });

        return sendAbortableRequest(
            TimelineModel.all(orgId, {
                factory: factoryParam,
                ...extraParams,
                ...filters.value,
            })
        ).then((model) => {
            if (!model) {
                return null;
            }

            paginationState.nextBackwardPageUrl = model.backward_paginator.next_page_url;
            paginationState.nextForwardPageUrl = model.forward_paginator.next_page_url;

            return model;
        });
    };

    const loadTimelineBackwardPage = (dynamicFactory = null) => {
        paginationState.isBackwardPageLoading = true;

        const pageBackward = new URLSearchParams(paginationState.nextBackwardPageUrl).get('page_backward');

        return loadTimeline({ page_backward: pageBackward }, dynamicFactory)
            .then((model) => {
                if (model === null) {
                    return null;
                }

                timelineState.data = {
                    ...model.data,
                    ...timelineState.data,
                };

                selectedDateIndex.value = Math.max(0, Object.entries(model.data).length - 1);
            })
            .finally(() => (paginationState.isBackwardPageLoading = false));
    };

    const loadTimelineForwardPage = (dynamicFactory = null) => {
        paginationState.isForwardPageLoading = true;

        const pageForward = new URLSearchParams(paginationState.nextForwardPageUrl).get('page_forward');

        return loadTimeline({ page_forward: pageForward }, dynamicFactory)
            .then((model) => {
                if (model === null) {
                    return null;
                }

                timelineState.data = {
                    ...timelineState.data,
                    ...model.data,
                };

                selectedDateIndex.value = selectedDateIndex.value + 1;
            })
            .finally(() => (paginationState.isForwardPageLoading = false));
    };

    watch(
        () => filters.value,
        () => {
            if (!isFactoryDynamic) {
                timelineState.isDataLoading = true;

                loadTimeline({ page_forward: 1, page_backward: 1, include_start_date: 1 })
                    .then(setTimeline)
                    .finally(() => (timelineState.isDataLoading = false));
            }
        },
        { immediate: true, deep: true }
    );

    return {
        timelineState,
        timelineDates,
        setTimeline,
        loadTimeline,

        selectedDateIndex,
        isDateSlideChanging,
        selectedOrdersList,

        paginationState,
        areLoadedBackwardPagesAvailable,
        areNextBackwardPagesAvailable,
        areLoadedForwardPagesAvailable,
        areNextForwardPagesAvailable,
        loadTimelineBackwardPage,
        loadTimelineForwardPage,

        filters,
        updateFilters,
    };
};

export default useTimeline;
