import Rule from './Rule';

export default class ACL {
    constructor() {
        this.rules = [];
    }

    defineRule(name, callback) {
        const rule = this.findRule(name);

        if (rule !== undefined) {
            throw new Error(`The ACL rule with the name "${name}" is already defined.`);
        }

        this.rules.push(new Rule(name, callback));

        return this;
    }

    can(name, ...args) {
        const rule = this.findRule(name);

        if (!rule) {
            throw new Error(`The ACL rule "${name}" is not defined.`);
        }

        return rule.execute(args);
    }

    findRule(name) {
        return this.rules.find((rule) => rule.getName() === name);
    }
}
