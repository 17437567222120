<script>
export default {
    name: 'LocationForm',
};
</script>

<script setup>
import { ref, reactive, computed, watch, onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';
import Avatar from 'vue-avatar-component';
import { required, maxLength, minLength } from '@vuelidate/validators';

import EventBus from '@/utils/EventBus';
import FormManager from '@/utils/form/FormManager';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import AccountModel from '@/models/Account';
import useAbortableRequest from '@/composition/useAbortableRequest';
import ListDisplay from '@/components/ListDisplay';
import TextInput from '@/components/form-controls/TextInput';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: null,
    },
    members: {
        type: Array,
        default: () => [],
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            name: '',
            members: [],
        },
        {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
        }
    )
);

const submitForm = () => {
    if (form.validate()) {
        emit('submit', form.getPayload());
    }
};

watch(
    () => props.name,
    (name) => (form.name = name),
    { immediate: true }
);

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.errors.record(errors);
        }
    }
);

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    accounts: [],
    isDataLoading: true,
});

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

const loadGeneralState = () => {
    sendAbortableRequest(AccountModel.all(orgId, { exclude_owner: 1 }))
        .then((accountModels) => (generalState.accounts = accountModels || []))
        .finally(() => (generalState.isDataLoading = false));
};

onBeforeMount(loadGeneralState);

/*------------------------------------------------------------------------
                               Members state
------------------------------------------------------------------------*/

const protectedMemberIds = ref(new Set());

const getSelectedMemberIndex = (member) => form.members.findIndex((id) => member.id === id);

const isMemberSelected = (member) => getSelectedMemberIndex(member) !== -1;

const toggleMember = (member) => {
    if (protectedMemberIds.value.has(member.id)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: `You can't remove ${member.name} because this employee is associated with only this location.`,
        });

        return;
    }

    const currentMemberIndex = getSelectedMemberIndex(member);

    if (currentMemberIndex !== -1) {
        form.members.splice(currentMemberIndex, 1);
    } else {
        form.members.push(member.id);
    }

    form.errors.clear('members');
    form.validate('members');
};

const selectedMembers = computed(() => generalState.accounts.filter(isMemberSelected));

watch(
    () => props.members,
    (members) => {
        const memberIds = [];

        members.forEach((member) => {
            memberIds.push(member.id);

            if (!member.can_be_detached) {
                protectedMemberIds.value.add(member.id);
            }
        });

        form.members = memberIds;
    },
    { immediate: true }
);

/*------------------------------------------------------------------------
                                  Swiper
------------------------------------------------------------------------*/

const swiperSettings = {
    modules: [Navigation],
    spaceBetween: 10,
    style: {
        width: '100%',
        height: '100%',
    },
    breakpoints: {
        1: {
            slidesPerView: 4,
        },
        400: {
            slidesPerView: 5,
        },
        480: {
            slidesPerView: 6,
        },
        580: {
            slidesPerView: 5,
        },
    },
};
</script>

<template>
    <FormUI
        data-test-id="location_form"
        :is-loading="isLoading"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerLg">
                <TextInput
                    v-model="form.name"
                    include-asterisk
                    label="Name of Location"
                    name="name"
                    autocomplete="name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :disabled="isLoading"
                    :has-error="form.errors.has('name')"
                    @input="form.errors.clear('name')"
                    @blur="form.validate('name')"
                />

                <ValidationErrors
                    v-if="form.errors.has('name')"
                    :data-testid="`${qaPrefix}_name_error`"
                    :errors="form.errors.get('name')"
                />
            </div>

            <transition-group
                appear
                name="down"
                type="transition"
            >
                <template v-if="!generalState.isDataLoading && !isDataLoading">
                    <div
                        key="selected_employees"
                        :class="classNames.spacerLg"
                    >
                        <h5 class="mb-0">
                            Employees ({{ selectedMembers.length }})
                        </h5>

                        <div
                            class="pl-selected-employees"
                            :class="{
                                'pl-selected-employees--empty': selectedMembers.length === 0,
                            }"
                        >
                            <small
                                v-if="selectedMembers.length === 0"
                                :data-testid="`${qaPrefix}_selected_employees_list_empty_note`"
                            >
                                Please select at least one employee.
                            </small>

                            <Swiper
                                v-else
                                navigation
                                :data-testid="`${qaPrefix}_selected_employees_list`"
                                :modules="swiperSettings.modules"
                                :slides-per-view="swiperSettings.slidesPerView"
                                :space-between="swiperSettings.spaceBetween"
                                :style="swiperSettings.style"
                                :breakpoints="swiperSettings.breakpoints"
                            >
                                <SwiperSlide
                                    v-for="(employee, index) in selectedMembers"
                                    :key="`selected-employee-${index}`"
                                    class="pl-selected-employee-card"
                                >
                                    <div class="pl-selected-employee-card__avatar">
                                        <Avatar
                                            :fullname="employee.name"
                                            :size="50"
                                        />

                                        <button
                                            type="button"
                                            :data-testid="`${qaPrefix}_remove_employee_btn`"
                                            @click="toggleMember(employee)"
                                        >
                                            <CrossIcon
                                                :width="8"
                                                :height="8"
                                            />
                                        </button>
                                    </div>

                                    <p :data-testid="`${qaPrefix}_employee_name`">
                                        {{ employee.name }}
                                    </p>
                                </SwiperSlide>
                            </Swiper>
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('members')"
                            :errors="form.errors.get('members')"
                            :data-testid="`${qaPrefix}_employees_error`"
                        />
                    </div>

                    <div
                        key="employees_list_wrapper"
                        class="pl-employees-list-wrapper"
                    >
                        <ListDisplay
                            v-if="generalState.accounts.length"
                            data-testid="employees_list"
                            :items="generalState.accounts"
                        >
                            <template #item="{ item: employee }">
                                <EmployeeCard
                                    :employee="employee"
                                    :data-qa-selected="isMemberSelected(employee) ? 'selected' : 'unselected'"
                                    @click="toggleMember(employee)"
                                >
                                    <template
                                        v-if="isMemberSelected(employee)"
                                        #avatar-badge
                                    >
                                        <div
                                            class="pl-employee-card__check-badge"
                                            :data-testid="`employee_check_badge`"
                                        >
                                            <CheckIcon
                                                :width="10"
                                                :height="10"
                                            />
                                        </div>
                                    </template>
                                </EmployeeCard>
                            </template>
                        </ListDisplay>

                        <p
                            v-else
                            class="text-center"
                            :data-testid="`${qaPrefix}_employees_list_empty_note`"
                        >
                            Employees list is empty.
                        </p>
                    </div>
                </template>
            </transition-group>
        </template>

        <template #actions="{ qaPrefix }">
            <button
                type="submit"
                class="btn btn-primary"
                :data-testid="`${qaPrefix}_submit_btn`"
                :disabled="form.errors.any() || !form.isFilled() || isLoading"
            >
                Save Location
            </button>
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-employees-list-wrapper {
    flex: 1 1 auto;
    height: custom-space(13.5);
    overflow-y: auto;

    :deep(.pl-list-display__item):not(:last-child) {
        margin-bottom: custom-space(0.5);
    }
}

.pl-selected-employees {
    position: relative;
    padding: custom-space(1) custom-space(2);
    border-bottom: 1px solid $gray-200;

    :deep(.swiper) {
        position: static;
    }

    &:has(.swiper-button-lock) {
        padding-left: 0;
        padding-right: 0;
    }

    &--empty {
        text-align: center;
        padding: custom-space(1.5) 0;
    }
}

.pl-selected-employee-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
    text-align: center;

    &__avatar {
        position: relative;

        & > button {
            $size: 14px;

            position: absolute;
            bottom: custom-space(0.375);
            right: custom-space(-0.125);
            width: $size;
            height: $size;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: $red-light;
            box-shadow: 0 0 0 1px $white;
            border: none;
            border-radius: 50%;
            line-height: 0;

            & > svg :deep(path) {
                fill: $white;
            }
        }
    }

    & > p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: $font-size-base * 0.75;
        font-weight: $font-weight-normal;
        line-height: 1;
        max-width: 90%;
        margin-bottom: 0;
    }
}

:deep(.pl-employee-card) {
    cursor: pointer;

    .pl-employee-card__avatar {
        position: relative;
    }

    .pl-employee-card__check-badge {
        display: flex;
        align-items: center;
        justify-content: center;
        width: custom-space(1.15);
        height: custom-space(1.15);
        background-color: $teal;
        padding-top: custom-space(0.1);
        position: absolute;
        bottom: custom-space(-0.125);
        right: custom-space(-0.3125);
        border-radius: 50%;
    }

    .pl-employee-card__name {
        font-weight: $font-weight-bolder;
    }

    .pl-employee-card__phone {
        font-weight: $font-weight-light;
    }
}
</style>
