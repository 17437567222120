<script>
export default {
    name: 'ValidationErrors',
};
</script>

<script setup>
defineProps({
    errors: {
        type: Array,
        required: true,
    },
});
</script>

<template>
    <ul class="pl-validation-errors">
        <li
            v-for="(error, index) in errors"
            :key="`validation-error-${index}`"
        >
            {{ error }}
        </li>
    </ul>
</template>

<style lang="scss" scoped>
.pl-validation-errors {
    padding: 0;
    margin: 0;
    list-style: none;

    & > li {
        margin-top: custom-space(0.25);
        color: $danger;
    }
}
</style>
