import { getBatchUnitQtyAmount } from '@/utils/batchUnitsUtils';

/*------------------------------------------------------------------------
                                  Common
------------------------------------------------------------------------*/

export const onceRefInitiated = (ref, callback, timeout = null) => {
    const intervalId = setInterval(() => {
        if (ref.value !== null) {
            clearInterval(intervalId);

            callback();
        }
    }, 10);

    if (timeout !== null) {
        setTimeout(() => clearInterval(intervalId), timeout);
    }
};

/*------------------------------------------------------------------------
                 src/components/form-controls/QtyInput.vue
------------------------------------------------------------------------*/

export const getClosestAllowedQty = (unitsData, currentUnit, qty) => {
    if (qty <= 0) {
        return 0;
    }

    return getBatchUnitQtyAmount(unitsData, currentUnit, currentUnit, qty, true);
};

export const getQtyPrecision = (step) => (Number.isInteger(+step) ? 0 : 2);
