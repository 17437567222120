<script>
export default {
    name: 'TimelineViewTemplate',
};
</script>

<script setup>
import { ref, inject, computed, watch, useSlots } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import { isAdmin, isManager, isAdminOrManager } from '@/utils/accountUtils';
import { mapGetters } from '@/utils/vuex';
import { isOrderEditing } from '@/utils/orderUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { TIMELINE_VIEW_MODES, TIMELINE_TABS_OPTIONS, TIMELINE_TABS } from '@/enums/timelineEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import useTimeline from '@/composition/useTimeline';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import Tabs from '@/components/Tabs';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import ListDisplay from '@/components/ListDisplay';
import UnfinishedOrderRibbon from '@/components/model-specific/orders/UnfinishedOrderRibbon';
import DatesCarousel from '@/components/model-specific/orders/DatesCarousel';
import ThreeDotsContentAdmin from './partials/ThreeDotsContentAdmin';
import ThreeDotsContentManager from './partials/ThreeDotsContentManager';
import ThreeDotsContentCook from './partials/ThreeDotsContentCook';
import CreateOrderFab from './partials/CreateOrderFab';
import OrderFiltersView from './partials/OrderFiltersView';

const props = defineProps({
    regularOrdersListKey: {
        type: String,
        required: true,
    },
    otherOrdersListKey: {
        type: String,
        required: true,
    },
    openItemsListKey: {
        type: String,
        default: 'open_items',
    },
    viewMode: {
        type: String,
        required: true,
    },
    factory: {
        type: String,
        required: true,
    },
    initialFiltersConfig: {
        type: Object,
        required: true,
    },
    freshTimeline: {
        type: Object,
        default: () => ({}),
    },
    includeTabs: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['set-selected-orders-list']);

const $acl = inject('$acl');

const slots = useSlots();
const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);


const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Timeline state
------------------------------------------------------------------------*/

const {
    timelineState,
    timelineDates,
    setTimeline,

    selectedDateIndex,
    isDateSlideChanging,
    selectedOrdersList,

    paginationState,
    areLoadedBackwardPagesAvailable,
    areNextBackwardPagesAvailable,
    areLoadedForwardPagesAvailable,
    areNextForwardPagesAvailable,
    loadTimelineBackwardPage,
    loadTimelineForwardPage,

    filters,
    updateFilters,
} = useTimeline(props.factory, {
    ...props.initialFiltersConfig,
    start_date: dayjs().startOf('day').format('YYYY-MM-DD'),
});

const unfinishedOrderLink = computed(() => {
    const order = currentPLOrder.value;

    if (isOrderEditing(order)) {
        return {
            name: PL_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        };
    }

    return { name: PL_ROUTE_NAMES.CREATE.INDEX };
});

const isAdminOrManagerMainView = computed(
    () => isAdminOrManager(currentAccount) && route.query.viewmode !== TIMELINE_VIEW_MODES.AS_COOK
);

watch(
    () => selectedOrdersList.value,
    (ordersList) => {
        emit('set-selected-orders-list', ordersList);
    }
);

watch(
    () => props.freshTimeline,
    (timelineModel) => {
        if (Object.keys(timelineModel).length > 0) {
            setTimeline(timelineModel, route.query.start_date);
        }
    }
);

/*------------------------------------------------------------------------
                                Tabs state
------------------------------------------------------------------------*/

const activeTab = ref(route.query.tab || null);

const setActiveTab = (value) => (activeTab.value = value);

const tabContentConfig = computed(() => {
    const { regularOrdersListKey, otherOrdersListKey, openItemsListKey } = props;

    if (activeTab.value === TIMELINE_TABS.ASSIGNED) {
        const otherList = selectedOrdersList.value[otherOrdersListKey] || [];

        return {
            otherList,
            includeArbitrarySection: otherList.length > 0 || !!slots['arbitrary-section'],
            mainList: selectedOrdersList.value[regularOrdersListKey] || [],
        };
    }

    return {
        otherList: [],
        includeArbitrarySection: false,
        mainList: selectedOrdersList.value[openItemsListKey] || [],
    };
});

watch(activeTab, (tab) => router.push({ query: { ...route.query, tab } }), { immediate: true });

/*------------------------------------------------------------------------
                           Three dots component
------------------------------------------------------------------------*/

const threeDotsContentComponent = computed(() => {
    if (isAdmin(currentAccount)) {
        return ThreeDotsContentAdmin;
    }

    if (isManager(currentAccount)) {
        return ThreeDotsContentManager;
    }

    return ThreeDotsContentCook;
});
</script>

<template>
    <TopBarLayout exclude-content-container>
        <template #title>
            <h1>Prep Lists</h1>
        </template>

        <template
            v-if="!timelineState.isDataLoading"
            #actions
        >
            <DatePickerHOC
                v-model="filters.start_date"
                menu-class-name="pl-timeline-view-calendar"
            />

            <OrderFiltersView
                v-if="isAdminOrManagerMainView"
                :initial-filters="{
                    department_id: filters.department_id,
                    category_id: filters.category_id,
                    status: filters.status,
                    only_my: filters.only_my,
                }"
                @update-filters="updateFilters"
            />

            <DropDown>
                <template #content>
                    <component
                        :is="threeDotsContentComponent"
                        :view-mode="viewMode"
                    />
                </template>
            </DropDown>
        </template>

        <template
            v-if="isAdminOrManagerMainView && currentPLOrder !== null"
            #ribbon
        >
            <UnfinishedOrderRibbon :navigate-to="unfinishedOrderLink" />
        </template>

        <InPlaceLoader v-if="timelineState.isDataLoading" />

        <div
            v-else-if="timelineDates === null"
            class="container"
        >
            <Alert :type="ALERT_TYPES.FAIL">
                Orders list could not be loaded for some reason. Please try again later.
            </Alert>
        </div>

        <template v-else>
            <DatesCarousel
                v-model="selectedDateIndex"
                :dates="timelineDates"
                :are-loaded-prev-pages-available="areLoadedBackwardPagesAvailable"
                :are-unloaded-prev-pages-available="areNextBackwardPagesAvailable"
                :is-prev-page-being-loaded="paginationState.isBackwardPageLoading"
                :are-loaded-next-pages-available="areLoadedForwardPagesAvailable"
                :are-unloaded-next-pages-available="areNextForwardPagesAvailable"
                :is-next-page-being-loaded="paginationState.isForwardPageLoading"
                @slide-start="isDateSlideChanging = true"
                @slide-end="isDateSlideChanging = false"
                @prev-page-requested="loadTimelineBackwardPage"
                @next-page-requested="loadTimelineForwardPage"
            />

            <div class="container">
                <Tabs
                    v-if="includeTabs"
                    :model-value="activeTab"
                    :tab-options="TIMELINE_TABS_OPTIONS"
                    :has-been-content-just-updated="isDateSlideChanging"
                    @update:modelValue="setActiveTab"
                >
                    <template #content>
                        <ListDisplay
                            v-if="tabContentConfig.mainList.length > 0"
                            data-testid="orders_list"
                            class="pl-timeline-view__orders-list"
                            :items="tabContentConfig.mainList"
                            :class="{
                                'pl-timeline-view__orders-list--space': !tabContentConfig.otherList.length,
                            }"
                        >
                            <template #item="{ item }">
                                <slot
                                    name="order-list-item"
                                    :item="item"
                                    :isRegularList="true"
                                />
                            </template>
                        </ListDisplay>

                        <p
                            v-else
                            class="pl-timeline-view__message"
                        >
                            There are no orders for the selected prep date.
                        </p>

                        <div
                            v-if="tabContentConfig.includeArbitrarySection"
                            class="pl-timeline-view__section--other"
                        >
                            <h2>
                                <span>Other</span>
                            </h2>

                            <ListDisplay
                                v-if="tabContentConfig.otherList.length > 0"
                                data-testid="other_orders_list"
                                class="pl-timeline-view__orders-list pl-timeline-view__orders-list--space"
                                :items="tabContentConfig.otherList"
                            >
                                <template #item="{ item }">
                                    <slot
                                        name="order-list-item"
                                        :item="item"
                                        :isRegularList="false"
                                    />
                                </template>
                            </ListDisplay>

                            <slot name="arbitrary-section" />
                        </div>
                    </template>
                </Tabs>

                <template v-else>
                    <transition-group
                        appear
                        name="down"
                        type="transition"
                    >
                        <template v-if="!isDateSlideChanging">
                            <ListDisplay
                                v-if="selectedOrdersList[regularOrdersListKey]?.length > 0"
                                key="orders_list"
                                data-testid="orders_list"
                                class="pl-timeline-view__orders-list"
                                :items="selectedOrdersList[regularOrdersListKey]"
                                :class="{
                                    'pl-timeline-view__orders-list--space':
                                        !selectedOrdersList[otherOrdersListKey]?.length,
                                }"
                            >
                                <template #item="{ item }">
                                    <slot
                                        name="order-list-item"
                                        :item="item"
                                        :isRegularList="true"
                                    />
                                </template>
                            </ListDisplay>

                            <p
                                v-else
                                key="no-regular-orders-message"
                                class="pl-timeline-view__message"
                            >
                                There are no orders for the selected prep date.
                            </p>

                            <div
                                v-if="selectedOrdersList[otherOrdersListKey]?.length > 0 || slots['arbitrary-section']"
                                key="other-orders-section"
                                class="pl-timeline-view__section--other"
                            >
                                <h2>
                                    <span>Other</span>
                                </h2>

                                <ListDisplay
                                    v-if="selectedOrdersList[otherOrdersListKey]?.length > 0"
                                    key="other_orders_list"
                                    data-testid="other_orders_list"
                                    class="pl-timeline-view__orders-list pl-timeline-view__orders-list--space"
                                    :items="selectedOrdersList[otherOrdersListKey]"
                                >
                                    <template #item="{ item }">
                                        <slot
                                            name="order-list-item"
                                            :item="item"
                                            :isRegularList="false"
                                        />
                                    </template>
                                </ListDisplay>

                                <slot name="arbitrary-section" />
                            </div>
                        </template>
                    </transition-group>
                </template>
            </div>

            <slot name="modal" />

            <CreateOrderFab v-if="isAdminOrManagerMainView && $acl.can(PL_RESTRICTIONS.CREATE, currentAccount)" />
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-timeline-view {
    &__orders-list {
        padding-top: custom-space(0.5);

        &--space {
            padding-bottom: custom-space(3);
        }
    }

    &__message {
        text-align: center;
        padding: custom-space(3) 0;
    }

    &__section--other > h2 {
        font-size: calc(1.275rem + 0.3vw);
        position: relative;
        text-align: center;
        margin: custom-space(0.5) 0;

        span {
            position: relative;
            background-color: $white;
            padding: 0 custom-space(0.6);
            z-index: 2;
        }

        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            display: block;
            height: 4px;
            background-image: radial-gradient(circle, $gray-500 31%, rgba($white, 0) 3%);
            background-position: center;
            background-size: 10px 2px;
            background-repeat: repeat-x;
            border-radius: 2px;
            z-index: 1;
        }
    }

    .pl-order-filters {
        padding-bottom: custom-space(1);
    }
}
</style>
