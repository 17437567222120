<script>
export default {
    name: 'ThreeDotsContentAdmin',
};
</script>

<script setup>
import { useRoute } from 'vue-router';

import { viewModePropValidator } from '@/utils/propsUtils';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { TIMELINE_VIEW_MODES } from '@/enums/timelineEnums';
import GroupedSettings from '@/components/constructions/grouped-settings';

defineProps({
    viewMode: {
        type: String,
        required: true,
        validator: viewModePropValidator,
    },
});

const route = useRoute();
</script>

<template>
    <GroupedSettings.Root class="pl-three-dots-content-admin">
        <GroupedSettings.List title="View mode">
            <GroupedSettings.ListItem :is-selected="viewMode === TIMELINE_VIEW_MODES.MAIN">
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.INDEX,
                        query: { start_date: route.query.start_date },
                    }"
                >
                    By order
                </router-link>
            </GroupedSettings.ListItem>

            <GroupedSettings.ListItem :is-selected="viewMode === TIMELINE_VIEW_MODES.SECONDARY">
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.SECONDARY_VIEW,
                        query: { start_date: route.query.start_date },
                    }"
                >
                    By item
                </router-link>
            </GroupedSettings.ListItem>
        </GroupedSettings.List>

        <GroupedSettings.List title="Orders">
            <GroupedSettings.ListItem>
                <router-link
                    :to="{
                        name: PL_ROUTE_NAMES.CREATE.INDEX,
                        query: { action: 'new-order' },
                    }"
                >
                    Create new order
                </router-link>
            </GroupedSettings.ListItem>

            <GroupedSettings.ListItem>
                <router-link :to="{ name: PL_ROUTE_NAMES.CREATE_FROM_TEMPLATE }">
                    Create new PAR-guided order
                </router-link>
            </GroupedSettings.ListItem>
        </GroupedSettings.List>
    </GroupedSettings.Root>
</template>

<style lang="scss" scoped>
.pl-three-dots-content-admin {
    width: custom-space(15);
}
</style>
