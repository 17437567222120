<script>
export default {
    name: 'PARTemplateEditStep4View',
};
</script>

<script setup>
import { watch, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PARTemplateModel from '@/models/PARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const templateState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadTemplate = (groupItemsBy) => {
    templateState.isDataLoading = true;

    sendAbortableRequest(
        PARTemplateModel.find(orgId, currentPARTemplate.value.id, {
            group_items_by: groupItemsBy,
        })
    )
        .then((modelData) => (templateState.data = modelData))
        .finally(() => (templateState.isDataLoading = false));
};

const confirmTemplateDraft = () => {
    templateState.isActionProcessing = true;

    templateState.data
        .completeDraft()
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Template has been successfully updated',
            });

            router.push({
                name: SETTINGS_ROUTE_NAMES.PAR.SHOW,
                params: { templateId: props.templateId },
            });

            store.commit('setCurrentPARTemplate', null);
        })
        .finally(() => (templateState.isActionProcessing = false));
};

watch(() => store.state.PLOrderViewSettings.groupItemsBy, loadTemplate, { immediate: true });
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        :is-step-submitting="templateState.isActionProcessing"
    >
        <template #step-content>
            <InPlaceLoader v-if="templateState.isDataLoading" />

            <OrderSummaryCard
                v-else-if="templateState.data !== null"
                :order="templateState.data"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="btn btn-secondary"
                :to="{
                    name: SETTINGS_ROUTE_NAMES.PAR.EDIT.SET_ASSIGNEES,
                    params: { templateId },
                }"
            >
                Set Assignees
            </router-link>
        </template>

        <template #next-step-action>
            <button
                type="button"
                class="btn btn-primary"
                :disabled="templateState.isDataLoading || templateState.isActionProcessing"
                @click="confirmTemplateDraft"
            >
                Confirm
            </button>
        </template>
    </OrderStepTemplate>
</template>
