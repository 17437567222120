<script>
export default {
    name: 'OrderIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 21,
    },
    height: {
        type: Number,
        default: 25,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 21 25"
        :width="width"
        :height="height"
    >
        <path
            d="M18.722.454H2.277C1.274.454.46 1.204.46 2.127v20.317c0 .924.813 1.673 1.816 1.673h16.445c1.003 0 1.816-.75 1.816-1.673V2.128c0-.925-.814-1.674-1.816-1.674Z"
            fill="#DADADA"
        />
        <path
            d="M18.205 4.38H2.795v16.508h15.41V4.38Z"
            fill="#fff"
        />
        <path
            d="M15.211 2.465v1.916c0 .927-.815 1.678-1.821 1.678H7.61c-1.006 0-1.822-.751-1.822-1.678V2.465h9.423Z"
            fill="#CF382F"
        />
        <path
            d="M1.673 22.444V2.127c0-.924.813-1.673 1.815-1.673H2.277C1.274.454.46 1.204.46 2.127v20.317c0 .924.813 1.673 1.816 1.673h1.21c-1.001 0-1.814-.75-1.814-1.673Z"
            fill="#BDBDBE"
        />
        <path
            d="M4.274 4.38H2.795v16.508h1.479V4.38Z"
            fill="#F2F2F2"
        />
        <path
            d="M6.689 3.846V3.09c0-.337.253-.61.566-.61H6.31c-.313 0-.567.273-.567.61v.756c0 1.19.895 2.156 2 2.156h.946c-1.104 0-2-.965-2-2.156Z"
            fill="#CA3C36"
        />
        <path
            d="M21 22.444V2.128c0-1.158-1.022-2.1-2.278-2.1H2.277C1.022.029 0 .97 0 2.129v20.316c0 1.157 1.022 2.099 2.277 2.099h16.446c1.256 0 2.277-.942 2.277-2.099Zm-.924 0c0 .688-.607 1.248-1.353 1.248H2.277c-.746 0-1.353-.56-1.353-1.248V2.127C.924 1.44 1.53.88 2.277.88h16.445c.747 0 1.354.56 1.354 1.248v20.316Z"
            fill="#64393F"
        />
        <path
            d="M18.206 3.955h-2.532v-1.49c0-.235-.207-.426-.462-.426H5.789c-.255 0-.462.191-.462.426v1.49H2.795c-.255 0-.462.19-.462.426v16.507c0 .235.207.425.462.425h15.41c.256 0 .463-.19.463-.425V4.38c0-.235-.207-.426-.462-.426ZM6.25 2.891h8.499v1.49c0 .69-.61 1.253-1.36 1.253H7.61c-.75 0-1.36-.562-1.36-1.253V2.89Zm11.493 17.571H3.257V4.807h2.117c.214.956 1.136 1.678 2.237 1.678h5.78c1.1 0 2.021-.722 2.236-1.678h2.117v15.655Z"
            fill="#64393F"
        />
        <path
            d="M7.605 6.84H4.957c-.256 0-.462.19-.462.425v2.441c0 .235.206.426.462.426h2.648c.255 0 .462-.19.462-.426v-2.44c0-.236-.207-.426-.462-.426Zm-.462 2.44H5.42V7.691h1.724v1.59ZM8.991 9.706c0 .235.207.426.462.426h6.283c.255 0 .462-.19.462-.426 0-.235-.207-.426-.462-.426H9.453c-.255 0-.462.191-.462.426ZM7.605 11.04H4.957c-.256 0-.462.19-.462.426v2.44c0 .235.206.426.462.426h2.648c.255 0 .462-.19.462-.426v-2.44c0-.236-.207-.426-.462-.426Zm-.462 2.44H5.42v-1.589h1.724v1.59ZM15.736 13.48H9.453c-.255 0-.462.191-.462.426s.207.426.462.426h6.283c.255 0 .462-.19.462-.426 0-.235-.207-.425-.462-.425ZM7.605 15.24H4.957c-.256 0-.462.19-.462.426v2.44c0 .236.206.426.462.426h2.648c.255 0 .462-.19.462-.425v-2.441c0-.235-.207-.426-.462-.426Zm-.462 2.44H5.42v-1.588h1.724v1.589ZM15.736 17.68H9.453c-.255 0-.462.191-.462.427 0 .235.207.425.462.425h6.283c.255 0 .462-.19.462-.425 0-.236-.207-.426-.462-.426Z"
            fill="#969AAB"
        />
    </svg>
</template>
