<script>
export default {
    name: 'PARTemplateSettingsForm',
};
</script>

<script setup>
import { computed, onBeforeMount, reactive, ref, watch } from 'vue';
import { minLength, required } from '@vuelidate/validators';
import dayjs from 'dayjs';

import FormManager from '@/utils/form/FormManager';
import { TAG_LIST_SELECT_EVENTS } from '@/enums/componentsEnums';
import {
    CALENDAR_MODES,
    CALENDAR_ANY_DAY_OPTION,
    CALENDAR_DAY_OPTIONS,
    CALENDAR_MODE_OPTIONS,
} from '@/enums/calendarEnums';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import TextInput from '@/components/form-controls/TextInput';
import TagListSelect from '@/components/form-controls/TagListSelect';
import FormUI from '@/components/UI/FormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    days: {
        type: Array,
        default: () => [CALENDAR_ANY_DAY_OPTION],
    },
    dates: {
        type: Array,
        default: null,
    },
    startDate: {
        type: String,
        default: null,
    },
    endDate: {
        type: String,
        default: null,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits(['set-is-submitting-allowed', 'set-has-changes-made', 'submit']);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            name: '',
            days: [CALENDAR_ANY_DAY_OPTION],
            dates: null,
        },
        {
            name: {
                required,
                minLength: minLength(2),
            },
        },
        {
            name: 'Name of Template',
        }
    )
);

const setInitialFormData = () => {
    const { name, days, dates, startDate, endDate } = props;

    form.name = name;
    form.days = days.length === CALENDAR_DAY_OPTIONS.length ? [CALENDAR_ANY_DAY_OPTION] : [...days];

    if (startDate && endDate) {
        form.dates = [startDate, endDate];
        calendarMode.value = CALENDAR_MODES.RANGE;
    } else {
        form.dates = dates?.length ? [...dates] : null;
        calendarMode.value = dates?.length ? CALENDAR_MODES.MULTIPLE : CALENDAR_MODES.DEFAULT;
    }

    initialCalendarMode.value = calendarMode.value;
};

const getTemplateDateSettings = (dates) => {
    if (!dates || dates.length === 0) {
        return { dates: null };
    }

    if (calendarMode.value === CALENDAR_MODES.RANGE) {
        return {
            start_date: dates[0],
            end_date: dates[1],
        };
    }

    return { dates };
};

const submitForm = () => {
    if (form.validate()) {
        const { dates, ...restPayload } = form.getPayload();

        emit('submit', {
            ...restPayload,
            ...getTemplateDateSettings(dates),
            days: highlightedWeekDays.value,
        });
    }
};

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.errors.record(errors);
        }
    },
    { immediate: true }
);

onBeforeMount(setInitialFormData);

/*------------------------------------------------------------------------
                                   Days
------------------------------------------------------------------------*/

const dayOptions = computed(() => {
    const options = [...CALENDAR_DAY_OPTIONS];

    if (form.days.length === 0 || form.days[0] === CALENDAR_ANY_DAY_OPTION) {
        options.unshift({
            value: CALENDAR_ANY_DAY_OPTION,
            text: 'Any day',
            isLocked: true,
        });
    }

    return options;
});

const highlightedWeekDays = computed(() => {
    const { days } = form;

    if (days[0] === CALENDAR_ANY_DAY_OPTION) {
        return CALENDAR_DAY_OPTIONS.map(({ value }) => value);
    }

    return days;
});

const disabledWeekDays = computed(() => {
    if (form.days[0] === CALENDAR_ANY_DAY_OPTION) {
        return [];
    }

    return [0, 1, 2, 3, 4, 5, 6].filter((dayNumber) => !highlightedWeekDays.value.includes(dayNumber));
});

const updateDays = ({ value, type }) => {
    switch (type) {
        case TAG_LIST_SELECT_EVENTS.ADD: {
            if (form.days[0] === CALENDAR_ANY_DAY_OPTION) {
                form.days = [value];
            } else {
                form.days.unshift(value);
            }

            break;
        }

        case TAG_LIST_SELECT_EVENTS.REMOVE: {
            const index = form.days.findIndex((dayValue) => dayValue === value);

            if (form.days.length > 1) {
                form.days.splice(index, 1);
            } else {
                form.days = [CALENDAR_ANY_DAY_OPTION];
            }

            break;
        }
    }
};

watch(
    () => form.days,
    (days) => {
        if (form.dates !== null && days[0] !== CALENDAR_ANY_DAY_OPTION) {
            form.dates = form.dates.filter((date) => days.includes(+dayjs(date).format('d')));
        }
    },
    { deep: true }
);

/*------------------------------------------------------------------------
                                   Dates
------------------------------------------------------------------------*/

const initialCalendarMode = ref(CALENDAR_MODES.DEFAULT);
const calendarMode = ref(CALENDAR_MODES.DEFAULT);

const setCalendarMode = (value) => {
    if (calendarMode.value !== value) {
        calendarMode.value = value;
        form.dates = null;
    }
};

watch(
    () => form.dates,
    (dates, prevDates) => {
        const isModeDefault = calendarMode.value === CALENDAR_MODES.DEFAULT && prevDates === null;
        const isRangeTheSame = calendarMode.value === CALENDAR_MODES.RANGE && dates && dates[0] === dates[1];

        if (isModeDefault || isRangeTheSame) {
            form.dates = [isRangeTheSame ? dates[0] : dates];
            calendarMode.value = CALENDAR_MODES.MULTIPLE;
        }
    }
);

/*------------------------------------------------------------------------
                             Check form state
------------------------------------------------------------------------*/

const hasDaysChanged = () => {
    const { days } = props;

    if (days.length === CALENDAR_DAY_OPTIONS.length) {
        return form.days[0] !== CALENDAR_ANY_DAY_OPTION;
    }

    return form.days.join('') !== days.join('');
};

const hasDatesChanged = () => {
    const { dates, startDate, endDate } = props;

    if (form.dates === null) {
        return dates !== null;
    }

    if (startDate && endDate) {
        return form.dates[0] !== startDate || form.dates[1] !== endDate;
    }

    return form.dates.join('') !== dates?.join('');
};

watch(
    () => [form.name, form.dates, form.days],
    ([name]) => {
        const hasChangesMade =
            props.name !== name ||
            calendarMode.value !== initialCalendarMode.value ||
            hasDaysChanged() ||
            hasDatesChanged();

        emit('set-has-changes-made', hasChangesMade);
        emit('set-is-submitting-allowed', !!name);
    },
    { immediate: true, deep: true }
);
</script>

<template>
    <FormUI
        class="pl-par-template-settings-form"
        data-test-id="par_template_settings_form"
        @submit="submitForm"
    >
        <template #content="{ classNames, qaPrefix }">
            <div :class="classNames.spacerMd">
                <TextInput
                    v-model="form.name"
                    include-asterisk
                    label="Name of Template"
                    name="name"
                    :data-test-id="`${qaPrefix}_name_input`"
                    :has-error="form.errors.has('name')"
                    @input="form.errors.clear('name')"
                    @blur="form.validate('name')"
                />

                <ValidationErrors
                    v-if="form.errors.has('name')"
                    :data-testid="`${qaPrefix}_name_error`"
                    :errors="form.errors.get('name')"
                />
            </div>

            <div :class="classNames.spacerMd">
                <TagListSelect
                    label="Template days"
                    :data-form-control-test-id="`${qaPrefix}_days_select`"
                    :data-form-control-test-id-error="`${qaPrefix}_days_errors`"
                    :tags="form.days"
                    :options="dayOptions"
                    :errors="form.errors.get('days')"
                    :has-error="form.errors.has('days')"
                    @update-tags-list="updateDays"
                />

                <ValidationErrors
                    v-if="form.errors.has('days')"
                    :data-testid="`${qaPrefix}_days_error`"
                    :errors="form.errors.get('days')"
                />
            </div>

            <div class="pl-par-template-settings-form__datepicker">
                <h6>Chosen dates</h6>

                <div class="pl-par-template-settings-form__calendar-mode-options">
                    <button
                        v-for="{ value, text } in CALENDAR_MODE_OPTIONS"
                        :key="`date type: ${value}`"
                        type="button"
                        class="pl-par-template-settings-form__calendar-mode-option"
                        :class="{
                            'pl-par-template-settings-form__calendar-mode-option--active': calendarMode === value,
                        }"
                        @click="setCalendarMode(value)"
                    >
                        {{ text }}
                    </button>
                </div>

                <DatePickerHOC
                    :key="`date picker: ${calendarMode}`"
                    v-model="form.dates"
                    inline
                    :class="{
                        'dp__mode--range': calendarMode === CALENDAR_MODES.RANGE,
                    }"
                    :highlight-week-days="highlightedWeekDays"
                    :disabled-week-days="disabledWeekDays"
                    :multi-dates="calendarMode === CALENDAR_MODES.MULTIPLE"
                    :range="calendarMode === CALENDAR_MODES.RANGE"
                />
            </div>
        </template>
    </FormUI>
</template>

<style lang="scss" scoped>
.pl-par-template-settings-form {
    &__datepicker {
        background-color: $white;
        padding: custom-space(0.5);
        border: 1px solid $form-select-border-color;
        border-radius: $border-radius;

        & > h6 {
            text-align: center;
            margin-bottom: custom-space(0.5);
        }

        :deep(.dp__menu) {
            flex-grow: 1;
        }
    }

    &__calendar-mode-options {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: custom-space(0.25);
        margin-bottom: custom-space(0.5);
    }

    &__calendar-mode-option {
        flex: 1 1 auto;
        background-color: $gray-400;
        padding: custom-space(0.25) custom-space(0.5);
        text-transform: capitalize;
        font-size: $font-size-base * 0.875;
        font-weight: 400;
        color: $black;
        border-radius: custom-space(1.5);

        &--active {
            background-color: $primary;
            color: $white;
        }
    }
}
</style>
