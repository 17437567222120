<script>
export default {
    name: 'LocationEditView',
};
</script>

<script setup>
import { reactive, onBeforeMount, computed, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { LOCATIONS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import LocationForm from '@/components/forms/LocationForm';

const props = defineProps({
    locationId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(LOCATIONS_RESTRICTIONS.UPDATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit locations.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            Location form state
------------------------------------------------------------------------*/

const locationFormState = reactive({
    data: null,
    validationErrors: null,
    isDataLoading: true,
    isSubmitting: false,
});

const loadLocationData = () => {
    sendAbortableRequest(LocationModel.find(orgId, props.locationId))
        .then((locationModelData) => (locationFormState.data = locationModelData))
        .finally(() => (locationFormState.isDataLoading = false));
};

const submitUpdateLocation = (attributes) => {
    locationFormState.isSubmitting = true;

    locationFormState.data
        .update(attributes)
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Location has been successfully updated',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                locationFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (locationFormState.isSubmitting = false));
};

const locationFormProps = computed(() => {
    const { data } = locationFormState;

    if (data === null) {
        return {};
    }

    return {
        name: data.name,
        members: data.accounts,
    };
});

onBeforeMount(loadLocationData);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.LOCATIONS.INDEX">
        <template #title>
            <h1>Edit location</h1>
        </template>

        <Alert
            v-if="locationFormState.data === null && !locationFormState.isDataLoading"
            :type="ALERT_TYPES.FAIL"
        >
            The location could not be loaded for some reason. Please try again later.
        </Alert>

        <LocationForm
            v-else
            :name="locationFormProps.name"
            :members="locationFormProps.members"
            :validation-errors="locationFormState.validationErrors"
            :is-data-loading="locationFormState.isDataLoading"
            :is-submitting="locationFormState.isSubmitting"
            @submit="submitUpdateLocation"
        />
    </TopBarLayout>
</template>
