import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class Report extends Model {
    static find(orgId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/report`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async download(orgId, query = {}) {
        const { data } = await ApiClient.get(`organizations/${orgId}/report/download`, {
            responseType: 'blob',
            params: query,
        });

        return data;
    }
}
