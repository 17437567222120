import { REPORTS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import ReportsIndexView from '@/views/dashboard/reports';

import ProductionReportView from '@/views/dashboard/reports/show/production-report';
import EmployeePerformanceView from '@/views/dashboard/reports/show/employee-performance';
import FulfillmentReportView from '@/views/dashboard/reports/show/fulfillment-report';


export default [
    {
        path: 'reports',
        name: REPORTS_ROUTE_NAMES.INDEX,
        component: ReportsIndexView,
    },

    {
        path: 'reports/production-report',
        name: REPORTS_ROUTE_NAMES.SHOW.PRODUCTION,
        component: ProductionReportView,
    },
    {
        path: 'reports/employee-performance',
        name: REPORTS_ROUTE_NAMES.SHOW.EMPLOYEE_PERFORMANCE,
        component: EmployeePerformanceView,
    },
    {
        path: 'reports/fulfillment-report',
        name: REPORTS_ROUTE_NAMES.SHOW.FULFILLMENT,
        component: FulfillmentReportView,
    },
];
