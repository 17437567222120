import { ref } from 'vue';

export default function useUniqueValueRegister() {
    const _register = ref(new Set());

    return {
        values: _register.value,
        toggle(value) {
            _register.value.has(value) ? _register.value.delete(value) : _register.value.add(value);
        },
        register(value) {
            _register.value.add(value);
        },
        all() {
            return _register.value.values();
        },
        unregister(value) {
            _register.value.delete(value);
        },
        isRegistered(value) {
            return _register.value.has(value);
        },
        isEmpty() {
            return _register.value.size === 0;
        },
        clear() {
            _register.value = new Set();
        },
    };
}
