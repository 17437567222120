<script>
export default {
    name: 'ShareTheWorkTab',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';

import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import DistributionTabUI from './DistributionTabUI';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    itemAssigneeData: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-distribution']);

/*------------------------------------------------------------------------
                          Distribution tab state
------------------------------------------------------------------------*/

const selectedIds = ref([]);

const assigneeOptions = computed(() =>
    props.item.available_assignees.map(({ id, name }) => ({
        value: id.toString(),
        text: name,
    }))
);

watch(
    () => props.itemAssigneeData,
    (assigneeData) => {
        if (assigneeData.distribution_type === DISTRIBUTION_TYPES.SHARE) {
            selectedIds.value = assigneeData.distribution.map((assignee) => assignee.assignee_id.toString());
        }
    },
    { immediate: true }
);

const setDistribution = () => {
    const payload = {
        distributionType: DISTRIBUTION_TYPES.SHARE,
        distribution: selectedIds.value.map((id) => ({ assignee_id: +id })),
    };

    emit('set-distribution', payload);
};
</script>

<template>
    <DistributionTabUI>
        <template #ribbon-text>
            All employees see the entire task. Any of them can report, and they all will be credited for all items
            produced.
        </template>

        <template #tab-content>
            <CheckboxInput
                v-model="selectedIds"
                name="share_the_work"
                :options="assigneeOptions"
            />
        </template>

        <template #footer-content="{ classNames }">
            <div :class="classNames.actions">
                <slot name="cancel-btn" />

                <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="selectedIds.length === 0"
                    @click="setDistribution"
                >
                    Apply
                </button>
            </div>
        </template>
    </DistributionTabUI>
</template>
