<script>
export default {
    name: 'CheckIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 32,
    },
    height: {
        type: Number,
        default: 23,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 32 23"
        :width="width"
        :height="height"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M31.3749 2.39999L11.7333 22.0416L0.625 10.9333L2.13327 9.42506L11.7333 19.0251L29.8666 0.891724L31.3749 2.39999Z"
            fill="white"
        />
    </svg>
</template>
