<template>
    <div class="pl-timeline-dates-carousel">
        <div class="container">
            <Swiper
                centered-slides
                :modules="swiperSettings.modules"
                :slides-per-view="swiperSettings.slidesPerView"
                :initial-slide="modelValue"
                :speed="swiperSettings.speed"
                @slide-change-transition-start="emit('slide-start')"
                @slide-change-transition-end="emit('slide-end')"
                @slide-change="onSlideChanged"
                @swiper="onSwiperInit"
            >
                <SwiperSlide
                    v-for="(date, index) in dates"
                    :key="`date-${index}`"
                >
                    <div class="fw-bold unselectable">
                        <div data-testid="timeline_current_date">
                            {{ dayjs(date).format('MMM D, YYYY') }}
                        </div>

                        <small
                            v-if="isToday(date)"
                            class="d-block text-center text-primary"
                        >
                            Today
                        </small>
                    </div>
                </SwiperSlide>

                <transition
                    appear
                    name="scale"
                    type="transition"
                >
                    <div
                        v-if="(areLoadedPrevPagesAvailable || areUnloadedPrevPagesAvailable) && !isNextPageBeingLoaded"
                        class="pl-timeline-dates-carousel__prev-btn"
                    >
                        <InPlaceLoader v-if="isPrevPageBeingLoaded" />

                        <button
                            v-else
                            type="button"
                            @click="onPrevSlide"
                        >
                            <CarouselArrowIcon />
                        </button>
                    </div>
                </transition>

                <transition
                    appear
                    name="scale"
                    type="transition"
                >
                    <div
                        v-if="(areLoadedNextPagesAvailable || areUnloadedNextPagesAvailable) && !isPrevPageBeingLoaded"
                        class="pl-timeline-dates-carousel__next-btn"
                    >
                        <InPlaceLoader v-if="isNextPageBeingLoaded" />

                        <button
                            v-else
                            type="button"
                            @click="onNextSlide"
                        >
                            <CarouselArrowIcon />
                        </button>
                    </div>
                </transition>
            </Swiper>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DatesCarousel',
};
</script>

<script setup>
import { ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import dayjs from 'dayjs';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import CarouselArrowIcon from '@/components/icons/CarouselArrowIcon';

const props = defineProps({
    modelValue: {
        type: Number,
        required: true,
    },
    dates: {
        type: Array,
        default: () => [],
    },
    areLoadedPrevPagesAvailable: {
        type: Boolean,
        required: true,
    },
    areUnloadedPrevPagesAvailable: {
        type: Boolean,
        required: true,
    },
    isPrevPageBeingLoaded: {
        type: Boolean,
        required: true,
    },
    areLoadedNextPagesAvailable: {
        type: Boolean,
        required: true,
    },
    areUnloadedNextPagesAvailable: {
        type: Boolean,
        required: true,
    },
    isNextPageBeingLoaded: {
        type: Boolean,
        required: true,
    },
});

const emit = defineEmits([
    'slide-start',
    'slide-end',
    'update:modelValue',
    'prev-page-requested',
    'next-page-requested',
]);

const route = useRoute();
const router = useRouter();

/*------------------------------------------------------------------------
                                  Swiper
------------------------------------------------------------------------*/

const swiperInstance = ref(null);

const swiperSettings = {
    modules: [Navigation],
    slidesPerView: 1,
    speed: 350,
};

const onSwiperInit = (swiper) => (swiperInstance.value = swiper);

const onSlideChanged = () => {
    if (swiperInstance.value) {
        const currentIndex = swiperInstance.value.activeIndex;

        router.push({
            query: {
                ...route.query,
                start_date: props.dates[currentIndex],
            },
        });

        emit('update:modelValue', currentIndex);
    }
};

const onNextSlide = () => {
    if (!props.areLoadedNextPagesAvailable && props.areUnloadedNextPagesAvailable) {
        return emit('next-page-requested');
    }

    swiperInstance.value.slideNext();
};

const onPrevSlide = () => {
    if (!props.areLoadedPrevPagesAvailable && props.areUnloadedPrevPagesAvailable) {
        return emit('prev-page-requested');
    }

    swiperInstance.value.slidePrev();
};

const isToday = (date) => dayjs().format('YYYY-MM-DD') === date;

watch(
    () => [props.isPrevPageBeingLoaded, props.isNextPageBeingLoaded],
    ([isPrevPageBeingLoaded, isNextPageBeingLoaded]) => {
        if (!isPrevPageBeingLoaded && !isNextPageBeingLoaded) {
            swiperInstance.value.slideTo(props.modelValue);
        }
    }
);
</script>

<style scoped lang="scss">
.pl-timeline-dates-carousel {
    display: flex;
    align-items: center;
    min-height: custom-space(3.75);
    margin-bottom: custom-space(1.5);

    &__prev-btn,
    &__next-btn {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 10;

        & > button {
            padding: custom-space(0.25) custom-space(0.5);
            margin: custom-space(-0.25) custom-space(-0.5);
        }

        & > .pl-in-place-loader {
            $size: 24px;

            min-width: auto;
            height: $size;
            width: $size;
        }
    }

    &__prev-btn {
        left: 0;
    }

    &__next-btn {
        right: 0;

        svg {
            transform: rotate(-180deg);
        }
    }
}

:deep(.swiper-wrapper) {
    align-items: center;
}

:deep(.swiper-slide) {
    text-align: center;
}
</style>
