<script>
export default {
    name: 'CreateQFOrderStepOneView',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import PrepDateForm from '@/components/forms/PrepDateForm';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const checkOrderStatus = () => {
    if (currentQFOrder.value === null) {
        router.push({ name: QFO_ROUTE_NAMES.INDEX });
    }
};

const cancelOrder = () => {
    currentQFOrder.value.cancel().then(() => {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.SUCCESS,
            message: 'Order has been successfully cancelled',
        });

        router.push({ name: QFO_ROUTE_NAMES.INDEX });

        store.commit('setCurrentQFOrder', null);
    });
};

/*------------------------------------------------------------------------
                         Order settings form state
------------------------------------------------------------------------*/

const orderSettingsFormState = reactive({
    validationErrors: null,
    isSubmittingAllowed: false,
    hasChangesMade: false,
    isSubmitting: false,
});

const setHasChangesMade = (hasChangesMade) => (orderSettingsFormState.hasChangesMade = hasChangesMade);

const setIsSubmittingAllowed = (isEnabled) => (orderSettingsFormState.isSubmittingAllowed = isEnabled);

const submitForm = (payload) => {
    let request = null;

    if (currentQFOrder.value !== null) {
        if (!orderSettingsFormState.hasChangesMade) {
            router.push({ name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS });
        } else {
            orderSettingsFormState.isSubmitting = true;

            request = currentQFOrder.value.update(payload).then((model) => {
                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Order settings has been successfully updated',
                });

                store.commit('setCurrentQFOrder', model);

                router.push({ name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS });
            });
        }
    } else {
        orderSettingsFormState.isSubmitting = true;

        request = QFOrderModel.create(orgId, payload).then((model) => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'New pending order has been successfully created',
            });

            store.commit('setCurrentQFOrder', model);

            router.push({ name: QFO_ROUTE_NAMES.CREATE.ADD_ITEMS });
        });
    }

    if (request !== null) {
        request
            .catch((error) => {
                const { status, data } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                    orderSettingsFormState.validationErrors = data?.errors || data?.data?.errors;
                }
            })
            .finally(() => (orderSettingsFormState.isSubmitting = false));
    }
};

const isSubmittingDisabled = computed(() => {
    const { isSubmittingAllowed, isSubmitting } = orderSettingsFormState;

    return !isSubmittingAllowed || isSubmitting;
});

const submitBtnText = computed(() => {
    if (currentQFOrder.value !== null) {
        return orderSettingsFormState.hasChangesMade ? 'Apply Changes' : 'Select Items';
    }

    return 'Next';
});
</script>

<template>
    <OrderStepTemplate
        :is-step-submitting="orderSettingsFormState.isSubmitting"
        @before-init-cancel-process="checkOrderStatus"
        @cancel-process="cancelOrder"
    >
        <template #step-content>
            <PrepDateForm
                id="pl-create-qf-order-step-1-form"
                :prep-date="currentQFOrder?.prep_date"
                :validation-errors="orderSettingsFormState.validationErrors"
                @set-is-submitting-allowed="setIsSubmittingAllowed"
                @set-has-changes-made="setHasChangesMade"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <button
                type="submit"
                form="pl-create-qf-order-step-1-form"
                class="btn btn-primary"
                :disabled="isSubmittingDisabled"
            >
                {{ submitBtnText }}
            </button>
        </template>
    </OrderStepTemplate>
</template>
