class localStorageInstance {
    _getInstance() {
        return window.localStorage;
    }

    _getPrefixedKey(key) {
        return `local.cache.${key}`;
    }

    get(key, defaultValue = null) {
        const value = this._getInstance().getItem(this._getPrefixedKey(key));

        if (value === null) {
            return defaultValue;
        }

        try {
            return JSON.parse(value);
        } catch (error) {
            return value;
        }
    }

    set(key, value) {
        const formattedValue = typeof value === 'object' ? JSON.stringify(value) : value;

        this._getInstance().setItem(this._getPrefixedKey(key), formattedValue);
    }

    remove(key) {
        this._getInstance().removeItem(this._getPrefixedKey(key));
    }
}

export const LS = new localStorageInstance();
