<script>
export default {
    name: 'OrderDetails',
};
</script>

<script setup>
import { BADGE_TYPES } from '@/enums/componentsEnums';
import Badge from '@/components/Badge';

defineProps({
    order: {
        type: Object,
        required: true,
    },
    includeContentContainer: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <div
        class="pl-order-details"
        :class="{
            'pl-order-details--has-container': includeContentContainer,
        }"
    >
        <div
            class="pl-order-details__content"
            :class="{
                container: includeContentContainer,
            }"
        >
            <div class="pl-order-details__name">
                <OrderIcon />

                <h5>
                    {{ order.name }}
                </h5>
            </div>

            <div
                v-if="order.prep_date || order.destination"
                class="pl-order-details__info"
            >
                <b
                    v-if="order.prep_date"
                    v-date-format="'D MMM, YYYY'"
                    data-testid="summary_header_order_prep_date"
                >
                    {{ order.prep_date }}
                </b>

                <Badge
                    v-if="order.destination"
                    size="sm"
                    :type="BADGE_TYPES.ORDER_CARD"
                >
                    To: {{ order.destination.name }}
                </Badge>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-order-details {
    padding-bottom: custom-space(1);
    border-bottom: 1px solid $gray-100;

    &__content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: custom-space(0.5) custom-space(1);
    }

    &__name {
        display: flex;
        align-items: center;
        gap: custom-space(0.5);

        & > svg {
            flex-shrink: 0;
        }

        & > h5 {
            line-height: 1;
            margin: 0;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: custom-space(0.25);
    }

    &__badges {
        flex-grow: 1;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: custom-space(0.25);
        padding-bottom: custom-space(0.5);

        & > .pl-badge:first-child {
            margin-right: auto;
        }
    }

    :deep(.pl-badge) {
        white-space: initial;
        overflow-wrap: anywhere;
        margin: 0;
    }

    &:not(&--has-container) {
        @include media-breakpoint-down(lg) {
            $space: calc($grid-gutter-width / 2);

            margin-left: -$space;
            margin-right: -$space;
            padding-left: $space;
            padding-right: $space;
        }
    }
}
</style>
