import { computed } from 'vue';
import { useStore } from 'vuex';

/**
 * Map getters into the current component
 *
 * @param getters {Array}
 */
export const mapGetters = (getters) => {
    const store = useStore();

    return Object.keys(store.getters).reduce((acc, name) => {
        if (getters.includes(name)) {
            acc[name] = computed(() => store.getters[name]);
        }

        return acc;
    }, {});
};
