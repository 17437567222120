<script>
export default {
    name: 'DistributionTabUI',
};
</script>

<script setup>
/*------------------------------------------------------------------------
                                Class names
------------------------------------------------------------------------*/

const rootClassName = 'pl-distribution-tab';

const classNames = {
    actions: `${rootClassName}__actions`,
};
</script>

<template>
    <section :class="rootClassName">
        <p :class="`${rootClassName}__ribbon`">
            <slot name="ribbon-text" />
        </p>

        <div :class="`${rootClassName}__tab-content`">
            <slot name="tab-content" />
        </div>

        <footer :class="`${rootClassName}__footer`">
            <slot
                name="footer-content"
                :classNames="classNames"
            />
        </footer>
    </section>
</template>

<style lang="scss">
.pl-distribution-tab {
    &__ribbon {
        text-align: center;
    }

    &__tab-content {
        margin-bottom: custom-space(1.75);

        .pl-checkbox .form-check:not(:last-child) {
            margin-bottom: custom-space(1);
        }
    }

    &__actions {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: custom-space(0.5);

        button.btn {
            flex: 1 1 50%;
        }
    }
}
</style>
