<script>
export default {
    name: 'SelectableItemsAssignee',
};
</script>

<script setup>
import { reactive, watch, computed, onBeforeMount } from 'vue';

import { mapLeafs } from '@/utils/treeUtils';
import { getBatchUnitQtyStep, getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { DISTRIBUTION_MULTIPLE_OPTION, DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import PLOrderModel from '@/models/PLOrder';
import PARTemplateModel from '@/models/PARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Tabs from '@/components/Tabs';
import TreeDisplay from '@/components/TreeDisplay';
import ListDisplay from '@/components/ListDisplay';
import ItemDistributionModal from '@/components/model-specific/prep-lists/item-distribution-modal';
import SelectableItemAssigneeCard from './partials/SelectableItemAssigneeCard';

const props = defineProps({
    organizationId: {
        type: Number,
        required: true,
    },
    orderId: {
        type: [String, Number],
        required: true,
    },
    enableSubmitting: {
        type: Boolean,
        required: true,
    },
    isTemplate: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['set-are-actions-disabled', 'submit-assignees']);

const { sendAbortableRequest } = useAbortableRequest();

/*-----------------------------------------------------------------------------------
                                Selected assignees state
-----------------------------------------------------------------------------------*/

const selectableAssignees = reactive({});

const setInitialAssigneeData = (item) => {
    const {
        id,
        assignee_id: defaultAssigneeId,
        available_assignees: availableAssignees,
        ordered_BY_qty: orderedBYQty,
    } = item;

    const availableAssigneeIds = new Set(availableAssignees.map((assignee) => assignee.id));

    let distributionType = item.distribution_type;
    let distribution = item.distribution;

    if (distribution.length > 0 && distribution[0].assignee_id === null) {
        distributionType = DISTRIBUTION_TYPES.OPEN_ITEMS;
        distribution = [{ qty: orderedBYQty }];
    }

    const isAssigneeUnavailable = distribution.some((assignee) => !availableAssigneeIds.has(assignee.assignee_id));

    if (distributionType !== DISTRIBUTION_TYPES.OPEN_ITEMS && isAssigneeUnavailable) {
        distributionType = null;
        distribution = [];
    }

    if (distributionType === null && availableAssigneeIds.has(defaultAssigneeId)) {
        distributionType = DISTRIBUTION_TYPES.DIRECT;
        distribution = [{ assignee_id: defaultAssigneeId, qty: orderedBYQty }];
    }

    selectableAssignees[id] = {
        item_id: id,
        distribution_type: distributionType,
        is_reassigned: item.is_reassigned,
        distribution,
    };
};

const setInitialSelectableAssignees = (items) => {
    mapLeafs(items, (item) => {
        const { prep_tasks: prepTasks } = item;

        if (prepTasks.length === 0) {
            setInitialAssigneeData(item);
        } else {
            prepTasks.forEach((prepTask) => {
                setInitialAssigneeData(prepTask);

                const { id, units_data: unitsData } = prepTask;
                const { prep_list_ordering_unit: orderingUnit } = unitsData;

                const prepTaskUnitStep = getBatchUnitQtyStep(unitsData, orderingUnit);

                selectableAssignees[id].qty = prepTask.ordered_BY_qty / prepTaskUnitStep;
                selectableAssignees[id].qtyStep = item.ordered_BY_qty / prepTaskUnitStep;
                selectableAssignees[id].unitStep = prepTaskUnitStep;
                selectableAssignees[id].orderingUnitText = orderingUnit?.value || 'batches';
                selectableAssignees[id].unitsData = unitsData;
            });
        }

        return item;
    });
};

/*-----------------------------------------------------------------------------------
                                    Order items state
-----------------------------------------------------------------------------------*/

const orderItemsState = reactive({
    data: null,
    isDataLoading: true,
});

const loadOrderItems = () => {
    orderItemsState.isDataLoading = true;

    sendAbortableRequest(
        (props.isTemplate ? PARTemplateModel : PLOrderModel).getAssignees(props.organizationId, props.orderId, {
            group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY,
        })
    )
        .then((data) => {
            const { items = {} } = data || {};

            orderItemsState.data = items;

            setInitialSelectableAssignees(items);
        })
        .finally(() => (orderItemsState.isDataLoading = false));
};

watch(
    () => orderItemsState.isDataLoading,
    (isDataLoading) => emit('set-are-actions-disabled', isDataLoading),
    { immediate: true }
);

/*-----------------------------------------------------------------------------------
                                        Tabs
-----------------------------------------------------------------------------------*/

const departmentsOptions = computed(() =>
    Object.keys(orderItemsState.data || {}).map((department) => ({ value: department, text: department }))
);

/*-----------------------------------------------------------------------------------
                                Item distribution modal
-----------------------------------------------------------------------------------*/

const itemDistributionState = reactive({
    item: null,
    itemAssigneeData: null,
    isModalOpened: false,
});

const handleOpenItemDistributionModal = (item) => {
    const { id, units_data: unitsData } = item;

    const itemAssigneeData = selectableAssignees[id];

    const getFreshOrderedBatchYieldQty = () => {
        if (!itemAssigneeData.qty) {
            return item.ordered_BY_qty;
        }

        return getBatchYieldQtyAmount(unitsData, unitsData.prep_list_ordering_unit, itemAssigneeData.qty);
    };

    itemDistributionState.isModalOpened = true;
    itemDistributionState.itemAssigneeData = itemAssigneeData;
    itemDistributionState.item = {
        ...item,
        ordered_BY_qty: getFreshOrderedBatchYieldQty(),
    };
};

const handleCloseItemDistributionModal = () => (itemDistributionState.isModalOpened = false);

const handleSetDistribution = (payload) => {
    const { id } = itemDistributionState.item;

    selectableAssignees[id].distribution_type = payload.distributionType;
    selectableAssignees[id].distribution = payload.distribution;

    handleCloseItemDistributionModal();
};

/*-----------------------------------------------------------------------------------
                                        Assignee
-----------------------------------------------------------------------------------*/

const handleSetAssignee = ({ value, item }) => {
    const { id } = item;
    const { ordered_BY_qty: orderedBYQty } = item;

    if (value === DISTRIBUTION_MULTIPLE_OPTION) {
        handleOpenItemDistributionModal(item);
    } else {
        if (typeof value === 'number') {
            selectableAssignees[id].distribution_type = DISTRIBUTION_TYPES.DIRECT;
            selectableAssignees[id].distribution = [{ assignee_id: value, qty: orderedBYQty }];
        } else {
            selectableAssignees[id].distribution_type = value;
            selectableAssignees[id].distribution = [{ qty: orderedBYQty }];
        }
    }
};

/*-----------------------------------------------------------------------------------
                                        Qty
-----------------------------------------------------------------------------------*/

const handleSetQty = ({ value, prepTask }) => {
    const assigneeData = selectableAssignees[prepTask.id];

    assigneeData.qty = value;
    assigneeData.distribution_type = null;
    assigneeData.distribution = [];
};

/*-----------------------------------------------------------------------------------
                                        Submit
-----------------------------------------------------------------------------------*/

const getPayload = () => {
    const payload = {};

    for (const assigneeKey in selectableAssignees) {
        const assigneeData = selectableAssignees[assigneeKey];

        if (assigneeData.is_reassigned) {
            continue;
        }

        const { qty } = assigneeData;

        payload[assigneeKey] = {
            item_id: assigneeData.item_id,
            distribution_type: qty === 0 ? null : assigneeData.distribution_type || DISTRIBUTION_TYPES.OPEN_ITEMS,
            distribution: qty === 0 ? [] : assigneeData.distribution,
        };

        if (qty !== undefined) {
            const { unitsData } = assigneeData;

            payload[assigneeKey].qty = getBatchYieldQtyAmount(unitsData, unitsData.prep_list_ordering_unit, qty);
        }
    }

    return payload;
};

watch(
    () => props.enableSubmitting,
    (enableSubmitting) => {
        if (enableSubmitting && !orderItemsState.isDataLoading) {
            emit('submit-assignees', getPayload());
        }
    }
);

/*-----------------------------------------------------------------------------------
                                    Load necessary data
-----------------------------------------------------------------------------------*/

onBeforeMount(loadOrderItems);
</script>

<template>
    <InPlaceLoader v-if="orderItemsState.isDataLoading" />

    <Tabs
        v-else
        include-content-container
        enable-slider-mode
        :tab-options="departmentsOptions"
    >
        <template #content="{ activeTab }">
            <TreeDisplay :data="orderItemsState.data[activeTab]">
                <template #leaf="{ leaf }">
                    <ListDisplay :items="leaf">
                        <template #item="{ item }">
                            <SelectableItemAssigneeCard
                                :item="item"
                                :selectable-assignees="selectableAssignees"
                                @set-assignee="handleSetAssignee"
                                @set-qty="handleSetQty"
                            />
                        </template>
                    </ListDisplay>
                </template>
            </TreeDisplay>
        </template>
    </Tabs>

    <ItemDistributionModal
        v-if="itemDistributionState.isModalOpened"
        :item="itemDistributionState.item"
        :item-assignee-data="itemDistributionState.itemAssigneeData"
        @close-modal="handleCloseItemDistributionModal"
        @set-distribution="handleSetDistribution"
    />
</template>
