import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import PARTemplatesIndexView from '@/views/dashboard/settings/PAR/index';
import PARTemplateShowView from '@/views/dashboard/settings/PAR/show';

import PARTemplateCreateView from '@/views/dashboard/settings/PAR/create';
import PARTemplateCreateStep1View from '@/views/dashboard/settings/PAR/create/step-1';
import PARTemplateCreateStep2View from '@/views/dashboard/settings/PAR/create/step-2';
import PARTemplateCreateStep3View from '@/views/dashboard/settings/PAR/create/step-3';
import PARTemplateCreateStep4View from '@/views/dashboard/settings/PAR/create/step-4';

import PARTemplateEditView from '@/views/dashboard/settings/PAR/edit';
import PARTemplateEditStep1View from '@/views/dashboard/settings/PAR/edit/step-1';
import PARTemplateEditStep2View from '@/views/dashboard/settings/PAR/edit/step-2';
import PARTemplateEditStep3View from '@/views/dashboard/settings/PAR/edit/step-3';
import PARTemplateEditStep4View from '@/views/dashboard/settings/PAR/edit/step-4';

export default [
    {
        path: 'settings/par-templates',
        name: SETTINGS_ROUTE_NAMES.PAR.INDEX,
        component: PARTemplatesIndexView,
    },

    {
        path: 'settings/par-templates/:templateId',
        name: SETTINGS_ROUTE_NAMES.PAR.SHOW,
        component: PARTemplateShowView,
        props: true,
    },

    {
        path: 'settings/par-templates/create',
        component: PARTemplateCreateView,
        children: [
            {
                path: '',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.INDEX,
                component: PARTemplateCreateStep1View,
            },
            {
                path: 'add-items',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.ADD_ITEMS,
                component: PARTemplateCreateStep2View,
            },
            {
                path: 'set-assignees',
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.SET_ASSIGNEES,
                component: PARTemplateCreateStep3View,
            },
            {
                name: SETTINGS_ROUTE_NAMES.PAR.CREATE.REVIEW_AND_CONFIRM,
                path: 'review-and-confirm',
                component: PARTemplateCreateStep4View,
                meta: { parentProps: { enableOrderGroupItems: true } },
            },
        ],
    },

    {
        path: 'settings/par-templates/:templateId/edit',
        component: PARTemplateEditView,
        props: true,
        children: [
            {
                path: '',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.INDEX,
                component: PARTemplateEditStep1View,
                props: true,
            },
            {
                path: 'add-items',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.ADD_ITEMS,
                component: PARTemplateEditStep2View,
                props: true,
            },
            {
                path: 'set-assignees',
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.SET_ASSIGNEES,
                component: PARTemplateEditStep3View,
                props: true,
            },
            {
                name: SETTINGS_ROUTE_NAMES.PAR.EDIT.REVIEW_AND_CONFIRM,
                path: 'review-and-confirm',
                component: PARTemplateEditStep4View,
                props: true,
                meta: { parentProps: { enableOrderGroupItems: true } },
            },
        ],
    },
];
