<script>
export default {
    name: 'SelectableItemWrapper',
};
</script>

<script setup>
import { computed } from 'vue';

import { getQFOrderItemBatchUnitsDisplay } from '@/utils/orderItemUtils';
import ItemCard from '@/components/model-specific/item/ItemCard';
import QtyInput from '@/components/form-controls/QtyInput';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    settings: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['on-qty-changed']);

/*-----------------------------------------------------------------------------------
                                        item
-----------------------------------------------------------------------------------*/

const batchUnitsDisplay = computed(() => {
    const { item } = props;

    return getQFOrderItemBatchUnitsDisplay(item.units_data, item.qty, true);
});
</script>

<template>
    <div
        class="pl-item-wrapper"
        :class="{
            'pl-item-wrapper--added': item.is_overlaid,
        }"
        :data-prototype-id="item.prototype_id"
    >
        <ItemCard
            include-category-name
            :item="item"
        >
            <template #info>
                <span class="pl-items-qfo-item-info">
                    {{ batchUnitsDisplay }}
                </span>
            </template>

            <template #actions>
                <div class="text-center">
                    <QtyInput
                        class="mb-2"
                        custom-on-success-handling
                        ask-for-saving-changes-on-blur
                        is-increment-mode
                        :min="0"
                        :max="9999"
                        :model-value="item.qty"
                        :loading="settings.isQtyInputLoading"
                        :disabled="settings.isQtyInputLoading"
                        @update:modelValue="(...args) => emit('on-qty-changed', ...args)"
                    />

                    <small class="d-block">{{ item.qty_label }}</small>
                </div>
            </template>

            <template
                v-if="settings.isItemBeingSaved || settings.hasItemBeenJustSaved"
                #footer
            >
                <transition
                    appear
                    name="scale"
                    type="transition"
                    mode="out-in"
                >
                    <span
                        v-if="settings.isItemBeingSaved"
                        :key="`saving-caption-${item.prototype_id}`"
                    >
                        Saving...
                    </span>

                    <span
                        v-else-if="settings.hasItemBeenJustSaved"
                        :key="`saved-caption-${item.prototype_id}`"
                    >
                        Saved
                    </span>
                </transition>
            </template>
        </ItemCard>
    </div>
</template>

<style scoped lang="scss">
.pl-items-qfo-item-info {
    font-size: 0.9rem;
    color: $gray-500;
}

:deep(.pl-item__name) {
    margin-top: custom-space(0.6);
    margin-bottom: custom-space(0.4);
}

.pl-item-wrapper {
    position: relative;
    background-color: $white;
    margin-bottom: custom-space(0.25);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    border: 1px solid $gray-200;
    @include pl-transition((background-color, border-color));

    &--added {
        background-color: $yellow-light;

        .pl-items-qfo-item-info {
            color: $gray-700;
        }
    }

    &--invalid {
        border-color: rgba($danger, 0.2);
        background-color: rgba($danger, 0.1);
    }

    &--highlighted {
        border-color: $yellow;
        background-color: darken($yellow, 5%);
    }
}

:deep(.pl-item-card__footer) span {
    font-weight: $font-weight-normal;
    font-size: $font-size-base * 0.85;
}
</style>
