import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class PARTemplateSelectableItem extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/par-templates/selectable-items`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async increment(orgId, templateId, prototypeId, attributes = { times: 1 }, query = {}) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/par-templates/${templateId}/selectable-items/${prototypeId}/increment`,
            attributes,
            { params: query }
        );

        return PARTemplateSelectableItem.make(data);
    }

    static async decrement(orgId, templateId, prototypeId, attributes = { times: 1 }, query = {}) {
        const { data } = await ApiClient.delete(
            `organizations/${orgId}/par-templates/${templateId}/selectable-items/${prototypeId}/decrement`,
            attributes,
            { params: query }
        );

        return PARTemplateSelectableItem.make(data);
    }
}
