import { cutFloatNumber } from '@/utils/numberUtils';
import { getBatchUnitQtyAmount, getBatchUnitQtyDisplay, getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';

/*------------------------------------------------------------------------
                           Quick Fill order item
------------------------------------------------------------------------*/

export const getQFOrderItemOrderedQtyDisplay = (orderItem, includeBatchesDisplay = false) => {
    const { units_data: unitsData } = orderItem;

    const orderingUnitQtyDisplay = getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.quick_fill_ordering_unit,
        orderItem.ordered_BY_qty
    );

    if (includeBatchesDisplay && unitsData.quick_fill_ordering_unit !== null) {
        const batchesUnitQtyDisplay = getBatchUnitQtyDisplay(
            unitsData,
            unitsData.batch_yield_unit,
            null,
            orderItem.ordered_BY_qty
        );

        return `${batchesUnitQtyDisplay} / ${orderingUnitQtyDisplay}`;
    }

    return orderingUnitQtyDisplay;
};

export const getQFOrderItemBatchUnitsDisplay = (unitsData, qty, includeOrderingUnit = false) => {
    const {
        batch_yield_unit: batchYieldUnit,
        batch_size_unit: batchSizeUnit,
        quick_fill_ordering_unit: quickFillOrderingUnit,
    } = unitsData;

    const batchYieldQtyAmount = getBatchYieldQtyAmount(unitsData, quickFillOrderingUnit, qty);
    const batchesQtyAmount = getBatchUnitQtyAmount(unitsData, quickFillOrderingUnit, null, qty, true);
    const batchSizeQtyAmount = getBatchUnitQtyAmount(
        unitsData,
        quickFillOrderingUnit,
        unitsData.batch_size_unit,
        qty,
        true
    );

    const unitsConfig = [{ qty: batchesQtyAmount, unit: null }];

    if (batchSizeUnit && batchYieldUnit.id !== batchSizeUnit.id) {
        unitsConfig.push({ qty: batchSizeQtyAmount, unit: batchSizeUnit });
    }

    unitsConfig.push({ qty: batchYieldQtyAmount, unit: batchYieldUnit });

    const unitsDisplay = new Set();

    unitsConfig.forEach(({ qty, unit }) => {
        if (includeOrderingUnit || quickFillOrderingUnit?.id !== unit?.id) {
            unitsDisplay.add(`${qty} ${unit?.value ?? 'batches'}`);
        }
    });

    return Array.from(unitsDisplay).join(' / ');
};

/*------------------------------------------------------------------------
                           Prep List order item
------------------------------------------------------------------------*/

export const getPLOrderItemOrderedQtyDisplay = (orderItem, includeBatchesDisplay = false, includeOnHandQty = false) => {
    const { units_data: unitsData } = orderItem;

    const onHandQty = includeOnHandQty ? orderItem.on_hand_qty ?? 0 : 0;

    const orderingUnitQtyDisplay = getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.prep_list_ordering_unit,
        orderItem.ordered_BY_qty,
        onHandQty
    );

    if (includeBatchesDisplay && unitsData.prep_list_ordering_unit !== null) {
        const batchesUnitQtyDisplay = getBatchUnitQtyDisplay(
            unitsData,
            unitsData.batch_yield_unit,
            null,
            orderItem.ordered_BY_qty,
            onHandQty
        );

        return `${batchesUnitQtyDisplay} / ${orderingUnitQtyDisplay}`;
    }

    return orderingUnitQtyDisplay;
};

export const getPLOrderItemProducedQtyDisplay = (orderItem) => {
    const { units_data: unitsData } = orderItem;

    return getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.prep_list_ordering_unit,
        orderItem.produced_BY_qty
    );
};

export const getPLOrderItemProducedPercentage = (orderItem, includeSign = false) => {
    const percentage = cutFloatNumber((orderItem.produced_BY_qty / orderItem.ordered_BY_qty) * 100);

    if (includeSign) {
        return `${percentage}%`;
    }

    return percentage;
};
