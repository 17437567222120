<script>
export default {
    name: 'ProgressSteps',
};
</script>

<script setup>
defineProps({
    activeStep: {
        type: Number,
        required: true,
        validator: (value) => value > 0,
    },
    steps: {
        type: Number,
        default: 3,
        validator: (value) => value > 0,
    },
});
</script>

<template>
    <div class="pl-progress-steps">
        <div
            v-for="step in steps"
            :key="`progress-step-${step}`"
            class="pl-progress-steps__step"
            :class="{
                'pl-progress-steps__step--active': activeStep === step,
            }"
        />
    </div>
</template>

<style lang="scss" scoped>
.pl-progress-steps {
    display: inline-flex;
    align-items: center;
    gap: custom-space(0.5);

    &__step {
        height: 4px;
        width: 100px;
        background: $gray-200;
        border-radius: $border-radius;

        &--active {
            background: $progress-bar-bg;
        }
    }
}
</style>
