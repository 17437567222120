import { ORDER_STATUSES } from '@/enums/orderEnums';

/*------------------------------------------------------------------------
                                 View mode
------------------------------------------------------------------------*/

export const TIMELINE_VIEW_MODES = {
    MAIN: 'main',
    SECONDARY: 'secondary',
    AS_COOK: 'as-cook',
};

/*------------------------------------------------------------------------
                             Filter by status
------------------------------------------------------------------------*/

export const FILTER_ORDERS_BY_STATUS_OPTIONS = [
    { value: ORDER_STATUSES.IN_PROGRESS, text: 'In progress' },
    { value: ORDER_STATUSES.COMPLETED, text: 'Completed' },
];

/*------------------------------------------------------------------------
                        Quick fill timeline filters
------------------------------------------------------------------------*/

export const QF_FILTER_ORDERS_BY = {
    ALL: 'quick_fill_all',
    CREATED_BY_ME: 'quick_fill_created_by_account',
    LOCATION: 'quick_fill_outgoing',
    DESTINATION: 'quick_fill_incoming',
};

export const QF_DEFAULT_FILTER_ORDERS_BY = QF_FILTER_ORDERS_BY.ALL;

/*------------------------------------------------------------------------
                                 Cook tabs
------------------------------------------------------------------------*/

export const TIMELINE_TABS = {
    ASSIGNED: 'assigned',
    OPEN_ITEMS: 'open_items',
};

export const TIMELINE_TABS_OPTIONS = [
    { value: TIMELINE_TABS.ASSIGNED, text: 'Assigned' },
    { value: TIMELINE_TABS.OPEN_ITEMS, text: 'Open items' },
];

/*------------------------------------------------------------------------
                           Cook arbitrary report
------------------------------------------------------------------------*/

export const TIMELINE_ARBITRARY_REPORT_TYPES = {
    ALL: 'all',
    ITEMS: 'items',
    PREP_TASKS: 'prep_tasks',
    ACTIONS: 'prep_actions',
};

export const TIMELINE_ARBITRARY_REPORT_TYPES_OPTIONS = {
    [TIMELINE_ARBITRARY_REPORT_TYPES.ALL]: 'all',
    [TIMELINE_ARBITRARY_REPORT_TYPES.ITEMS]: 'item',
    [TIMELINE_ARBITRARY_REPORT_TYPES.PREP_TASKS]: 'prep task',
    [TIMELINE_ARBITRARY_REPORT_TYPES.ACTIONS]: 'action',
};
