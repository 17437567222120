import dayjs from 'dayjs';

export default {
    beforeMount(el, { value }) {
        el.textContent = dayjs(el.textContent).format(value);
    },

    updated(el, { value }) {
        el.textContent = dayjs(el.textContent).format(value);
    },
};
