<script>
export default {
    name: 'DatePickerHOC',
};
</script>

<script setup>
import dayjs from 'dayjs';
import Datepicker from '@vuepic/vue-datepicker';

import CalendarIcon from '@/components/icons/CalendarIcon';
</script>

<template>
    <Datepicker
        no-swipe
        no-today
        auto-apply
        model-type="format"
        format="yyyy-MM-dd"
        month-name-format="long"
        teleport=".pl-app"
        :week-start="0"
        :enable-time-picker="false"
        :month-change-on-scroll="false"
        :day-names="['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT']"
    >
        <template #day="{ day, date }">
            <div :data-qa-calendar-day="dayjs(date).format('YYYY-MM-DD')">
                {{ day }}
            </div>
        </template>

        <template #trigger>
            <slot name="invoker">
                <CalendarIcon />
            </slot>
        </template>
    </Datepicker>
</template>
