<script>
export default {
    name: 'MyAccountsIndexView',
};
</script>

<script setup>
import { computed, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { PL_ROUTE_NAMES, SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import UserAccountModel from '@/models/UserAccount';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Badge from '@/components/Badge';
import ListDisplay from '@/components/ListDisplay';
import Tabs from '@/components/Tabs';
import DeletionModal from '@/components/DeletionModal';

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                               Accounts state
------------------------------------------------------------------------*/

const accountsState = reactive({
    isDataLoading: true,
    isActionProcessing: false,
});

const loadAccounts = () =>
    sendAbortableRequest(UserAccountModel.all())
        .then((models) => {
            if (models !== null) {
                store.commit('setAccounts', models);
            }
        })
        .finally(() => (accountsState.isDataLoading = false));

const enterCompany = (account) => {
    store.commit('auth/setCurrentAccount', account);
    store.commit('itemBatchMeta/setMeta', null);
    store.commit('SVE/clearAllBags');

    router.push({ name: PL_ROUTE_NAMES.INDEX });
};

const acceptInvitation = (account) => {
    accountsState.isActionProcessing = true;

    account
        .join()
        .then((model) => {
            enterCompany(model);

            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: "Congratulations! You've successfully accepted the invitation.",
            });
        })
        .finally(() => (accountsState.isActionProcessing = false));
};

const initCreateNewCompany = () => {
    store.commit('auth/setCurrentAccount', null);

    router.push({ name: 'registration.step-1' });
};

const getSortedAccountList = (list) =>
    [...list].sort((a, b) => {
        if (currentAccount.id === a.id) {
            return -1;
        }

        if (currentAccount.id === b.id) {
            return 1;
        }

        return 0;
    });

const accountsLists = computed(() => ({
    joined: getSortedAccountList(store.getters.joinedAccounts),
    invitations: store.getters.invitedAccounts,
}));

/*------------------------------------------------------------------------
                                   Tabs
------------------------------------------------------------------------*/

const tabOptions = computed(() =>
    Object.entries(accountsLists.value).map(([key, values]) => ({
        value: key,
        text: `${key} (${values.length})`,
    }))
);

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const { isDeletionModalOpened, isDeletionPending, openDeletionModal, closeDeletionModal, deletionCallback } =
    useDeletionModal();

const submitDeleteAccounts = () => {
    deletionCallback(
        () =>
            UserAccountModel.deleteAccounts().then(() => {
                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Your account has been successfully deleted!',
                });

                setTimeout(() => {
                    store.commit('auth/setCurrentAccount', null);

                    window.location.href = '/';
                }, 1000);
            }),
        false
    );
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(loadAccounts);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>My accounts</h1>
        </template>

        <OverlayLoader v-if="accountsState.isActionProcessing" />

        <InPlaceLoader v-if="accountsState.isDataLoading" />

        <Tabs
            v-else
            :tab-options="tabOptions"
        >
            <template #content="{ activeTab }">
                <ListDisplay
                    v-if="accountsLists[activeTab].length > 0"
                    has-borders
                    :data-testid="`${activeTab}_accounts_list`"
                    :items="accountsLists[activeTab]"
                >
                    <template #item="{ item: account }">
                        <div class="pl-account-card">
                            <h6>
                                {{ account.organization.name }}
                            </h6>

                            <div class="pl-account-card__actions">
                                <template v-if="activeTab === tabOptions[0].value">
                                    <Badge
                                        v-if="currentAccount.id === account.id"
                                        size="sm"
                                        data-testid="current_organization_name"
                                    >
                                        Current
                                    </Badge>

                                    <button
                                        v-else
                                        type="button"
                                        class="btn btn-primary btn-sm"
                                        data-testid="organization_name_switch_btn"
                                        @click="enterCompany(account)"
                                    >
                                        Switch
                                    </button>
                                </template>

                                <button
                                    v-else
                                    type="button"
                                    class="btn btn-primary btn-sm"
                                    data-testid="organization_name_accept_btn"
                                    @click="acceptInvitation(account)"
                                >
                                    Accept
                                </button>
                            </div>
                        </div>
                    </template>
                </ListDisplay>

                <p
                    v-else
                    :data-testid="`${activeTab}_empty_list_message`"
                >
                    The list is empty.
                </p>

                <div
                    class="pl-accounts__actions"
                    style="transition-delay: 50ms"
                >
                    <button
                        type="button"
                        @click="openDeletionModal"
                    >
                        Delete Account
                    </button>

                    <button
                        type="button"
                        class="btn btn-primary"
                        @click="initCreateNewCompany"
                    >
                        Create new company
                    </button>
                </div>
            </template>
        </Tabs>

        <DeletionModal
            v-if="isDeletionModalOpened"
            qa-prefix="delete_account_modal"
            :is-pending="isDeletionPending"
            @close="closeDeletionModal"
            @submit-delete="submitDeleteAccounts"
        >
            <template #title>
                Delete your Prep List account?
            </template>

            <div class="pl-delete-account-modal-content">
                <span>Organization(s) you joined:</span>

                <span
                    v-for="account in store.state.accounts"
                    :key="`account: ${account.id}`"
                >
                    {{ account.organization.name }}
                </span>
            </div>

            <b>Are you sure you want to delete your account?</b>
        </DeletionModal>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-tabs__content) > p {
    text-align: center;
}

.pl-account-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: custom-space(1);

    & > h6 {
        font-weight: $font-weight-normal;
        margin: 0;
    }

    &__actions {
        flex-shrink: 0;
    }
}

.pl-accounts__actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: custom-space(0.5);
    margin-top: auto;
    padding-top: custom-space(2);

    & > button:first-child {
        color: $primary;
        font-weight: $font-weight-light;
    }

    & > .btn-primary {
        @include media-breakpoint-down(md) {
            width: 100%;
        }
    }
}

.pl-delete-account-modal-content {
    display: flex;
    flex-direction: column;
    margin-bottom: custom-space(1);
}
</style>
