<template>
    <DepartmentForm
        v-if="targetDepartment"
        :name="targetDepartment.name"
        :members="targetDepartment.members"
        :accounts="employees"
        @submit="cacheData"
    />
    <Alert
        v-else
        :type="ALERT_TYPES.FAIL"
    >
        Unable to load department.
    </Alert>
</template>

<script>
export default {
    name: 'RegistrationStep3EditDepartment',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';

import { LS } from '@/instances/localStorage';
import Alert from '@/components/Alert';
import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DepartmentForm from '@/components/forms/DepartmentForm';

const store = useStore();
const route = useRoute();
const router = useRouter();

const index = route.params.index;

const departments = LS.get('onboarding_departments', []);
const employees = LS.get('onboarding_employees', []);

const targetDepartment = departments[index];

const cacheData = (payload) => {
    const apiErrorsBag = store.getters['SVE/getErrorsBag']('registration');

    departments.splice(index, 1, payload);
    LS.set('onboarding_departments', departments);

    if (apiErrorsBag?.has(`departments.${index}.name`) && targetDepartment.name !== payload.name) {
        apiErrorsBag.clear(`departments.${index}.name`);
    }

    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.SUCCESS,
        message: 'Department updated',
    });
    router.back();
};
</script>

<style lang="scss"></style>
