import ApiClient from '@/utils/ApiClient';
import Model from './Model';
import QFOrder from './QFOrder';

export default class QFSelectableItem extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/quick-fill-orders/selectable-items`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async increment(orgId, orderId, prototypeId, attributes = { times: 1 }, query = {}) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/increment`,
            attributes,
            { params: query }
        );

        return QFSelectableItem.make(data);
    }

    static async decrement(orgId, orderId, prototypeId, attributes = { times: 1 }, query = {}) {
        const { data } = await ApiClient.delete(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/decrement`,
            attributes,
            { params: query }
        );

        return QFSelectableItem.make(data);
    }

    static async fulfill(orgId, orderId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/fulfill`,
            attributes
        );

        return QFOrder.make(data);
    }

    static async confirmDelivery(orgId, orderId, prototypeId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/quick-fill-orders/${orderId}/selectable-items/${prototypeId}/confirm`,
            attributes
        );

        return QFOrder.make(data);
    }
}
