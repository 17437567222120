<script>
export default {
    name: 'SearchIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 16,
    },
    height: {
        type: Number,
        default: 16,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 16"
        :width="width"
        :height="height"
    >
        <path
            d="M11.742 10.344C12.7103 9.02267 13.144 7.38447 12.9563 5.75713C12.7687 4.12979 11.9735 2.63332 10.7298 1.56711C9.48616 0.500899 7.88579 -0.056418 6.24888 0.00665514C4.61197 0.0697283 3.05923 0.748541 1.90131 1.90729C0.743395 3.06603 0.0656939 4.61926 0.00379204 6.25621C-0.0581098 7.89317 0.500353 9.49314 1.56745 10.736C2.63455 11.9789 4.13159 12.773 5.75906 12.9596C7.38654 13.1461 9.02442 12.7112 10.345 11.742H10.344C10.374 11.782 10.406 11.82 10.442 11.857L14.292 15.707C14.4796 15.8946 14.7339 16.0001 14.9992 16.0002C15.2645 16.0003 15.5189 15.895 15.7065 15.7075C15.8942 15.52 15.9997 15.2656 15.9997 15.0003C15.9998 14.7351 15.8946 14.4806 15.707 14.293L11.857 10.443C11.8213 10.4068 11.7828 10.3734 11.742 10.343V10.344ZM12 6.49998C12 7.22225 11.8578 7.93745 11.5814 8.60474C11.305 9.27203 10.8999 9.87834 10.3891 10.3891C9.87841 10.8998 9.27209 11.3049 8.6048 11.5813C7.93751 11.8577 7.22231 12 6.50004 12C5.77777 12 5.06258 11.8577 4.39528 11.5813C3.72799 11.3049 3.12168 10.8998 2.61096 10.3891C2.10023 9.87834 1.69511 9.27203 1.41871 8.60474C1.14231 7.93745 1.00004 7.22225 1.00004 6.49998C1.00004 5.04129 1.57951 3.64234 2.61096 2.61089C3.64241 1.57944 5.04135 0.999979 6.50004 0.999979C7.95873 0.999979 9.35768 1.57944 10.3891 2.61089C11.4206 3.64234 12 5.04129 12 6.49998Z"
            fill="#fff"
        />
    </svg>
</template>