import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import isToday from 'dayjs/plugin/isToday';
import isTomorrow from 'dayjs/plugin/isTomorrow';
import isYesterday from 'dayjs/plugin/isYesterday';

dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isYesterday);

const getRelativeTime = (dateString, { withTime }) => {
    const dateObj = dayjs(dateString);

    switch (true) {
        case dateObj.isTomorrow():
            return withTime ? `tomorrow at ${dateObj.format('HH:MM')}` : 'tomorrow';
        case dateObj.isToday():
            return withTime ? `today at ${dateObj.format('HH:MM')}` : 'today';
        case dateObj.isYesterday():
            return withTime ? `yesterday at ${dateObj.format('HH:MM')}` : 'yesterday';
        default:
            return dateObj.fromNow();
    }
};

export default {
    beforeMount(el, options) {
        if (!el.initialTextContent) {
            el.initialTextContent = el.textContent;
        }

        el.textContent = getRelativeTime(el.initialTextContent, options.modifiers);
    },

    updated(el, options) {
        el.textContent = getRelativeTime(el.initialTextContent, options.modifiers);
    },
};
