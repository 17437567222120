<script>
export default {
    name: 'OpenGloveIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 15,
    },
    height: {
        type: Number,
        default: 16,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 15 16"
        :width="width"
        :height="height"
    >
        <path
            fill="#F8C34F"
            d="M4.812 11.758v2.137h9.41V4.538S13.852.938 9.554 1c-4.298.062-4.742 3.537-4.742 3.537v3.02l-1.778-1.4s-.964-.662-1.705.37c-.74 1.03 0 1.841 0 1.841l3.483 3.389Z"
        />
        <path
            fill="#64393F"
            fill-rule="evenodd"
            d="M13.257 2.915c-.575-.814-1.65-1.649-3.699-1.62-2.054.03-3.153.868-3.748 1.672a4.044 4.044 0 0 0-.68 1.483 2.205 2.205 0 0 0-.022.11v3.608L2.86 6.398a1.084 1.084 0 0 0-.433-.149c-.234-.026-.552.026-.857.45-.313.436-.304.803-.233 1.057a1.207 1.207 0 0 0 .209.411l3.562 3.467V13.6h8.818V4.555l-.002-.016a3.622 3.622 0 0 0-.11-.477 4.021 4.021 0 0 0-.557-1.147Zm.965 1.623.295-.03.002.015v9.667H4.514v-2.308l-3.4-3.308-.005-.006.22-.199-.22.198h-.001l-.001-.002-.004-.004a.775.775 0 0 1-.04-.048 1.794 1.794 0 0 1-.299-.599c-.113-.409-.104-.963.323-1.558.437-.608.97-.74 1.404-.693a1.679 1.679 0 0 1 .703.249l.004.003h.002v.001l-.168.243.17-.243.007.006 1.305 1.027V4.52l.003-.019.294.038a21.513 21.513 0 0 1-.294-.038v-.005a1.076 1.076 0 0 1 .008-.045 4.087 4.087 0 0 1 .142-.549c.115-.349.318-.814.665-1.283.702-.95 1.973-1.88 4.217-1.912 2.249-.032 3.507.9 4.192 1.87a4.61 4.61 0 0 1 .747 1.753c.01.053.017.096.021.127l.005.036.001.01v.004l.001.001-.295.03Zm0 10.757h-9.41v-.59h9.41v.59Z"
            clip-rule="evenodd"
        />
    </svg>
</template>
