<script>
export default {
    name: 'PARTemplatesIndexView',
};
</script>

<script setup>
import { computed, inject, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { isOrderEditing } from '@/utils/orderUtils';
import { PAR_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PARTemplateModel from '@/models/PARTemplate';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import Fab from '@/components/Fab';
import ListDisplay from '@/components/ListDisplay';
import UnfinishedOrderRibbon from '@/components/model-specific/orders/UnfinishedOrderRibbon';
import PARTemplateCard from './partials/PARTemplateCard';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PAR_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to PAR templates.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                            PAR templates state
------------------------------------------------------------------------*/

const templatesState = reactive({
    data: null,
    isDataLoading: true,
});

const loadPARTemplates = () => {
    sendAbortableRequest(PARTemplateModel.all(orgId))
        .then((models) => (templatesState.data = models))
        .finally(() => (templatesState.isDataLoading = false));
};

const unfinishedOrderLink = computed(() => {
    const template = currentPARTemplate.value;

    if (isOrderEditing(template)) {
        return {
            name: SETTINGS_ROUTE_NAMES.PAR.EDIT.INDEX,
            params: { templateId: template.parent_id },
        };
    }

    return { name: SETTINGS_ROUTE_NAMES.PAR.CREATE.INDEX };
});

onBeforeMount(loadPARTemplates);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>PAR</h1>
        </template>

        <template
            v-if="currentPARTemplate !== null"
            #ribbon
        >
            <UnfinishedOrderRibbon :navigate-to="unfinishedOrderLink">
                You haven't finished your template. Back to template
            </UnfinishedOrderRibbon>
        </template>

        <InPlaceLoader v-if="templatesState.isDataLoading" />

        <Alert
            v-else-if="templatesState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            PAR information could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <ListDisplay
                    v-if="templatesState.data.length > 0"
                    has-borders
                    :items="templatesState.data"
                >
                    <template #item="{ item: template }">
                        <PARTemplateCard
                            :template="template"
                            :current-account="currentAccount"
                        />
                    </template>
                </ListDisplay>

                <p
                    v-else
                    class="pl-par-templates__message"
                >
                    The list of templates is empty.
                </p>
            </transition>

            <Fab
                v-if="$acl.can(PAR_RESTRICTIONS.CREATE, currentAccount)"
                data-testid="create_new_par_template_fab_btn"
            >
                <router-link :to="{ name: SETTINGS_ROUTE_NAMES.PAR.CREATE.INDEX, query: { action: 'new-template' } }">
                    <PlusIcon />
                </router-link>
            </Fab>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-par-templates__message {
    text-align: center;
    padding-top: custom-space(1);
}
</style>
