<script>
export default {
    name: 'PrepTaskForm',
};
</script>

<script setup>
import { reactive, ref, computed, watch } from 'vue';
import { useStore } from 'vuex';
import {
    required,
    maxLength,
    integer,
    numeric,
    minValue,
    requiredIf,
    minLength,
} from '@vuelidate/validators';
import { Line } from 'vue-chartjs';

import EventBus from '@/utils/EventBus';
import FormManager from '@/utils/form/FormManager';
import {
    getLocationAssigneeOptions,
    validateBatchSizeAmount,
    getBatchSettingsPayload,
    getBatchTimeSettingsPayload,
    getBatchTimeChartData,
    getBatchTimeChartOptions,
    getSelectedUnitsOptions,
    getInitCreateNewEntityOptionValue,
    getItemEntityOptions,
} from '@/utils/itemUtils';
import { ACCOUNT_ROLES } from '@/enums/accountEnums';
import {
    ADVANCED_BATCH_TIME_CHART_TYPES,
    ADVANCED_BATCH_TIME_CHART_TYPES_OPTIONS,
    ADVANCED_BATCH_TIME_CHART_DISPLAYS,
    ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS,
    REPORTING_UNIT_CHECKBOX_OPTIONS,
    ITEM_CONFIGURATION_ENTITIES_NAME,
} from '@/enums/itemEnums';
import { ALERT_TYPES, TAG_LIST_SELECT_EVENTS } from '@/enums/componentsEnums';
import { PREP_TASK_TIME_DELAY } from '@/enums/prepTaskEnums';
import DepartmentModel from '@/models/Department';
import LocationModel from '@/models/Location';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useItemConfiguration from '@/composition/useItemConfiguration';
import Alert from '@/components/Alert';
import Accordion from '@/components/Accordion';
import Switcher from '@/components/Switcher';
import SimpleEntityFormModal from '@/components/forms/SimpleEntityFormModal';
import TextInput from '@/components/form-controls/TextInput';
import NumericInput from '@/components/form-controls/NumericInput';
import TagListSelect from '@/components/form-controls/TagListSelect';
import SelectInput from '@/components/form-controls/SelectInput';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import Radio from '@/components/form-controls/Radio';
import ItemFormUI from '@/components/model-specific/item/ItemFormUI';

const props = defineProps({
    name: {
        type: String,
        default: '',
    },
    departments: {
        type: Array,
        default: () => [],
    },
    timeDelay: {
        type: String,
        default: '',
    },
    locationSpecificSettings: {
        type: Array,
        default: null,
    },
    batchSizeAmount: {
        type: Number,
        default: null,
    },
    batchSizeUnitId: {
        type: Number,
        default: null,
    },
    batchYieldAmount: {
        type: Number,
        default: null,
    },
    batchYieldUnitId: {
        type: Number,
        default: null,
    },
    batchWeightAmount: {
        type: String,
        default: null,
    },
    batchWeightUnit: {
        type: String,
        default: null,
    },
    batchCost: {
        type: String,
        default: null,
    },
    batchLaborTime: {
        type: Number,
        default: null,
    },
    laborTimeSettings: {
        type: Object,
        default: null,
    },
    orderingUnitId: {
        type: Number,
        default: null,
    },
    defaultReportingUnitId: {
        type: Number,
        default: null,
    },
    isChangeableReportingUnit: {
        type: Boolean,
        default: false,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
    isEditMode: {
        type: Boolean,
        default: false,
    },
    isDataLoading: {
        type: Boolean,
        default: false,
    },
    isSubmitting: {
        type: Boolean,
        default: false,
    },
    enableLockInitialDepartments: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['submit']);

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            name: props.name,
            departments: props.departments,
            time_delay: props.timeDelay,
            location_specific_settings: [],
            batch_size_amount: props.batchSizeAmount,
            batch_size_unit_id: props.batchSizeUnitId,
            batch_yield_amount: props.batchYieldAmount,
            batch_yield_unit_id: props.batchYieldUnitId,
            batch_weight_amount: props.batchWeightAmount,
            batch_weight_unit: props.batchWeightUnit,
            batch_cost: props.batchCost,
            batch_labor_time: props.batchLaborTime,
            batch_time_speedup: null,
            batch_time_initial: null,
            batch_time_final: null,
            ordering_unit_id: props.orderingUnitId,
            default_reporting_unit_id: props.defaultReportingUnitId,
            is_changeable_reporting_unit: [],
        },
        {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            departments: {
                required,
            },
            time_delay: {
                required,
            },
            batch_size_amount: {
                integer,
                required,
                minValue: minValue(1),
            },
            batch_size_unit_id: {
                required,
            },
            batch_yield_amount: {
                integer,
                required,
                minValue: minValue(1),
            },
            batch_yield_unit_id: {
                required,
            },
            batch_weight_amount: {
                required,
                integer,
            },
            batch_weight_unit: {
                required,
            },
            batch_cost: {
                required,
                numeric,
            },
            batch_labor_time: {
                integer,
                minValue: minValue(1),
                requiredIf: requiredIf(() => !batchTimeSettings.isAdvancedModeActivated),
            },
            batch_time_speedup: {
                integer,
                requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
                minValue: minValue(2),
            },
            batch_time_initial: {
                integer,
                minValue: minValue(1),
                requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            },
            batch_time_final: {
                integer,
                requiredIf: requiredIf(() => batchTimeSettings.isAdvancedModeActivated),
            },
        },
        {
            name: 'Task name',
            departments: 'Department',
            time_delay: 'Time delay',
            batch_size_unit_id: 'Batch size unit',
            batch_yield_unit_id: 'Batch yield unit',
            batch_weight_unit: 'Batch weight unit',
            batch_labor_time: 'Labor time',
            batch_time_initial: 'Batch initial time',
            batch_time_final: 'Batch final time',
        }
    )
);

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            form.errors.record(errors);
        }
    }
);

/*------------------------------------------------------------------------
                            Item configuration
------------------------------------------------------------------------*/

const {
    itemConfigurationState,
    activeEntityConfigurationState,
    itemEntityFormState,
    openItemEntityForm,
    closeItemEntityForm,
    submitItemEntityForm,
} = useItemConfiguration();

const setCurrentUnitId = (id = null) => {
    if (form.batch_yield_unit_id === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        form.batch_yield_unit_id = id;
    } else {
        form.batch_size_unit_id = id;
    }
};

const handleCloseItemEntityForm = () => {
    setCurrentUnitId();

    closeItemEntityForm();
};

const handleSubmitItemEntityForm = (attributes) => {
    const resolveCallback = (models) => {
        setCurrentUnitId(models[models.length - 1].id);
    };

    submitItemEntityForm(attributes, resolveCallback);
};

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const generalState = reactive({
    locations: [],
    isDataLoading: false,
});

const loadGeneralState = () => {
    generalState.isDataLoading = true;

    const departmentsIds = props.departments.map(({ id }) => id);

    Promise.all([
        sendAbortableRequest(
            DepartmentModel.all(orgId, {
                only_joined_members: 1,
                'member_role_in[0]': ACCOUNT_ROLES.COOK,
                'member_role_in[1]': ACCOUNT_ROLES.MANAGER,
                'on_demand[0]': 'assignees',
                ...(departmentsIds.length > 0 ? { force_load: departmentsIds } : {}),
            })
        ),
        sendAbortableRequest(LocationModel.all(orgId)),
        store.dispatch('itemBatchMeta/loadMeta', orgId),
    ])
        .then(([departmentModels, locationModels]) => {
            itemConfigurationState.departments = departmentModels;
            generalState.locations = locationModels || [];

            form.name = props.name;
            form.departments = departmentsIds;
            form.time_delay = props.timeDelay;
            form.location_specific_settings =
                props.locationSpecificSettings ??
                generalState.locations.map(({ id, name }) => ({
                    location_name: name,
                    location_id: id,
                    assignee_id: null,
                }));
            form.batch_size_amount = props.batchSizeAmount;
            form.batch_size_unit_id = props.batchSizeUnitId;
            form.batch_yield_amount = props.batchYieldAmount;
            form.batch_yield_unit_id = props.batchYieldUnitId;
            form.batch_weight_amount = props.batchWeightAmount;
            form.batch_weight_unit = props.batchWeightUnit;
            form.batch_cost = props.batchCost;
            form.batch_labor_time = props.batchLaborTime;
            form.ordering_unit_id = props.orderingUnitId;
            form.default_reporting_unit_id = props.defaultReportingUnitId;
            form.is_changeable_reporting_unit = props.isChangeableReportingUnit ? ['true'] : [];

            const { laborTimeSettings } = props;

            if (laborTimeSettings !== null) {
                form.batch_time_speedup = laborTimeSettings.period;
                form.batch_time_initial = laborTimeSettings.init_time;
                form.batch_time_final = laborTimeSettings.final_time;

                batchTimeSettings.chartType = laborTimeSettings.strategy;
                batchTimeSettings.isAdvancedModeActivated = true;
            }
        })
        .finally(() => (generalState.isDataLoading = false));
};

/*------------------------------------------------------------------------
                                Departments
------------------------------------------------------------------------*/

const departmentOptions = computed(() =>
    itemConfigurationState.departments.map(({ id, name }) => {
        const { enableLockInitialDepartments, departments } = props;

        return {
            value: id,
            text: name,
            isLocked: enableLockInitialDepartments && departments.some((department) => department.id === id),
        };
    })
);

const updateDepartments = ({ value, type }) => {
    switch (type) {
        case TAG_LIST_SELECT_EVENTS.ADD: {
            form.departments.unshift(value);

            break;
        }

        case TAG_LIST_SELECT_EVENTS.REMOVE: {
            const { can_be_deleted: canBeDeleted } =
                props.departments.find((department) => department.id === value) || {};

            if (canBeDeleted === undefined || canBeDeleted) {
                const index = form.departments.findIndex((departmentValue) => departmentValue === value);

                form.departments.splice(index, 1);
            } else {
                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.FAIL,
                    message: 'This department has an associated item, it can not be deleted.',
                });
            }

            break;
        }
    }
};

/*------------------------------------------------------------------------
                                Time delay
------------------------------------------------------------------------*/

const timeDelayOptions = computed(() =>
    Object.entries(PREP_TASK_TIME_DELAY).map(([value, text]) => ({
        value,
        text,
    }))
);

/*------------------------------------------------------------------------
                             Default assignees
------------------------------------------------------------------------*/

const availableAssignees = computed(() => {
    const selectedDepartmentIds = new Set(form.departments.map((id) => +id));

    const uniqueAssigneeIds = new Set();
    const uniqueAssignees = [];

    itemConfigurationState.departments
        .filter(({ id }) => selectedDepartmentIds.has(+id))
        .flatMap(({ assignees }) => assignees)
        .forEach((assignee) => {
            if (!uniqueAssigneeIds.has(assignee.id)) {
                uniqueAssigneeIds.add(assignee.id);
                uniqueAssignees.push(assignee);
            }
        });

    return uniqueAssignees;
});

const isAssigneeListEmpty = computed(() => form.departments.length > 0 && availableAssignees.value.length === 0);

const defaultAssigneesRibbonText = computed(() => {
    if (form.departments.length === 0) {
        return 'Please select a department';
    }

    if (isAssigneeListEmpty.value) {
        return 'The selected department does not have available assignees.';
    }

    return null;
});

watch(
    () => form.departments,
    () => {
        const assigneeIds = new Set(availableAssignees.value.map(({ id }) => id));

        form.location_specific_settings.forEach((location) => {
            if (!assigneeIds.has(location.assignee_id)) {
                location.assignee_id = null;
            }
        });
    },
    { deep: true }
);

/*------------------------------------------------------------------------
                          General batch settings
------------------------------------------------------------------------*/

const batchSettingsRef = ref(null);

const batchUnitsState = computed(() => {
    const {
        batch_size_units: batchSizeUnits,
        batch_yield_units: batchYieldUnits,
        batch_weight_units: batchWeightUnits,
    } = store.state.itemBatchMeta.meta || {};

    return {
        sizeOptions: getItemEntityOptions(batchSizeUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, true, false),
        yieldOptions: getItemEntityOptions(batchYieldUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, true, false),
        weightOptions: getItemEntityOptions(batchWeightUnits, ITEM_CONFIGURATION_ENTITIES_NAME.UNIT, false, false),
    };
});

const handleBatchUnitUpdated = (value, batchKey) => {
    if (value === getInitCreateNewEntityOptionValue(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)) {
        openItemEntityForm(ITEM_CONFIGURATION_ENTITIES_NAME.UNIT);
    } else {
        form.validate('batch_size_amount');
    }

    form.errors.clear(batchKey);
};

/*------------------------------------------------------------------------
                       Advanced batch time settings
------------------------------------------------------------------------*/

const batchTimeSettings = reactive({
    isAdvancedModeActivated: false,
    chartType: ADVANCED_BATCH_TIME_CHART_TYPES.LINEAR,
    chartDisplay: ADVANCED_BATCH_TIME_CHART_DISPLAYS.PER_BATCH,
});

const toggleBatchTimeAdvancedMode = () => {
    batchTimeSettings.isAdvancedModeActivated = !batchTimeSettings.isAdvancedModeActivated;

    form.errors.clear('batch_labor_time');
    form.errors.clear('batch_time_speedup');
    form.errors.clear('batch_time_initial');
    form.errors.clear('batch_time_final');
};

const isBatchTimeChartInvalid = computed(
    () => !(form.batch_time_speedup && form.batch_time_initial && form.batch_time_final)
);

const batchTimeChartData = computed(() =>
    getBatchTimeChartData(
        form,
        batchTimeSettings.chartType,
        batchTimeSettings.chartDisplay === ADVANCED_BATCH_TIME_CHART_DISPLAYS.TOTAL
    )
);

const batchTimeChartOptions = computed(() => getBatchTimeChartOptions(isBatchTimeChartInvalid.value));

/*------------------------------------------------------------------------
                                 Ordering
------------------------------------------------------------------------*/

const resetFieldIfInvalid = (field, options) => {
    const values = new Set(options.map(({ value }) => value));

    if (!values.has(form[field])) {
        form[field] = options[0].value;
    }
};

const orderingUnitOptions = computed(() => getSelectedUnitsOptions(form, batchUnitsState.value));

watch(orderingUnitOptions, (options) => {
    resetFieldIfInvalid('ordering_unit_id', options);
});

/*------------------------------------------------------------------------
                                 Reporting
------------------------------------------------------------------------*/

const reportingUnitOptions = computed(() => {
    const defaultOption = {
        value: null,
        text: 'batches',
    };

    return getSelectedUnitsOptions(form, batchUnitsState.value, defaultOption);
});

watch(reportingUnitOptions, (options) => {
    resetFieldIfInvalid('default_reporting_unit_id', options);
});

/*------------------------------------------------------------------------
                                Submit form
------------------------------------------------------------------------*/

const handleBatchSizeError = (errorMessage) => {
    form.errors.push('batch_size_amount', errorMessage);
};

const submitForm = () => {
    if (!validateBatchSizeAmount(form, handleBatchSizeError)) {
        batchSettingsRef.value.scrollIntoView();
    } else if (form.validate()) {
        const {
            batch_labor_time: batchLaborTime,
            batch_time_speedup: batchTimeSpeedup,
            batch_time_initial: batchTimeInitial,
            batch_time_final: batchTimeFinal,
            is_changeable_reporting_unit: isChangeableReportingUnit,
            ...restFormPayload
        } = form.getPayload();

        const payload = {
            ...restFormPayload,
            ...getBatchSettingsPayload(form),
            ...getBatchTimeSettingsPayload(
                batchLaborTime,
                batchTimeSpeedup,
                batchTimeInitial,
                batchTimeFinal,
                batchTimeSettings.chartType,
                batchTimeSettings.isAdvancedModeActivated
            ),
            is_changeable_reporting_unit: isChangeableReportingUnit.length !== 0,
        };

        emit('submit', payload);
    }
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

const isLoading = computed(() => generalState.isDataLoading || props.isDataLoading || props.isSubmitting);

watch(
    () => props.isDataLoading,
    (isDataLoading) => {
        if (!isDataLoading) {
            loadGeneralState();
        }
    },
    { immediate: true }
);
</script>

<template>
    <transition
        appear
        name="down"
        type="transition"
    >
        <Alert
            v-if="itemConfigurationState.departments.length === 0 && !isLoading"
            :type="ALERT_TYPES.WARNING"
        >
            You can't create an prep task because there are no available departments. Please note only departments that
            have at least one joined employee with the role "Cook" or "Manager" will be available.
        </Alert>

        <ItemFormUI
            v-else
            is-prep-task
            :is-loading="isLoading"
            @submit="submitForm"
        >
            <template #content="{ classNames, qaPrefix, accordionModifiers }">
                <Accordion
                    title="General Information"
                    :data-test-id="`${qaPrefix}_general_information`"
                    :has-error="form.errors.hasOneOf(['name', 'departments', 'time_delay'])"
                >
                    <div :class="classNames.spacerMd">
                        <TextInput
                            v-model="form.name"
                            include-asterisk
                            name="name"
                            label="Task name"
                            :data-test-id="`${qaPrefix}_name_input`"
                            :has-error="form.errors.has('name')"
                            @input="form.errors.clear('name')"
                            @blur="form.validate('name')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('name')"
                            :data-testid="`${qaPrefix}_name_errors`"
                            :errors="form.errors.get('name')"
                        />
                    </div>

                    <TagListSelect
                        include-asterisk
                        label="Departments"
                        :data-form-control-test-id="`${qaPrefix}_departments_select`"
                        :data-form-control-test-id-error="`${qaPrefix}_departments_errors`"
                        :class="classNames.spacerMd"
                        :tags="form.departments"
                        :options="departmentOptions"
                        :errors="form.errors.get('departments')"
                        :has-error="form.errors.has('departments')"
                        :disabled="departmentOptions.length === 0"
                        @update-tags-list="updateDepartments"
                    />

                    <SelectInput
                        v-model="form.time_delay"
                        include-asterisk
                        searchable
                        label="Time delay"
                        :data-test-id="`${qaPrefix}_time_delay_select`"
                        :options="timeDelayOptions"
                        :has-error="form.errors.has('time_delay')"
                        @blur="form.validate('time_delay')"
                        @update:modelValue="form.errors.clear('time_delay')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('time_delay')"
                        :data-testid="`${qaPrefix}_time_delay_errors`"
                        :errors="form.errors.get('time_delay')"
                    />
                </Accordion>

                <Accordion
                    title="Default Assignees"
                    :data-test-id="`${qaPrefix}_default_assignees`"
                >
                    <div
                        v-for="(setting, index) in form.location_specific_settings"
                        :key="`location-specific-default-employee-field-${index}`"
                        :class="{
                            [classNames.spacerSm]: index < form.location_specific_settings.length - 1,
                        }"
                    >
                        <SelectInput
                            v-model="form.location_specific_settings[index].assignee_id"
                            searchable
                            :data-test-id="`${qaPrefix}_default_assignee_select`"
                            :label="setting.location_name"
                            :options="getLocationAssigneeOptions(availableAssignees, setting.location_id)"
                            :has-error="form.errors.has(`location_specific_settings.${index}.assignee_id`)"
                            :disabled="availableAssignees.length === 0"
                            @update:modelValue="form.errors.clear(`location_specific_settings.${index}.assignee_id`)"
                        />

                        <small
                            v-if="defaultAssigneesRibbonText !== null"
                            :class="classNames.ribbon"
                        >
                            {{ defaultAssigneesRibbonText }}
                        </small>

                        <ValidationErrors
                            v-if="form.errors.has(`location_specific_settings.${index}.assignee_id`)"
                            :data-testid="`${qaPrefix}_assignee_${index}_errors`"
                            :errors="form.errors.get(`location_specific_settings.${index}.assignee_id`)"
                        />
                    </div>
                </Accordion>

                <Accordion
                    ref="batchSettingsRef"
                    title="Batch Settings"
                    :data-test-id="`${qaPrefix}_batch_settings`"
                    :has-error="
                        form.errors.hasOneOf([
                            'batch_size_amount',
                            'batch_size_unit_id',
                            'batch_yield_amount',
                            'batch_yield_unit_id',
                            'batch_weight_amount',
                            'batch_weight_unt',
                            'batch_cost',
                            'batch_labor_time',
                            'batch_time_speedup',
                            'batch_time_initial',
                            'batch_time_final',
                        ])
                    "
                >
                    <div :class="classNames.spacerMd">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="form.batch_size_amount"
                                only-positive
                                include-asterisk
                                name="batch_size_amount"
                                label="Batch Size"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_size_amount_input`"
                                :has-error="form.errors.has('batch_size_amount')"
                                @input="form.errors.clear('batch_size_amount')"
                                @blur="form.validate('batch_size_amount')"
                            />

                            <SelectInput
                                v-model="form.batch_size_unit_id"
                                searchable
                                is-last-option-marked
                                include-asterisk
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_size_unit_select`"
                                :options="batchUnitsState.sizeOptions"
                                :has-error="form.errors.has('batch_size_unit_id')"
                                :disabled="batchUnitsState.sizeOptions.length === 0"
                                @update:modelValue="($value) => handleBatchUnitUpdated($value, 'batch_size_unit_id')"
                                @blur="form.validate('batch_size_unit_id')"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('batch_size_amount')"
                            :data-testid="`${qaPrefix}_batch_size_amount_errors`"
                            :errors="form.errors.get('batch_size_amount')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_size_unit_id')"
                            :data-testid="`${qaPrefix}_batch_size_unit_errors`"
                            :errors="form.errors.get('batch_size_unit_id')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="form.batch_yield_amount"
                                only-positive
                                include-asterisk
                                name="batch_yield_amount"
                                label="Batch Yield"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_yield_amount_input`"
                                :has-error="form.errors.has('batch_yield_amount')"
                                :disabled="isEditMode"
                                @input="form.errors.clear('batch_yield_amount')"
                                @blur="
                                    form.validate('batch_size_amount');
                                    form.validate('batch_yield_amount');
                                "
                            />

                            <SelectInput
                                v-model="form.batch_yield_unit_id"
                                searchable
                                is-last-option-marked
                                include-asterisk
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_yield_unit_select`"
                                :options="batchUnitsState.yieldOptions"
                                :has-error="form.errors.has('batch_yield_unit_id')"
                                :disabled="batchUnitsState.yieldOptions.length === 0 || isEditMode"
                                @blur="form.validate('batch_yield_unit_id')"
                                @update:modelValue="($value) => handleBatchUnitUpdated($value, 'batch_yield_unit_id')"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('batch_yield_amount')"
                            :data-testid="`${qaPrefix}_batch_yield_amount_errors`"
                            :errors="form.errors.get('batch_yield_amount')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_yield_unit_id')"
                            :data-testid="`${qaPrefix}_batch_yield_unit_errors`"
                            :errors="form.errors.get('batch_yield_unit_id')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <div class="input-group input-group--select-mode">
                            <NumericInput
                                v-model="form.batch_weight_amount"
                                include-asterisk
                                only-positive
                                name="batch_weight_amount"
                                label="Batch Weight"
                                :max="9999"
                                :data-test-id="`${qaPrefix}_batch_weight_amount_input`"
                                :has-error="form.errors.has('batch_weight_amount')"
                                @input="form.errors.clear('batch_weight_amount')"
                                @blur="form.validate('batch_weight_amount')"
                            />

                            <SelectInput
                                v-model="form.batch_weight_unit"
                                searchable
                                include-asterisk
                                label="unit"
                                :data-test-id="`${qaPrefix}_batch_weight_unit_select`"
                                :options="batchUnitsState.weightOptions"
                                :has-error="form.errors.has('batch_weight_unit')"
                                :disabled="batchUnitsState.weightOptions.length === 0"
                                @update:modelValue="($value) => handleBatchUnitUpdated($value, 'batch_weight_unit')"
                                @blur="form.validate('batch_weight_unit')"
                            />
                        </div>

                        <ValidationErrors
                            v-if="form.errors.has('batch_weight_amount')"
                            :data-testid="`${qaPrefix}_batch_weight_amount_errors`"
                            :errors="form.errors.get('batch_weight_amount')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_weight_unit')"
                            :data-testid="`${qaPrefix}_batch_weight_unit_errors`"
                            :errors="form.errors.get('batch_weight_unit')"
                        />
                    </div>

                    <div :class="classNames.spacerMd">
                        <NumericInput
                            v-model="form.batch_cost"
                            only-positive
                            always-use-dot
                            include-asterisk
                            name="batch_cost"
                            label="Batch Cost"
                            :max="9999"
                            :data-test-id="`${qaPrefix}_batch_cost_input`"
                            :max-precision="2"
                            :has-error="form.errors.has('batch_cost')"
                            @input="form.errors.clear('batch_cost')"
                            @blur="form.validate('batch_cost')"
                        />

                        <ValidationErrors
                            v-if="form.errors.has('batch_cost')"
                            :data-testid="`${qaPrefix}_batch_cost_errors`"
                            :errors="form.errors.get('batch_cost')"
                        />
                    </div>

                    <div :class="classNames.batchTimeWrapper">
                        <template v-if="batchTimeSettings.isAdvancedModeActivated">
                            <TextInput
                                disabled
                                model-value="Advanced mode activated"
                                name="batch_labor_time"
                                label="Labor Time"
                            />

                            <button
                                type="button"
                                :data-test-id="`${qaPrefix}_batch_labor_time_advanced_btn_close`"
                                @click="toggleBatchTimeAdvancedMode"
                            >
                                <CrossIcon
                                    :height="18"
                                    :width="18"
                                />
                            </button>
                        </template>

                        <template v-else>
                            <NumericInput
                                v-model="form.batch_labor_time"
                                only-positive
                                include-asterisk
                                label="Labor Time (minutes)"
                                name="batch_labor_time"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_labor_time_input`"
                                :has-error="form.errors.has('batch_labor_time')"
                                @input="form.errors.clear('batch_labor_time')"
                                @blur="form.validate('batch_labor_time')"
                            />

                            <button
                                type="button"
                                :disabled="+form.batch_labor_time !== 0"
                                :data-test-id="`${qaPrefix}_batch_labor_time_advanced_btn_open`"
                                @click="toggleBatchTimeAdvancedMode"
                            >
                                <SettingsIcon
                                    :height="30"
                                    :width="30"
                                />
                            </button>
                        </template>
                    </div>

                    <template v-if="batchTimeSettings.isAdvancedModeActivated">
                        <div :class="classNames.spacerSm">
                            <NumericInput
                                v-model="form.batch_time_speedup"
                                only-positive
                                include-asterisk
                                label="Speedup period (batches)"
                                name="batch_time_speedup"
                                :max="10"
                                :data-test-id="`${qaPrefix}_batch_time_speedup_input`"
                                :has-error="form.errors.has('batch_time_speedup')"
                                @input="form.errors.clear('batch_time_speedup')"
                                @blur="form.validate('batch_time_speedup')"
                            />

                            <ValidationErrors
                                v-if="form.errors.has('batch_time_speedup')"
                                :data-testid="`${qaPrefix}_batch_time_speedup_errors`"
                                :errors="form.errors.get('batch_time_speedup')"
                            />
                        </div>

                        <div :class="classNames.spacerSm">
                            <NumericInput
                                v-model="form.batch_time_initial"
                                only-positive
                                include-asterisk
                                label="Initial Time (minutes)"
                                name="batch_time_initial"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_time_initial_input`"
                                :has-error="form.errors.has('batch_time_initial')"
                                @input="form.errors.clear('batch_time_initial')"
                                @blur="form.validate('batch_time_initial')"
                            />

                            <ValidationErrors
                                v-if="form.errors.has('batch_time_initial')"
                                :data-testid="`${qaPrefix}_batch_time_initial_errors`"
                                :errors="form.errors.get('batch_time_initial')"
                            />
                        </div>

                        <div :class="classNames.spacerMd">
                            <NumericInput
                                v-model="form.batch_time_final"
                                only-positive
                                include-asterisk
                                label="Final Time (minutes)"
                                name="batch_time_final"
                                :max="480"
                                :data-test-id="`${qaPrefix}_batch_time_final_input`"
                                :has-error="form.errors.has('batch_time_final')"
                                @input="form.errors.clear('batch_time_final')"
                                @blur="form.validate('batch_time_final')"
                            />

                            <ValidationErrors
                                v-if="form.errors.has('batch_time_final')"
                                :data-testid="`${qaPrefix}_batch_time_final_errors`"
                                :errors="form.errors.get('batch_time_final')"
                            />
                        </div>

                        <div :class="classNames.batchTimeChart">
                            <div :class="classNames.batchTimeChartTypeOptions">
                                <button
                                    v-for="{ value, text } in ADVANCED_BATCH_TIME_CHART_TYPES_OPTIONS"
                                    :key="value"
                                    type="button"
                                    :class="{
                                        [classNames.batchTimeChartTypeOption]: true,
                                        [classNames.batchTimeChartTypeOptionActive]:
                                            batchTimeSettings.chartType === value,
                                    }"
                                    :disabled="isBatchTimeChartInvalid"
                                    @click="batchTimeSettings.chartType = value"
                                >
                                    {{ text }}
                                </button>
                            </div>

                            <small v-if="isBatchTimeChartInvalid">
                                Please specify advanced settings to see the chart.
                            </small>

                            <div :class="classNames.batchTimeChartContainer">
                                <Line
                                    :data="batchTimeChartData"
                                    :options="batchTimeChartOptions"
                                />
                            </div>

                            <Switcher
                                v-if="!isBatchTimeChartInvalid"
                                :model-value="batchTimeSettings.chartDisplay"
                                :options="ADVANCED_BATCH_TIME_CHART_DISPLAY_OPTIONS"
                                @update:modelValue="batchTimeSettings.chartDisplay = $event"
                            />
                        </div>
                    </template>

                    <ValidationErrors
                        v-else-if="form.errors.has('batch_labor_time')"
                        :data-testid="`${qaPrefix}_batch_labor_time_errors`"
                        :errors="form.errors.get('batch_labor_time')"
                    />
                </Accordion>

                <Accordion
                    title="Ordering"
                    :data-test-id="`${qaPrefix}_ordering`"
                >
                    <SelectInput
                        v-model="form.ordering_unit_id"
                        searchable
                        size="sm"
                        label="Ordering unit"
                        :data-test-id="`${qaPrefix}_ordering_unit_select`"
                        :options="orderingUnitOptions"
                    />
                </Accordion>

                <Accordion
                    title="Reporting"
                    :data-test-id="`${qaPrefix}_reporting`"
                    :content-modifier-prefix="accordionModifiers.space"
                >
                    <label>By default, report using these units:</label>

                    <Radio
                        v-model="form.default_reporting_unit_id"
                        name="reporting-unit"
                        :data-testid="`${qaPrefix}_default_reporting_unit_radio`"
                        :options="reportingUnitOptions"
                    />

                    <CheckboxInput
                        v-model="form.is_changeable_reporting_unit"
                        name="reporting-unit-checkbox"
                        :qa-prefix="qaPrefix"
                        :class="classNames.reportingCheckbox"
                        :options="REPORTING_UNIT_CHECKBOX_OPTIONS"
                    />
                </Accordion>
            </template>

            <template #actions="{ qaPrefix }">
                <button
                    type="submit"
                    class="btn btn-primary"
                    :data-testid="`${qaPrefix}_submit_btn`"
                    :disabled="form.errors.any() || !form.isFilled() || isAssigneeListEmpty || isLoading"
                >
                    Save prep task
                </button>

                <slot name="delete-prep-task-btn" />
            </template>
        </ItemFormUI>
    </transition>

    <SimpleEntityFormModal
        v-if="itemEntityFormState.isModalOpened"
        :departments="itemConfigurationState.departments"
        :entity-name="activeEntityConfigurationState.name"
        :form-data="itemEntityFormState.entityData"
        :validation-errors="itemEntityFormState.validationErrors"
        :is-submitting="itemEntityFormState.isSubmitting"
        @submit="handleSubmitItemEntityForm"
        @close="handleCloseItemEntityForm"
    />
</template>
