import { ACCOUNT_ROLES } from '@/enums/accountEnums';

/*------------------------------------------------------------------------
                               Account roles
------------------------------------------------------------------------*/

const hasRole = (account, role) => account?.role === role;

export const isOwner = (account) => account?.is_owner;
export const isAdmin = (account) => hasRole(account, ACCOUNT_ROLES.ADMIN);
export const isManager = (account) => hasRole(account, ACCOUNT_ROLES.MANAGER);
export const isCook = (account) => hasRole(account, ACCOUNT_ROLES.COOK);
export const isAdminOrManager = (account) => isAdmin(account) || isManager(account);
