<script>
export default {
    name: 'CreateItemEntityFab',
};
</script>

<script setup>
import { ITEM_CONFIGURATION_ENTITIES_NAME } from '@/enums/itemEnums';
import DropDown from '@/components/DropDown';
import Fab from '@/components/Fab';
import GroupedSettings from '@/components/constructions/grouped-settings';

const emit = defineEmits(['set-active-entity']);
</script>

<template>
    <Fab data-testid="create_new_item_entity_btn">
        <DropDown pos-y="top">
            <template #invoker>
                <PlusIcon />
            </template>

            <template #content>
                <GroupedSettings.Root>
                    <GroupedSettings.List>
                        <GroupedSettings.ListItem
                            @click="emit('set-active-entity', ITEM_CONFIGURATION_ENTITIES_NAME.CATEGORY)"
                        >
                            Create new category
                        </GroupedSettings.ListItem>

                        <GroupedSettings.ListItem
                            @click="emit('set-active-entity', ITEM_CONFIGURATION_ENTITIES_NAME.UNIT)"
                        >
                            Create new unit
                        </GroupedSettings.ListItem>

                        <GroupedSettings.ListItem
                            @click="emit('set-active-entity', ITEM_CONFIGURATION_ENTITIES_NAME.STATION)"
                        >
                            Create new station
                        </GroupedSettings.ListItem>
                    </GroupedSettings.List>
                </GroupedSettings.Root>
            </template>
        </DropDown>
    </Fab>
</template>
