<script>
export default {
    name: 'CarouselArrowIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 9,
    },
    height: {
        type: Number,
        default: 14,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 9 14"
        :width="width"
        :height="height"
    >
        <path
            stroke="#333"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6.657 1 1 6.657l5.657 5.657"
        />
    </svg>
</template>