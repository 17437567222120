<script>
export default {
    name: 'ShowQFOrderView',
};
</script>

<script setup>
import { onBeforeMount, ref, inject, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mapGetters } from '@/utils/vuex';
import EventBus from '@/utils/EventBus';
import { isOrderEditing } from '@/utils/orderUtils';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { QFO_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import DeletionModal from '@/components/DeletionModal';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderItemsWrapper from './partials/OrderItemsWrapper';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const store = useStore();
const router = useRouter();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

const { sendAbortableRequest } = useAbortableRequest();

/*-----------------------------------------------------------------------------------
                                    Order state
-----------------------------------------------------------------------------------*/

const order = ref(null);
const isDataLoading = ref(true);
const serverError = ref(null);

const loadOrder = () => {
    isDataLoading.value = true;

    sendAbortableRequest(QFOrderModel.find(orgId, props.orderId, { group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY }))
        .then((model) => (order.value = model))
        .catch((error) => {
            const { status } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.INTERNAL_SERVER_ERROR) {
                serverError.value = 'Order could not be loaded for some reason. Please try again later.';
            } else if (status !== undefined) {
                serverError.value = error.response.data.message;
            }
        })
        .finally(() => (isDataLoading.value = false));
};

const handleRefreshOrder = (freshOrder) => (order.value = freshOrder);

const orderPermissions = computed(() => ({
    update: $acl.can(QFO_RESTRICTIONS.UPDATE, currentAccount, order.value),
    delete: $acl.can(QFO_RESTRICTIONS.DELETE, currentAccount, order.value),
}));

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const handleOpenDeleteOrderModal = () => {
    if (order.value.has_batches_done) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to delete the order, because some items are fulfilled.',
        });

        closeDeletionModal();
    } else {
        openDeletionModal(order.value);
    }
};

const handleDeleteOrder = () => {
    deletionCallback(() =>
        deletionModel.value.delete().then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully deleted',
            });

            router.push({ name: QFO_ROUTE_NAMES.INDEX });
        })
    );
};

/*-----------------------------------------------------------------------------------
                                    Init order editing
-----------------------------------------------------------------------------------*/

const isFormSubmitting = ref(false);

const handleInitOrderEditing = () => {
    if (currentQFOrder.value !== null) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.WARNING,
            message: 'You currently have a pending order. Please finalize or cancel it, before editing a new one.',
        });

        if (isOrderEditing(currentQFOrder.value)) {
            router.push({
                name: QFO_ROUTE_NAMES.EDIT.INDEX,
                params: { orderId: currentQFOrder.value.parent_id },
            });
        } else {
            router.replace({ name: QFO_ROUTE_NAMES.CREATE.INDEX });
        }
    } else if (order.value.has_batches_done) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to edit the order, because some items are fulfilled.',
        });
    } else {
        isFormSubmitting.value = true;

        order.value
            .createDraft()
            .then(({ data }) => {
                store.commit('setCurrentQFOrder', data);

                router.push({
                    name: QFO_ROUTE_NAMES.EDIT.INDEX,
                    params: { orderId: order.value.id },
                });
            })
            .catch((error) => {
                const { status } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.NOT_FOUND) {
                    EventBus.$emit('pl.flash-notification', {
                        type: ALERT_TYPES.FAIL,
                        message: 'Order has been successfully deleted',
                    });
                }
            })
            .finally(() => (isFormSubmitting.value = false));
    }
};

/*-----------------------------------------------------------------------------------
                                    Load necessary data
-----------------------------------------------------------------------------------*/

onBeforeMount(loadOrder);
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="QFO_ROUTE_NAMES.INDEX"
    >
        <template #title>
            <h1>Order Details</h1>
        </template>

        <template #actions>
            <DropDown v-if="order !== null">
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem is-selected>
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List
                            v-if="orderPermissions.update || orderPermissions.delete"
                            title="Actions"
                        >
                            <GroupedSettings.ListItem
                                v-if="orderPermissions.update"
                                @click="handleInitOrderEditing"
                            >
                                {{ order.draft !== null ? ' Continue editing' : 'Edit order' }}
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem
                                v-if="orderPermissions.delete"
                                @click="handleOpenDeleteOrderModal"
                            >
                                Delete order
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <InPlaceLoader v-if="isDataLoading" />

        <div
            v-else-if="serverError"
            class="container"
        >
            <Alert :type="ALERT_TYPES.FAIL">
                {{ serverError }}
            </Alert>
        </div>

        <template v-else>
            <OverlayLoader
                v-if="isFormSubmitting"
                enable-sticky
            />

            <OrderItemsWrapper
                :order="order"
                @handle-refresh-order="handleRefreshOrder"
            />

            <!--------------------- Delete item modal --------------------->
            <DeletionModal
                v-if="isDeletionModalOpened"
                qa-prefix="delete_item_modal"
                :is-pending="isDeletionPending"
                @close="closeDeletionModal"
                @submit-delete="handleDeleteOrder"
            >
                <template #title>
                    Quick Fill
                </template>

                Are you sure you want to delete the order "{{ deletionModel.name }}"?
            </DeletionModal>
            <!---------------------/ Delete item modal --------------------->
        </template>
    </TopBarLayout>
</template>
