<script>
export default {
    name: 'ReportsIndexView',
};
</script>

<script setup>
import { markRaw, onBeforeMount, inject } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { REPORTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { REPORTS_NAVIGATION } from '@/enums/reportsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import VerticalNavigation from '@/components/VerticalNavigation';
import ReportIcon from '@/components/icons/ReportIcon';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(REPORTS_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to reports.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                               Reports state
------------------------------------------------------------------------*/

const reportsNavigationConfig = Object.entries(REPORTS_NAVIGATION).map(([routeName, title]) => ({
    name: title,
    route: routeName,
    icon: markRaw(ReportIcon)
}));
</script>

<template>
    <TopBarLayout>
        <template #title>
            <h1>Reports</h1>
        </template>

        <VerticalNavigation
            data-test-id="reports_vertical_navigation"
            :navigation-config="reportsNavigationConfig"
        />
    </TopBarLayout>
</template>
