<script>
export default {
    name: 'BellIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 20,
    },
    height: {
        type: Number,
        default: 23,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 20 23"
        :width="width"
        :height="height"
    >
        <path
            fill="#969AAB"
            d="M19.425 15.865c-.6-1.036-1.32-3.006-1.32-6.49v-.721c0-4.5-3.606-8.186-8.044-8.216H10a8.115 8.115 0 0 0-8.105 8.124v.813c0 3.484-.72 5.454-1.32 6.49a1.625 1.625 0 0 0 1.401 2.447h3.961a4.062 4.062 0 1 0 8.125 0h3.961a1.616 1.616 0 0 0 1.625-1.63 1.646 1.646 0 0 0-.223-.817ZM10 20.75a2.448 2.448 0 0 1-2.438-2.438h4.875A2.447 2.447 0 0 1 10 20.75Zm-8.024-4.063c.701-1.218 1.544-3.463 1.544-7.312v-.813a6.48 6.48 0 0 1 6.48-6.5h.05c3.545.02 6.43 2.986 6.43 6.592v.721c0 3.85.843 6.094 1.543 7.313H1.977Z"
        />
    </svg>
</template>
