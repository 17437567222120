<script>
export default {
    name: 'EditQFOrderIndexView',
};
</script>

<script setup>
import { inject, onBeforeMount } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { isOrderCreating } from '@/utils/orderUtils';
import { getBackRoute } from '@/utils/navigationUtils';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { QFO_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

const notifyAboutPendingOrder = () => {
    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.WARNING,
        message: 'You currently have a pending order. Please finalize or cancel it, before editing a new one.',
    });
};

onBeforeMount(() => {
    const order = currentQFOrder.value;

    if (order === null || !$acl.can(QFO_RESTRICTIONS.UPDATE, currentAccount, order)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to edit the order.',
        });

        return router.replace({
            name: QFO_ROUTE_NAMES.SHOW,
            params: { orderId: props.orderId },
        });
    }

    if (isOrderCreating(order)) {
        notifyAboutPendingOrder();

        router.replace({ name: QFO_ROUTE_NAMES.CREATE.INDEX });
    } else if (order.parent_id !== +props.orderId) {
        notifyAboutPendingOrder();

        router.replace({ params: { orderId: order.parent_id } });
    }
});
</script>

<template>
    <TopBarLayout
        exclude-content-container
        :back-route="getBackRoute(route, QFO_ROUTE_NAMES.EDIT, QFO_ROUTE_NAMES.INDEX)"
    >
        <template #title>
            <h1>Edit Order</h1>
        </template>

        <template #actions>
            <DropDown v-if="route.name !== QFO_ROUTE_NAMES.EDIT.INDEX">
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem is-selected>
                                Category
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <router-view v-if="currentQFOrder !== null" />
    </TopBarLayout>
</template>
