import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class PARTemplate extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/par-templates`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, templateId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/par-templates/${templateId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/par-templates`, attributes);

        return this.make(data);
    }

    static getAssignees(orgId, templateId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/par-templates/${templateId}/assignees`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async setAssignees(orgId, templateId, attributes) {
        return ApiClient.post(`organizations/${orgId}/par-templates/${templateId}/assignees`, attributes);
    }

    async confirm() {
        const { data } = await ApiClient.put(`organizations/${this.organization_id}/par-templates/${this.id}/confirm`);

        return this.fill(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/par-templates/${this.id}`,
            attributes
        );

        return this.fill(data);
    }

    async createDraft() {
        const { data } = await ApiClient.post(`organizations/${this.organization_id}/par-templates/${this.id}/draft`);

        return this.fill(data);
    }

    async completeDraft() {
        const { data } = await ApiClient.post(
            `organizations/${this.organization_id}/par-templates/${this.parent_id}/draft/complete`
        );

        return this.fill(data);
    }

    async cancel() {
        return ApiClient.delete(`organizations/${this.organization_id}/par-templates/${this.id}/cancel`);
    }

    delete() {
        return ApiClient.delete(`organizations/${this.organization_id}/par-templates/${this.id}`);
    }
}
