<template>
    <DepartmentForm
        :accounts="employees"
        @submit="cacheData"
    />
</template>

<script>
export default {
    name: 'RegistrationStep3AddDepartment',
};
</script>

<script setup>
import { useRouter } from 'vue-router';

import { LS } from '@/instances/localStorage';
import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import DepartmentForm from '@/components/forms/DepartmentForm';

const router = useRouter();

const departments = LS.get('onboarding_departments', []);
const employees = LS.get('onboarding_employees', []);

const cacheData = (payload) => {
    departments.push(payload);

    LS.set('onboarding_departments', departments);

    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.SUCCESS,
        message: 'Department added',
    });
    router.back();
};
</script>

<style lang="scss"></style>
