import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import SettingsIndexView from '@/views/dashboard/settings';

import company from './company';
import configurationOfItem from './configuration-of-item';
import departments from './departments';
import items from './items';
import locations from './locations';
import suppliers from './suppliers';
import myAccounts from './my-accounts';
import noPrepActions from './no-prep-actions';
import PAR from './PAR';
import people from './people';
import prepTasks from './prep-tasks';

export default [
    {
        path: 'settings',
        name: SETTINGS_ROUTE_NAMES.INDEX,
        component: SettingsIndexView,
    },
].concat(
    company,
    configurationOfItem,
    departments,
    items,
    locations,
    suppliers,
    myAccounts,
    noPrepActions,
    PAR,
    people,
    prepTasks
);
