<script>
export default {
    name: 'ReportItemFormModal',
};
</script>

<script setup>
import { reactive, computed, ref, onBeforeMount, watch } from 'vue';
import { useRoute } from 'vue-router';
import { numeric, required } from '@vuelidate/validators';

import { cutFloatNumber } from '@/utils/numberUtils';
import { getBatchUnitQtyStep, getBatchYieldQtyAmount } from '@/utils/batchUnitsUtils';
import { getClosestAllowedQty, getQtyPrecision } from '@/utils/componentsUtils';
import FormManager from '@/utils/form/FormManager';
import { ITEM_TYPES } from '@/enums/itemEnums';
import OrderItemModel from '@/models/OrderItem';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Modal from '@/components/Modal';
import QtyInput from '@/components/form-controls/QtyInput';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    organizationId: {
        type: Number,
        required: true,
    },
    validationErrors: {
        type: Object,
        default: null,
    },
});

const emit = defineEmits(['submit', 'close-modal']);

const route = useRoute();

const { sendAbortableRequest } = useAbortableRequest();

/*------------------------------------------------------------------------
                           Reporting units state
------------------------------------------------------------------------*/

const reportingUnitsState = reactive({
    data: null,
    currentUnit: null,
    isLoading: false,
});

const setInitialReportingUnitsState = (data) => {
    if (data !== null) {
        reportingUnitsState.data = data;
        reportingUnitsState.currentUnit = data.units_data.default_reporting_unit || {
            id: null,
            value: 'batches',
        };
    }
};

const loadReportingUnits = () => {
    const { item } = props;

    if (!item.status) {
        reportingUnitsState.isLoading = true;

        sendAbortableRequest(
            OrderItemModel.getReportingUnits(props.organizationId, item.prototype_id, {
                assignee_id: item.assignee_id,
                assignee_type: item.assignee_type,
                date: route.query.start_date,
            })
        )
            .then(setInitialReportingUnitsState)
            .finally(() => (reportingUnitsState.isLoading = false));
    } else {
        setInitialReportingUnitsState(item);
    }
};

const isReportingUnitOptionDisabled = (unit) =>
    !reportingUnitsState.data.units_data.is_changeable_reporting_unit && currentUnitData.value.id !== unit.id;

const isItemOrdered = ({ status }) => status === 'ordered' || status === 'open_item';

const getQtyStep = (unitStep) => {
    const { data } = reportingUnitsState;

    if (data.type !== ITEM_TYPES.PREP_ACTION) {
        return 1 / unitStep;
    }

    if (unitStep === 1) {
        return 5;
    }

    return 0.25;
};

const currentUnitData = computed(() => {
    const { currentUnit, data } = reportingUnitsState;

    if (currentUnit === null) {
        return {};
    }

    const { units_data: unitsData } = data;

    const unitStep = getBatchUnitQtyStep(unitsData, currentUnit);

    const orderedQty = data.ordered_BY_qty / unitStep;
    const producedQty = data.produced_BY_qty / unitStep;

    const shortProducedQty = cutFloatNumber(producedQty);

    return {
        orderedQty,
        producedQty,
        id: currentUnit.id,
        text: currentUnit.value,
        unitStep: 1 / unitStep,
        qtyStep: getQtyStep(unitStep),
        isQtyUnitsInfoVisible: form.qty > 0 && unitsData.batch_yield_unit.id !== currentUnit.id,
        donePreviouslyDisplayQty: isItemOrdered(props.item)
            ? `${shortProducedQty} / ${cutFloatNumber(orderedQty)}`
            : shortProducedQty || 0,
    };
});

const selectableUnits = computed(() => {
    const { data, currentUnit } = reportingUnitsState;

    if (currentUnit === null) {
        return [];
    }

    const { batch_yield_unit: batchYieldUnit, batch_size_unit: batchSizeUnit } = data.units_data;

    const options = [];

    if (data.type !== ITEM_TYPES.PREP_ACTION) {
        options.push({ id: null, value: 'batches' });
    }

    if (batchSizeUnit !== null && batchYieldUnit.id !== batchSizeUnit.id) {
        options.push({ id: batchSizeUnit.id, value: batchSizeUnit.value });
    }

    options.push({ id: batchYieldUnit.id, value: batchYieldUnit.value });

    return options;
});

onBeforeMount(loadReportingUnits);

/*------------------------------------------------------------------------
                                Setup form
------------------------------------------------------------------------*/

const form = reactive(
    new FormManager(
        {
            qty: 0,
        },
        {
            qty: {
                numeric,
                required,
            },
        }
    )
);

const isFormSubmitting = ref(false);

const setRemainingQty = () => {
    const { orderedQty, producedQty } = currentUnitData.value;

    if (producedQty >= orderedQty) {
        form.qty = 0;
    } else {
        const { data, currentUnit } = reportingUnitsState;

        const remainingQty = orderedQty - producedQty;

        form.qty = getClosestAllowedQty(data.units_data, currentUnit, remainingQty);
    }
};

const onBlurQty = () => {
    const { data, currentUnit } = reportingUnitsState;

    form.qty = getClosestAllowedQty(data.units_data, currentUnit, form.qty);
};

const batchYieldQty = computed(() => {
    const { data, currentUnit } = reportingUnitsState;

    if (data === null) {
        return 0;
    }

    return getBatchYieldQtyAmount(data.units_data, currentUnit, form.qty);
});

const submitForm = () => {
    if (form.validate()) {
        isFormSubmitting.value = true;

        emit('submit', batchYieldQty.value);
    }
};

const isSubmitBtnDisabled = computed(
    () => form.errors.any() || batchYieldQty.value === 0 || isFormSubmitting.value || reportingUnitsState.isLoading
);

watch(
    () => props.validationErrors,
    (errors) => {
        if (errors !== null) {
            isFormSubmitting.value = false;

            form.errors.record(errors);
        }
    }
);

watch(
    () => reportingUnitsState.currentUnit,
    (unit) => {
        if (unit !== null) {
            setRemainingQty();
        }
    }
);

/*------------------------------------------------------------------------
                               Modal content
------------------------------------------------------------------------*/

const modalTitle = computed(() => {
    const { item } = props;
    const { name } = item;
    const { text, orderedQty } = currentUnitData.value;

    if (!reportingUnitsState.isLoading && isItemOrdered(item)) {
        return `${name} - ${cutFloatNumber(orderedQty)} ${text}`;
    }

    return name;
});
</script>

<template>
    <Modal
        :is-close-disabled="isFormSubmitting"
        @close="emit('close-modal')"
    >
        <template #title>
            {{ modalTitle }}
        </template>

        <template #content>
            <OverlayLoader v-if="isFormSubmitting" />

            <InPlaceLoader v-if="reportingUnitsState.isLoading" />

            <form
                v-else
                id="pl-report-item-form"
                novalidate
                @submit.prevent="submitForm"
            >
                <div class="pl-report-item-form__details">
                    <div class="pl-report-item-form__top-section">
                        <span>Report in:</span>

                        <div class="pl-report-item-form__units">
                            <button
                                v-for="unit in selectableUnits"
                                :key="`reporting unit: ${unit.id}`"
                                type="button"
                                class="pl-report-item-form__unit-option"
                                :class="{
                                    'pl-report-item-form__unit-option--active': currentUnitData.id === unit.id,
                                }"
                                :disabled="isReportingUnitOptionDisabled(unit)"
                                @click="reportingUnitsState.currentUnit = unit"
                            >
                                {{ unit.value }}
                            </button>
                        </div>
                    </div>

                    <div class="pl-report-item-form__progress-qty">
                        <span>
                            {{ currentUnitData.text }}

                            {{ reportingUnitsState.data.type === ITEM_TYPES.PREP_ACTION ? 'reported' : 'done' }}
                            previously:
                        </span>

                        <span>{{ currentUnitData.donePreviouslyDisplayQty }}</span>
                    </div>
                </div>

                <div class="pl-report-item-form__qty-wrapper">
                    <div class="pl-report-item-form__current-qty">
                        {{ currentUnitData.text }}

                        {{ reportingUnitsState.data.type === ITEM_TYPES.PREP_ACTION ? 'reported' : 'done' }} this time:
                    </div>

                    <QtyInput
                        v-model="form.qty"
                        is-input-always-allowed
                        is-increment-mode
                        size="lg"
                        :min="0"
                        :max="9999"
                        :step="currentUnitData.qtyStep"
                        :precision="getQtyPrecision(currentUnitData.unitStep)"
                        @blur="onBlurQty"
                        @update:modelValue="form.errors.clear('qty')"
                    />

                    <ValidationErrors
                        v-if="form.errors.has('qty')"
                        :errors="form.errors.get('qty')"
                    />

                    <p v-if="currentUnitData.isQtyUnitsInfoVisible">
                        <span>{{ currentUnitData.text }}</span>
                        <span>
                            ({{ batchYieldQty }} {{ reportingUnitsState.data.units_data.batch_yield_unit.value }})
                        </span>
                    </p>
                </div>
            </form>
        </template>

        <template #actions>
            <button
                type="button"
                class="btn btn-secondary"
                @click="emit('close-modal')"
            >
                Cancel
            </button>

            <button
                type="submit"
                class="btn btn-primary"
                form="pl-report-item-form"
                :disabled="isSubmitBtnDisabled"
            >
                Save
            </button>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.pl-report-item-form {
    &__details {
        font-weight: 400;
        padding-bottom: custom-space(1.25);
        border-bottom: 1px solid $gray-200;
    }

    &__top-section {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: custom-space(1.25);
    }

    &__units {
        display: flex;
    }

    &__unit-option {
        padding: 0 custom-space(0.6);
        cursor: pointer;
        border-radius: 0.4rem;
        border: 1px solid $redLight;

        &:not(:last-child) {
            margin-right: custom-space(0.4);
        }

        &--active {
            color: $white;
            background-color: $redLight;
        }

        &:disabled,
        &[disabled] {
            color: $gray-200;
            border: 1px solid $gray-200;
            cursor: not-allowed;
        }
    }

    &__progress-qty {
        display: flex;
        align-items: center;
        justify-content: space-between;

        span:first-letter {
            text-transform: uppercase;
        }
    }

    &__qty-wrapper {
        margin-top: custom-space(1);

        & > p {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            font-size: $font-size-base * 0.875;
            line-height: $font-size-base;
            margin: 0;
        }

        :deep(.pl-qty-input) {
            justify-content: center;
        }

        :deep(.pl-validation-errors) {
            text-align: center;
        }
    }

    &__current-qty {
        text-align: center;
        font-weight: 400;
        margin-bottom: custom-space(0.25);

        &::first-letter {
            text-transform: uppercase;
        }
    }
}
</style>
