<template>
    <OverlayLoader v-if="state.areCountriesLoading" />

    <div class="d-flex flex-column flex-grow-1">
        <transition
            appear
            name="down"
            type="transition"
        >
            <p
                v-if="!state.employees.length"
                class="mb-5 text-center"
            >
                The list is empty. Please press the plus icon below to add an employee.
            </p>
        </transition>

        <ListDisplay
            v-if="state.employees.length"
            has-borders
            :items="state.employees"
            class="pl-employees-list"
            data-testid="employees_list"
        >
            <template #item="{ item: employee, index }">
                <EmployeeCard
                    exclude-avatar
                    :employee="employee"
                    :class="{
                        'text-danger': hasApiValidationErrors(index),
                    }"
                >
                    <template #actions>
                        <button
                            type="button"
                            data-testid="auth_wizard_edit_employee_btn"
                            @click="editEmployee(employee, index)"
                        >
                            <EditIcon />
                        </button>

                        <button
                            type="button"
                            data-testid="auth_wizard_delete_employee_btn"
                            @click="openDeletionModal(employee)"
                        >
                            <DeleteIcon />
                        </button>
                    </template>
                </EmployeeCard>

                <ValidationErrors
                    v-if="hasApiValidationErrors(index)"
                    :errors="getApiValidationErrors(index)"
                />
            </template>
        </ListDisplay>

        <transition
            appear
            name="down"
            type="transition"
        >
            <div
                class="text-center"
                :style="`transition-delay: ${state.employees.length * 0.5 * 100}ms`"
            >
                <router-link
                    :to="{ name: 'registration.step-2.add-employee' }"
                    data-testid="auth_wizard_add_employee_btn"
                >
                    <div
                        v-if="!state.employees.length"
                        class="pl-empty-list-add-icon-box"
                    >
                        <AddEmployeesIcon />

                        <b>Add employee</b>
                    </div>
                    <div v-else>
                        <PlusIcon />
                    </div>
                </router-link>
            </div>
        </transition>

        <transition
            appear
            name="down"
            type="transition"
        >
            <div
                class="mt-auto"
                :style="`transition-delay: ${(state.employees.length + 1) * 0.5 * 100}ms`"
            >
                <div class="mt-5">
                    <div class="d-grid mt-auto">
                        <router-link
                            :to="{ name: 'registration.step-3' }"
                            class="btn btn-primary"
                            data-testid="auth_wizard_complete_step_2_btn"
                        >
                            Continue
                        </router-link>
                    </div>
                </div>
            </div>
        </transition>

        <DeletionModal
            v-if="isDeletionModalOpened"
            qa-prefix="delete_employee_modal"
            submit-btn-text="remove"
            :is-pending="isDeletionPending"
            @close="closeDeletionModal"
            @submit-delete="deleteEmployee"
        >
            Are you sure you want to remove the invitation of employee "{{ deletionModel.name }}"?
        </DeletionModal>
    </div>
</template>

<script>
export default {
    name: 'RegistrationStep2EmployeesList',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { onBeforeMount, reactive } from 'vue';

import { LS } from '@/instances/localStorage';
import useDeletionModal from '@/composition/useDeletionModal';

import ListDisplay from '@/components/ListDisplay';
import DeletionModal from '@/components/DeletionModal';
import EmployeeCard from '@/components/model-specific/employee/EmployeeCard';
import AddEmployeesIcon from '@/components/icons/AddEmployeesIcon';

const router = useRouter();
const store = useStore();

const state = reactive({
    employees: LS.get('onboarding_employees', []),
    areCountriesLoading: false,
});

const deleteEmployeeFromDepartments = (employee) => {
    const departments = LS.get('onboarding_departments', []);

    if (departments.length > 0) {
        for (let department of departments) {
            const targetEmployeeIndex = department.members.findIndex((account) => {
                return (
                    account.phone.phone_country_id == employee.phone.phone_country_id &&
                    account.phone.phone == employee.phone.phone
                );
            });

            if (targetEmployeeIndex !== -1) {
                department.members.splice(targetEmployeeIndex, 1);
            }
        }

        LS.set('onboarding_departments', departments);
    }
};

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const deleteEmployee = () => {
    deletionCallback(() => {
        state.employees.splice(state.employees.indexOf(deletionModel.value), 1);
        LS.set('onboarding_employees', state.employees);
        deleteEmployeeFromDepartments(deletionModel.value);
        return Promise.resolve();
    });
};

/*------------------------------------------------------------------------------------
                    Edit employee
-----------------------------------------------------------------------------------*/

const editEmployee = (employee, index) => {
    router.push({
        name: 'registration.step-2.edit-employee',
        params: { index },
    });
};

/*---------------------------------------------------------------------
                        API validation errors
--------------------------------------------------------------------*/

const hasApiValidationErrors = (index) => {
    return store.getters['SVE/getErrorsBag']('registration')?.hasOneOf([
        `employees.${index}.name`,
        `employees.${index}.phone`,
    ]);
};
const getApiValidationErrors = (index) => {
    return store.getters['SVE/getErrorsBag']('registration')?.get([
        `employees.${index}.name`,
        `employees.${index}.phone`,
    ]);
};

onBeforeMount(() => {
    if (state.employees.length > 0) {
        state.areCountriesLoading = true;

        store.dispatch('countries/loadCountries').finally(() => (state.areCountriesLoading = false));
    }
});
</script>

<style lang="scss" scoped>
.pl-empty-list-add-icon-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    b {
        position: relative;
        top: -25px;
    }
}

.pl-employees-list {
    margin-bottom: custom-space(1);

    :deep(.pl-validation-errors) {
        font-size: $font-size-base * 0.875;
        line-height: 1.4;
    }
}
</style>
