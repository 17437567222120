<script>
export default {
    name: 'Progress',
};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
    min: {
        type: [String, Number],
        required: true,
    },
    max: {
        type: [String, Number],
        required: true,
    },
    value: {
        type: [String, Number],
        required: true,
    },
});

/*------------------------------------------------------------------------
                              Progress state
------------------------------------------------------------------------*/

const percentages = computed(() => {
    const value = +props.value;
    const max = +props.max;

    if (value > max) {
        return {
            value: 100,
            overlayValue: ((value - max) / value) * 100,
        };
    }

    return {
        value: (value / max) * 100,
        overlayValue: 0,
    };
});
</script>

<template>
    <div class="pl-progress">
        <div
            role="progressbar"
            class="pl-progress__bar"
            :aria-valuenow="value"
            :aria-valuemin="min"
            :aria-valuemax="max"
            :style="`width: ${percentages.value}%`"
        />

        <div
            v-if="percentages.overlayValue > 0"
            role="progressbar"
            class="pl-progress__bar pl-progress__bar--overlay"
            :aria-valuenow="percentages.overlayValue"
            :aria-valuemin="percentages.overlayValue"
            :aria-valuemax="percentages.overlayValue"
            :style="`width: ${percentages.overlayValue}%`"
        />
    </div>
</template>

<style lang="scss" scoped>
.pl-progress {
    position: relative;
    display: flex;
    height: 5px;
    background-color: $gray-200;
    border-radius: $border-radius;
    box-shadow: 0 2px 2px rgba($black, 0.1) inset;
    overflow: hidden;

    &__bar {
        display: flex;
        flex-direction: column;
        background: $progress-bar-bg;
        border-radius: inherit;
        overflow: hidden;
        transition: width 0.6s ease;

        &--overlay {
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            background: $progress-bar-bg--overproduction;
        }
    }
}
</style>
