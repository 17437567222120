<script>
export default {
    name: 'PLOrdersIndexMainView',
};
</script>

<script setup>
import OrdersIndexViewTemplate from '@/components/model-specific/orders/OrdersIndexViewTemplate';
import TimelineMainCook from './partials/timeline-main-cook';
import TimelineMainManager from './partials/TimelineMainManager';
</script>

<template>
    <OrdersIndexViewTemplate>
        <template #viewAsCook>
            <TimelineMainCook />
        </template>

        <template #viewAsManager>
            <TimelineMainManager />
        </template>
    </OrdersIndexViewTemplate>
</template>
