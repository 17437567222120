<script>
export default {
    name: 'EditQFOrderStepThreeView',
};
</script>

<script setup>
import { reactive, onBeforeMount } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import ErrorsBag from '@/utils/form/ErrorsBag';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ORDER_TYPES, ORDER_GROUP_ITEMS_BY } from '@/enums/orderEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import QFOrderModel from '@/models/QFOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const store = useStore();
const router = useRouter();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount?.organization.id;

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const orderState = reactive({
    data: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadOrder = () => {
    sendAbortableRequest(
        QFOrderModel.find(orgId, props.orderId, {
            group_items_by: ORDER_GROUP_ITEMS_BY.CATEGORY,
        })
    )
        .then((data) => (orderState.data = data))
        .finally(() => (orderState.isDataLoading = false));
};

const completeOrderDraft = () => {
    orderState.isActionProcessing = true;

    orderState.data
        .completeDraft()
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully changed',
            });

            router.push({
                name: QFO_ROUTE_NAMES.SHOW,
                params: { orderId: props.orderId },
            });

            store.commit('setCurrentQFOrder', null);
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                if ('prep_date' in data.errors) {
                    EventBus.$emit('pl.global-modal.open', {
                        title: 'Error occurred',
                        message: new ErrorsBag().flatten(data.errors).join('\r\n'),
                    });
                }
            }
        })
        .finally(() => (orderState.isActionProcessing = false));
};

/*------------------------------------------------------------------------
                            Load necessary data
------------------------------------------------------------------------*/

onBeforeMount(loadOrder);
</script>

<template>
    <OrderStepTemplate :is-step-submitting="orderState.isActionProcessing">
        <template #step-content>
            <InPlaceLoader v-if="orderState.isDataLoading" />

            <OrderSummaryCard
                v-if="orderState.data !== null"
                :order="orderState.data.draft"
                :order-type="ORDER_TYPES.QUICK_FILL"
            />
        </template>

        <template #prev-step-link>
            <router-link
                class="btn btn-secondary"
                :to="{
                    name: QFO_ROUTE_NAMES.EDIT.ADD_ITEMS,
                    params: { orderId },
                }"
            >
                Select Items
            </router-link>
        </template>

        <template #next-step-action>
            <button
                type="button"
                class="btn btn-primary"
                :disabled="orderState.isDataLoading || orderState.isActionProcessing"
                @click="completeOrderDraft"
            >
                Confirm
            </button>
        </template>
    </OrderStepTemplate>
</template>
