<script>
export default {
    name: 'SplitTheWorkTab',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';

import { getSplitDistributionPayload } from '@/utils/selectableItemsUtils';
import { getBatchUnitQtyDisplay } from '@/utils/batchUnitsUtils';
import { cutFloatNumber } from '@/utils/numberUtils';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import RangeSliderInput from '@/components/form-controls/RangeSliderInput';
import DistributionTabUI from './DistributionTabUI';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    itemAssigneeData: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-distribution']);

/*------------------------------------------------------------------------
                              Assignees state
------------------------------------------------------------------------*/

const assigneesDistribution = ref([]);

const getDistributionData = ({ id, name }) => {
    const { item, itemAssigneeData } = props;
    const { distribution, distribution_type: distributionType } = itemAssigneeData;

    const defaultDistributionData = {
        name,
        assignee_id: id,
        percentage: 0,
        capped: 100,
    };

    if (distributionType !== DISTRIBUTION_TYPES.SPLIT) {
        return defaultDistributionData;
    }

    const distributionAssigneeData = distribution.find((assignee) => assignee.assignee_id === id);

    if (!distributionAssigneeData) {
        return defaultDistributionData;
    }

    const percentage = distributionAssigneeData.qty * (100 / item.ordered_BY_qty);

    return {
        ...defaultDistributionData,
        percentage,
        capped: percentage,
    };
};

watch(
    () => props.itemAssigneeData,
    () => (assigneesDistribution.value = props.item.available_assignees.map(getDistributionData)),
    { immediate: true }
);

/*------------------------------------------------------------------------
                             Percentage state
------------------------------------------------------------------------*/

const getAssigneeQtyDisplay = (percentage) => {
    const { units_data: unitsData, ordered_BY_qty: orderedBYQty } = props.item;

    return getBatchUnitQtyDisplay(
        unitsData,
        unitsData.batch_yield_unit,
        unitsData.prep_list_ordering_unit,
        (orderedBYQty * percentage) / 100
    );
};

const assigneesTotalPercentage = computed(() => {
    const total = assigneesDistribution.value.reduce((total, { percentage }) => total + percentage, 0);

    return Math.min(total, 100);
});

const validateAssigneeDistribution = (assigneeIndex) => {
    const { ordered_BY_qty: orderedBYQty } = props.item;

    const assignee = assigneesDistribution.value[assigneeIndex];

    const coefficient = (assignee.percentage * orderedBYQty) / 100;

    if (!Number.isInteger(coefficient)) {
        const closestAllowedPercentage = (Math.round(coefficient) * 100) / orderedBYQty;

        assignee.percentage = Math.min(closestAllowedPercentage, assignee.capped);
    }

    for (let assignee of assigneesDistribution.value) {
        assignee.capped = assignee.percentage + (100 - assigneesTotalPercentage.value);
    }
};

const percentageRangeSliderStep = computed(() => Math.min(100 / props.item.ordered_BY_qty, 1));

/*------------------------------------------------------------------------
                          Distribution tab state
------------------------------------------------------------------------*/

const getDistributionPayload = () => {
    const distributionData = assigneesDistribution.value.reduce((acc, assigneeData) => {
        if (assigneeData.percentage > 0) {
            acc.push({
                id: assigneeData.assignee_id,
                percentage: assigneeData.percentage,
            });
        }

        return acc;
    }, []);

    return getSplitDistributionPayload(props.item, distributionData);
};

const setDistribution = () => {
    const payload = {
        distributionType: DISTRIBUTION_TYPES.SPLIT,
        distribution: getDistributionPayload(),
    };

    emit('set-distribution', payload);
};
</script>

<template>
    <DistributionTabUI>
        <template #ribbon-text>
            Each employee only sees their part and must report on it separately from the others.
        </template>

        <template #tab-content>
            <ul class="pl-split-distribution-tab__list">
                <li
                    v-for="(assignee, index) in assigneesDistribution"
                    :key="`assignee-${assignee.name}`"
                >
                    <div class="pl-split-distribution-tab__details">
                        <span>{{ assignee.name }}</span>

                        <p>
                            {{ cutFloatNumber(assignee.percentage) }}%

                            <small v-if="assignee.percentage > 0">
                                ({{ getAssigneeQtyDisplay(assignee.percentage) }})
                            </small>
                        </p>
                    </div>

                    <RangeSliderInput
                        v-model="assignee.percentage"
                        show-max
                        show-min
                        show-min-template="${value}%"
                        show-max-template="${value}%"
                        :precision="2"
                        :step="percentageRangeSliderStep"
                        :capped="assignee.capped"
                        @slider-stopped="validateAssigneeDistribution(index)"
                    />
                </li>
            </ul>
        </template>

        <template #footer-content="{ classNames }">
            <div :class="classNames.actions">
                <slot name="cancel-btn" />

                <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="assigneesTotalPercentage < 100"
                    @click="setDistribution"
                >
                    Apply
                </button>
            </div>

            <div class="pl-split-distribution-tab__total-percentage">
                {{ cutFloatNumber(assigneesTotalPercentage) }}% allocated to employees
            </div>
        </template>
    </DistributionTabUI>
</template>

<style lang="scss" scoped>
.pl-split-distribution-tab {
    &__list {
        padding-left: custom-space(0.5);
        list-style: none;

        @include media-breakpoint-down(md) {
            padding-left: custom-space(0.75);
        }

        & > li {
            margin-bottom: custom-space(0.75);
        }
    }

    &__details {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: custom-space(1);
        font-weight: 400;

        & > span {
            font-size: $font-size-base * 1.1;

            &:last-child {
                color: $primary;
                flex-shrink: 0;
            }
        }

        & > p {
            flex-shrink: 0;
            color: $primary;
            line-height: 1.25;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 0;

            & > small {
                color: $black;
            }
        }
    }

    &__total-percentage {
        text-align: center;
        font-weight: 400;
        margin-top: custom-space(1);
    }
}
</style>
