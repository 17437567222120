export default class {
    constructor() {
        this._bag = {};
    }

    record(bag = {}) {
        this._bag = bag;
        this.notify();
    }

    any() {
        return !!Object.entries(this._bag).length;
    }

    push(field, error) {
        if (!this._bag[field]) {
            this._bag[field] = [];
        }

        this._bag[field].push(error);
        this.notify();
    }

    has(field) {
        return !!(field in this._bag && this._bag[field].length);
    }

    hasOneOf(fields) {
        const bagKeys = Object.keys(this._bag);

        return fields.some((field) => {
            if (/\*/.test(field)) {
                return (
                    bagKeys.find((key) => new RegExp(`^${field.replaceAll('*', '[0-9]+')}$`).test(key)) !== undefined
                );
            }
            return bagKeys.includes(field);
        });
    }

    clear(field) {
        if (Array.isArray(field)) {
            const bagKeys = Object.keys(this._bag);

            for (let name of field) {
                if (/\*/.test(name)) {
                    delete this._bag[
                        bagKeys.find((key) => new RegExp(`^${name.replaceAll('*', '[0-9]+')}$`).test(key))
                    ];
                } else {
                    delete this._bag[name];
                }
            }
        } else {
            delete this._bag[field];
        }

        this.notify();
    }

    clearAll() {
        this._bag = {};
        this.notify();
    }

    get(field = null) {
        if (Array.isArray(field)) {
            return field.reduce((carry, current) => {
                if (/\*/.test(current)) {
                    return [
                        ...carry,
                        ...new Set(
                            Object.entries(this._bag)
                                .filter(([key]) => new RegExp(`^${current.replaceAll('*', '[0-9]+')}$`).test(key))
                                .reduce((subCarry, subCurrent) => [...subCarry, ...subCurrent[1]], [])
                        ),
                    ];
                }
                return [...carry, ...(this._bag[current] || [])];
            }, []);
        }
        return field ? this._bag[field] : this._bag;
    }

    static flatten(bag = null) {
        return (
            Object.entries(bag ?? this._bag)
                // eslint-disable-next-line no-unused-vars
                .reduce((carry, [field, errors]) => carry.concat(errors), [])
        );
    }

    mergeFor(field, errors) {
        for (const error of errors) {
            this.push(field, error);
        }
        this.notify();
    }

    notify() {
        this.__ob__?.dep?.notify();
    }
}
