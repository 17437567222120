import { createRouter, createWebHistory } from 'vue-router';

import EventBus from '@/utils/EventBus';
import { AUTH_ROUTE_NAMES, NOT_FOUND_ROUTE_NAME } from '@/enums/routesNameEnums';

import LoginView from '@/views/auth/login';
import JoinView from '@/views/auth/join';
import AuthOptionsView from '@/views/auth/options';

import RegistrationStep1 from '@/views/auth/registration/step-1';

import RegistrationStep2Index from '@/views/auth/registration/step-2';
import RegistrationStep2Main from '@/views/auth/registration/step-2/main';
import RegistrationStep2Create from '@/views/auth/registration/step-2/create';
import RegistrationStep2Edit from '@/views/auth/registration/step-2/edit';

import RegistrationStep3Index from '@/views/auth/registration/step-3';
import RegistrationStep3Main from '@/views/auth/registration/step-3/main';
import RegistrationStep3Create from '@/views/auth/registration/step-3/create';
import RegistrationStep3Edit from '@/views/auth/registration/step-3/edit';

import NotFoundView from '@/views/not-found';

import dashboard from './dashboard';

const routes = [
    {
        path: '/',
        name: AUTH_ROUTE_NAMES.LOGIN,
        component: LoginView,
        meta: { guestOnly: true },
    },
    {
        path: '/join',
        name: 'join',
        component: JoinView,
    },
    {
        path: '/auth/options',
        name: 'auth.options',
        component: AuthOptionsView,
        meta: { authOnly: true },
    },
    {
        path: '/registration/step-1',
        name: 'registration.step-1',
        component: RegistrationStep1,
        meta: { authOnly: true },
    },
    {
        path: '/registration/step-2',
        component: RegistrationStep2Index,
        meta: { authOnly: true },
        children: [
            {
                path: '',
                name: 'registration.step-2',
                component: RegistrationStep2Main,
            },
            {
                path: 'add-employee',
                name: 'registration.step-2.add-employee',
                component: RegistrationStep2Create,
            },
            {
                path: 'edit-employee/:index',
                name: 'registration.step-2.edit-employee',
                component: RegistrationStep2Edit,
            },
        ],
    },
    {
        path: '/registration/step-3',
        component: RegistrationStep3Index,
        meta: { authOnly: true },
        children: [
            {
                path: '',
                name: 'registration.step-3',
                component: RegistrationStep3Main,
            },
            {
                path: 'add-department',
                name: 'registration.step-3.add-department',
                component: RegistrationStep3Create,
            },
            {
                path: 'edit-department/:index',
                name: 'registration.step-3.edit-department',
                component: RegistrationStep3Edit,
            },
        ],
    },
]
    .concat(dashboard)
    .concat([
        {
            path: '/:pathMatch(.*)*',
            name: NOT_FOUND_ROUTE_NAME,
            component: NotFoundView,
            meta: { authOnly: true },
        },
    ]);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

const loadingAppState = new Promise((resolve, reject) => {
    EventBus.$on('appstate.loading.succeed', resolve);
    EventBus.$on('appstate.loading.failed', reject);
});

router.beforeEach(async (to, from, next) => {
    await loadingAppState
        .then(({ user, authState }) => {
            /**
             * In case there is no current account at this point, it means
             * the application did not manage to determine what the current
             * account is after the loading app state from the server (see
             * store.loadAppState).
             *
             * In this case we should redirect the user to the auth options page
             * so that (s)he is able to pick the current account manually.
             */
            const allowedRoutesWithoutAccount = [
                'auth.options',
                'registration.step-1',
                'registration.step-2',
                'registration.step-2.add-employee',
                'registration.step-2.edit-employee',
                'registration.step-3',
                'registration.step-3.add-department',
                'registration.step-3.edit-department',
            ];

            if (user !== null && !authState.currentAccount && !allowedRoutesWithoutAccount.includes(to.name)) {
                return next({ name: 'auth.options' });
            }

            /*---------------------------------------------------------------------
                                Regular handling auth & guests
            --------------------------------------------------------------------*/

            if (to.matched.some((route) => route.meta?.authOnly) && null === user) {
                next({
                    name: to.meta?.redirectTo || AUTH_ROUTE_NAMES.LOGIN,
                    query: {
                        come_from: window.location.href,
                    },
                });
            } else if (to.matched.some((route) => route.meta?.guestOnly) && null !== user) {
                next({ name: to.meta?.redirectTo || 'auth.options' });
            } else {
                next();
            }
        })
        .catch(() => next({ name: 'auth.options' })); // TODO: 500 page
});

export default router;
