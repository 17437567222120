/*------------------------------------------------------------------------
                                Time delay
------------------------------------------------------------------------*/

export const PREP_TASK_TIME_DELAY = {
    0: 'Same Day',
    1: 'A Day Before',
    2: 'Two Days Before',
};

/*------------------------------------------------------------------------
                                 Group by
------------------------------------------------------------------------*/

export const PREP_TASKS_GROUP_BY = {
    DEPARTMENT: 'department',
};

/*------------------------------------------------------------------------
                          Filter settings config
------------------------------------------------------------------------*/

export const PREP_TASKS_FILTER_SETTINGS_CONFIG = {
    group_by: {
        title: 'Group By',
        options: [{ value: PREP_TASKS_GROUP_BY.DEPARTMENT, text: 'Department' }],
        defaultValue: null,
    },
};
