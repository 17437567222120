import ApiClient from '@/utils/ApiClient';

export default {
    namespaced: true,

    state: () => ({
        countries: null,
        currentRequest: null,
    }),

    mutations: {
        setCountries(state, countries) {
            state.countries = countries;
        },
        setCurrentRequest(state, request) {
            state.currentRequest = request;
        },
    },

    actions: {
        loadCountries({ state, commit, getters }) {
            if (state.currentRequest !== null) {
                return state.currentRequest;
            }

            if (!getters.areCountriesLoaded) {
                const request = ApiClient.get('countries?is_active=1');

                commit('setCurrentRequest', request);

                request
                    .then(({ data }) => {
                        commit('setCountries', data);
                        commit('setCurrentRequest', null);
                        return data;
                    })
                    .catch(() => commit('setCountries', null));

                return request;
            }

            return Promise.resolve(state.countries);
        },
    },

    getters: {
        areCountriesLoaded: (state) => {
            return state.countries !== null;
        },
    },
};
