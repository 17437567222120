import { echo } from '@/plugins/echo';
import { LS } from '@/instances/localStorage';

import { Document } from '@/instances/document';
import ApiClient from '@/utils/ApiClient';
import EventBus from '@/utils/EventBus';

const state = () => ({
    user: null,
    currentAccount: null,
    currentAccountNotificationsChannel: null,
    isLogOutPending: false,
});

const mutations = {
    setUser(state, user) {
        state.user = user;
        EventBus.$emit('auth.user.updated', state.user);
    },
    setCurrentAccount(state, account) {
        // leave previously set private notifications channel anyway
        echo.leave(`App.Models.OrganizationAccount.${state.currentAccount?.id}`);
        state.currentAccountNotificationsChannel = null;

        if (!account) {
            state.currentAccount = null;
            LS.remove('actor.account.id');
            LS.remove('actor.account.chosen.id');
            ApiClient.setHeaders({ 'X-ACTOR-ACCOUNT-ID': null });
            return;
        }

        state.currentAccount = account;
        Document.setUnfinishedActionsInTitle(state.currentAccount.unread_notifications_count);

        // subscribe to private notifications channel
        state.currentAccountNotificationsChannel = echo
            .private(`App.Models.OrganizationAccount.${account.id}`)
            .notification((notification) => {
                EventBus.$emit('pl.notification.received', notification);
                state.currentAccount.unread_notifications_count++;
                Document.setUnfinishedActionsInTitle(state.currentAccount.unread_notifications_count);
            });

        LS.set('actor.account.id', account.id);
        LS.set('actor.account.chosen.id', account.id);
        ApiClient.setHeaders({ 'X-ACTOR-ACCOUNT-ID': account.id });
    },
    setOrganizationName(state, organizationName) {
        state.currentAccount.organization.name = organizationName;
    },
    setIsLogOutPending(state, isLogOutPending) {
        state.isLogOutPending = isLogOutPending;
    },
};

const actions = {
    logout({ state, commit }) {
        commit('setIsLogOutPending', true);

        return ApiClient.post('auth/logout')
            .then(() => {
                echo.leave(`App.Models.OrganizationAccount.${state.currentAccount?.id}`);
                LS.remove('actor.account.id');
                ApiClient.setHeaders({ 'X-ACTOR-ACCOUNT-ID': null });
            })
            .catch(() => commit('setIsLogOutPending', false));
    },
};

const getters = {};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
