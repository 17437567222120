<script>
export default {
    name: 'TimelineView',
};
</script>

<script setup>
import { computed, inject, watch } from 'vue';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import { isCook } from '@/utils/accountUtils';
import { mapGetters } from '@/utils/vuex';
import { isOrderEditing } from '@/utils/orderUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { QFO_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { QFO_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { QF_FILTER_ORDERS_BY, QF_DEFAULT_FILTER_ORDERS_BY } from '@/enums/timelineEnums';
import useTimeline from '@/composition/useTimeline';
import Alert from '@/components/Alert';
import Fab from '@/components/Fab';
import DropDown from '@/components/DropDown';
import DatePickerHOC from '@/components/HOC/DatePickerHOC';
import ListDisplay from '@/components/ListDisplay';
import TimelineOrderCard from '@/components/model-specific/orders/TimelineOrderCard';
import DatesCarousel from '@/components/model-specific/orders/DatesCarousel';
import UnfinishedOrderRibbon from '@/components/model-specific/orders/UnfinishedOrderRibbon';
import GroupedSettings from '@/components/constructions/grouped-settings';
import FilterIcon from '@/components/icons/FilterIcon';

const $acl = inject('$acl');

const store = useStore();

const { currentAccount } = store.state.auth;

const { currentQFOrder } = mapGetters(['currentQFOrder']);

/*------------------------------------------------------------------------
                                 Timeline
------------------------------------------------------------------------*/

const initialFiltersConfig = {
    start_date: dayjs().format('YYYY-MM-DD'),
};

const {
    timelineState,
    timelineDates,
    setTimeline,
    loadTimeline,

    selectedDateIndex,
    isDateSlideChanging,
    selectedOrdersList,

    paginationState,
    areLoadedBackwardPagesAvailable,
    areNextBackwardPagesAvailable,
    areLoadedForwardPagesAvailable,
    areNextForwardPagesAvailable,
    loadTimelineBackwardPage,
    loadTimelineForwardPage,

    filters,
} = useTimeline(null, initialFiltersConfig, true);

const factory = computed(() => {
    if (isCook(currentAccount)) {
        return QF_FILTER_ORDERS_BY.ALL;
    }

    return store.state.QFOrderViewSettings.filterBy;
});

const unfinishedOrderLink = computed(() => {
    const order = currentQFOrder.value;

    if (isOrderEditing(order)) {
        return {
            name: QFO_ROUTE_NAMES.EDIT.INDEX,
            params: { orderId: order.parent_id },
        };
    }

    return { name: QFO_ROUTE_NAMES.CREATE.INDEX };
});

/*------------------------------------------------------------------------
                              Filter items by
------------------------------------------------------------------------*/

const filterOrderByOptions = computed(() => {
    const { name } = currentAccount.location;

    return {
        [QF_FILTER_ORDERS_BY.ALL]: 'All',
        [QF_FILTER_ORDERS_BY.CREATED_BY_ME]: 'Orders I created',
        [QF_FILTER_ORDERS_BY.LOCATION]: `Produced in ${name}`,
        [QF_FILTER_ORDERS_BY.DESTINATION]: `Shipped to ${name}`,
    };
});

const isOptionFilterBySelected = (optionValue) => store.state.QFOrderViewSettings.filterBy === optionValue;

const setOptionFilterBy = (optionValue) => store.commit('QFOrderViewSettings/setFilterBy', optionValue);

/*------------------------------------------------------------------------
                               Load timeline
------------------------------------------------------------------------*/

watch(
    () => [store.state.QFOrderViewSettings.filterBy, filters],
    () => {
        timelineState.isDataLoading = true;

        loadTimeline({ page_forward: 1, page_backward: 1, include_start_date: 1 }, factory.value)
            .then(setTimeline)
            .finally(() => (timelineState.isDataLoading = false));
    },
    { deep: true, immediate: true }
);
</script>

<template>
    <TopBarLayout exclude-content-container>
        <template #title>
            <h1>Quick Fill Orders</h1>
        </template>

        <template
            v-if="!timelineState.isDataLoading"
            #actions
        >
            <DatePickerHOC
                v-model="filters.start_date"
                menu-class-name="pl-timeline-view-calendar"
            />

            <DropDown v-if="!isCook(currentAccount)">
                <template #content>
                    <GroupedSettings.Root class="pl-qf-timeline__grouped-settings">
                        <GroupedSettings.List title="Show">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in filterOrderByOptions"
                                :key="`filter-order-by-option-${value}`"
                                :is-selected="isOptionFilterBySelected(value)"
                                @click="setOptionFilterBy(value)"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List title="Orders">
                            <GroupedSettings.ListItem>
                                <router-link
                                    :to="{
                                        name: QFO_ROUTE_NAMES.CREATE.INDEX,
                                        query: { action: 'new-order' },
                                    }"
                                >
                                    Create new order
                                </router-link>
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <template
            v-if="currentQFOrder !== null"
            #ribbon
        >
            <UnfinishedOrderRibbon :navigate-to="unfinishedOrderLink" />
        </template>

        <InPlaceLoader v-if="timelineState.isDataLoading" />

        <div
            v-else-if="timelineDates === null"
            class="container"
        >
            <Alert :type="ALERT_TYPES.FAIL">
                Orders list could not be loaded for some reason. Please try again later.
            </Alert>
        </div>

        <template v-else>
            <DatesCarousel
                v-model="selectedDateIndex"
                :dates="timelineDates"
                :are-loaded-prev-pages-available="areLoadedBackwardPagesAvailable"
                :are-unloaded-prev-pages-available="areNextBackwardPagesAvailable"
                :is-prev-page-being-loaded="paginationState.isBackwardPageLoading"
                :are-loaded-next-pages-available="areLoadedForwardPagesAvailable"
                :are-unloaded-next-pages-available="areNextForwardPagesAvailable"
                :is-next-page-being-loaded="paginationState.isForwardPageLoading"
                @slide-start="isDateSlideChanging = true"
                @slide-end="isDateSlideChanging = false"
                @prev-page-requested="loadTimelineBackwardPage(factory)"
                @next-page-requested="loadTimelineForwardPage(factory)"
            />

            <div class="container">
                <transition-group
                    appear
                    name="down"
                    type="transition"
                >
                    <template v-if="!isDateSlideChanging">
                        <div
                            v-if="factory !== QF_DEFAULT_FILTER_ORDERS_BY"
                            key="active_filter"
                            class="pl-qf-timeline__active-filter"
                        >
                            <p>
                                <FilterIcon />

                                <span>
                                    {{ filterOrderByOptions[factory] }}
                                </span>
                            </p>

                            <button
                                type="button"
                                @click="setOptionFilterBy(QF_DEFAULT_FILTER_ORDERS_BY)"
                            >
                                <FilterIcon />

                                <span>
                                    <CrossIcon />
                                </span>
                            </button>
                        </div>

                        <ListDisplay
                            v-if="selectedOrdersList?.length > 0"
                            key="orders_list"
                            :items="selectedOrdersList"
                            class="pl-qf-timeline__orders-list"
                            data-testid="orders_list"
                        >
                            <template #item="{ item: order }">
                                <TimelineOrderCard
                                    is-quick-fill
                                    :order="order"
                                />
                            </template>
                        </ListDisplay>

                        <p
                            v-else
                            key="no_orders_message"
                            class="pl-qf-timeline__message"
                        >
                            There are no orders for the selected prep date.
                        </p>
                    </template>
                </transition-group>
            </div>

            <Fab
                v-if="$acl.can(QFO_RESTRICTIONS.CREATE, currentAccount)"
                data-testid="create_new_qfo_fab_btn"
            >
                <router-link :to="{ name: QFO_ROUTE_NAMES.CREATE.INDEX, query: { action: 'new-order' } }">
                    <PlusIcon />
                </router-link>
            </Fab>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
.pl-qf-timeline {
    &__active-filter {
        $space: $grid-gutter-width * 0.5;

        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: $orange-300;
        margin-top: custom-space(-1.5);
        padding: custom-space(0.3) $space;
        gap: custom-space(0.5);

        @include media-breakpoint-down(lg) {
            padding: custom-space(0.3) 0;
            box-shadow: $space 0 0 $orange-300, -$space 0 0 $orange-300;
        }

        & > p {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: custom-space(0.5);
            margin: 0;

            span {
                font-size: $font-size-base * 0.775;
                font-weight: 400;
                color: $orange-700;
                padding-top: 2px;
            }
        }

        & > button {
            position: relative;
            cursor: pointer;
            line-height: 1;

            svg :deep(path) {
                fill: $orange-800;
                stroke: $orange-800;
            }

            span {
                position: absolute;
                bottom: 0;
                right: -2px;
                display: flex;

                svg {
                    width: 6px;
                    height: 6px;
                }
            }
        }

        svg {
            width: 17px;
            height: 17px;
            transform: rotateY(180deg);
        }
    }

    &__orders-list {
        padding: custom-space(0.5) 0 custom-space(3) 0;
    }

    &__message {
        text-align: center;
        padding: custom-space(3) 0;
    }
}

:deep(.pl-dropdown__content > .pl-grouped-settings) {
    width: custom-space(14);
}
</style>
