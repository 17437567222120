/*------------------------------------------------------------------------
                               Account roles
------------------------------------------------------------------------*/

export const ACCOUNT_ROLES = {
    ADMIN: 'admin',
    MANAGER: 'manager',
    COOK: 'cook',
};

export const ROLE_OPTIONS = [
    { value: ACCOUNT_ROLES.ADMIN, text: 'Admin' },
    { value: ACCOUNT_ROLES.MANAGER, text: 'Manager' },
    { value: ACCOUNT_ROLES.COOK, text: 'Cook' },
];
