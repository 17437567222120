import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import MyAccountsIndexView from '@/views/dashboard/settings/my-accounts';

export default [
    {
        path: 'settings/my-accounts',
        name: SETTINGS_ROUTE_NAMES.MY_ACCOUNTS,
        component: MyAccountsIndexView,
    },
];
