<script>
export default {
    name: 'OrderItemsSummary',
};
</script>

<script setup>
import TreeDisplay from '@/components/TreeDisplay';
import ListDisplay from '@/components/ListDisplay';
import SummaryTotal from '@/components/model-specific/orders/SummaryTotal';
import OrderItemSummary from './OrderItemSummary';

defineProps({
    order: {
        type: Object,
        required: true,
    },
    items: {
        type: Object,
        required: true,
    },
});
</script>

<template>
    <article class="pl-order-summary">
        <TreeDisplay
            :data="items"
            node-source-column="data"
        >
            <template #node="{ key }">
                <h5>{{ key }}</h5>
            </template>

            <template #leaf="{ leaf }">
                <ListDisplay :items="leaf.data">
                    <template #item="{ item }">
                        <OrderItemSummary :item="item" />
                    </template>
                </ListDisplay>
            </template>
        </TreeDisplay>

        <SummaryTotal
            :labor-time="items.labor_time"
            :food-cost="items.food_cost"
            :labor-cost="items.labor_cost"
        />
    </article>
</template>

<style lang="scss" scoped>
:deep(.pl-tree-display__node) {
    margin-bottom: custom-space(1.5);

    > h5 {
        margin-bottom: 0;
    }
}
</style>
