<script>
export default {
    name: 'PlusAltIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 21,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        :width="width"
        :height="height"
    >
        <g
            clip-path="url(#a)"
            fill="#CF382F"
        >
            <path d="M12 22.5a10.5 10.5 0 1 1 0-21 10.5 10.5 0 0 1 0 21Zm0 1.5a12 12 0 1 0 0-24 12 12 0 0 0 0 24Z" />
            <path
                d="M12 6a.75.75 0 0 1 .75.75v4.5h4.5a.75.75 0 1 1 0 1.5h-4.5v4.5a.75.75 0 1 1-1.5 0v-4.5h-4.5a.75.75 0 1 1 0-1.5h4.5v-4.5A.75.75 0 0 1 12 6Z"
            />
        </g>
        <defs>
            <clipPath id="a">
                <path
                    fill="#fff"
                    d="M0 0h24v24H0z"
                />
            </clipPath>
        </defs>
    </svg>
</template>