class documentInstance {
    constructor() {
        this.originalTitle = this._getInstance().title;
    }

    _getInstance() {
        return window.document;
    }

    setUnfinishedActionsInTitle(amount = 0) {
        const instance = this._getInstance();

        if (amount > 0) {
            instance.title = `${this.originalTitle} (${amount})`;
        } else {
            instance.title = this.originalTitle;
        }
    }
}

export const Document = new documentInstance();
