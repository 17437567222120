<template>
    <AuthLayout is-content-centered>
        <h1 class="h4 text-center mb-5">
            Joining Prep List...
        </h1>

        <InPlaceLoader v-if="loading" />

        <Alert
            v-if="errorOcurred"
            :type="ALERT_TYPES.FAIL"
        >
            Your invitation link is invalid. Please contact company owner to get a new one.
        </Alert>
    </AuthLayout>
</template>

<script>
export default {
    name: 'JoinView',
};
</script>

<script setup>
import { onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import Alert from '@/components/Alert';
import ApiClient from '@/utils/ApiClient';
import { ALERT_TYPES } from '@/enums/componentsEnums';

import AccountModel from '@/models/Account';

const route = useRoute();
const store = useStore();

const loading = ref(true);
const errorOcurred = ref(false);

const login = () =>
    ApiClient.get(`auth/login/invitation?join_token=${route.query.join_token}`)
        .then(({ data }) => {
            store.commit('auth/setCurrentAccount', AccountModel.make(data.account));
            window.location.href = route.query.come_from || '/';
        })
        .catch(() => {
            errorOcurred.value = true;
            loading.value = false;
        });

onMounted(async () => {
    if (store.state.auth.currentAccount) {
        await store.dispatch('auth/logout');
        return await login();
    }
    login();
});
</script>
