<script>
export default {
    name: 'SupplierCard',
};
</script>

<script setup>
import { useSlots } from 'vue';

defineProps({
    supplier: {
        type: Object,
        required: true,
    },
});

const slots = useSlots();

/*------------------------------------------------------------------------
                                    QA
------------------------------------------------------------------------*/

const qaPrefix = 'supplier';
</script>

<template>
    <div
        class="pl-supplier-card"
        :data-testid="qaPrefix"
    >
        <h6 :data-testid="`${qaPrefix}_name`">
            {{ supplier.name }}
        </h6>

        <div
            v-if="slots.actions"
            class="pl-supplier-card__actions"
        >
            <slot
                name="actions"
                :qaPrefix="qaPrefix"
            />
        </div>
    </div>
</template>

<style lang="scss" scoped>
.pl-supplier-card {
    display: flex;
    align-items: center;

    & > h6 {
        flex-grow: 1;
        font-weight: $font-weight-normal;
        margin: 0;
    }

    &__actions {
        flex-shrink: 0;
        display: flex;
        align-items: center;
        gap: custom-space(0.75);
    }
}
</style>
