<template>
    <div
        ref="$root"
        class="pl-text-input"
        :class="rootEleDynamicClasses"
        v-bind="rootElFallThroughAttrs"
    >
        <input
            ref="$input"
            type="text"
            inputmode="decimal"
            class="form-control"
            :pattern="patternAttr"
            :value="valueWithPrecision"
            :class="inputElDynamicClasses"
            v-bind="inputElFallthroughAttrs"
            :data-testid="dataTestId"
            @blur="onBlur"
            @input="onInput"
        />

        <label
            v-if="label"
            class="form-label"
            :class="{
                'form-label--required': includeAsterisk,
            }"
        >
            {{ label }}
        </label>
    </div>
</template>

<script>
export default {
    name: 'NumericInput',
    inheritAttrs: false,
};
</script>

<script setup>
/**
 * NumericInput component.
 * @version 1.0.0
 * @author Paul Kyslychenko
 */
import { ref, nextTick, useAttrs, computed, onMounted } from 'vue';

const emit = defineEmits(['update:modelValue', 'blur']);

const props = defineProps({
    label: {
        type: String,
        default: null,
    },
    modelValue: {
        type: [String, Number],
        default: null,
    },
    autofocus: {
        type: Boolean,
        default: false,
    },
    max: {
        type: [String, Number],
        default: null,
    },
    min: {
        type: [String, Number],
        default: null,
    },
    loading: {
        type: Boolean,
        default: false,
    },
    hasError: {
        type: Boolean,
        default: false,
    },
    minPrecision: {
        type: Number,
        default: null,
        validator: (value) => value > 0,
    },
    maxPrecision: {
        type: Number,
        default: null,
        validator: (value) => value > 0,
    },
    alwaysUseDot: {
        type: Boolean,
        default: false,
    },
    onlyPositive: {
        type: Boolean,
        default: false,
    },
    required: {
        type: Boolean,
        default: false,
    },
    includeAsterisk: {
        type: Boolean,
        default: false,
    },
    dataTestId: {
        type: String,
        default: null,
    },
});

const $input = ref(null);

/*---------------------------------------------------------------------
                        Dynamic classes
--------------------------------------------------------------------*/

const inputElDynamicClasses = computed(() => ({
    'is-invalid': props.hasError,
}));

const rootEleDynamicClasses = computed(() => ({
    'pl-text-input--loading': !!props.loading,
    'pl-text-input--filled': !!props.modelValue,
    'pl-text-input--has-label': !!props.label,
}));

/*---------------------------------------------------------------------
                    Fallthrough attributes
--------------------------------------------------------------------*/

const attrs = useAttrs();

const inputElFallthroughAttrs = computed(() => {
    const { id, name, tabindex, disabled, placeholder, autocomplete } = attrs;

    return {
        id,
        name,
        tabindex,
        disabled,
        placeholder,
        autocomplete,
    };
});

const rootElFallThroughAttrs = computed(() => {
    const attrNames = Object.keys(attrs);
    const customAttrs = {};

    // include every custom data-* attribute
    for (let name of attrNames) {
        if (/^data-.*$/.test(name)) {
            customAttrs[name] = attrs[name];
        }
    }

    return {
        id: attrs['id'],
        class: attrs['class'],
        title: attrs['title'],
        ...customAttrs,
    };
});

/*---------------------------------------------------------------------
                        Pattern attribute
--------------------------------------------------------------------*/

const patternAttr = computed(() => {
    const { minPrecision, maxPrecision } = props;

    let pattern = null;

    switch (true) {
        case minPrecision !== null && maxPrecision !== null:
            pattern = `\\d+[.,]\\d{${minPrecision},${maxPrecision}}`;
            break;
        case minPrecision !== null:
            pattern = `\\d+[.,]\\d{${minPrecision},}`;
            break;
        case maxPrecision !== null:
            pattern = `\\d+(?:[.,]\\d{1,${maxPrecision}})?`;
            break;
        case props.alwaysUseDot:
            pattern = '\\d+[.,]?\\d{0,2}';
            break;
        default:
            pattern = '\\d+';
    }

    if (!props.onlyPositive) {
        pattern = `-?${pattern}`;
    }

    return pattern;
});

/*---------------------------------------------------------------------
                        Input pattern
--------------------------------------------------------------------*/

const inputCheckPattern = computed(() => {
    let pattern = null;
    const { minPrecision, maxPrecision, onlyPositive } = props;

    switch (true) {
        case maxPrecision !== null:
            pattern = `^(?:\\d+(?:[.,](?:\\d{0,${maxPrecision}}))?)?$`;
            break;
        case minPrecision !== null:
            pattern = '^(?:\\d+(?:[.,](?:\\d)*)?)?$';
            break;
        case props.alwaysUseDot:
            pattern = '^(?:\\d+[.,]?\\d{0,2})?$';
            break;
        default:
            pattern = '^(?:\\d+)?$';
    }

    if (!onlyPositive) {
        pattern = '^-?' + pattern.substring(1);
    }

    return new RegExp(pattern);
});

/*---------------------------------------------------------------------
                        Event handlers
--------------------------------------------------------------------*/

const valueWithPrecision = computed(() => cutFloatNumber(props.modelValue));

const onInput = ($event) => {
    const currentValue = new Number($event.target.value);

    // 1. Check if the specified low limit has been reached
    if (props.min !== null && currentValue < props.min) {
        $event.target.value = '';
        return;
    }

    // 2. Check if the specified high limit has been reached
    if (props.max !== null && currentValue > props.max) {
        $event.target.value = valueWithPrecision.value;
        return;
    }

    // 3. Check if the specified value does not math the required pattern
    if (!inputCheckPattern.value.test($event.target.value) && $event.target.value !== '') {
        $event.target.value = valueWithPrecision.value;
        return;
    }

    // Finally, it's okay to emit updated value
    const sanitizedValue = props.alwaysUseDot ? $event.target.value.replace(',', '.') : $event.target.value;
    emit('update:modelValue', sanitizedValue);
};

const onBlur = ($event) => {
    const { modelValue } = props;

    if (props.required && $event.target.value === '') {
        $event.target.focus();
        return;
    }

    emit('blur', modelValue);
};

const cutFloatNumber = (num) => {
    // if is a float number we cut
    if (num % 1 !== 0) {
        const strNum = String(num);
        const dotIndex = strNum.indexOf('.') + 1;

        const precision = dotIndex + props.maxPrecision;

        return strNum.slice(0, precision);
    }

    return num;
};

/*---------------------------------------------------------------------
                    Lifecycle hooks
--------------------------------------------------------------------*/

onMounted(() => {
    if (props.autofocus) {
        nextTick(() => $input.value.focus());
    }
});
</script>

<style scoped lang="scss">
.pl-text-input {
    position: relative;

    > .form-label {
        position: absolute;
        top: $input-border-width;
        left: 0;
        width: 100%;
        text-align: left;
        line-height: $input-font-size * $input-line-height + $input-padding-y * 2;
        pointer-events: none;
        padding-left: $input-padding-x;
        padding-right: rem(45px);
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @include pl-transition(all, true);

        &--required::after {
            content: '*';
            position: absolute;
            top: 50%;
            color: $red-light;
            font-size: $font-size-base;
            line-height: $font-size-base * 0.75;
            padding-left: custom-space(0.1);
            transform: translateY(-50%);
        }
    }

    &--filled > .form-control:not(.is-invalid) {
        border-color: $black;
    }

    &--has-label .form-control:focus,
    &--filled.pl-text-input--has-label > .form-control {
        padding-top: $input-padding-y * 1.4;
        padding-bottom: $input-padding-y * 0.6;

        ~ .form-label {
            font-size: rem(12px);
            transform: translate3d(0, -22%, 0);
        }
    }

    &--loading .form-control {
        background-size: custom-space(1) custom-space(1);
        background-image: url('~@/assets/images/loader.gif');
        background-position: calc(100% - custom-space(1)) 50%;
        background-repeat: no-repeat;
    }
}
</style>