<script>
export default {
    name: 'FullScreenLoader',
};
</script>

<template>
    <div class="pl-full-screen-loader">
        <img
            src="/images/logo.svg"
            alt="Logo"
            width="300"
        />

        <img
            src="~@/assets/images/loader.gif"
            width="20"
            height="20"
            alt="Loading..."
        />

        <img
            src="/images/splash_bg.svg"
            alt="Splash screen"
            width="280"
        />
    </div>
</template>

<style lang="scss" scoped>
.pl-full-screen-loader {
    position: fixed;
    inset: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: $white;
    z-index: 100;

    & > img:nth-child(2) {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -100px);
    }
}
</style>
