<script>
export default {
    name: 'PrepTaskCreateView',
};
</script>

<script setup>
import { inject, reactive, onBeforeMount } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { PREP_TASKS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import PrepTaskModel from '@/models/PrepTask';
import PrepTaskForm from '@/components/forms/PrepTaskForm';

const $acl = inject('$acl');

const router = useRouter();
const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(PREP_TASKS_RESTRICTIONS.CREATE, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to create prep tasks.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                           Prep task form state
------------------------------------------------------------------------*/

const prepTaskFormState = reactive({
    isSubmitting: false,
    validationErrors: null,
});

const submitCreatePrepTask = (attributes) => {
    prepTaskFormState.isSubmitting = true;

    PrepTaskModel.create(orgId, attributes)
        .then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Prep task has been successfully created',
            });

            router.push({ name: SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX, query: route.query });
        })
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                prepTaskFormState.validationErrors = data?.errors || data?.data?.errors;
            }
        })
        .finally(() => (prepTaskFormState.isSubmitting = false));
};
</script>

<template>
    <TopBarLayout
        include-back-route-query
        :back-route="SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX"
    >
        <template #title>
            <h1>Create Prep Task</h1>
        </template>

        <PrepTaskForm
            :validation-errors="prepTaskFormState.validationErrors"
            :is-submitting="prepTaskFormState.isSubmitting"
            @submit="submitCreatePrepTask"
        />
    </TopBarLayout>
</template>
