import { reactive, toRefs } from 'vue';

const useDeletionModal = () => {
    const state = reactive({
        deletionModel: null,
        isDeletionModalOpened: false,
        isDeletionPending: false,
    });

    const openDeletionModal = (deletionModel) => {
        state.deletionModel = deletionModel;
        state.isDeletionModalOpened = true;
    };

    const closeDeletionModal = () => {
        if (!state.isDeletionPending) {
            state.deletionModel = null;
            state.isDeletionModalOpened = false;
        }
    };

    const deletionCallback = (callback, enableCloseAfterComplete = true) => {
        state.isDeletionPending = true;

        callback().finally(() => {
            if (enableCloseAfterComplete) {
                state.isDeletionPending = false;

                closeDeletionModal();
            }
        });
    };

    return {
        ...toRefs(state),
        openDeletionModal,
        closeDeletionModal,
        deletionCallback,
    };
};

export default useDeletionModal;
