<script>
export default {
    name: 'MinusIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 50,
    },
    height: {
        type: Number,
        default: 51,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 50 51"
        :width="width"
        :height="height"
    >
        <path
            d="M25 0.312256C11.225 0.312256 0 11.5373 0 25.3123C0 39.0873 11.225 50.3123 25 50.3123C38.775 50.3123 50 39.0873 50 25.3123C50 11.5373 38.775 0.312256 25 0.312256ZM34.8 27.1873H14.8C13.775 27.1873 12.925 26.3373 12.925 25.3123C12.925 24.2873 13.775 23.4373 14.8 23.4373H34.8C35.825 23.4373 36.675 24.2873 36.675 25.3123C36.675 26.3373 35.85 27.1873 34.8 27.1873Z"
            fill="#CF382F"
        />
    </svg>
</template>
