<script>
export default {
    name: 'DeletionModal',
};
</script>

<script setup>
import Modal from '@/components/Modal';
import LoadingButton from '@/components/form-controls/LoadingButton';

defineProps({
    qaPrefix: {
        type: String,
        required: true,
    },
    isPending: {
        type: Boolean,
        default: false,
    },
    submitBtnText: {
        type: String,
        default: 'Delete',
    },
    cancelBtnText: {
        type: String,
        default: 'Cancel',
    },
});

const emit = defineEmits(['close', 'submit-delete']);
</script>

<template>
    <Modal
        :data-testid="qaPrefix"
        :is-close-disabled="isPending"
        @close="emit('close')"
    >
        <template #title>
            <slot name="title">
                Prep List
            </slot>
        </template>

        <template #content>
            <slot />
        </template>

        <template #actions>
            <button
                type="button"
                class="btn btn-secondary"
                :data-testid="`${qaPrefix}_close_btn`"
                :disabled="isPending"
                @click="emit('close')"
            >
                {{ cancelBtnText }}
            </button>

            <LoadingButton
                type="button"
                class="btn btn-primary"
                :data-testid="`${qaPrefix}_delete_btn`"
                :loading="isPending"
                :disabled="isPending"
                @click="emit('submit-delete')"
            >
                {{ submitBtnText }}
            </LoadingButton>
        </template>
    </Modal>
</template>
