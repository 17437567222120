<script>
export default {
    name: 'SplitEqualTheWorkTab',
};
</script>

<script setup>
import { computed, ref, watch } from 'vue';

import EventBus from '@/utils/EventBus';
import { getSplitDistributionPayload } from '@/utils/selectableItemsUtils';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { DISTRIBUTION_TYPES } from '@/enums/selectableItemsEnums';
import CheckboxInput from '@/components/form-controls/CheckboxInput';
import DistributionTabUI from './DistributionTabUI';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    itemAssigneeData: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-distribution']);

/*------------------------------------------------------------------------
                          Distribution tab state
------------------------------------------------------------------------*/

const selectedIds = ref([]);

const validateSelectedIds = (ids) => {
    const { ordered_BY_qty: orderedBYQty } = props.item;

    if (ids.length > orderedBYQty) {
        selectedIds.value.pop();

        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: `It's not allowed to distribute the ordered quantity between more than ${orderedBYQty} employees.`,
        });
    }
};

const getDistributionPayload = () => {
    const distributionData = selectedIds.value.map((id) => ({
        id,
        percentage: 100 / selectedIds.value.length,
    }));

    return getSplitDistributionPayload(props.item, distributionData);
};

const setDistribution = () => {
    const payload = {
        distributionType: DISTRIBUTION_TYPES.SPLIT_EQUAL,
        distribution: getDistributionPayload(),
    };

    emit('set-distribution', payload);
};

const assigneeOptions = computed(() =>
    props.item.available_assignees.map(({ id, name }) => ({
        value: id.toString(),
        text: name,
    }))
);

watch(
    () => props.itemAssigneeData,
    (assigneeData) => {
        if (assigneeData.distribution_type === DISTRIBUTION_TYPES.SPLIT_EQUAL) {
            selectedIds.value = assigneeData.distribution.map(({ assignee_id: assigneeId }) => assigneeId.toString());
        }
    },
    { immediate: true }
);
</script>

<template>
    <DistributionTabUI>
        <template #ribbon-text>
            Each employee only sees their part and must report on it separately from the others.
        </template>

        <template #tab-content>
            <CheckboxInput
                v-model="selectedIds"
                name="split_equal_work"
                :options="assigneeOptions"
                @update:modelValue="validateSelectedIds"
            />
        </template>

        <template #footer-content="{ classNames }">
            <div :class="classNames.actions">
                <slot name="cancel-btn" />

                <button
                    type="button"
                    class="btn btn-primary"
                    :disabled="selectedIds.length === 0"
                    @click="setDistribution"
                >
                    Apply
                </button>
            </div>
        </template>
    </DistributionTabUI>
</template>
