<script>
export default {
    name: 'PARTemplateEditStep1View',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import PARTemplateSettingsForm from '@/components/forms/PARTemplateSettingsForm';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const cancelTemplateDraft = () => {
    currentPARTemplate.value.cancel().then(() => {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.SUCCESS,
            message: 'Template editing process has been successfully cancelled',
        });

        router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });

        store.commit('setCurrentPARTemplate', null);
    });
};

/*------------------------------------------------------------------------
                       Template settings form state
------------------------------------------------------------------------*/

const templateSettingsFormState = reactive({
    validationErrors: null,
    isSubmittingAllowed: false,
    hasChangesMade: false,
    isSubmitting: false,
});

const setHasChangesMade = (hasChangesMade) => (templateSettingsFormState.hasChangesMade = hasChangesMade);

const setIsSubmittingAllowed = (isSubmittingAllowed) =>
    (templateSettingsFormState.isSubmittingAllowed = isSubmittingAllowed);

const navigateToNextStep = () => {
    router.push({
        name: SETTINGS_ROUTE_NAMES.PAR.EDIT.ADD_ITEMS,
        params: { templateId: props.templateId },
    });
};

const submitForm = (payload) => {
    if (!templateSettingsFormState.hasChangesMade) {
        navigateToNextStep();
    } else {
        templateSettingsFormState.isSubmitting = true;

        currentPARTemplate.value
            .update(payload)
            .then((model) => {
                EventBus.$emit('pl.flash-notification', {
                    type: ALERT_TYPES.SUCCESS,
                    message: 'Template settings has been successfully updated',
                });

                store.commit('setCurrentPARTemplate', model);

                navigateToNextStep();
            })
            .catch((error) => {
                const { status, data } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.UNPROCESSABLE_ENTITY) {
                    templateSettingsFormState.validationErrors = data?.errors || data?.data?.errors;
                }
            })
            .finally(() => (templateSettingsFormState.isSubmitting = false));
    }
};

const templateSettingsFormProps = computed(() => {
    const { name, par_template_settings: settings } = currentPARTemplate.value;


    return {
        name,
        days: settings.days,
        dates: settings.dates,
        startDate: settings.start_date,
        endDate: settings.end_date,
    };
});

const isSubmittingDisabled = computed(() => {
    const { isSubmittingAllowed, isSubmitting } = templateSettingsFormState;

    return !isSubmittingAllowed || isSubmitting;
});

const submitBtnText = computed(() => (templateSettingsFormState.hasChangesMade ? 'Apply Changes' : 'Select Items'));
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        :is-step-submitting="templateSettingsFormState.isSubmitting"
        @cancel-process="cancelTemplateDraft"
    >
        <template #step-content>
            <PARTemplateSettingsForm
                id="pl-edit-template-step-1-form"
                :name="templateSettingsFormProps.name"
                :days="templateSettingsFormProps.days"
                :dates="templateSettingsFormProps.dates"
                :start-date="templateSettingsFormProps.startDate"
                :end-date="templateSettingsFormProps.endDate"
                :validation-errors="templateSettingsFormState.validationErrors"
                @set-has-changes-made="setHasChangesMade"
                @set-is-submitting-allowed="setIsSubmittingAllowed"
                @submit="submitForm"
            />
        </template>

        <template #next-step-action>
            <button
                type="submit"
                form="pl-edit-template-step-1-form"
                class="btn btn-primary"
                :disabled="isSubmittingDisabled"
            >
                {{ submitBtnText }}
            </button>
        </template>
    </OrderStepTemplate>
</template>
