<script>
export default {
    name: 'SelectableItemAssigneeCard',
};
</script>

<script setup>
import EventBus from '@/utils/EventBus';
import { constructAssigneeOptions, constructAssigneeSelectedOption } from '@/utils/selectableItemsUtils';
import { getClosestAllowedQty, getQtyPrecision } from '@/utils/componentsUtils';
import { getPLOrderItemOrderedQtyDisplay } from '@/utils/orderItemUtils';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import ItemCard from '@/components/model-specific/item/ItemCard';
import SelectInput from '@/components/form-controls/SelectInput';
import Alert from '@/components/Alert';
import Badge from '@/components/Badge';
import QtyInput from '@/components/form-controls/QtyInput';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    selectableAssignees: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-assignee', 'set-qty']);

/*-----------------------------------------------------------------------------------
                                        Assignee
-----------------------------------------------------------------------------------*/

const getItemAssigneeData = (item) => {
    const assigneeData = props.selectableAssignees[item.id];

    return {
        distributionType: assigneeData.distribution_type,
        distribution: assigneeData.distribution,
        availableAssignees: item.available_assignees,
    };
};

const getSelectedAssigneeId = (item) => {
    const { distributionType, distribution } = getItemAssigneeData(item);

    return constructAssigneeSelectedOption(distributionType, distribution);
};

const getAssigneeOptions = (item) => {
    const { distributionType, distribution, availableAssignees } = getItemAssigneeData(item);

    return constructAssigneeOptions(distributionType, distribution, availableAssignees);
};

const handleSetAssignee = (value, item) => emit('set-assignee', { value, item });

/*-----------------------------------------------------------------------------------
                                        Qty
-----------------------------------------------------------------------------------*/

const hasOneActivePrepTaskLeft = () => {
    const { item, selectableAssignees } = props;

    let activePrepTasksCount = 0;

    for (const prepTask of item.prep_tasks) {
        if (+selectableAssignees[prepTask.id].qty > 0) {
            activePrepTasksCount++;

            if (activePrepTasksCount > 1) {
                return false;
            }
        }
    }

    return true;
};

const handleSetQty = (qty, prepTask) => {
    const { qtyStep, unitsData } = props.selectableAssignees[prepTask.id];

    let value = getClosestAllowedQty(unitsData, unitsData.prep_list_ordering_unit, qty);

    if (value === 0 && hasOneActivePrepTaskLeft()) {
        value = qtyStep;

        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'All prep tasks cannot be removed from the item.',
        });
    }

    emit('set-qty', { value, prepTask });
};
</script>

<template>
    <div
        class="pl-selectable-item-assignee-card"
        :class="{
            'pl-selectable-item-assignee-card--added':
                item.prep_tasks.length === 0 && selectableAssignees[item.id].distribution_type !== null,
        }"
    >
        <Badge
            v-if="item.prep_tasks.length > 0"
            is-absolute
            size="sm"
            :type="BADGE_TYPES.ORDER_CARD"
        >
            Prep Task ({{ item.prep_tasks.length }})
        </Badge>

        <ItemCard
            include-category-name
            :item="item"
        >
            <template #info>
                <span>{{ getPLOrderItemOrderedQtyDisplay(item) }}</span>
            </template>

            <template #actions>
                <template v-if="item.prep_tasks.length > 0">
                    <div
                        v-for="prepTask in item.prep_tasks"
                        :key="`item-prep-task-${prepTask.id}`"
                        class="pl-selectable-item-assignee-card__prep-task"
                        :class="{
                            'pl-selectable-item-assignee-card__prep-task--added':
                                selectableAssignees[prepTask.id].qty > 0,
                        }"
                    >
                        <div class="pl-selectable-item-assignee-card__box">
                            <div class="pl-selectable-item-assignee-card__name">
                                <span>{{ prepTask.name }}</span>

                                <small>({{ selectableAssignees[prepTask.id].orderingUnitText }})</small>
                            </div>

                            <QtyInput
                                :model-value="selectableAssignees[prepTask.id].qty"
                                is-increment-mode
                                ask-for-saving-changes-on-blur
                                class="pl-selectable-item-assignee-card__qty"
                                size="sm"
                                :max="9999"
                                :min="0"
                                :disabled="item.is_reassigned"
                                :step="selectableAssignees[prepTask.id].qtyStep"
                                :precision="getQtyPrecision(selectableAssignees[prepTask.id].qtyStep)"
                                @update:modelValue="handleSetQty($event, prepTask)"
                            />
                        </div>

                        <SelectInput
                            :model-value="getSelectedAssigneeId(prepTask)"
                            is-last-option-marked
                            searchable
                            label="Assign to"
                            size="sm"
                            :disabled="item.is_reassigned || selectableAssignees[prepTask.id].qty === 0"
                            :options="getAssigneeOptions(prepTask)"
                            @update:modelValue="handleSetAssignee($event, prepTask)"
                        />
                    </div>
                </template>

                <SelectInput
                    v-else
                    :key="item.id"
                    :model-value="getSelectedAssigneeId(item)"
                    is-last-option-marked
                    searchable
                    label="Assign to"
                    size="sm"
                    :options="getAssigneeOptions(item)"
                    :disabled="item.is_reassigned"
                    @update:modelValue="handleSetAssignee($event, item)"
                />

                <Alert
                    v-if="item.is_reassigned"
                    :type="ALERT_TYPES.WARNING"
                >
                    You can't change this item because it was reassigned.
                </Alert>
            </template>
        </ItemCard>
    </div>
</template>

<style lang="scss" scoped>
.pl-selectable-item-assignee-card {
    position: relative;
    background-color: $white;
    margin-bottom: custom-space(0.5);
    padding: custom-space(0.5) custom-space(0.75);
    border-radius: $border-radius;
    border: 1px solid $gray-200;

    &--added {
        background-color: $yellow-light;
        border-color: $yellow;
    }

    &__prep-task {
        display: flex;
        flex-direction: column;
        padding: custom-space(0.25);
        border: 1px solid rgba($gray-200, 0.5);
        border-radius: custom-space(0.5);

        &--added {
            background-color: $yellow-light;
            border-color: $yellow;
        }
    }

    &__box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
    }

    &__name {
        padding-left: custom-space(0.75);
        line-height: $font-size-base;
        font-size: $font-size-base * 0.875;

        span {
            font-size: $font-size-base * 0.875;
            font-weight: 400;
            margin-right: custom-space(0.25);
        }
    }

    &__qty {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        flex: 0 0 130px;

        :deep(.pl-qty-input) {
            margin: 0;
            width: 100%;
        }
    }

    .pl-badge.pl-badge--absolute {
        left: auto;
        right: custom-space(0.75);
    }
}

:deep(.pl-item-card__wrapper) {
    flex-direction: column;
    align-items: flex-start;
}

:deep(.pl-item-card__content-wrapper) {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > span {
        font-size: $font-size-base * 0.85;
        font-weight: 400;
        flex-shrink: 0;
        margin-left: custom-space(0.5);
        max-width: 100px;
        line-height: $font-size-base;
    }
}

:deep(.pl-item-card__actions) {
    flex: auto;
    flex-direction: column;
    width: 100%;
    gap: custom-space(0.5);
    padding-left: 0;

    .pl-select {
        max-width: 100%;
        margin-top: custom-space(0.25);
    }
}
</style>
