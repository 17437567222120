<script>
export default {
    name: 'PLOrderShowView',
};
</script>

<script setup>
import { inject, watch, reactive, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import { mapGetters } from '@/utils/vuex';
import EventBus from '@/utils/EventBus';
import { isOrderEditing } from '@/utils/orderUtils';
import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { PL_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import { ALERT_TYPES, BADGE_TYPES } from '@/enums/componentsEnums';
import PLOrderModel from '@/models/PLOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useDeletionModal from '@/composition/useDeletionModal';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import Badge from '@/components/Badge';
import DeletionModal from '@/components/DeletionModal';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { currentPLOrder } = mapGetters(['currentPLOrder']);

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const orderState = reactive({
    data: null,
    serverError: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadOrder = () => {
    orderState.isDataLoading = true;

    sendAbortableRequest(
        PLOrderModel.find(orgId, props.orderId, {
            group_items_by: store.state.PLOrderViewSettings.groupItemsBy,
        })
    )
        .then((model) => (orderState.data = model))
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.INTERNAL_SERVER_ERROR) {
                orderState.serverError = 'Order could not be loaded for some reason. Please try again later.';
            } else if (status !== undefined) {
                orderState.serverError = data.message;
            }
        })
        .finally(() => (orderState.isDataLoading = false));
};

const refreshOrder = (freshOrder) => (orderState.data = freshOrder);

const initEditOrder = () => {
    const order = orderState.data;

    if (currentPLOrder.value !== null) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.WARNING,
            message: 'You currently have a pending order. Please finalize or cancel it, before editing a new one.',
        });

        if (isOrderEditing(currentPLOrder.value)) {
            router.push({
                name: PL_ROUTE_NAMES.EDIT.INDEX,
                params: { orderId: currentPLOrder.value.parent_id },
            });
        } else {
            router.replace({ name: PL_ROUTE_NAMES.CREATE.INDEX });
        }
    } else if (order.has_batches_done) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to edit the order, because some items are reported.',
        });
    } else {
        orderState.isActionProcessing = true;

        order
            .createDraft()
            .then(({ data }) => {
                store.commit('setCurrentPLOrder', data);

                router.push({
                    name: PL_ROUTE_NAMES.EDIT.INDEX,
                    params: { orderId: order.id },
                });
            })
            .catch((error) => {
                const { status } = error.response || {};

                if (status === HTTP_RESPONSES_CODE.NOT_FOUND) {
                    EventBus.$emit('pl.flash-notification', {
                        type: ALERT_TYPES.FAIL,
                        message: 'Order has been successfully deleted',
                    });
                }
            })
            .finally(() => (orderState.isActionProcessing = false));
    }
};

const orderPermissions = computed(() => ({
    update: $acl.can(PL_RESTRICTIONS.UPDATE, currentAccount, orderState.data),
    delete: $acl.can(PL_RESTRICTIONS.DELETE, currentAccount, orderState.data),
}));

watch(() => store.state.PLOrderViewSettings.groupItemsBy, loadOrder, { immediate: true });

/*------------------------------------------------------------------------
                           Deletion modal state
------------------------------------------------------------------------*/

const {
    deletionModel,
    isDeletionModalOpened,
    isDeletionPending,
    openDeletionModal,
    closeDeletionModal,
    deletionCallback,
} = useDeletionModal();

const openDeleteOrderModal = () => {
    if (orderState.data.has_batches_done) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You are not allowed to delete the order, because some items are fulfilled.',
        });
    } else {
        openDeletionModal(orderState.data);
    }
};

const submitDeleteOrder = () => {
    deletionCallback(() =>
        deletionModel.value.delete().then(() => {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.SUCCESS,
                message: 'Order has been successfully deleted',
            });

            router.push({ name: PL_ROUTE_NAMES.INDEX });
        })
    );
};
</script>

<template>
    <TopBarLayout :back-route="PL_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>
                <span>
                    Order Details

                    <Badge
                        v-if="orderState.data?.is_par"
                        is-absolute
                        size="xs"
                        :type="BADGE_TYPES.PAR"
                    >
                        PAR
                    </Badge>
                </span>
            </h1>
        </template>

        <template
            v-if="orderState.data !== null"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in ORDER_GROUP_ITEMS_BY_OPTIONS"
                                :key="`group-items-by-option-${value}`"
                                :is-selected="store.state.PLOrderViewSettings.groupItemsBy === value"
                                @click="store.commit('PLOrderViewSettings/setGroupItemsBy', value)"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>

                        <GroupedSettings.List
                            v-if="orderPermissions.update || orderPermissions.delete"
                            title="Actions"
                        >
                            <GroupedSettings.ListItem
                                v-if="orderPermissions.update"
                                @click="initEditOrder"
                            >
                                {{ orderState.data.draft !== null ? ' Continue editing' : 'Edit order' }}
                            </GroupedSettings.ListItem>

                            <GroupedSettings.ListItem
                                v-if="orderPermissions.delete"
                                @click="openDeleteOrderModal"
                            >
                                Delete order
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <InPlaceLoader v-if="orderState.isDataLoading" />

        <Alert
            v-else-if="orderState.serverError !== null"
            :type="ALERT_TYPES.FAIL"
        >
            {{ orderState.serverError }}
        </Alert>

        <template v-else>
            <OverlayLoader
                v-if="orderState.isActionProcessing"
                enable-sticky
            />

            <OrderSummaryCard
                show-item-progress
                enable-reassign
                :order="orderState.data"
                @refresh-order="refreshOrder"
            />

            <DeletionModal
                v-if="isDeletionModalOpened"
                qa-prefix="delete_order_modal"
                :is-pending="isDeletionPending"
                @close="closeDeletionModal"
                @submit-delete="submitDeleteOrder"
            >
                Are you sure you want to delete the order "{{ deletionModel.name }}"?
            </DeletionModal>
        </template>
    </TopBarLayout>
</template>
