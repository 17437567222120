<script>
export default {
    name: 'ItemDistributionModal',
};
</script>

<script setup>
import { onBeforeMount, ref } from 'vue';

import { getPLOrderItemOrderedQtyDisplay } from '@/utils/orderItemUtils';
import { DISTRIBUTION_TYPES, DISTRIBUTION_TITLE_BY_TYPE } from '@/enums/selectableItemsEnums';
import Modal from '@/components/Modal';
import ShareTheWorkTab from './partials/ShareTheWorkTab';
import SplitEqualTheWorkTab from './partials/SplitEqualTheWorkTab';
import SplitTheWorkTab from './partials/SplitTheWorkTab';

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
    itemAssigneeData: {
        type: Object,
        required: true,
    },
});

const emit = defineEmits(['set-distribution', 'close-modal']);

/*------------------------------------------------------------------------
                          Distribution tabs state
------------------------------------------------------------------------*/

const distributionTabs = {
    [DISTRIBUTION_TYPES.SPLIT_EQUAL]: SplitEqualTheWorkTab,
    [DISTRIBUTION_TYPES.SPLIT]: SplitTheWorkTab,
    [DISTRIBUTION_TYPES.SHARE]: ShareTheWorkTab,
};

const activeDistributionTab = ref(null);

const isDistributionTabActive = (distributionTab) => activeDistributionTab.value === distributionTab;

const setActiveDistributionTab = (distributionTab) => (activeDistributionTab.value = distributionTab);

const setDistribution = ({ distributionType, distribution }) => {
    const payload = {
        distribution,
        distributionType: distribution.length === 1 ? DISTRIBUTION_TYPES.DIRECT : distributionType,
    };

    emit('set-distribution', payload);
};

onBeforeMount(() => {
    const { distribution_type: distributionType } = props.itemAssigneeData;

    const distributionTabsKeys = Object.keys(distributionTabs);
    const distributionTab = distributionTabsKeys.includes(distributionType)
        ? distributionType
        : distributionTabsKeys[0];

    setActiveDistributionTab(distributionTab);
});
</script>

<template>
    <Modal
        disable-click-outside-mechanism
        class="pl-item-distribution-modal"
        @close="emit('close-modal')"
    >
        <template #title>
            {{ item.name }}

            <span>{{ getPLOrderItemOrderedQtyDisplay(item) }}</span>
        </template>

        <template #content>
            <ul class="pl-item-distribution-modal__tabs">
                <li
                    v-for="(_, distributionTab) in distributionTabs"
                    :key="`distribution tab: ${distributionTab}`"
                    class="pl-item-distribution-modal__tab"
                    :class="{
                        'pl-item-distribution-modal__tab--active': isDistributionTabActive(distributionTab),
                    }"
                    @click="setActiveDistributionTab(distributionTab)"
                >
                    {{ DISTRIBUTION_TITLE_BY_TYPE[distributionTab] }}
                </li>
            </ul>

            <component
                :is="distributionTabs[activeDistributionTab]"
                :item="item"
                :item-assignee-data="itemAssigneeData"
                @set-distribution="setDistribution"
            >
                <template #cancel-btn>
                    <button
                        type="button"
                        class="btn btn-secondary"
                        @click="emit('close-modal')"
                    >
                        Cancel
                    </button>
                </template>
            </component>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.pl-item-distribution-modal {
    &__tabs {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        list-style: none;
        padding: 0;
        margin-bottom: custom-space(1.25);
    }

    &__tab {
        flex-grow: 1;
        position: relative;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-bolder;
        text-align: center;
        cursor: pointer;
        text-transform: uppercase;
        padding: custom-space(0.5);
        border-bottom: 1px solid $black;
        @include pl-transition(color);

        &::before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            border-bottom: 3px solid $primary;
            opacity: 0;
            z-index: 2;
            @include pl-transition(opacity);
        }

        &--active {
            color: $primary;

            &::before {
                opacity: 1;
            }
        }
    }

    :deep(.pl-modal__wrapper) > section > h2 {
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: $font-size-base * 1.5;

        & > span {
            font-size: $font-size-base * 0.9;
            font-weight: 400;
        }
    }
}
</style>
