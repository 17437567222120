<script>
export default {
    name: 'PARTemplateEditStep2View',
};
</script>

<script setup>
import { computed, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { mapGetters } from '@/utils/vuex';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import OrderStepTemplate from '@/components/model-specific/orders/OrderStepTemplate';
import SelectableItemsQty from '@/components/model-specific/prep-lists/selectable-items-qty';

const props = defineProps({
    templateId: {
        type: [String, Number],
        required: true,
    },
});

const router = useRouter();
const store = useStore();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

const { currentPARTemplate } = mapGetters(['currentPARTemplate']);

/*------------------------------------------------------------------------
                        Selectable items qty state
------------------------------------------------------------------------*/

const selectableItemsQtyState = reactive({
    areActionsDisabled: false,
    isAnyItemAdded: false,
});

const setAreActionsDisabled = (areActionsDisabled) => (selectableItemsQtyState.areActionsDisabled = areActionsDisabled);
const setIsAnyItemAdded = (isAnyItemAdded) => (selectableItemsQtyState.isAnyItemAdded = isAnyItemAdded);

/*------------------------------------------------------------------------
                              Template state
------------------------------------------------------------------------*/

const cancelTemplateDraft = () => {
    currentPARTemplate.value.cancel().then(() => {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.SUCCESS,
            message: 'Template editing process has been successfully cancelled',
        });

        router.push({ name: SETTINGS_ROUTE_NAMES.PAR.INDEX });

        store.commit('setCurrentPARTemplate', null);
    });
};

const navigateToNextStep = () => {
    router.push({
        name: SETTINGS_ROUTE_NAMES.PAR.EDIT.SET_ASSIGNEES,
        params: { templateId: props.templateId },
    });
};

const isNextStepDisabled = computed(() => {
    const { isAnyItemAdded, areActionsDisabled } = selectableItemsQtyState;

    return !isAnyItemAdded || areActionsDisabled;
});
</script>

<template>
    <OrderStepTemplate
        is-template
        is-edit-mode
        exclude-content-container
        :is-cancel-process-disabled="selectableItemsQtyState.areActionsDisabled"
        @cancel-process="cancelTemplateDraft"
    >
        <template #step-content>
            <SelectableItemsQty
                is-template
                :organization-id="orgId"
                :overlaid-by-order-id="currentPARTemplate.id"
                :overlaid-by-location-id="currentPARTemplate.par_template_settings.location.id"
                @set-are-actions-disabled="setAreActionsDisabled"
                @set-is-any-item-added="setIsAnyItemAdded"
            />
        </template>

        <template #next-step-action>
            <button
                type="button"
                class="btn btn-primary"
                :disabled="isNextStepDisabled"
                @click="navigateToNextStep"
            >
                Set Assignees
            </button>
        </template>

        <template
            v-if="!selectableItemsQtyState.isAnyItemAdded"
            #ribbon-text
        >
            Please add at least one item to your template.
        </template>
    </OrderStepTemplate>
</template>
