<script>
export default {
    name: 'NoPrepActionsIndexView',
};
</script>

<script setup>
import { inject, onBeforeMount, reactive } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import EventBus from '@/utils/EventBus';
import { NPA_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import NoPrepActionModel from '@/models/NoPrepAction';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import ListDisplay from '@/components/ListDisplay';

const $acl = inject('$acl');

const router = useRouter();
const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                              Restrict access
------------------------------------------------------------------------*/

onBeforeMount(() => {
    if (!$acl.can(NPA_RESTRICTIONS.VIEW, currentAccount)) {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.FAIL,
            message: 'You do not have access to no prep actions.',
        });

        router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
    }
});

/*------------------------------------------------------------------------
                           No prep actions state
------------------------------------------------------------------------*/

const actionsState = reactive({
    data: null,
    isDataLoading: true,
});

const loadActions = () => {
    sendAbortableRequest(NoPrepActionModel.all(orgId))
        .then((models) => (actionsState.data = models))
        .finally(() => (actionsState.isDataLoading = false));
};

onBeforeMount(loadActions);
</script>

<template>
    <TopBarLayout :back-route="SETTINGS_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>No Prep Actions</h1>
        </template>

        <InPlaceLoader v-if="actionsState.isDataLoading" />

        <Alert
            v-else-if="actionsState.data === null"
            :type="ALERT_TYPES.FAIL"
        >
            No Prep Actions could not be loaded for some reason. Please try again later.
        </Alert>

        <template v-else>
            <transition
                appear
                name="down"
                type="transition"
                mode="out-in"
            >
                <ListDisplay
                    has-borders
                    data-testid="no_prep_actions_list"
                    :items="actionsState.data"
                >
                    <template #item="{ item: action }">
                        {{ action.name }}
                    </template>
                </ListDisplay>
            </transition>
        </template>
    </TopBarLayout>
</template>

<style lang="scss" scoped>
:deep(.pl-list-display__item) {
    font-weight: $font-weight-normal;
}
</style>
