<template>
    <EmployeeForm
        v-if="targetEmployee"
        submit-btn-text="Save"
        :name="targetEmployee.name"
        :hourly-rate="targetEmployee.hourly_rate"
        :phone="targetEmployee.phone"
        :email="targetEmployee.email"
        :role="targetEmployee.role"
        :hidden-fields="['available_locations']"
        :added-employees="state.employees"
        @submit="cacheData"
    />
    <Alert
        v-else
        :type="ALERT_TYPES.FAIL"
    >
        Unable to load employee.
    </Alert>
</template>

<script>
export default {
    name: 'RegistrationStep2EditEmployee',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { LS } from '@/instances/localStorage';
import EventBus from '@/utils/EventBus';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import Alert from '@/components/Alert';
import EmployeeForm from '@/components/forms/EmployeeForm';

const route = useRoute();
const store = useStore();
const router = useRouter();

const index = route.params.index;

const state = reactive({
    employees: LS.get('onboarding_employees', []),
    departments: LS.get('onboarding_departments', []),
});

const targetEmployee = state.employees[index];
const oldEmployeePhone = targetEmployee.phone;

const cacheData = (payload) => {
    const apiErrorsBag = store.getters['SVE/getErrorsBag']('registration');

    state.employees.splice(index, 1, payload);

    LS.set('onboarding_employees', state.employees);

    if (apiErrorsBag?.has(`employees.${index}.name`) && targetEmployee.name !== payload.name) {
        apiErrorsBag.clear(`employees.${index}.name`);
    }

    if (
        oldEmployeePhone.phone != payload.phone.phone ||
        oldEmployeePhone.phone_country_id != payload.phone.phone_country_id
    ) {
        if (apiErrorsBag?.has(`employees.${index}.phone`)) {
            apiErrorsBag.clear(`employees.${index}.phone`);
        }

        if (state.departments.length > 0) {
            for (let department of state.departments) {
                const targetEmployeeIndex = department.members.findIndex((account) => {
                    return (
                        account.phone.phone_country_id == oldEmployeePhone.phone_country_id &&
                        account.phone.phone == oldEmployeePhone.phone
                    );
                });

                if (targetEmployeeIndex !== -1) {
                    department.members.splice(targetEmployeeIndex, 1, {
                        phone: payload.phone,
                    });
                }
            }

            LS.set('onboarding_departments', state.departments);
        }
    }

    EventBus.$emit('pl.flash-notification', {
        type: ALERT_TYPES.SUCCESS,
        message: 'Employee updated',
    });
    router.back();
};
</script>
