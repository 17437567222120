import DashboardView from '@/views/dashboard';

import quickFillOrders from './quick-fill-orders';
import prepLists from './prep-lists';
import reports from './reports';
import settings from './settings';

export default [
    {
        path: '/dashboard',
        component: DashboardView,
        children: [].concat(quickFillOrders).concat(prepLists).concat(reports).concat(settings),
        meta: { authOnly: true },
    },
];
