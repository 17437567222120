<script>
export default {
    name: 'PlaneIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 18,
    },
    height: {
        type: Number,
        default: 18,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 18 18"
        :width="width"
        :height="height"
    >
        <path
            d="M17.7825 0.217503C17.6813 0.116758 17.5534 0.0470054 17.4139 0.0164475C17.2744 -0.0141104 17.1291 -0.00420373 16.995 0.0450027L0.495001 6.045C0.352702 6.09898 0.230191 6.19497 0.143739 6.32022C0.0572872 6.44547 0.0109863 6.59406 0.0109863 6.74625C0.0109863 6.89844 0.0572872 7.04703 0.143739 7.17229C0.230191 7.29754 0.352702 7.39353 0.495001 7.4475L6.9375 10.02L11.6925 5.25L12.75 6.3075L7.9725 11.085L10.5525 17.5275C10.6081 17.6671 10.7043 17.7867 10.8286 17.8709C10.953 17.9552 11.0998 18.0001 11.25 18C11.4016 17.9969 11.5486 17.9479 11.6718 17.8596C11.795 17.7712 11.8885 17.6476 11.94 17.505L17.94 1.005C17.9911 0.87231 18.0034 0.727825 17.9755 0.588395C17.9477 0.448965 17.8807 0.320335 17.7825 0.217503Z"
            fill="#FF3535"
        />
    </svg>
</template>
