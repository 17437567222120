import { LS } from '@/instances/localStorage';
import { QF_DEFAULT_FILTER_ORDERS_BY } from '@/enums/timelineEnums';

export default {
    namespaced: true,
    state: () => ({
        filterBy: LS.get('qf_order.view_settings.filter_by', QF_DEFAULT_FILTER_ORDERS_BY),
    }),
    mutations: {
        setFilterBy: (state, value) => {
            state.filterBy = value;

            LS.set('qf_order.view_settings.filter_by', value);
        },
    },
};
