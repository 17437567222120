import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class Supplier extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/suppliers`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/suppliers`, attributes);

        return this.make(data);
    }

    static async update(orgId, locationId, attributes) {
        const { data } = await ApiClient.put(`organizations/${orgId}/suppliers/${locationId}`, attributes);

        return this.make(data);
    }

    async delete(orgId) {
        const { data } = await ApiClient.delete(`organizations/${orgId}/suppliers/${this.id}`);

        return Supplier.make(data);
    }
}
