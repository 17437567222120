import ApiClient from '@/utils/ApiClient';
import Model from './Model';

export default class PLOrder extends Model {
    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/prep-list-orders`, attributes);

        return this.make(data);
    }

    static async createFromTemplate(orgId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/prep-list-orders/create-from-template`,
            attributes
        );

        return this.make(data);
    }

    static find(orgId, orderId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/prep-list-orders/${orderId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async markAsClosed(orgId, orderId, query = {}) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/prep-list-orders/${orderId}/mark-as-closed`,
            {},
            { params: query }
        );

        return this.make(data);
    }

    static getAssignees(orgId, orderId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/prep-list-orders/${orderId}/assignees`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async setAssignees(orgId, orderId, attributes) {
        return ApiClient.post(`organizations/${orgId}/prep-list-orders/${orderId}/assignees`, attributes);
    }

    static getReassignState(orgId, orderId, departmentId, accountId) {
        return this.withAbort(
            ApiClient.get(
                `organizations/${orgId}/prep-list-orders/${orderId}/departments/${departmentId}/reassign-account/${accountId}`
            ),
            ({ data }) => this.make(data)
        );
    }

    static async setReassign(orgId, orderId, departmentId, accountId, attributes) {
        const { data } = await ApiClient.post(
            `organizations/${orgId}/prep-list-orders/${orderId}/departments/${departmentId}/reassign-account/${accountId}`,
            attributes
        );

        return this.make(data);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(
            `organizations/${this.organization_id}/prep-list-orders/${this.id}`,
            attributes
        );

        return this.fill(data);
    }

    async delete() {
        return ApiClient.delete(`organizations/${this.organization_id}/prep-list-orders/${this.id}`);
    }

    async cancel() {
        return ApiClient.delete(`organizations/${this.organization_id}/prep-list-orders/${this.id}/cancel`);
    }

    async confirm() {
        return ApiClient.put(`organizations/${this.organization_id}/prep-list-orders/${this.id}/confirm`);
    }

    createDraft() {
        return ApiClient.post(`organizations/${this.organization_id}/prep-list-orders/${this.id}/draft`);
    }

    completeDraft() {
        return ApiClient.post(
            `organizations/${this.organization_id}/prep-list-orders/${this.parent_id}/draft/complete`
        );
    }

    getRelationships() {
        return {
            draft: PLOrder,
        };
    }
}
