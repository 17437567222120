<script>
export default {
    name: 'CaretDownIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 10,
    },
    height: {
        type: Number,
        default: 8,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 10 8"
        :width="width"
        :height="height"
    >
        <path
            d="M4.58903 7.7845C4.6349 7.85112 4.69628 7.90559 4.76787 7.94322C4.83947 7.98086 4.91914 8.00052 5.00003 8.00052C5.08091 8.00052 5.16059 7.98086 5.23218 7.94322C5.30378 7.90559 5.36516 7.85112 5.41103 7.7845L9.91103 1.2845C9.96312 1.20953 9.99366 1.12172 9.99935 1.0306C10.005 0.93949 9.98564 0.848562 9.94327 0.767697C9.90091 0.686832 9.83719 0.619124 9.75905 0.571929C9.6809 0.524734 9.59132 0.499857 9.50003 0.500001H0.500029C0.408951 0.500377 0.319698 0.525574 0.24187 0.572882C0.164041 0.62019 0.100581 0.68782 0.0583138 0.768497C0.0160467 0.849175 -0.00342795 0.939848 0.00198403 1.03077C0.00739601 1.12168 0.0374899 1.20941 0.0890293 1.2845L4.58903 7.7845Z"
        />
    </svg>
</template>
