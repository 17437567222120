/*------------------------------------------------------------------------
                           Notification channels
------------------------------------------------------------------------*/

export const NOTIFICATION_CHANNELS = {
    EMAIL: 'email',
};

export const NOTIFICATION_CHANNELS_OPTIONS = [
    { value: NOTIFICATION_CHANNELS.EMAIL, text: 'Email' },
];
