<script>
export default {
    name: 'DeleteIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 24,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        :width="width"
        :height="height"
    >
        <rect
            x="4.5"
            y="5.1"
            width="15"
            height="18"
            rx="1.5"
            stroke="#CF382F"
            stroke-width="1.5"
        />
        <rect
            x="2.25"
            y="2.1"
            width="19.5"
            height="3"
            rx=".75"
            stroke="#CF382F"
            stroke-width="1.5"
            stroke-linejoin="round"
        />
        <path
            d="M9 .9h6M12 9v10.5M15.75 9v10.5M8.25 9v10.5"
            stroke="#CF382F"
            stroke-width="1.5"
            stroke-linecap="round"
        />
    </svg>
</template>
