import { reactive, onBeforeMount, inject, ref, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import dayjs from 'dayjs';

import EventBus from '@/utils/EventBus';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { REPORTS_RESTRICTIONS } from '@/enums/restrictionsEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { REPORTS } from '@/enums/reportsEnums';
import ReportModel from '@/models/Report';
import useAbortableRequest from '@/composition/useAbortableRequest';

const useReportData = (reportName, initialFiltersConfig) => {
    const $acl = inject('$acl');

    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const { sendAbortableRequest } = useAbortableRequest();

    const { currentAccount } = store.state.auth;
    const orgId = currentAccount.organization.id;

    /*------------------------------------------------------------------------
                                  Restrict access
    ------------------------------------------------------------------------*/

    onBeforeMount(() => {
        if (!$acl.can(REPORTS_RESTRICTIONS.VIEW, currentAccount)) {
            EventBus.$emit('pl.flash-notification', {
                type: ALERT_TYPES.FAIL,
                message: 'You do not have access to report.',
            });

            router.replace({ name: SETTINGS_ROUTE_NAMES.INDEX });
        }
    });

    /*------------------------------------------------------------------------
                                   Date settings
    ------------------------------------------------------------------------*/

    const currentDate = dayjs().format('YYYY-MM-DD');

    const dateSettings = reactive({
        start_date: route.query.start_date || currentDate,
        end_date: route.query.end_date || currentDate,
    });

    const updateDateSettings = ({ startDate, endDate }) => {
        dateSettings.start_date = startDate;
        dateSettings.end_date = endDate;
    };

    /*------------------------------------------------------------------------
                                      Filters
    ------------------------------------------------------------------------*/

    const getInitialFilters = () =>
        Object.entries(initialFiltersConfig).reduce((acc, [key, defaultValue]) => {
            let value = route.query[key];

            if (typeof defaultValue === 'boolean') {
                value = value === 'true';
            }

            return {
                ...acc,
                [key]: value || defaultValue,
            };
        }, {});

    const filters = ref(getInitialFilters());

    const updateFilters = (updatedFilters) => {
        filters.value = {
            ...filters.value,
            ...updatedFilters,
        };
    };

    /*------------------------------------------------------------------------
                                   Report state
    ------------------------------------------------------------------------*/

    const reportState = reactive({
        data: null,
        isDataLoading: true,
    });

    const loadReportData = () => {
        reportState.data = null;
        reportState.isDataLoading = true;

        const queryParams = {
            ...filters.value,
            ...dateSettings,
        };

        router.replace({ query: queryParams });

        sendAbortableRequest(ReportModel.find(orgId, { report: reportName, ...queryParams }))
            .then((data) => {
                if (data !== null) {
                    reportState.data = data.report.data;
                }
            })
            .finally(() => (reportState.isDataLoading = false));
    };

    const getReportFileName = () => {
        const { TITLE } = Object.values(REPORTS).find(({ NAME }) => reportName === NAME);

        const startDate = dayjs(dateSettings.start_date).format('D MMM YYYY');
        const endDate = dayjs(dateSettings.end_date).format('D MMM YYYY');

        const fullDate = startDate !== endDate ? `${startDate} - ${endDate}` : startDate;

        return `${TITLE} (${fullDate})`;
    };

    const downloadReport = () => {
        EventBus.$emit('pl.flash-notification', {
            type: ALERT_TYPES.SUCCESS,
            message: `Downloading..`,
        });

        ReportModel.download(orgId, {
            report: reportName,
            ...filters.value,
            ...dateSettings,
        }).then((data) => {
            const url = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${getReportFileName()}.xlsx`);

            document.body.appendChild(link);
            link.click();

            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        });
    };

    watch(() => [dateSettings, filters.value], loadReportData, { immediate: true, deep: true });

    return {
        filters,
        dateSettings,
        reportState,
        updateFilters,
        updateDateSettings,
        downloadReport,
    };
};

export default useReportData;
