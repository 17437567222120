<script>
export default {
    name: 'ThreeDotsIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 6,
    },
    height: {
        type: Number,
        default: 24,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 6 24"
        class="pl-three-dots-icon"
        :width="width"
        :height="height"
    >
        <circle
            cx="2"
            cy="2"
            r="2"
            fill="#fff"
        />
        <circle
            cx="2"
            cy="10"
            r="2"
            fill="#fff"
        />
        <circle
            cx="2"
            cy="18"
            r="2"
            fill="#fff"
        />
    </svg>
</template>

<style lang="scss" scoped>
.pl-three-dots-icon {
    position: relative;
    top: 2px;
}
</style>
