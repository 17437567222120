import ApiClient from '@/utils/ApiClient';
import Model from './Model';
import Timeline from './Timeline';

export default class OrderItem extends Model {
    static getReportingUnits(orgId, itemId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/items/${itemId}/reporting-units`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async report(orgId, itemId, attributes, query = {}) {
        const { data } = await ApiClient.post(`organizations/${orgId}/items/${itemId}/batches-done`, attributes, {
            params: query,
        });

        return Timeline.make(data);
    }
}
