<script>
export default {
    name: 'OrderStepTemplate',
};
</script>

<script setup>
import { reactive, useSlots } from 'vue';

import Modal from '@/components/Modal';
import LoadingButton from '@/components/form-controls/LoadingButton';

defineProps({
    excludeContentContainer: {
        type: Boolean,
        default: false,
    },
    isCancelProcessDisabled: {
        type: Boolean,
        default: false,
    },
    isStepSubmitting: {
        type: Boolean,
        default: false,
    },
    isEditMode: {
        type: Boolean,
        default: false,
    },
    isTemplate: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['before-init-cancel-process', 'cancel-process']);

const slots = useSlots();

/*------------------------------------------------------------------------
                           Cancel process state
------------------------------------------------------------------------*/

const cancelProcessState = reactive({
    isModalOpened: false,
    isActionProcessing: false,
});

const openCancelProcessModal = () => {
    emit('before-init-cancel-process');

    cancelProcessState.isModalOpened = true;
};

const closeCancelProcessModal = () => (cancelProcessState.isModalOpened = false);

const cancelProcess = () => {
    cancelProcessState.isActionProcessing = true;

    emit('cancel-process');
};
</script>

<template>
    <div
        class="pl-order-step"
        :class="{
            container: !excludeContentContainer,
        }"
    >
        <OverlayLoader
            v-if="isStepSubmitting"
            enable-sticky
        />

        <transition
            appear
            name="down"
            type="transition"
        >
            <div class="pl-order-step__content">
                <slot name="step-content" />
            </div>
        </transition>

        <div
            class="pl-order-step__actions-wrapper"
            :class="{
                container: excludeContentContainer,
            }"
        >
            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    class="pl-order-step__actions"
                    style="transition-delay: 100ms"
                >
                    <slot name="prev-step-link">
                        <button
                            type="button"
                            class="btn btn-secondary"
                            :disabled="isCancelProcessDisabled || isStepSubmitting"
                            @click="openCancelProcessModal"
                        >
                            {{ isEditMode ? 'Cancel editing' : `Cancel ${isTemplate ? 'template' : 'order'}` }}
                        </button>
                    </slot>

                    <slot name="next-step-action" />

                    <small
                        v-if="slots['ribbon-text']"
                        class="pl-order-step__ribbon"
                    >
                        <slot name="ribbon-text" />
                    </small>
                </div>
            </transition>
        </div>
    </div>

    <Modal
        v-if="cancelProcessState.isModalOpened"
        is-content-centered
        :is-close-disabled="cancelProcessState.isActionProcessing"
        @close="closeCancelProcessModal"
    >
        <template #title>
            Action required
        </template>

        <template #content>
            {{
                isEditMode
                    ? 'Are you sure you want to cancel editing process?'
                    : `Are you sure you want to cancel the ${isTemplate ? 'template' : 'order'}?`
            }}
        </template>

        <template #actions>
            <button
                type="button"
                class="btn btn-secondary"
                :disabled="cancelProcessState.isActionProcessing"
                @click="closeCancelProcessModal"
            >
                No
            </button>

            <LoadingButton
                type="button"
                class="btn btn-primary"
                :loading="cancelProcessState.isActionProcessing"
                @click="cancelProcess"
            >
                Yes
            </LoadingButton>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
.pl-order-step {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    height: 100%;

    &.container &__content {
        display: flex;
        justify-content: center;
    }

    &__content {
        flex-grow: 1;
    }

    &__actions-wrapper {
        display: flex;
        justify-content: center;
        margin-top: custom-space(2);
    }

    &__actions {
        display: flex;
        flex-direction: column;
        gap: custom-space(0.5);
        width: 50%;

        @include media-breakpoint-down(xl) {
            width: 75%;
        }

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        & > :deep(.btn) {
            width: 100%;
        }
    }

    &__ribbon {
        text-align: center;
        color: $gray-800;
    }
}
</style>
