import useVuelidate from '@vuelidate/core';
import ErrorsBag from './ErrorsBag';

export default class {
    constructor({ payload, rules, fieldNames = null, customMessages = null }) {
        this.payload = payload;
        this.rules = rules;
        this.fieldNames = fieldNames;
        this.messages = customMessages;
        this.errors = new ErrorsBag();
    }

    validate(field = null) {
        if (!this.rulesDefined()) return true;

        this.errors.clearAll();

        this.$v = useVuelidate(this.rules, this.payload);

        this.$v.value.$touch();

        if (field && !this.$v.value.$errors.some((e) => e.$propertyPath === field)) {
            return true;
        } else if (!this.$v.value.$errors.length) {
            return true;
        }

        this._recordVuelidateErrors();
        return false;
    }

    _recordVuelidateErrors() {
        const preparedErrors = {};

        for (let $error of this.$v.value.$errors) {
            const errorMessage = this._getMappedVuelidateErrorMessages($error.$propertyPath, $error.$params)[
                $error.$validator
            ];

            if ($error.$propertyPath in preparedErrors) {
                preparedErrors[$error.$propertyPath].push(errorMessage);
            } else {
                preparedErrors[$error.$propertyPath] = [errorMessage];
            }
        }

        this.errors.record(preparedErrors);
    }

    _getMappedVuelidateErrorMessages(field, params) {
        return Object.assign(
            {
                required: `${this._getFieldName(field)} is required.`,
                requiredIf: `${this._getFieldName(field)} is required.`,
                email: 'Email address is not valid.',
                url: `The ${this._getFieldName(field)} field must contain a valid url address.`,
                minLength: `${this._getFieldName(field)} is too short.`,
                // minLength: `${this._getFieldName(field)} cannot contain less than ${$params.min} characters.`,
                maxLength: `${this._getFieldName(field)} is too long.`,
                decimal: `${this._getFieldName(field)} must be numeric.`,
                integer: `${this._getFieldName(field)} must be an integer.`,
                numeric: `${this._getFieldName(field)} must contain a valid float or integer value.`,
                phone: `You provided an incorrect or incompatible phone number, please correct it and try again.`,
                minValue: `${this._getFieldName(field)} can't be less then ${params?.min}.`,
                maxValue: `${this._getFieldName(field)} can't be more then ${params?.max}.`,
            },
            this.messages || {}
        );
    }

    _getFieldName(field) {
        let specifiedName;

        if (this.fieldNamesDefined()) {
            specifiedName = this.fieldNames[field];
        }

        return specifiedName || field.replace(/[-_.]/g, ' ').replace(/^(\b[a-z](?!\s))/g, (x) => x.toUpperCase());
    }

    getErrors(field = null) {
        return this.errors.get(field);
    }

    rulesDefined() {
        return this.rules && Object.entries(this.rules).length;
    }

    fieldNamesDefined() {
        return this.fieldNames && Object.entries(this.fieldNames).length;
    }
}
