<script>
export default {
    name: 'CrossIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 19,
    },
    height: {
        type: Number,
        default: 19,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 19 19"
        :width="width"
        :height="height"
    >
        <path
            d="M11.41 9.00002L18.7 1.71002C18.8638 1.51872 18.9494 1.27264 18.9397 1.02096C18.93 0.769288 18.8256 0.530549 18.6475 0.352454C18.4694 0.17436 18.2307 0.0700268 17.979 0.0603056C17.7274 0.0505845 17.4813 0.136191 17.29 0.300017L9.99997 7.59002L2.70997 0.290017C2.51867 0.126191 2.2726 0.0405852 2.02092 0.0503063C1.76924 0.0600275 1.53051 0.164359 1.35241 0.342454C1.17432 0.520548 1.06998 0.759287 1.06026 1.01096C1.05054 1.26264 1.13615 1.50872 1.29997 1.70002L8.58997 9.00002L1.28997 16.29C1.18529 16.3797 1.10027 16.49 1.04025 16.614C0.980225 16.7381 0.946494 16.8732 0.941175 17.011C0.935855 17.1487 0.959061 17.286 1.00934 17.4143C1.05961 17.5427 1.13587 17.6592 1.23332 17.7567C1.33078 17.8541 1.44733 17.9304 1.57565 17.9807C1.70398 18.0309 1.84131 18.0541 1.97903 18.0488C2.11675 18.0435 2.25188 18.0098 2.37594 17.9497C2.50001 17.8897 2.61033 17.8047 2.69997 17.7L9.99997 10.41L17.29 17.7C17.4813 17.8638 17.7274 17.9494 17.979 17.9397C18.2307 17.93 18.4694 17.8257 18.6475 17.6476C18.8256 17.4695 18.93 17.2307 18.9397 16.9791C18.9494 16.7274 18.8638 16.4813 18.7 16.29L11.41 9.00002Z"
            fill="#FF3535"
        />
    </svg>
</template>
