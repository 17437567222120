<template>
    <AuthLayout>
        <template #header>
            <div class="mb-2">
                <a
                    href="#"
                    class="fs-6"
                    data-testid="auth_wizard_back_btn"
                    @click.prevent="$router.back()"
                >
                    <BackArrowIcon />
                </a>
            </div>

            <div class="text-center">
                <ProgressSteps :active-step="2" />
            </div>
        </template>

        <transition
            appear
            name="down"
            type="transition"
        >
            <h1 class="h2 mb-4 text-center">
                Add your Teammates
            </h1>
        </transition>

        <router-view />
    </AuthLayout>
</template>

<script>
export default {
    name: 'RegistrationStep2',
};
</script>

<script setup>
import { onBeforeMount } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import ProgressSteps from '@/components/model-specific/auth/ProgressSteps';

const store = useStore();
const router = useRouter();
const { currentAccount } = store.state.auth;

onBeforeMount(() => {
    if (currentAccount !== null) {
        router.push({ name: PL_ROUTE_NAMES.INDEX });
    }
});
</script>
