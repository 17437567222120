<script>
export default {
    name: 'BoldCheckIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 16,
    },
    height: {
        type: Number,
        default: 16,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 16 16"
        :width="width"
        :height="height"
    >
        <path
            d="m13.666.938-7.332 9.665L2 6.272l-2 2 6.666 6.665 9.334-12-2.334-2Z"
            fill="#c8c8c8"
        />
    </svg>
</template>