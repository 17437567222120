import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import CompanyEditView from '@/views/dashboard/settings/company/edit';

export default [
    {
        path: 'settings/company/edit',
        name: SETTINGS_ROUTE_NAMES.COMPANY,
        component: CompanyEditView,
    },
];
