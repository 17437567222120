<script>
export default {
    name: 'GroupedSettingsListItem',
};
</script>

<script setup>
defineProps({
    isSelected: {
        type: Boolean,
        default: false,
    },
});
</script>

<template>
    <li class="pl-grouped-settings__list-item">
        <span>
            <slot />
        </span>

        <slot
            v-if="isSelected"
            name="icon"
        >
            <CheckIcon
                :width="16"
                :height="16"
            />
        </slot>
    </li>
</template>

<style lang="scss" scoped>
.pl-grouped-settings__list-item {
    display: flex;
    align-items: center;
    gap: custom-space(0.5);
    cursor: pointer;
    @include pl-transition(color, true);

    & > span {
        flex-grow: 1;
        display: flex;
        font-size: $font-size-base * 0.875;
        font-weight: $font-weight-normal;
        color: $primary;

        & > :slotted(a) {
            flex-grow: 1;
        }
    }

    & > svg {
        flex-shrink: 0;

        & > :deep(path) {
            fill: $primary;
        }
    }

    &:hover > span {
        color: lighten($primary, 10%);
    }
}
</style>
