<script>
export default {
    name: 'Tabs',
};
</script>

<script setup>
import { ref, watch } from 'vue';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

const props = defineProps({
    modelValue: {
        type: String,
        default: null,
    },
    tabOptions: {
        type: Array,
        required: true,
    },
    hasBeenContentJustUpdated: {
        type: Boolean,
        default: false,
    },
    enableSliderMode: {
        type: Boolean,
        default: false,
    },
    includeContentContainer: {
        type: Boolean,
        default: false,
    },
});

const emit = defineEmits(['update:modelValue']);

/*------------------------------------------------------------------------
                             Active tab state
------------------------------------------------------------------------*/

const activeTab = ref(props.modelValue);
const hasBeenTabJustUpdated = ref(false);

const isTabActive = (tabOption) => activeTab.value === tabOption.value;

const setActiveTab = ({ value }) => {
    if (activeTab.value !== value) {
        activeTab.value = value;
        hasBeenTabJustUpdated.value = true;

        setTimeout(() => (hasBeenTabJustUpdated.value = false), 250);
    }
};

watch(
    () => activeTab.value,
    (value) => {
        emit('update:modelValue', value);
    }
);

/*------------------------------------------------------------------------
                                  Swiper
------------------------------------------------------------------------*/

const swiperSettings = {
    modules: [Navigation],
    slidesPerView: 2,
};

watch(
    () => props.tabOptions,
    (options) => {
        if (options.length > 0 && activeTab.value === null) {
            activeTab.value = options[0].value;
        }
    },
    { immediate: true }
);
</script>

<template>
    <div class="pl-tabs">
        <div
            v-if="enableSliderMode"
            class="pl-tabs__slider-wrapper"
        >
            <div class="container">
                <Swiper
                    navigation
                    :modules="swiperSettings.modules"
                    :slides-per-view="swiperSettings.slidesPerView"
                >
                    <SwiperSlide
                        v-for="tabOption in tabOptions"
                        :key="`slide-tab: ${tabOption.value}`"
                        class="pl-tabs__slide"
                        :data-testid="tabOption.qaPrefix ? `${tabOption.qaPrefix}_tab` : undefined"
                        :class="{
                            'pl-tabs__slide--selected': isTabActive(tabOption),
                        }"
                        @click.stop="setActiveTab(tabOption)"
                    >
                        {{ tabOption.text }}
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>

        <div
            v-else
            class="pl-tabs__options"
        >
            <button
                v-for="tabOption in tabOptions"
                :key="`tab: ${tabOption.value}`"
                type="button"
                class="pl-tabs__option"
                :class="{
                    'pl-tabs__option--active': isTabActive(tabOption),
                }"
                :data-testid="`tab-${tabOption.value}`"
                @click="setActiveTab(tabOption)"
            >
                {{ tabOption.text }}
            </button>
        </div>

        <transition
            appear
            name="down"
            type="transition"
            mode="out-in"
        >
            <div
                v-if="activeTab !== null && !hasBeenTabJustUpdated && !hasBeenContentJustUpdated"
                class="pl-tabs__content"
                :class="{
                    container: includeContentContainer,
                }"
            >
                <slot
                    name="content"
                    :activeTab="activeTab"
                />
            </div>
        </transition>
    </div>
</template>

<style lang="scss" scoped>
.pl-tabs {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin-top: custom-space(-1.5);

    &__slider-wrapper {
        word-break: break-word;
        background-color: $gray-50;
        margin-bottom: custom-space(1.5);
        border-bottom: 1px solid $gray-100;
    }

    &__slide {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: custom-space(1) custom-space(1.5);
        color: $gray-600;
        font-weight: $font-weight-normal;
        cursor: pointer;
        text-transform: capitalize;

        &--selected {
            color: $primary;
            background-color: $white;
        }
    }

    &__options {
        display: flex;
        align-items: center;
        justify-content: space-between;
        min-width: 100%;
        margin-bottom: custom-space(2);

        @include media-breakpoint-down(lg) {
            $space: calc($grid-gutter-width / -2);

            margin-left: $space;
            margin-right: $space;
        }
    }

    &__option {
        flex: 1 1 50%;
        padding: custom-space(0.625);
        color: $gray-500;
        background-color: $gray-50;
        text-align: center;
        text-transform: uppercase;
        font-size: $font-size-base * 0.95;
        font-weight: $font-weight-normal;
        border-bottom: 1px solid $gray-400;
        @include pl-transition(all);

        @include media-breakpoint-down(lg) {
            font-size: $font-size-base * 0.85;
        }

        &:not(&--active):hover,
        &:not(&--active):focus {
            box-shadow: inset 0 -2px 0 $gray-400;
        }

        &--active {
            background-color: transparent;
            color: $primary;
            border-bottom-color: $primary;
            box-shadow: inset 0 -2px 0 $primary;
        }
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }
}
</style>
