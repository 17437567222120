import { NOT_FOUND_ROUTE_NAME } from '@/enums/routesNameEnums';

export const isRouteLinkActive = (currentRoute, linkPath) =>
    currentRoute.path.indexOf(linkPath) === 0 && currentRoute.name !== NOT_FOUND_ROUTE_NAME;

export const getExternalLinkTo = (link) => {
    const url = new URL(link);

    const queryParams = {};

    url.searchParams.forEach((value, key) => {
        queryParams[key] = value;
    });

    return {
        path: url.pathname,
        query: queryParams,
    };
};

export const getBackRoute = (route, routesObj, initialRoute) => {
    const routesNames = Object.values(routesObj);

    const currentRouteIndex = routesNames.findIndex((routeName) => routeName === route.name);

    if (currentRouteIndex > 0) {
        return routesNames[currentRouteIndex - 1];
    }

    return initialRoute;
};
