import { REPORTS_ROUTE_NAMES } from '@/enums/routesNameEnums';

/*------------------------------------------------------------------------
                                  General
------------------------------------------------------------------------*/

export const REPORTS = {
    PRODUCTION: {
        ROUTE: REPORTS_ROUTE_NAMES.SHOW.PRODUCTION,
        NAME: 'production_report',
        TITLE: 'Production report',
    },
    EMPLOYEE_PERFORMANCE: {
        ROUTE: REPORTS_ROUTE_NAMES.SHOW.EMPLOYEE_PERFORMANCE,
        NAME: 'employee_performance',
        TITLE: 'Employee performance',
    },
    FULFILLMENT: {
        ROUTE: REPORTS_ROUTE_NAMES.SHOW.FULFILLMENT,
        NAME: 'fulfillment_report',
        TITLE: 'Fulfillment report',
    },
};

export const REPORTS_NAVIGATION = {
    [REPORTS.PRODUCTION.ROUTE]: REPORTS.PRODUCTION.TITLE,
    [REPORTS.EMPLOYEE_PERFORMANCE.ROUTE]: REPORTS.EMPLOYEE_PERFORMANCE.TITLE,
    [REPORTS.FULFILLMENT.ROUTE]: REPORTS.FULFILLMENT.TITLE,
};

/*------------------------------------------------------------------------
                                  Periods
------------------------------------------------------------------------*/

export const REPORT_PERIODS = {
    DAY: 'day',
    WEEK: 'week',
    MONTH: 'month',
    CUSTOM: 'custom',
};

/*------------------------------------------------------------------------
                                Directions
------------------------------------------------------------------------*/

export const REPORT_DIRECTIONS = {
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
};

/*------------------------------------------------------------------------
                               Break down by
------------------------------------------------------------------------*/

export const REPORT_BREAK_DOWN_BY = {
    ORDER: 'order',
    ITEM: 'item',
    PREP_TASK: 'prep_task',
};

export const COMMON_REPORT_DEFAULT_BREAK_DOWN_BY = REPORT_BREAK_DOWN_BY.ITEM;
export const FULFILLMENT_REPORT_DEFAULT_BREAK_DOWN_BY = REPORT_BREAK_DOWN_BY.ORDER;

/*------------------------------------------------------------------------
                                 Group by
------------------------------------------------------------------------*/

export const REPORT_GROUP_BY = {
    DESTINATION: 'destination',
    LOCATION: 'location',
    DEPARTMENT_N_CATEGORY: 'department_n_category',
    DEPARTMENT: 'department',
};

/*------------------------------------------------------------------------
                                 Order by
------------------------------------------------------------------------*/

export const REPORT_ORDER_BY = {
    ASC: 'asc',
    DESC: 'desc',
};

export const FULFILLMENT_REPORT_DEFAULT_ORDER_BY = REPORT_ORDER_BY.ASC;

/*------------------------------------------------------------------------
                 Production report filter settings config
------------------------------------------------------------------------*/

export const PRODUCTION_REPORT_FILTER_SETTINGS_CONFIG = {
    group_by: {
        title: 'Group By',
        options: [
            { value: null, text: 'No grouping', selectedText: 'No grouping' },
            { value: REPORT_GROUP_BY.DEPARTMENT, text: 'Department' },
            { value: REPORT_GROUP_BY.DEPARTMENT_N_CATEGORY, text: 'Department & category' },
            { value: REPORT_GROUP_BY.LOCATION, text: 'Location' },
        ],
        defaultValue: null,
        component: 'select',
    },
    break_down_by: {
        title: 'Break Down By',
        options: [
            { value: REPORT_BREAK_DOWN_BY.ITEM, text: 'Item' },
            { value: REPORT_BREAK_DOWN_BY.PREP_TASK, text: 'Prep task' },
        ],
        defaultValue: COMMON_REPORT_DEFAULT_BREAK_DOWN_BY,
    },
    include_all_locations: {
        options: [{ value: true, text: 'Show report for all locations' }],
        defaultValue: false,
        component: 'checkbox',
    },
};

/*------------------------------------------------------------------------
                  Employee report filter settings config
------------------------------------------------------------------------*/

export const EMPLOYEE_PERFORMANCE_FILTER_SETTINGS_CONFIG = {
    break_down_by: {
        title: 'Break Down By',
        options: [
            { value: REPORT_BREAK_DOWN_BY.ITEM, text: 'Item' },
            { value: REPORT_BREAK_DOWN_BY.PREP_TASK, text: 'Prep task' },
        ],
        defaultValue: COMMON_REPORT_DEFAULT_BREAK_DOWN_BY,
    },
    group_by: {
        title: 'Group By',
        options: [
            { value: REPORT_GROUP_BY.DEPARTMENT, text: 'Department' },
            { value: REPORT_GROUP_BY.LOCATION, text: 'Location' },
        ],
        defaultValue: null,
    },
    include_all_locations: {
        options: [{ value: true, text: 'Show report for all locations' }],
        defaultValue: false,
        component: 'checkbox',
    },
};

/*------------------------------------------------------------------------
                 Fulfillment report filter settings config
------------------------------------------------------------------------*/

export const FULFILLMENT_REPORT_FILTER_SETTINGS_CONFIG = {
    break_down_by: {
        title: 'Break Down By',
        options: [
            { value: REPORT_BREAK_DOWN_BY.ORDER, text: 'Order' },
            { value: REPORT_BREAK_DOWN_BY.ITEM, text: 'Item' },
        ],
        defaultValue: FULFILLMENT_REPORT_DEFAULT_BREAK_DOWN_BY,
    },
    group_by: {
        title: 'Group By',
        options: [
            { value: REPORT_GROUP_BY.DESTINATION, text: 'Destination' },
            { value: REPORT_GROUP_BY.LOCATION, text: 'location' },
        ],
        defaultValue: null,
    },
    order_by: {
        title: 'Order By',
        options: [
            { value: REPORT_ORDER_BY.ASC, text: 'Newest' },
            { value: REPORT_ORDER_BY.DESC, text: 'Oldest' },
        ],
        defaultValue: FULFILLMENT_REPORT_DEFAULT_ORDER_BY,
    },
    direction: {
        title: 'Direction',
        options: [
            { value: REPORT_DIRECTIONS.INCOMING, text: 'Incoming' },
            { value: REPORT_DIRECTIONS.OUTGOING, text: 'Outgoing' },
        ],
        defaultValue: null,
    },
};
