<template>
    <AuthLayout is-content-centered>
        <template #header>
            <div class="mb-2">
                <a
                    href="#"
                    class="fs-6"
                    data-testid="auth_wizard_back_btn"
                    @click.prevent="onPressBack"
                >
                    <BackArrowIcon />
                </a>
            </div>

            <div class="text-center">
                <ProgressSteps :active-step="1" />
            </div>
        </template>

        <FullScreenLoader v-if="store.state.auth.isLogOutPending" />

        <transition
            appear
            name="down"
            type="transition"
        >
            <h1 class="h2 text-center">
                Please enter your information
            </h1>
        </transition>

        <form
            novalidate
            @submit.prevent="cacheData"
        >
            <transition
                appear
                name="down"
                type="transition"
            >
                <div class="mb-4">
                    <TextInput
                        v-model="form.name"
                        :has-error="form.errors.hasOneOf(['name', 'owner.name'])"
                        name="name"
                        autofocus
                        tabindex="1"
                        autocomplete="given-name"
                        data-test-id="auth_wizard_owner_name_input"
                        label="Your name"
                        @input="form.errors.clear(['name', 'owner.name'])"
                        @blur="form.validate('name')"
                    />

                    <ValidationErrors
                        v-if="form.errors.hasOneOf(['name', 'owner.name'])"
                        :errors="form.errors.get(['name', 'owner.name'])"
                        data-testid="auth_wizard_owner_name_error"
                    />
                </div>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    class="mb-4"
                    style="transition-delay: 50ms"
                >
                    <TextInput
                        v-model="form.email"
                        :has-error="form.errors.hasOneOf(['email', 'owner.email'])"
                        type="email"
                        name="email"
                        tabindex="2"
                        autocomplete="email"
                        data-test-id="auth_wizard_owner_email_input"
                        label="Your email"
                        @input="form.errors.clear(['email', 'owner.email'])"
                        @blur="form.validate('email')"
                    />

                    <ValidationErrors
                        v-if="form.errors.hasOneOf(['email', 'owner.email'])"
                        :errors="form.errors.get(['email', 'owner.email'])"
                        data-testid="auth_wizard_owner_email_error"
                    />
                </div>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    class="mb-5"
                    style="transition-delay: 100ms"
                >
                    <TextInput
                        v-model="form.organization_name"
                        :has-error="form.errors.hasOneOf(['organization_name', 'organization.name'])"
                        name="organization_name"
                        autocomplete="organization"
                        tabindex="3"
                        data-test-id="auth_wizard_company_name_input"
                        label="Company name"
                        @input="form.errors.clear(['organization_name', 'organization.name'])"
                        @blur="form.validate('organization_name')"
                    />

                    <ValidationErrors
                        v-if="form.errors.hasOneOf(['organization_name', 'organization.name'])"
                        :errors="form.errors.get(['organization_name', 'organization.name'])"
                        data-testid="auth_wizard_company_name_error"
                    />
                </div>
            </transition>

            <transition
                appear
                name="down"
                type="transition"
            >
                <div
                    class="d-grid"
                    style="transition-delay: 150ms"
                >
                    <button
                        type="submit"
                        class="btn btn-primary"
                        tabindex="4"
                        :disabled="form.errors.any() || !form.isFilled()"
                        data-testid="auth_wizard_complete_step_1_btn"
                    >
                        Continue
                    </button>
                </div>
            </transition>
        </form>
    </AuthLayout>
</template>

<script>
export default {
    name: 'RegistrationStep1',
};
</script>

<script setup>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { reactive, onBeforeMount } from 'vue';
import { required, maxLength, minLength, email } from '@vuelidate/validators';

import { LS } from '@/instances/localStorage';
import FormManager from '@/utils/form/FormManager';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import ProgressSteps from '@/components/model-specific/auth/ProgressSteps';
import TextInput from '@/components/form-controls/TextInput';

const store = useStore();
const router = useRouter();
const { currentAccount } = store.state.auth;

onBeforeMount(() => {
    if (currentAccount !== null) {
        router.push({ name: PL_ROUTE_NAMES.INDEX });
    }
});

const form = reactive(
    new FormManager(
        {
            name: LS.get('onboarding_owner', {}).name || '',
            email: LS.get('onboarding_owner', {}).email || '',
            organization_name: LS.get('onboarding_organization', {}).name || '',
        },
        {
            name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
            email: {
                required,
                email,
                maxLength: maxLength(255),
            },
            organization_name: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(255),
            },
        },
        {
            organization_name: 'Company name',
        }
    )
);

const cacheData = () => {
    if (form.validate()) {
        LS.set('onboarding_owner', { name: form.name, email: form.email });
        LS.set('onboarding_organization', { name: form.organization_name });

        router.push({ name: 'registration.step-2' });
    }
};

const recordApiValidationErrors = () => {
    const apiErrorsBag = store.getters['SVE/getErrorsBag']('registration');
    const fieldsOnThisScreen = ['owner.name', 'owner.email', 'organization.name'];
    if (null !== apiErrorsBag) {
        for (let field of fieldsOnThisScreen) {
            if (apiErrorsBag.has(field)) {
                form.errors.mergeFor(field, apiErrorsBag.get(field));
            }
        }
    }
};

onBeforeMount(() => recordApiValidationErrors());

const onPressBack = () => {
    if (store.state.accounts.length > 0) {
        router.back();
    } else {
        store.dispatch('auth/logout').then(() => (window.location.href = '/'));
    }
};
</script>
