import ApiClient from '@/utils/ApiClient';
import Model from './Model';
import Organization from './Organization';

export default class Account extends Model {
    static all(orgId, query = {}) {
        return this.withAbort(ApiClient.get(`organizations/${orgId}/accounts`, { params: query }), ({ data }) =>
            this.make(data)
        );
    }

    static find(orgId, accountId, query = {}) {
        return this.withAbort(
            ApiClient.get(`organizations/${orgId}/accounts/${accountId}`, { params: query }),
            ({ data }) => this.make(data)
        );
    }

    static async create(orgId, attributes) {
        const { data } = await ApiClient.post(`organizations/${orgId}/accounts`, attributes);

        return this.make(data);
    }

    static changeLocation(orgId, accountId, attributes) {
        return ApiClient.post(`organizations/${orgId}/accounts/${accountId}/change-current-location`, attributes);
    }

    async update(attributes) {
        const { data } = await ApiClient.put(`organizations/${this.organization.id}/accounts/${this.id}`, attributes);

        return this.fill(data);
    }

    async delete(query) {
        const { data } = await ApiClient.delete(
            `organizations/${this.organization.id}/accounts/${this.id}`,
            {},
            { params: query }
        );

        return Account.make(data);
    }

    getRelationships() {
        return {
            organization: Organization,
        };
    }
}
