<script>
export default {
    name: 'OccurredErrorModal',
};
</script>

<script setup>
import { ref } from 'vue';

import Modal from '@/components/Modal';
import Tooltip from '@/components/Tooltip';

defineProps({
    errorMessage: {
        type: String,
        required: true,
    },
});

/*------------------------------------------------------------------------
                               General state
------------------------------------------------------------------------*/

const isReloading = ref(false);

const reloadPage = () => {
    isReloading.value = true;

    window.location.reload();
};
</script>

<template>
    <Modal is-content-centered>
        <template #title>
            An unexpected error occurred
        </template>

        <template #actions>
            <Tooltip
                enable-wide-content
                :text="errorMessage"
            >
                Error Details
            </Tooltip>

            <button
                type="button"
                class="btn btn-primary"
                :disabled="isReloading"
                @click="reloadPage"
            >
                Reload
            </button>
        </template>
    </Modal>
</template>

<style lang="scss" scoped>
:deep(.pl-modal__actions) {
    flex-direction: column;

    > button {
        width: 100%;
    }
}

:deep(.pl-tooltip) {
    color: $primary;
    text-decoration: underline;
}
</style>
