<script>
export default {
    name: 'CreateOrderFab',
};
</script>

<script setup>
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import Fab from '@/components/Fab';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';
</script>

<template>
    <Fab data-testid="create_new_order_fab_btn">
        <DropDown pos-y="top">
            <template #invoker>
                <PlusIcon />
            </template>

            <template #content>
                <GroupedSettings.Root>
                    <GroupedSettings.List>
                        <GroupedSettings.ListItem>
                            <router-link
                                :to="{
                                    name: PL_ROUTE_NAMES.CREATE.INDEX,
                                    query: { action: 'new-order' },
                                }"
                            >
                                New Order
                            </router-link>
                        </GroupedSettings.ListItem>

                        <GroupedSettings.ListItem>
                            <router-link :to="{ name: PL_ROUTE_NAMES.CREATE_FROM_TEMPLATE }">
                                New PAR-Guided Order
                            </router-link>
                        </GroupedSettings.ListItem>
                    </GroupedSettings.List>
                </GroupedSettings.Root>
            </template>
        </DropDown>
    </Fab>
</template>
