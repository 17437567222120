import { ref, computed } from 'vue';

import { findLeafs } from '@/utils/treeUtils';
import useAbortableRequest from '@/composition/useAbortableRequest';
import useUniqueHashTable from '@/composition/useUniqueHashTable';
import useUniqueValueRegister from '@/composition/useUniqueValueRegister';

const useSelectableItemsQty = (modelClass, debounceTimeout = 500) => {
    const { sendAbortableRequest } = useAbortableRequest();

    /*--------------------------------------------------------------------
                                    Items
    --------------------------------------------------------------------*/

    const items = ref({});

    const areItemsAbsent = computed(() => Object.entries(items.value).length === 0);
    const selectedItems = computed(() => findLeafs(items.value, (leaf) => leaf.qty > 0));

    const setItems = (tree) => (items.value = tree);

    const getCurrentItemsByTab = (tab) => {
        const itemsByTab = items.value[tab] || {};

        const selectedItemsLeafs = findLeafs(itemsByTab, (item) => item.is_overlaid);
        const notSelectedItemsLeafs = findLeafs(itemsByTab, (item) => !item.is_overlaid);

        return [...selectedItemsLeafs, ...notSelectedItemsLeafs];
    };

    const loadSelectableItems = (orgId, queryParams) =>
        sendAbortableRequest(modelClass.all(orgId, queryParams)).then((models) => (items.value = models));

    /*--------------------------------------------------------------------
                                    Tabs
    --------------------------------------------------------------------*/

    const departmentOptions = computed(() =>
        Object.keys(items.value).map((department) => {
            const amount = findLeafs(items.value[department], (item) => item.qty > 0, [], true).length;

            return {
                value: department,
                text: `${department} (${amount})`,
                qaPrefix: 'department',
            };
        })
    );

    /*--------------------------------------------------------------------
                                Certain item state
    --------------------------------------------------------------------*/

    const { put: setItemState, get: getItemState, delete: clearItemState } = useUniqueHashTable();

    const setItemLoadingControls = (prototypeId, loadingControls) =>
        setItemState(`${prototypeId}.loading_controls`, loadingControls);

    const resetItemLoadingControls = (prototypeId) => clearItemState(`${prototypeId}.loading_controls`);

    const areItemControlsBeingLoaded = (item, controls) =>
        getItemState(`${item.prototype_id}.loading_controls`)?.some((control) => controls.includes(control));

    const isItemBeingSaved = (prototypeId) => getItemState(`${prototypeId}.is_being_saved`);

    const hasItemBeenJustSaved = (prototypeId) => getItemState(`${prototypeId}.has_just_been_saved`);

    /*--------------------------------------------------------------------
                            Debounced item actions
    --------------------------------------------------------------------*/

    const scheduledCallsRegister = useUniqueValueRegister();

    const requestsStack = [];

    let pendingIncrementRequest = null;
    let incrementDebounceUndoCallback = null;

    const incrementItem = (
        orgId,
        orderId,
        prototypeId,
        isQtyOnHand,
        attributes,
        undoCallback = null,
        queryParams = {},
        loadingControls = []
    ) => {
        return new Promise(function (resolve, reject) {
            let incrementScheduledCallbackKey = `${prototypeId}.increment_scheduled_callback`;

            // Set controls in the "loading" state
            setItemLoadingControls(prototypeId, loadingControls);

            // Tell the system the prototype is currently being handled
            setItemState(`${prototypeId}.is_being_saved`, true);

            // Determine how many times to increment should be requested
            let times = getItemState(`${prototypeId}.increment_debounce_times`) ?? 0;

            /**
             * Save "undo" callback that will return the app into the state
             * where the incrementing was started.
             */
            if (times === 0) {
                incrementDebounceUndoCallback = undoCallback;
            }

            // Cancel scheduled function that shows the caption "Saved"
            const savedCaptionTimeoutId = getItemState(`${prototypeId}.saved_caption_timeout_id`);
            clearTimeout(savedCaptionTimeoutId);

            // Increase amount of times by 1 for the forthcoming request
            setItemState(`${prototypeId}.increment_debounce_times`, ++times);

            // Cancel previous scheduled call
            scheduledCallsRegister.unregister(incrementScheduledCallbackKey);
            clearTimeout(getItemState(incrementScheduledCallbackKey));

            // Schedule new call
            setItemState(
                incrementScheduledCallbackKey,
                setTimeout(() => {
                    clearItemState(`${prototypeId}.increment_debounce_times`);

                    const payload = {};

                    if (attributes.qty >= 0) {
                        payload.qty = attributes.qty;
                    } else {
                        payload.times = times;
                    }

                    const requestCallback = isQtyOnHand ? modelClass.incrementOnHand : modelClass.increment;

                    pendingIncrementRequest = requestCallback(orgId, orderId, prototypeId, payload, queryParams)
                        .then((tree) => {
                            requestsStack.pop();

                            clearItemState(`${prototypeId}.loading_controls`);
                            clearItemState(`${prototypeId}.is_being_saved`);

                            setItemState(`${prototypeId}.has_just_been_saved`, true);

                            const savedCaptionTimeoutId = setTimeout(
                                () => clearItemState(`${prototypeId}.has_just_been_saved`),
                                2000
                            );

                            setItemState(`${prototypeId}.saved_caption_timeout_id`, savedCaptionTimeoutId);

                            if (requestsStack.length === 0 && scheduledCallsRegister.isEmpty()) {
                                setItems(tree);
                            }

                            resolve();
                        })
                        .catch((error) => {
                            requestsStack.pop();
                            clearItemState(`${prototypeId}.is_being_saved`);
                            incrementDebounceUndoCallback();
                            resetItemLoadingControls(prototypeId);
                            reject(error);
                        })
                        .finally(() => {
                            incrementDebounceUndoCallback = null;
                        });

                    requestsStack.push(pendingIncrementRequest);
                    scheduledCallsRegister.unregister(incrementScheduledCallbackKey);
                }, debounceTimeout)
            );

            scheduledCallsRegister.register(incrementScheduledCallbackKey);
        });
    };

    let pendingDecrementRequest = null;
    let decrementDebounceUndoCallback = null;

    const decrementItem = (
        orgId,
        orderId,
        prototypeId,
        isQtyOnHand,
        undoCallback = null,
        queryParams = {},
        qty = null,
        loadingControls = []
    ) => {
        return new Promise((resolve, reject) => {
            let decrementScheduledCallbackKey = `${prototypeId}.decrement_scheduled_callback`;

            // Set controls in the "loading" state
            setItemLoadingControls(prototypeId, loadingControls);

            // Tell the system the prototype is currently being handled
            setItemState(`${prototypeId}.is_being_saved`, true);

            // Determine how many times to increment should be requested
            let times = getItemState(`${prototypeId}.decrement_debounce_times`) ?? 0;

            /**
             * Save "undo" callback that will return the app into the state
             * where the incrementing was started.
             */
            if (times === 0) {
                decrementDebounceUndoCallback = undoCallback;
            }

            // Cancel scheduled function that shows the caption "Saved"
            const savedCaptionTimeoutId = getItemState(`${prototypeId}.saved_caption_timeout_id`);
            clearTimeout(savedCaptionTimeoutId);

            // Decrease amount of times by 1 for the forthcoming request
            setItemState(`${prototypeId}.decrement_debounce_times`, ++times);

            // Cancel previous scheduled call
            scheduledCallsRegister.unregister(decrementScheduledCallbackKey);
            clearTimeout(getItemState(decrementScheduledCallbackKey));

            const attributes = {};

            if (qty !== null) {
                attributes.qty = qty;
            } else {
                attributes.times = times;
            }

            // Schedule new call
            setItemState(
                decrementScheduledCallbackKey,
                setTimeout(() => {
                    clearItemState(`${prototypeId}.decrement_debounce_times`);

                    const requestCallback = isQtyOnHand ? modelClass.decrementOnHand : modelClass.decrement;

                    pendingDecrementRequest = requestCallback(orgId, orderId, prototypeId, attributes, queryParams)
                        .then((tree) => {
                            requestsStack.pop();

                            clearItemState(`${prototypeId}.loading_controls`);
                            clearItemState(`${prototypeId}.is_being_saved`);
                            setItemState(`${prototypeId}.has_just_been_saved`, true);

                            const savedCaptionTimeoutId = setTimeout(
                                () => clearItemState(`${prototypeId}.has_just_been_saved`),
                                2000
                            );

                            setItemState(`${prototypeId}.saved_caption_timeout_id`, savedCaptionTimeoutId);

                            if (requestsStack.length === 0 && scheduledCallsRegister.isEmpty()) {
                                setItems(tree);
                            }

                            resolve();
                        })
                        .catch((error) => {
                            requestsStack.pop();
                            clearItemState(`${prototypeId}.is_being_saved`);
                            decrementDebounceUndoCallback();
                            resetItemLoadingControls(prototypeId);
                            reject(error);
                        })
                        .finally(() => {
                            decrementDebounceUndoCallback = null;
                        });

                    scheduledCallsRegister.unregister(decrementScheduledCallbackKey);
                    requestsStack.push(pendingDecrementRequest);
                }, debounceTimeout)
            );

            scheduledCallsRegister.register(decrementScheduledCallbackKey);
        });
    };

    return {
        items,
        setItems,
        areItemsAbsent,
        selectedItems,
        getCurrentItemsByTab,
        loadSelectableItems,

        departmentOptions,

        isItemBeingSaved,
        hasItemBeenJustSaved,
        setItemLoadingControls,
        resetItemLoadingControls,
        areItemControlsBeingLoaded,

        incrementItem,
        decrementItem,
    };
};

export default useSelectableItemsQty;
