<script>
export default {
    // Floating Action Button
    name: 'Fab',
};
</script>

<script setup>
defineProps({
    posX: {
        type: String,
        default: 'right',
        validator: (value) => ['left', 'right'].includes(value),
    },
    posY: {
        type: String,
        default: 'bottom',
        validator: (value) => ['top', 'bottom'].includes(value),
    },
});
</script>

<template>
    <transition
        appear
        name="scale"
        type="transition"
    >
        <div
            class="pl-fab"
            :class="`pl-fab--pos-x-${posX} pl-fab--pos-y-${posY}`"
            style="transition-delay: 250ms"
        >
            <slot />
        </div>
    </transition>
</template>

<style lang="scss" scoped>
.pl-fab {
    $space: $grid-gutter-width;
    $space--sm: calc($space / 2);

    position: fixed;
    background-color: $white;
    border: 2px solid $white;
    border-radius: 50%;
    box-shadow: 0 0 custom-space(1) 0 rgba($black, 0.15);
    z-index: 22;

    &--pos-x-right {
        right: $space;

        @include media-breakpoint-down(lg) {
            right: $space--sm;
        }
    }

    &--pos-x-left {
        left: $space;

        @include media-breakpoint-down(lg) {
            left: $space--sm;
        }
    }

    &--pos-y-top {
        top: $space;

        @include media-breakpoint-down(lg) {
            top: $space--sm;
        }
    }

    &--pos-y-bottom {
        bottom: $space;

        @include media-breakpoint-down(lg) {
            bottom: $space--sm;
        }
    }
}
</style>
