<script>
export default {
    name: 'GroupedSettings',
};
</script>

<template>
    <div class="pl-grouped-settings">
        <slot />
    </div>
</template>

<style lang="scss" scoped>
.pl-grouped-settings {
    width: custom-space(12);
    padding: custom-space(1);
    background-color: $white;
    border-radius: $border-radius;
    box-shadow: $box-shadow;
}
</style>
