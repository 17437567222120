<script>
export default {
    name: 'ItemCardUI',
};
</script>

<script setup>
/*-----------------------------------------------------------------------------------
                                    Class names
-----------------------------------------------------------------------------------*/

const rootClassName = 'pl-item-card';

const classNames = {
    wrapper: `${rootClassName}__wrapper`,
    content: `${rootClassName}__content`,
    contentWrapper: `${rootClassName}__content-wrapper`,
    categoryName: `${rootClassName}__category-name`,
    name: `${rootClassName}__name`,
    infoList: `${rootClassName}__info-list`,
    stationsList: `${rootClassName}__stations-list`,
    actions: `${rootClassName}__actions`,
    footer: `${rootClassName}__footer`,
};
</script>

<template>
    <div :class="rootClassName">
        <slot :classNames="classNames" />
    </div>
</template>

<style lang="scss">
.pl-item-card {
    position: relative;

    &__wrapper {
        display: flex;
        align-items: center;
    }

    &__content,
    &__content-wrapper {
        width: 100%;
    }

    &__category-name {
        color: $black;
        margin-bottom: custom-space(0.25);
        font-size: $font-size-base * 0.9;
        font-weight: 400;
    }

    &__name {
        display: block;
        color: $primary;
        margin-bottom: custom-space(0.1);
    }

    &__info-list {
        font-size: $font-size-base * 0.875;
        line-height: 1.35;
        list-style: none;
        padding: 0;

        &:not(:last-child) {
            margin-bottom: space(1);
        }

        & > li {
            display: inline-block;

            &:not(:first-child)::before {
                color: $primary;
                content: '\2022';
                margin-left: custom-space(0.3);
                margin-right: custom-space(0.3);
            }

            span:not(:first-child) {
                margin-left: custom-space(0.5);
            }
        }
    }

    &__stations-list > li > span:not(:last-child):after {
        content: ',';
        display: inline;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
        padding-left: custom-space(1);
        flex-basis: custom-space(5);
        flex-shrink: 0;
    }

    &__footer {
        flex-basis: 100%;
    }
}
</style>
