import { ref } from 'vue';

const useCodeGenerator = (codeLength = 4) => {
    const uniqueCodes = ref(new Set());

    const getUniqueCode = () => {
        const numbers = [];

        for (let i = 0; i < codeLength; i++) {
            numbers.push(Math.ceil(Math.random() * 10));
        }

        return numbers.join('').slice(0, codeLength);
    };

    const generateUniqueCode = () => {
        let uniqueCode = getUniqueCode();

        while (uniqueCodes.value.has(uniqueCode)) {
            uniqueCode = getUniqueCode();
        }

        uniqueCodes.value.add(uniqueCode);

        return uniqueCode;
    };

    const clearUsedCode = (usedCode) => {
        uniqueCodes.value.delete(usedCode);
    };

    return { generateUniqueCode, clearUsedCode };
};

export default useCodeGenerator;
