<script>
export default {
    name: 'AddItemsIcon',
};
</script>

<script setup>
defineProps({
    width: {
        type: Number,
        default: 224,
    },
    height: {
        type: Number,
        default: 224,
    },
});
</script>

<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 224 224"
        :width="width"
        :height="height"
    >
        <g filter="url(#filter0_d_992_2404)">
            <circle
                cx="112"
                cy="112"
                r="77"
                fill="white"
            />
        </g>
        <path
            d="M130.931 80.6896H107.034C105.778 80.6896 104.759 79.6707 104.759 78.4138C104.759 77.1568 105.778 76.1379 107.034 76.1379H130.931C132.188 76.1379 133.207 77.1568 133.207 78.4138C133.207 79.6707 132.188 80.6896 130.931 80.6896Z"
            fill="#CF382F"
        />
        <path
            d="M91.1035 75C86.0758 75 82 79.0758 82 84.1035C82 89.1312 86.0758 93.207 91.1035 93.207C96.1312 93.207 100.207 89.1312 100.207 84.1035C100.207 79.0758 96.1311 75 91.1035 75ZM96.4597 81.8737L90.3905 87.9423C90.1681 88.1647 89.8772 88.2757 89.586 88.2757C89.2948 88.2757 89.0036 88.1646 88.7815 87.9423L85.7472 84.908C85.3027 84.4635 85.3027 83.7433 85.7472 83.2989C86.1916 82.8544 86.9118 82.8544 87.3563 83.2989L89.586 85.5288L94.8507 80.2647C95.2951 79.8202 96.0153 79.8202 96.4598 80.2647C96.9044 80.7091 96.9041 81.4292 96.4597 81.8737Z"
            fill="#CF382F"
        />
        <path
            d="M142.31 86.3792H119.552C118.923 86.3792 118.414 85.8697 118.414 85.2413C118.414 84.6128 118.923 84.1033 119.552 84.1033H142.31C142.939 84.1033 143.448 84.6128 143.448 85.2413C143.448 85.8697 142.939 86.3792 142.31 86.3792Z"
            fill="#5E6273"
        />
        <path
            d="M115.001 86.3792H105.897C105.268 86.3792 104.759 85.8697 104.759 85.2413C104.759 84.6128 105.268 84.1033 105.897 84.1033H115.001C115.629 84.1033 116.139 84.6128 116.139 85.2413C116.139 85.8697 115.629 86.3792 115.001 86.3792Z"
            fill="#5E6273"
        />
        <path
            d="M146.862 90.931H140.034C139.406 90.931 138.896 90.4214 138.896 89.793C138.896 89.1646 139.406 88.655 140.034 88.655H146.862C147.49 88.655 148 89.1646 148 89.793C148 90.4216 147.49 90.931 146.862 90.931Z"
            fill="#C7CFE2"
        />
        <path
            d="M135.482 90.931H105.897C105.268 90.931 104.759 90.4214 104.759 89.793C104.759 89.1646 105.268 88.655 105.897 88.655H135.482C136.111 88.655 136.62 89.1646 136.62 89.793C136.62 90.4216 136.111 90.931 135.482 90.931Z"
            fill="#C7CFE2"
        />
        <path
            d="M130.931 104.586H107.034C105.778 104.586 104.759 103.567 104.759 102.31C104.759 101.053 105.778 100.034 107.034 100.034H130.931C132.188 100.034 133.207 101.053 133.207 102.31C133.207 103.567 132.188 104.586 130.931 104.586Z"
            fill="#CF382F"
        />
        <path
            d="M91.1035 98.8965C86.0758 98.8965 82 102.972 82 108C82 113.028 86.0758 117.103 91.1035 117.103C96.1312 117.103 100.207 113.028 100.207 108C100.207 102.972 96.1311 98.8965 91.1035 98.8965ZM96.4597 105.77L90.3905 111.839C90.1681 112.061 89.8772 112.172 89.586 112.172C89.2948 112.172 89.0036 112.061 88.7815 111.839L85.7472 108.804C85.3027 108.36 85.3027 107.64 85.7472 107.195C86.1916 106.751 86.9118 106.751 87.3563 107.195L89.5861 109.425L94.8508 104.161C95.2953 103.717 96.0155 103.717 96.4599 104.161C96.9045 104.605 96.9041 105.326 96.4597 105.77Z"
            fill="#CF382F"
        />
        <path
            d="M142.31 110.276H119.552C118.923 110.276 118.414 109.766 118.414 109.138C118.414 108.509 118.923 108 119.552 108H142.31C142.939 108 143.448 108.51 143.448 109.138C143.448 109.766 142.939 110.276 142.31 110.276Z"
            fill="#5E6273"
        />
        <path
            d="M115.001 110.276H105.897C105.268 110.276 104.759 109.766 104.759 109.138C104.759 108.509 105.268 108 105.897 108H115.001C115.629 108 116.139 108.509 116.139 109.138C116.139 109.766 115.629 110.276 115.001 110.276Z"
            fill="#5E6273"
        />
        <path
            d="M146.862 114.828H140.034C139.406 114.828 138.896 114.318 138.896 113.69C138.896 113.061 139.406 112.552 140.034 112.552H146.862C147.49 112.552 148 113.061 148 113.69C148 114.318 147.49 114.828 146.862 114.828Z"
            fill="#C7CFE2"
        />
        <path
            d="M135.482 114.828H105.897C105.268 114.828 104.759 114.318 104.759 113.69C104.759 113.061 105.268 112.552 105.897 112.552H135.482C136.111 112.552 136.62 113.061 136.62 113.69C136.62 114.318 136.111 114.828 135.482 114.828Z"
            fill="#C7CFE2"
        />
        <path
            d="M127.517 128.483H107.034C105.778 128.483 104.759 127.464 104.759 126.207C104.759 124.95 105.778 123.931 107.034 123.931H127.517C128.774 123.931 129.793 124.95 129.793 126.207C129.793 127.464 128.774 128.483 127.517 128.483Z"
            fill="#EFF2FA"
        />
        <path
            d="M91.1035 122.793C86.0758 122.793 82 126.869 82 131.897C82 136.924 86.0758 141 91.1035 141C96.1312 141 100.207 136.924 100.207 131.897C100.207 126.869 96.1311 122.793 91.1035 122.793Z"
            fill="#EFF2FA"
        />
        <path
            d="M142.31 134.172H119.552C118.923 134.172 118.414 133.663 118.414 133.034C118.414 132.406 118.923 131.896 119.552 131.896H142.31C142.939 131.896 143.448 132.406 143.448 133.034C143.448 133.663 142.939 134.172 142.31 134.172Z"
            fill="#AFB9D2"
        />
        <path
            d="M115.001 134.172H105.897C105.268 134.172 104.759 133.663 104.759 133.034C104.759 132.406 105.268 131.896 105.897 131.896H115.001C115.629 131.896 116.139 132.406 116.139 133.034C116.139 133.663 115.629 134.172 115.001 134.172Z"
            fill="#AFB9D2"
        />
        <path
            d="M146.862 138.724H140.034C139.406 138.724 138.896 138.215 138.896 137.586C138.896 136.958 139.406 136.448 140.034 136.448H146.862C147.49 136.448 148 136.958 148 137.586C148 138.215 147.49 138.724 146.862 138.724Z"
            fill="#C7CFE2"
        />
        <path
            d="M135.482 138.724H105.897C105.268 138.724 104.759 138.215 104.759 137.586C104.759 136.958 105.268 136.448 105.897 136.448H135.482C136.111 136.448 136.62 136.958 136.62 137.586C136.62 138.215 136.111 138.724 135.482 138.724Z"
            fill="#C7CFE2"
        />
        <path
            d="M93.7187 138.719H92.7187V139.719V145C92.7187 145.052 92.6977 145.107 92.6521 145.152C92.6065 145.198 92.5517 145.219 92.5 145.219C92.4482 145.219 92.3935 145.198 92.3479 145.152C92.3022 145.107 92.2812 145.052 92.2812 145V139.719V138.719H91.2812H86C85.9482 138.719 85.8935 138.698 85.8479 138.652C85.8022 138.607 85.7812 138.552 85.7812 138.5C85.7812 138.448 85.8022 138.394 85.8479 138.348C85.8935 138.303 85.9482 138.281 86 138.281H91.2812H92.2812V137.281V132C92.2812 131.948 92.3022 131.894 92.3479 131.848C92.3935 131.803 92.4482 131.781 92.5 131.781C92.5517 131.781 92.6065 131.803 92.6521 131.848C92.6977 131.894 92.7187 131.948 92.7187 132V137.281V138.281H93.7187H99C99.0517 138.281 99.1065 138.303 99.1521 138.348C99.1977 138.394 99.2187 138.448 99.2187 138.5C99.2187 138.552 99.1977 138.607 99.1521 138.652C99.1065 138.698 99.0517 138.719 99 138.719H93.7187ZM92.5 121.25C82.9939 121.25 75.25 128.994 75.25 138.5C75.25 148.006 82.9939 155.75 92.5 155.75C102.006 155.75 109.75 148.006 109.75 138.5C109.75 128.994 102.006 121.25 92.5 121.25Z"
            fill="#CF382F"
            stroke="white"
            stroke-width="2"
        />
        <defs>
            <filter
                id="filter0_d_992_2404"
                x="0"
                y="0"
                width="224"
                height="224"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood
                    flood-opacity="0"
                    result="BackgroundImageFix"
                />
                <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="10"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_992_2404"
                />
                <feOffset />
                <feGaussianBlur stdDeviation="12.5" />
                <feComposite
                    in2="hardAlpha"
                    operator="out"
                />
                <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0.95 0 0 0 0 0.95 0 0 0 0 0.95 0 0 0 1 0"
                />
                <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow_992_2404"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow_992_2404"
                    result="shape"
                />
            </filter>
        </defs>
    </svg>
</template>
