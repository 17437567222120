import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import PeopleIndexView from '@/views/dashboard/settings/people';
import PeopleCreateView from '@/views/dashboard/settings/people/create';
import PeopleEditView from '@/views/dashboard/settings/people/edit';

export default [
    {
        path: 'settings/people',
        name: SETTINGS_ROUTE_NAMES.PEOPLE.INDEX,
        component: PeopleIndexView,
    },
    {
        path: 'settings/people/invite',
        name: SETTINGS_ROUTE_NAMES.PEOPLE.CREATE,
        component: PeopleCreateView,
    },
    {
        path: 'settings/people/:accountId/edit',
        name: SETTINGS_ROUTE_NAMES.PEOPLE.EDIT,
        component: PeopleEditView,
        props: true,
    },
];
