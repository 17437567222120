<script>
export default {
    name: 'LayoutHeader',
};
</script>

<script setup>
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

import { isRouteLinkActive } from '@/utils/navigationUtils';
import { BADGE_TYPES } from '@/enums/componentsEnums';
import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';
import Badge from '@/components/Badge';
import DropDown from '@/components/DropDown';
import BellIcon from '@/components/icons/BellIcon';
import HamburgerIcon from '@/components/icons/HamburgerIcon';
import HeaderNavigation from './partials/HeaderNavigation';
import ReceivedNotifications from './partials/received-notifications';
import ChangeLocationModal from './partials/ChangeLocationModal';

const route = useRoute();
const store = useStore();

const { currentAccount } = store.state.auth;

/*------------------------------------------------------------------------
                           Change location state
------------------------------------------------------------------------*/

const isChangeLocationModalOpened = ref(false);

const openChangeLocationModal = () => (isChangeLocationModalOpened.value = true);
const closeChangeLocationModal = () => (isChangeLocationModalOpened.value = false);
</script>

<template>
    <header class="pl-layout-header">
        <div class="container">
            <slot name="ribbon" />

            <div class="pl-layout-header__top">
                <router-link :to="{ name: SETTINGS_ROUTE_NAMES.INDEX }">
                    <template #default="{ route: linkRoute }">
                        <button
                            type="button"
                            data-testid="hamburger_btn"
                            class="pl-layout-header__hamburger"
                            :class="{
                                'pl-layout-header__hamburger--active': isRouteLinkActive(route, linkRoute.path),
                            }"
                        >
                            <HamburgerIcon />
                        </button>
                    </template>
                </router-link>

                <p>
                    <span data-testid="top_bar_company_name">
                        {{ currentAccount.organization.name }}
                    </span>

                    <button
                        type="button"
                        data-testid="top_bar_current_location"
                        @click="openChangeLocationModal"
                    >
                        {{ currentAccount.location.name }}
                    </button>
                </p>

                <DropDown>
                    <template #invoker>
                        <span data-testid="open_received_notifications">
                            <transition
                                name="down"
                                type="transition"
                            >
                                <Badge
                                    v-if="currentAccount.unread_notifications_count"
                                    is-absolute
                                    size="sm"
                                    :type="BADGE_TYPES.ROUNDED"
                                >
                                    {{ currentAccount.unread_notifications_count }}
                                </Badge>
                            </transition>

                            <BellIcon />
                        </span>
                    </template>

                    <template #content>
                        <ReceivedNotifications />
                    </template>
                </DropDown>
            </div>

            <HeaderNavigation />
        </div>
    </header>

    <ChangeLocationModal
        v-if="isChangeLocationModalOpened"
        @close="closeChangeLocationModal"
    />
</template>

<style lang="scss" scoped>
.pl-layout-header {
    background-color: $white;

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: custom-space(1);
        padding: custom-space(0.75) 0;

        & > p {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            margin: 0;
            font-weight: $font-weight-normal;
            text-align: center;
            word-break: break-word;
            line-height: 1.25;

            & > button {
                color: $primary;
                text-decoration: underline;
            }
        }

        :deep(.pl-dropdown) > button .pl-badge {
            top: custom-space(-0.4);
            right: custom-space(-0.4);
            line-height: 1;
            z-index: 1;
        }
    }

    &__hamburger {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $click-space;
        border-radius: 50%;

        &,
        svg :deep(path) {
            @include pl-transition(all);
        }

        &:not(&--active) {
            margin-left: -$click-space;

            &:hover {
                background-color: $gray-100;

                svg :deep(path) {
                    stroke: $black;
                }
            }
        }

        &--active {
            background-color: $primary;

            svg :deep(path) {
                stroke: $white;
            }
        }
    }
}
</style>
