import { SETTINGS_ROUTE_NAMES } from '@/enums/routesNameEnums';

import PrepTasksIndexView from '@/views/dashboard/settings/prep-tasks/index';
import PrepTaskCreateView from '@/views/dashboard/settings/prep-tasks/create';
import PrepTaskEditView from '@/views/dashboard/settings/prep-tasks/edit';

export default [
    {
        path: 'settings/prep-tasks',
        name: SETTINGS_ROUTE_NAMES.PREP_TASKS.INDEX,
        component: PrepTasksIndexView,
    },
    {
        path: 'settings/prep-tasks/create',
        name: SETTINGS_ROUTE_NAMES.PREP_TASKS.CREATE,
        component: PrepTaskCreateView,
    },
    {
        path: 'settings/prep-tasks/:prepTaskId/edit',
        name: SETTINGS_ROUTE_NAMES.PREP_TASKS.EDIT,
        component: PrepTaskEditView,
        props: true,
    },
];
