<script>
export default {
    name: 'ShowOverproductionPLOrderView',
};
</script>

<script setup>
import { watch, reactive } from 'vue';
import { useStore } from 'vuex';

import { HTTP_RESPONSES_CODE } from '@/enums/httpEnums';
import { PL_ROUTE_NAMES } from '@/enums/routesNameEnums';
import { ALERT_TYPES } from '@/enums/componentsEnums';
import { ORDER_DEFAULT_GROUP_ITEMS_BY, OVERPRODUCTION_ORDER_GROUP_ITEMS_BY_OPTIONS } from '@/enums/orderEnums';
import PLOrderModel from '@/models/PLOrder';
import useAbortableRequest from '@/composition/useAbortableRequest';
import Alert from '@/components/Alert';
import DropDown from '@/components/DropDown';
import GroupedSettings from '@/components/constructions/grouped-settings';
import OrderSummaryCard from '@/components/model-specific/orders/order-summary-card';

const props = defineProps({
    orderId: {
        type: [Number, String],
        required: true,
    },
});

const store = useStore();

const { sendAbortableRequest } = useAbortableRequest();

const { currentAccount } = store.state.auth;
const orgId = currentAccount.organization.id;

/*------------------------------------------------------------------------
                                Order state
------------------------------------------------------------------------*/

const orderState = reactive({
    data: null,
    groupItemsBy: ORDER_DEFAULT_GROUP_ITEMS_BY,
    serverError: null,
    isDataLoading: true,
    isActionProcessing: false,
});

const loadOrder = () => {
    orderState.isDataLoading = true;

    sendAbortableRequest(
        PLOrderModel.find(orgId, props.orderId, {
            group_items_by: orderState.groupItemsBy,
        })
    )
        .then((model) => (orderState.data = model))
        .catch((error) => {
            const { status, data } = error.response || {};

            if (status === HTTP_RESPONSES_CODE.INTERNAL_SERVER_ERROR) {
                orderState.serverError = 'Order could not be loaded for some reason. Please try again later.';
            } else if (status !== undefined) {
                orderState.serverError = data.message;
            }
        })
        .finally(() => (orderState.isDataLoading = false));
};

watch(() => orderState.groupItemsBy, loadOrder, { immediate: true });
</script>

<template>
    <TopBarLayout :back-route="PL_ROUTE_NAMES.INDEX">
        <template #title>
            <h1>Order Details</h1>
        </template>

        <template
            v-if="orderState.data !== null"
            #actions
        >
            <DropDown>
                <template #content>
                    <GroupedSettings.Root>
                        <GroupedSettings.List title="Group items by">
                            <GroupedSettings.ListItem
                                v-for="(text, value) in OVERPRODUCTION_ORDER_GROUP_ITEMS_BY_OPTIONS"
                                :key="`group-items-by-option-${value}`"
                                :is-selected="orderState.groupItemsBy === value"
                                @click="orderState.groupItemsBy = value"
                            >
                                {{ text }}
                            </GroupedSettings.ListItem>
                        </GroupedSettings.List>
                    </GroupedSettings.Root>
                </template>
            </DropDown>
        </template>

        <InPlaceLoader v-if="orderState.isDataLoading" />

        <Alert
            v-else-if="orderState.serverError"
            :type="ALERT_TYPES.FAIL"
        >
            {{ orderState.serverError }}
        </Alert>

        <template v-else>
            <OverlayLoader
                v-if="orderState.isActionProcessing"
                enable-sticky
            />

            <OrderSummaryCard
                :order="orderState.data"
                :local-group-items-by="orderState.groupItemsBy"
            />
        </template>
    </TopBarLayout>
</template>
