<script>
export default {
    name: 'GlobalModal',
};
</script>

<script setup>
import { reactive } from 'vue';

import EventBus from '@/utils/EventBus';
import Modal from '@/components/Modal';

/*------------------------------------------------------------------------
                            Global modal state
------------------------------------------------------------------------*/

const globalModalState = reactive({
    title: null,
    message: null,
    isOpened: false,
});

const openGlobalModal = (data) => {
    globalModalState.title = data.title;
    globalModalState.message = data.message;
    globalModalState.isOpened = true;
};

const closeGlobalModal = () => {
    globalModalState.title = null;
    globalModalState.message = null;
    globalModalState.isOpened = false;
};

EventBus.$on('pl.global-modal.open', openGlobalModal);
</script>

<template>
    <Modal
        v-if="globalModalState.isOpened"
        is-content-centered
        @close="closeGlobalModal"
    >
        <template
            v-if="globalModalState.title"
            #title
        >
            {{ globalModalState.title }}
        </template>

        <template
            v-if="globalModalState.message"
            #content
        >
            <span>{{ globalModalState.message }}</span>
        </template>

        <template #actions>
            <button
                type="button"
                class="btn btn-primary"
                @click="closeGlobalModal"
            >
                Close
            </button>
        </template>
    </Modal>
</template>
